import React, { useState, useEffect } from "react";
import { Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { customErrorMessage, customMessage, SendSuccessResponse } from "../../helpers/response";
import url from '../../helpers/development.json';
import moment from 'moment';
import AxiosInstance from "../../helpers/AxiosRequest";
import { useSearchParams } from 'react-router-dom';
const opayoFormUrl = process.env.REACT_APP_OPAYO_FORM_URL;
const amountAnnual = process.env.REACT_APP_ANNUAL_AMOUNT;
const amountMonthly = process.env.REACT_APP_MONTHLY_AMOUNT;

const MembershipDetail = () => {
    const [membershipDetails, setMembershipDetails] = useState({});
    const [status, setStatus] = useState(true);
    const [updateModal, setUpdateModal] = useState(false);
    const [rejoinLoading, setRejoinLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [, setIsLoading] = useState(false)
    const lastEight = membershipDetails?._id ? membershipDetails?._id.slice(-10) : null;
    const [cancelModel, setCancelModel] = useState(false);
    const [rejoinModel, setRejoinModel] = useState(false);
    const [cryptField, setCryptField] = useState('');
    const [formUrl, setFormUrl] = useState(null);
    const [userDetails, setUserDetails] = useState('')
    const [params] = useSearchParams();
    const [, setTransactionId] = useState(null);
    const [, setFees] = useState(null);
    const [refund, setRefund] = useState("");
    const [monthDuration, setMonthDuration] = useState();

    useEffect(() => {
        getMembershipDetails()
        getUserDetails()
        setFormUrl(opayoFormUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (userDetails && membershipDetails) {
            verifyDetails()
        }
    }, [userDetails, membershipDetails])


    useEffect(() => {
        const decrypt = params.get('crypt') && params.get('crypt').substring(1);
        if (decrypt) {
            decryptData(decrypt)
        }
    }, [])

    useEffect(() => {
        getrefundCount()
    }, [])

    const getMembershipDetails = async () => {
        try {
            const response = await AxiosInstance.get(url.getMembershipDetails);
            if (response.status === 200 && response.data.statusText === "Success") {
                setMembershipDetails(response?.data?.data);
                setFees(response.data.data.fee)
                setStatus(response.data.data.status);
            } else {
                return false
            }
        } catch (error) {
            customErrorMessage("Something went wrong");
        }
    };

    const getUserDetails = async () => {
        try {
            const response = await AxiosInstance.get(url.getUserDetails);
            if (response.status === 200 && response.data.statusText === "Success") {
                setUserDetails(response.data.data);
            } else {
                return false
            }
        } catch (error) {
            customErrorMessage("Something went wrong");
        }
    };

    const getrefundCount = async () => {
        try {
            const response = await AxiosInstance.get(url.refundCount);
            if (response.status === 200 && response.data.statusText === "Success") {
                setRefund(response?.data);
                const findDurationAmount = response?.data?.data?.durationAmount / 150;
                setMonthDuration(findDurationAmount?.toFixed(2));
            } else {
                return false
            }
        } catch (error) {
            customErrorMessage("Something went wrong");
        }
    };


    const verifyDetails = async () => {
        const vendorTxCode = `subscriptionTransaction-${Math.floor(Math.random() * 100000)}`;
        const splitName = userDetails.name.split(" ");
        const crypt = `VendorData=Testing Data&Description=Membership subscription&Currency=GBP&VendorTxCode=${vendorTxCode}&amount=${membershipDetails?.fee}&BillingSurname=${splitName[1]}&BillingFirstnames=${splitName[0]}&BillingAddress1=${userDetails.address}&BillingCity=${userDetails.city}&VendorEMail=shubham.r@codiste.com&CustomerEMail=${userDetails.email}&BillingPostCode=${userDetails.pincode}&BillingCountry=GB&DeliverySurname=${splitName[1]}&DeliveryFirstnames=${splitName[0]}&DeliveryAddress1=${userDetails.address}&DeliveryCity=${userDetails.city}&DeliveryPostCode=${userDetails.pincode}&DeliveryCountry=GB&ApplyAVSCV2=1&Apply3DSecure=1&SuccessURL=https://meridiem.club/membership-detail&FailureURL=https://meridiem.club/membership-detail&CustomerEMail=${userDetails.email}&VendorEMail=support@meridiem.club`;
        try {
            const response = await AxiosInstance.post(url.cryptData, { crypt: crypt })
            setCryptField(response.data.data.ciphertext.toUpperCase())

        } catch (error) {
            console.log(error)
        }
    }

    const decryptData = async (crypt) => {
        try {
            const response = await AxiosInstance.post(url.decryptData, { crypt: crypt })
            const splitedArray = response.data.data.decryptedText.split("&");
            const findKeys = ["VPSTxId", "Status", "TxAuthNo", "3DSecureStatus", "Amount"];
            const transactionObj = {}
            findKeys.forEach((current) => {
                splitedArray.map((ele) => {
                    if (ele.includes(current)) {
                        return transactionObj[current] = ele.split('=')?.[1]
                    }
                })
            })
            const _transactionId = transactionObj.VPSTxId.substring(1, transactionObj.VPSTxId.length - 1)
            setTransactionId(_transactionId)
            if (transactionObj.Status === "OK") {
                const response = await AxiosInstance.post(url.updateCardDetails, {
                    payment_id: transactionObj.TxAuthNo,
                    payment_type: "Payment",
                    payment_mode: "Card",
                    plan_type: "Payment",
                    // fee: membershipDetails.fee,
                    tx_id: _transactionId,
                    amount: parseInt(transactionObj.Amount),
                    status: true,
                    subscription: userDetails.membership_type === "Annual" ? amountAnnual : amountMonthly,
                })
                if (response.status === 200 && response.data.statusText === "Success")
                    SendSuccessResponse("Card update successfully!");
                getMembershipDetails();
            } else {
                setIsLoading(false)
                customErrorMessage("Your card is not updated !");
            }
        } catch (error) {
            setIsLoading(false)
            customErrorMessage("Something went wrong!");
        }
    }

    const cancelMembership = async () => {
        try {
            setCancelLoading(true);
            const response = await AxiosInstance.post(url.cancelMembership);
            if (response.status === 200 && response.data.statusText === "Success") {
                customMessage("Membership Cancellation Initiated Successfully!");
                await getMembershipDetails();
                setCancelLoading(false);
            } else {
                customErrorMessage("Something went wrong!");
                setCancelLoading(false);
            }
        } catch (error) {
            throw error;
        }
    }

    const rejoinMembership = async () => {
        try {
            setRejoinLoading(true);
            const response = await AxiosInstance.post(url.rejoinMembership);
            if (response.status === 200 && response.data.statusText === "Success") {
                customMessage("Membership Rejoin Successfully!");
                await getMembershipDetails();
                setRejoinLoading(false);
            } else {
                customErrorMessage("Something went wrong!");
                setRejoinLoading(false);
            }
        } catch (error) {
            throw error;
        }
    }

    return (
        <>
            <div className="mt-4 mb-4 page_main membershipDetailPage">
                <Container fluid>
                    <Row className="">
                        <div className="col-md-12">
                            <div className="divBg_white">
                                <div className="my-1">
                                    <h6 className="headingMain mb-3 ms-1">Membership Details</h6>
                                </div>
                                <div className="p-4 bgLightBlue">
                                    <div className="d-flex flex-column gap-3 details">
                                        <div>
                                            <span className="me-2 title">Membership Type :</span>
                                            <span className="content">{membershipDetails?.subscription}</span>
                                        </div>
                                        <div>
                                            <span className="me-2 title">Membership Amount :</span>
                                            <span className="content">£{membershipDetails?.fee}</span>
                                        </div>
                                        <div>
                                            <span className="me-2 title">Member ID :</span>
                                            <span className="content">
                                                {lastEight}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="me-2 title">Date of Purchase :</span>
                                            <span className="content">
                                                {moment(membershipDetails?.purchase_date).format("DD MMMM YYYY")}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="me-2 title">Date of Expire :</span>
                                            <span className="content">
                                                {moment(membershipDetails?.expiry_date).format("DD MMMM YYYY")}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="me-2 title">Payment Mode :</span>
                                            <span className="content">
                                                Credit Card (********){" "}
                                                <span
                                                    className="text-decoration-underline"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => setUpdateModal(true)}>
                                                    Card Update
                                                </span>
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center gap-4 flex-wrap mt-2">
                                            <button
                                                type="button"
                                                className="btn_Orange"
                                                onClick={() => setRejoinModel(true)}
                                                style={{ cursor: `${status || rejoinLoading ? "default" : "pointer"}` }}
                                                disabled={status || rejoinLoading}>
                                                {rejoinLoading ?
                                                    <>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                                    </> : ''
                                                }
                                                {rejoinLoading ? 'loading...' : ' Rejoin Membership'}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn_Black btnCancel"
                                                style={{ cursor: `${!status || cancelLoading ? "default" : "pointer"}` }}
                                                onClick={() => setCancelModel(true)}
                                                disabled={!status || cancelLoading} >
                                                {cancelLoading ?
                                                    <>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                                    </> : ''
                                                }
                                                {cancelLoading ? 'loading...' : 'Cancel Membership'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>


            <Modal show={updateModal} onHide={() => setUpdateModal(false)} size="lg" centered>
                <Form id="paymentOpayoForm" action={formUrl} method="post" onSubmit={verifyDetails} className="loginForm claimForm">
                    <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center">
                        <div className="p-5">
                            <h5 className="mb-4">Please note we will charge you according  your subscription & we will use this new card for further payment </h5>
                            <input type="hidden" name="VPSProtocol" value="3.00" />
                            <input type="hidden" name="TxType" value="PAYMENT" />
                            <input type="hidden" name="Vendor" value="beirisoltd" />
                            <input type="hidden" name="Crypt" value={`@${cryptField}`} />

                            <button type="submit" className="btn_Orange lm ms-2" >
                                Ok
                            </button>
                            <button onClick={() => setUpdateModal(false)} type="button" className="btn_White lm" style={{ marginLeft: 20 }}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </Modal>

            <Modal
                show={cancelModel}
                onHide={() => setCancelModel(false)}
                size="lg"
                centered
            >
                <Form className="loginForm claimForm">
                    <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center">
                        <div className="p-5">
                            <h5 className="mb-4">Are you sure you want to Cancel Membership?</h5>
                            <div>
                                {userDetails?.membership_type === "Annual" && (
                                    <div>
                                        <p> You have been charged for amount: {refund?.data ? refund.data?.durationAmount : ''}£</p>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Monthly Amount</th>
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                                <tr>
                                                    <td>150£</td>
                                                    <td>{monthDuration} Month</td>
                                                    <td>150 x {refund?.data?.durationAmount / 150} = {refund?.data?.durationAmount}£</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <strong>Your refund amount : {refund?.data ? refund?.data?.totalRefundAmount : ''}£</strong>
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                )}

                                <div className="">
                                    <button type="button" className="btn_Orange lm ms-2" onClick={() => { cancelMembership(); setCancelModel(false); }}>
                                        {cancelLoading ?
                                            <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                            </> : ''
                                        }
                                        {cancelLoading ? 'loading...' : 'Yes'}
                                    </button>

                                    <button onClick={() => setCancelModel(false)} type="button" className="btn_White lm" style={{ marginLeft: 20 }}>
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>

            <Modal
                show={rejoinModel}
                onHide={() => setRejoinModel(false)}
                size="lg"
                centered
            >
                <Form className="loginForm claimForm">
                    <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center">
                        <div className="p-5">
                            <h5 className="mb-4">Are you sure you want to Rejoin Membership?</h5>

                            <div className="">
                                <button type="button" className="btn_Orange lm ms-2" onClick={() => { rejoinMembership(); setRejoinModel(false); }}>
                                    {cancelLoading ?
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                        </> : ''
                                    }
                                    {cancelLoading ? 'loading...' : 'Yes'}
                                </button>

                                <button onClick={() => setRejoinModel(false)} type="button" className="btn_White lm" style={{ marginLeft: 20 }}>
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    );
};


export default MembershipDetail;
