import React from "react";
import { Container, Row, Accordion, Breadcrumb } from "react-bootstrap";
import Footer from "../publicPages/common/Footer";
import Header from "../publicPages/common/Header";

const Faq1 = () => {
  const isLogin = localStorage.getItem("accessToken");
  return (
      <>
      <Header />
      <div className="mt-4 mb-4 page_main faqPage">
        <Container fluid>
          <h1>
            Frequently Asked Questions
            <span className="font14Blk"></span>
          </h1>

          <Row className="">
            <div className="col-md-12">
              <div className="divBg_white">
                <Breadcrumb>
                  <Breadcrumb.Item href="/update-profile">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
                </Breadcrumb>

                <div className="Customaccordion">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="m-0">
                        How to create an opportunity and seek funding for a
                        project.
                      </Accordion.Header>
                      <Accordion.Body>
                        Once a member you can access the online platform and
                        seek funding for an investment raise. By clicking the
                        seek funding link on the main dashboard a form will be
                        provided to enter the investment opportunity’s details.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="m-0">
                        What is an investment round
                      </Accordion.Header>
                      <Accordion.Body>
                        Investment rounds are the staged processes of the
                        collective scheme.
                        <br></br>
                        Members can view the listed investment opportunities
                        first, if they are happy with the list, they can invest
                        and vote. The option with the highest votes from the
                        list will receive the funds in the treasury, regardless
                        of what your vote was. If you are not keen on any of the
                        options listed you can choose not to participate in that
                        investment round and wait for another investment round
                        to go live on the platform.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header className="m-0">
                        Why are you using polygon blockchain
                      </Accordion.Header>
                      <Accordion.Body>
                        Because it is an EVM based smart contract platform that
                        can process transactions and other data similar to that
                        of smart contracts on the Ethereum network. Maintaining
                        security with reduced gas fees in comparison to other
                        blockchain platforms.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header className="m-0">
                        What is the e-community wallet
                      </Accordion.Header>
                      <Accordion.Body>
                        The e-community wallet is the treasury that sits on the
                        blockchain, the details of deposit amount and
                        transactions are all recorded and available for members
                        to view. The 5% of the collective dividend from
                        investment rounds will be transferred into the
                        e-community wallet where they will be distributed to
                        Mcoin holders. (read our terms and conditions)
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header className="m-0">
                        What is the mcoin
                      </Accordion.Header>
                      <Accordion.Body>
                        The Mcoin is minted during a successful purchase of an
                        asset or subscription of shares. The purpose of the
                        Mcoin is to bring an increased mutual benefit to all
                        members regardless of their original investment. Please
                        view Mcoin for further information.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header className="m-0">
                        What is the investment treasury
                      </Accordion.Header>
                      <Accordion.Body>
                        The treasury is a secure wallet that holds the investors
                        contribution during an investment raise. The treasury is
                        also visible to all members.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header className="m-0">
                        Who manages the investment treasury
                      </Accordion.Header>
                      <Accordion.Body>
                        The administrative staff manage the treasury; however,
                        the members can view the treasury transaction and
                        information.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header className="m-0">
                        Who manages the e-community wallet
                      </Accordion.Header>
                      <Accordion.Body>
                        Smart contracts on the blockchain manage the e-community
                        wallet, this includes dividend pay outs from the
                        e-community wallet.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header className="m-0">
                        Who owns the platform
                      </Accordion.Header>
                      <Accordion.Body>
                        The platform is owned by the investors via the meridiem
                        nominee ltd. Once a member sale their shares, mcoins and
                        deletes their online account and membership, they will
                        no longer have ownership.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header className="m-0">
                        How do I edit my profile
                      </Accordion.Header>
                      <Accordion.Body>
                        On the top right-hand corner of your dashboard a
                        clickable person icon will provide you with the option
                        to view your profile, use the edit button to edit your
                        details.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                      <Accordion.Header className="m-0">
                        What is upcoming stage
                      </Accordion.Header>
                      <Accordion.Body>
                        The investment round is processed in consecutive stages
                        on the platform. Each stage comes with a time line.
                        Upcoming is the first stage of the process, where the
                        members can view the opportunities listed in order to
                        decide whether they wish to participate in investing and
                        voting.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                      <Accordion.Header className="m-0">
                        What is raise stage
                      </Accordion.Header>
                      <Accordion.Body>
                        The investment round is processed in consecutive stages
                        on the platform. Each stage comes with a time line. The
                        raise is the second stage of the process, where the
                        members contribute towards the investment treasury and
                        vote in the next stage. Contributions are considered as
                        an agreed commitment to proceed with the investment
                        purchase. Should the minimum target not be met or
                        investor terms not agreed, then investors will
                        automatically have their contributions returned to their
                        personal wallet.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12">
                      <Accordion.Header className="m-0">
                        What is vote stage
                      </Accordion.Header>
                      <Accordion.Body>
                        The investment round is processed in consecutive stages
                        on the platform. Each stage comes with a time line. The
                        voting is the third stage of the process, where the
                        members who had contributed in the raise stage can now
                        cast their votes on which opportunity, they believe is
                        the best for the collective to invest in.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                      <Accordion.Header className="m-0">
                        What is result stage
                      </Accordion.Header>
                      <Accordion.Body>
                        The investment round is processed in consecutive stages
                        on the platform. Each stage comes with a time line. The
                        result stage is the fourth and last stage of the
                        process, where all members can view the successful
                        opportunity with the most votes casted by the
                        participants.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                      <Accordion.Header className="m-0">
                        How are investment terms agreed?
                      </Accordion.Header>
                      <Accordion.Body>
                        Meridiem manages the communication between the entity
                        and the investors. Once the investors agree the terms,
                        Meridiem will move the funds from the investment
                        treasury to purchase the asset. <br></br>Meridiem Nominee Ltd
                        will be the nominee shareholder for the shares and
                        assets purchased.{" "}
                        <span className="fw-bold">
                          Ownership and benefits belong to the investors.
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                      <Accordion.Header className="m-0">
                        How do I change password
                      </Accordion.Header>
                      <Accordion.Body>
                        In the dashboard in the top right corner there is a
                        personal icon, if you click on the icon, it will list
                        the reset password option. If you click on the link and
                        follow the instructions, you will be able to reset your
                        password.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="16">
                      <Accordion.Header className="m-0">
                        Why is meridiem the nominee shareholder?
                      </Accordion.Header>
                      <Accordion.Body>
                        Due to the volume of different shareholders, members and
                        assets, it is a much easier and quicker process that
                        meridiem is the nominee shareholder. There is
                        contractual agreement placed between meridiem and the
                        investor, there are also other benefits such as members
                        not wishing to be in public view on companies’ house for
                        shareholding. To find out more please review the
                        <span className="text-decoration-underline">
                          nominee page.
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="17">
                      <Accordion.Header className="m-0">
                        What is the asset portfolio
                      </Accordion.Header>
                      <Accordion.Body>
                        The asset portfolio in the dashboard displays all the
                        successful assets accumulated on the platform via the
                        investment rounds.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="18">
                      <Accordion.Header className="m-0">
                        What is my investment
                      </Accordion.Header>
                      <Accordion.Body>
                        My investment in the dashboard displays your personal
                        investments made via the investment scheme.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="19">
                      <Accordion.Header className="m-0">
                        When do the m coins pay the yearly income
                      </Accordion.Header>
                      <Accordion.Body>
                        Mcoin holders will receive a yearly dividend once there
                        are accumulated funds in the investment treasury.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="20">
                      <Accordion.Header className="m-0">
                        How do I sell my mcoins
                      </Accordion.Header>
                      <Accordion.Body>
                        On the dashboard there is a link under the Mcoin display
                        area, there is a sell link click on the link to enter
                        the quantity of Mcoins you wish to sell. The price is
                        predetermined and valued every April 01<sup>st</sup>.
                        The price is valued at x2 of the accumulated dividend in
                        the e-community wallet.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="21">
                      <Accordion.Header className="m-0">
                        How do I buy more Mcoins
                      </Accordion.Header>
                      <Accordion.Body>
                        On the dashboard there is a link under the Mcoin display
                        area, a link that says buy, once you click on that link
                        there will be listed option of Mcoin and you can
                        purchase from Mcoins have been listed by users.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="22">
                      <Accordion.Header className="m-0">
                        What is “exit investment rounds”
                      </Accordion.Header>
                      <Accordion.Body>
                        Exit investment round allows a member to propose to
                        other investors, to sell its asset from the collective
                        asset portfolio. The proposal will be listed to be
                        viewed by the investors. Only investors who had invested
                        in that round can cast their vote. If the majority is
                        yes then Meridiem will be engaged to sell the asset on
                        behalf of the investors.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="23">
                      <Accordion.Header className="m-0">
                        How do I exit an investment round
                      </Accordion.Header>
                      <Accordion.Body>
                        Dashboard, click the exit investment link and choose
                        which of the investment rounds from the list you wish to
                        exit.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="24">
                      <Accordion.Header className="m-0">
                        What happens to my Mcoins if I cancel my membership
                      </Accordion.Header>
                      <Accordion.Body>
                        The membership is recurring either yearly or monthly, if
                        you decide to cancel or no longer renew your membership,
                        you will not be able to access the physical clubs but
                        you can still access the online dashboard. Therefore,
                        you can still access your Mcoin, and receive dividends
                        and other due payments. Please review our refund policy
                        for further information on refunds and cancellations.
                        <br></br>
                        You can sell your Mcoins or assets to other members via
                        the platform if you wish to exit completely and close
                        your account.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="25">
                      <Accordion.Header className="m-0">
                        What happens if I do not renew my membership
                      </Accordion.Header>
                      <Accordion.Body>
                        Cancelling your membership only cancels your access to
                        the physical clubs, but does not affect your membership
                        online, you can still have access to the dashboard.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="26">
                      <Accordion.Header className="m-0">
                        What is peer to peer asset transfer
                      </Accordion.Header>
                      <Accordion.Body>
                        A peer-to-peer asset transfer is where you can sell your
                        portion of the asset or shares that you hold to another
                        member. You can sell your portion via the dashboard, my
                        investment tab, peer to peer transfer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="27">
                      <Accordion.Header className="m-0">
                        How do I sell my shares
                      </Accordion.Header>
                      <Accordion.Body>
                        Dashboard, my investment, peer to peer transfer link.
                        This will notify all members that you wish to sell your
                        personal portion of an asset.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="28">
                      <Accordion.Header className="m-0">
                        How do I get in touch with other members
                      </Accordion.Header>
                      <Accordion.Body>
                        You can connect via the online platform or via the
                        physical clubs. Our clubs are intimate venues. When
                        there is an investment round live, we invite all
                        members, both investors and the representative of the
                        opportunity to our physical clubs to present and discuss
                        the opportunities listed. You will have the opportunity
                        to meet other members during these events.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="29">
                      <Accordion.Header className="m-0">
                        What is connect wallet
                      </Accordion.Header>
                      <Accordion.Body></Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="30">
                      <Accordion.Header className="m-0">
                        What is labour pool
                      </Accordion.Header>
                      <Accordion.Body>
                        The labour pool lists both work opportunities for
                        members to view, and employers can post their vacancies.
                        Members can also create a profile of their services to
                        offer or their credentials for potential recruitment.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="31">
                      <Accordion.Header className="m-0">
                        What is the difference between community and personal in
                        the Mcoin section of the dashboard.
                      </Accordion.Header>
                      <Accordion.Body>
                        The Mcoin community displays the total dividend in the
                        ecommunity wallet and total Mcoins in circulation. The
                        personal displays the quantity of Mcoins you are
                        personally holding and the amount of dividend you will
                        be receiving from the Mcoin.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="32">
                      <Accordion.Header className="m-0">
                        How will I receive my dividend
                      </Accordion.Header>
                      <Accordion.Body>
                        Once the asset pay-outs dividend to Meridiem’s client
                        account, Meridiem will convert these to stable coins and
                        transfer them to the blockchain and the members will
                        receive funds automatically to their wallet.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="33">
                      <Accordion.Header className="m-0">
                        Who owns the shares if Meridiem is the nominee?
                      </Accordion.Header>
                      <Accordion.Body>
                        The members own their shares, Meridem has no ownership
                        or rights over the assets, Meridiem is managing them on
                        the members behalf.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="34">
                      <Accordion.Header className="m-0">
                        How do I upload my profile for candidate listing
                      </Accordion.Header>
                      <Accordion.Body>
                        Dashboard, labour pool, upload your profile.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="35">
                      <Accordion.Header className="m-0">
                        What is candidate listing
                      </Accordion.Header>
                      <Accordion.Body>
                        It is a listing of members who have uploaded their
                        profile, qualifications and services that they can
                        offer. You can view the listing via dashboard, labour
                        pool.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="36">
                      <Accordion.Header className="m-0">
                        How do I book for a restaurant?
                      </Accordion.Header>
                      <Accordion.Body>
                        Dashboard, reservations, choose the executive club, time
                        and date, and number of guests.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="37">
                      <Accordion.Header className="m-0">
                        What does notify members check box do?
                      </Accordion.Header>
                      <Accordion.Body>
                        If you check the notify members upon reserving your
                        booking at one of our executive clubs, all members will
                        be notified that you may be attending a club. It will
                        always be unchecked by default, if you leave it
                        unchecked then a notification will <strong>not</strong> be sent.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="38">
                      <Accordion.Header className="m-0">
                        Who can join Meridiem
                      </Accordion.Header>
                      <Accordion.Body>
                        The applicant needs to be over 18years and have
                        experience in investing in their own business or other
                        businesses. Entrepreneurs and Venture capitalists.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="39">
                      <Accordion.Header className="m-0">
                        Is Meridiem authorised with the fca?
                      </Accordion.Header>
                      <Accordion.Body>
                        No Meridiem is not required to be FCA regulated. We do
                        not need to be regulated because we do not make any
                        commission or revenue from the investments made on their
                        platform, therefore we are not required to be regulated.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="40">
                      <Accordion.Header className="m-0">
                        Forget password
                      </Accordion.Header>
                      <Accordion.Body>
                        If you forget your password, you can use the forget your
                        password link
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="41">
                      <Accordion.Header className="m-0">
                        How much can I contribute to each investment round
                      </Accordion.Header>
                      <Accordion.Body>
                        There is a minimum of £500 but currently there is no
                        maximum amount set for you to contribute.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="42">
                      <Accordion.Header className="m-0">
                        What are the fees meridiem charge
                      </Accordion.Header>
                      <Accordion.Body>
                        Meridiem currently only charges in three areas and that
                        is the yearly membership for access to the physical
                        venues, a percentage of 2% of the sale of an assets
                        after the investment if Meridiem arranges the sale of
                        the assets for the members, and from the food and
                        beverage of the sales in the executive clubs.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="43">
                      <Accordion.Header className="m-0">
                        What happens if the minimum target is not reached
                      </Accordion.Header>
                      <Accordion.Body>
                        If the treasury does not manage to obtain all the funds
                        required to hit the minimum target, the investment will
                        not continue and all members will be refunded their
                        contribution straight back to the personal wallets and
                        an email will be sent to them to notify them.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="44">
                      <Accordion.Header className="m-0">
                        Does overfunding affect the amount of equity available
                        to the members?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, it does, members will receive a higher equity
                        amount for the higher funds raised.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="45">
                      <Accordion.Header className="m-0">
                        What happens after the share certificates have been
                        issued?
                      </Accordion.Header>
                      <Accordion.Body>
                        Meridiem will start the negotiation process with the
                        opportunity with the most votes, and once both members
                        and the opportunity come to a mutual agreement on terms,
                        Meridiem will instruct their legal team to proceed with
                        the administrative work on the purchase of the asset.
                        The fees for this service will be taken from the raise.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="46">
                      <Accordion.Header className="m-0">
                        Can I sell or transfer my shares or bonds?
                      </Accordion.Header>
                      <Accordion.Body>
                        You can use the peer to peer transfer on the platform.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="47">
                      <Accordion.Header className="m-0">
                        How do I make a return on my investment?
                      </Accordion.Header>
                      <Accordion.Body>
                        The best way to make a return on your investment is once
                        the company value goes up you can sell your shares for a
                        higher amount then you paid, alternatively you hold onto
                        the shares with the hope that the company will pay a
                        dividend eventually and the value will keep going up.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="48">
                      <Accordion.Header className="m-0">
                        Dividend paid via blockchain into wallet.
                      </Accordion.Header>
                      <Accordion.Body>
                        Meridiem will convert the dividend into stable coins and
                        transfer it straight to your personal wallet.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="49">
                      <Accordion.Header className="m-0">
                        What happens to my funds if a vote wins that I did not
                        vote for.
                      </Accordion.Header>
                      <Accordion.Body>
                        If you contributed to an investment round and voted for
                        an opportunity but your vote was not the winning one,
                        then your funds will be assigned to the majority vote.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="50">
                      <Accordion.Header className="m-0">
                        How to make an investment
                      </Accordion.Header>
                      <Accordion.Body>
                        Once you are a member, you can view the investment
                        rounds on the dashboard and if there are any opportunity
                        that you like, you can wait for the investment round to
                        go to raise and from there you can deposit funds towards
                        the investment treasury.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="51">
                      <Accordion.Header className="m-0">
                        How do I retrieve my dividend
                      </Accordion.Header>
                      <Accordion.Body>
                        Once your asset pays a dividend, the dividend will be
                        transferred directly to your personal wallet.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="52">
                      <Accordion.Header className="m-0">
                        How do I retrieve capital gains from a sale
                      </Accordion.Header>
                      <Accordion.Body>
                        straight to your wallet as soon as the sale is
                        successful.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="53">
                      <Accordion.Header className="m-0">
                        What about tax?
                      </Accordion.Header>
                      <Accordion.Body>
                        Capital gain tax maybe applicable but it is best to seek
                        advice from your accountant or financial adviser.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="54">
                      <Accordion.Header className="m-0">
                        What type of members are accepted?
                      </Accordion.Header>
                      <Accordion.Body>
                        These clubs are for experienced investors or persons who
                        have experience in the financial industry. The criteria
                        include persons who have invested in their own business
                        or other businesses. There is no maximum or minimum
                        investment criteria, the only criteria are that
                        potential members have experience and knowledge of
                        investing or the financial industry (banking,
                        accounting, private equity, wealth advisers, fund
                        managers). In addition, persons who have entrepreneurial
                        experience, and venture capitalist.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="55">
                      <Accordion.Header className="m-0">
                        What is the MCoin economics
                      </Accordion.Header>
                      <Accordion.Body>
                        The mcoin economics is driven by businesses or assets
                        that produce a dividend, therefore it is of fundamental
                        importance that the businesses invested in are profit
                        focused.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="56">
                      <Accordion.Header className="m-0">
                        What is the asset value increase
                      </Accordion.Header>
                      <Accordion.Body>
                        The asset value increase is an indication of how much
                        your portion of the asset has increased benched marked
                        against your original investment. You can view the
                        yearly percentage increases or decreases for any of your
                        assets in the dashboard.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
     <Footer /> 
      </>

  );
};

export default Faq1;
