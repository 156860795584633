import React, { useEffect } from 'react';
import { useLocation } from "react-router";
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Header from "./common/Header";
import Slide1 from "./assets/images/slide1.png";
import Footer from './common/Footer';

const Holborn = () => {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    return (
        <>
            <Header />

            <div className='middlePart'>

                {/* Slider */}
                <div className='carouserCustom'>
                    <img className="d-none d-md-block w-100" src={Slide1} alt="First slide" />
                </div>

                {/* Holborn Content */}
                <div className='holbornContent'>
                    <div className='container'>
                        <h1 className='font45Blk text-center' style={{marginBottom: "0.5em", fontSize: "48px" }}>Holborn</h1>
                        <p className='font40Blk text-center mb-0'>The importance of maintaining and <br /> building  a trusted relationship amongst <br /> our members is  promoted through our <br /> executive  clubs; as a  social forum for <br />  our members. The very first   of our clubs <br /> is in Holborn, where you can relax  and enjoy <br />  this hidden gem in the heart of central London.</p>
                    </div>
                </div>

                {/* Section Lounges */}
                <div className='loungesPart'>
                    <div className='row g-0 align-items-stretch h-100'>
                        <div className='col-12 col-lg-6 loungesImg order-last order-lg-first'>
                            {/* <img src={loungesImg} className="w-100" /> */}
                        </div>
                        <div className='col-12 col-lg-6 order-first order-lg-last'>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8'>
                                <div className='content'>
                                    <h2 className='fontBlack34 text-start'>LOUNGES AND<br/>WORKSPACES</h2>
                                    <p className='font18Black'>Our venues are designed to cater to an evening of lounging or a productive day of working, for days that you just want a break from the office or home.</p>
                                    <div className='d-table mt-5'>
                                        <Nav.Link as={Link} className='btn siteBlackBtn' to={"/enquire"}>ENQUIRE</Nav.Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Meeting */}
                <div className='meetingPart'>
                    <div className='row g-0 align-items-stretch h-100'>
                        <div className='col-12 col-lg-6 d-flex justify-content-center'>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8'>
                                <div className='content'>
                                    <h2 className='fontBlack34 text-start'>MEETING ROOMS</h2>
                                    <p className='font18Black'>If you need a bit more room to collaborate or hold a discussion, why not use one of our meeting rooms</p>
                                    <p className='font18Black'>If you require a meeting room please contact our events team in advance to prebook our space.</p>
                                    <div className='d-table mt-5'>
                                        <Nav.Link as={Link} className='btn siteBlackBtn' to={"/enquire"}>Book now</Nav.Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 meetingRoomImg'>
                            {/* <img src={meetingRoomImg} className="w-100" /> */}
                        </div>
                    </div>
                </div>

                {/* Section BAR & RESTAURANT */}
                <div className='restaurantPart'>
                    <div className='row g-0 align-items-stretch h-100'>
                        <div className='col-12 col-lg-6 restaurantImg order-last order-lg-first'>
                            {/* <img src={restaurantImg} className="w-100" /> */}
                        </div>
                        <div className='col-12 col-lg-6 order-first order-lg-last'>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8'>
                                <div className='content'>
                                    <h2 className='fontBlack34 text-start'>BAR & RESTAURANT</h2>
                                    <p className='font18Black'>Enjoy our full bar with great cocktails and extensive wine list.</p>
                                    <p className='font18Black'>Each of our venue’s offers a different menu for breakfast, lunch or dinner.</p>
                                    <div className='d-table mt-5'>
                                        <Nav.Link as={Link} className='btn siteBlackBtn' to={"/food-and-beverage"}>Menu</Nav.Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Last BlankPart */}
                <div className='lastBG'></div>



            </div>

            <Footer />



        </>
    )
}

export default Holborn