import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LogoBig from "./assets/images/logoBig.png";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import AxiosInstance from "../../helpers/AxiosRequest";
import url from "../../helpers/development.json";
import { customErrorMessage, customMessage } from "../../helpers/response";

const ForgotPassword = () => {
    const [isLoading, ] = useState(false);
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        sessionStorage.removeItem("timer");
        sessionStorage.removeItem("resent");
    }, [])
    

    const onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const response = await AxiosInstance.post(url.forgotPassword, { email: values.email });
            if(response.status === 200 && response.data.statusText === "Success") {
                customMessage("We sent a password reset link to " + values.email);
                navigate(`/check-email/${response.data.data.token}`);
            } else {
                customErrorMessage(response?.data?.message);
            }
        }
    }

    return (
        <>
            <div className="login_parent">
                <Container fluid>
                    <Row className="height100vh_">
                    <Col
                            lg={6}
                            md={12}
                            sm={12}
                            className="p-0 d-none d-md-none d-lg-block"
                        >
                            <div className="leftSlider">
                                <div className="logo text-center">
                                    <img src={LogoBig} alt="" />
                                </div>
                                <div className="d-flex align-itmes-center flex-column contentSlide">
                                    <div className="bottomContent text-center">
                                        <p>
                                            Collective investment schemes on the blockchain.<br />
                                            A Private members club in the heart of Holborn.
                                        </p>
                                        <h4>
                                            A trusted society of investors,<br />entrepreneurs and venture capitalist.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="p-0">
                            <div className="rightForm">
                                <div className="d-flex flex-column justify-content-center col-10 col-xl-8 mx-auto h-100 gap80">
                                    <h4 className="loginTitle">Forgot Password</h4>
                                    <div className="formGuide text-center">
                                        Enter the email associated with your account and we'll<br />
                                        send an email with instructions to reset your password.
                                    </div>
                                    <Form
                                        noValidate
                                        className="loginForm"
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form.Group
                                            className="mb-5 inputOuter_"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="defLabel_">Email ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Your Email ID"
                                                className="inputs defInput_"
                                                name="email"
                                                required
                                                onChange={onFormChange}
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Button type="submit" className='btn_Black'>
                                            {isLoading ? <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                            </>
                                                : ''
                                            }
                                            {isLoading ? 'loading...' : 'Submit'}
                                        </Button>

                                        <div className="text-center registerNow mt-4">
                                            <NavLink to="/login" className="linkBlk">
                                                Back to login
                                            </NavLink>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default ForgotPassword;