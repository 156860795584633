import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
const unAuthRoutes = [
    "/AboutUs", "/farringdon", "/membership-page", "/terms-and-conditions", "/privacy-policy", "/food-and-beverage", "/enquire", "/holborn", "/",
]

const AddBG = () => {
    const location = useLocation()
    useEffect(() => {
        const custBody = document.getElementById("custBody");
        if (unAuthRoutes.includes(location.pathname)) {
            custBody?.classList?.add("custBody");
            custBody?.classList?.remove("custBodyAuth");

        } else {
            custBody?.classList?.add("custBodyAuth");
            custBody?.classList?.remove("custBody");

        }
    },[location])
}

export default AddBG