import React, { useState, useEffect, useMemo } from "react";
import {
    Container,
    Col,
    Table,
    Spinner,
    Breadcrumb,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDividendList } from "../../../store/user/action";
import PaginationComponent from "../../common/PaginationComponent";
import moment from "moment";

const DividendList = () => {
    const dispatch = useDispatch();

    // const user = useMemo(() => localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null, [])
    const { userDividendList } = useSelector((state) => state.userStore);

    const initFilter = { page: 1, limit: 10 }
    const [filter, setFilter] = useState(initFilter);
    const totalPages = useMemo(() => {
        return Math.ceil((userDividendList?.metaData?.total / filter.limit) || 1)
    }, [userDividendList, filter.limit]);

    useEffect(() => {
        dispatch(getUserDividendList({ payload: filter }))
    }, [dispatch, filter])

    return (
        <>
            <div className="mt-4 mb-4 page_main dashboard">
                <Container fluid>
                    <h1>
                        My Dividends
                        <span className="font14Blk"></span>
                    </h1>
                    <div className="divBg_white mb-3 ">
                        <div>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>My Dividends</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Col md={12} lg={12}>
                            <div className="">
                                {userDividendList.loading ?
                                    <div className="d-flex align-items-center justify-content-center py-5 my-5 h-100 w-100">
                                        <Spinner size="md" variant="dark" />
                                    </div> :
                                    <div className="tableSM">
                                        <Table responsive striped borderless>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Investment Title</th>
                                                    <th className="text-center">Opportunity Title</th>
                                                    <th className="text-center">Invested Amount</th>
                                                    <th className="text-center">Dividend Amount</th>
                                                    <th className="text-center">Dividend List</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userDividendList.data.length === 0 ?
                                                    <tr>
                                                        <th className="text-center py-5" colSpan={5}>No Dividends Found!</th>
                                                    </tr> :
                                                    userDividendList.data?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text-center">{item?.investment?.investment_Title}</td>
                                                            <td className="text-center">{item?.opportunity?.investment_title}</td>
                                                            <td className="text-center">{item.paymentLog.amount}</td>
                                                            <td className="text-center">{item.dividend_amount}</td>
                                                            <td className="text-center">{moment(item?.investmentdividends?.dividend_date).format("MMMM DD, YYYY hh:mm A")}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                        <PaginationComponent
                                            selectedPage={filter.page}
                                            handlePageChange={p => setFilter(prev => ({ ...prev, page: p }))}
                                            totalPages={totalPages}
                                        />
                                    </div>
                                }
                            </div>
                        </Col>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default DividendList;
