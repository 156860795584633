import React, { useEffect, useState } from "react";
import { Container, NavLink, Form, Breadcrumb, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../helpers/AxiosRequest";
import { toast } from "react-toastify";
import { customErrorMessage } from "../../helpers/response";
import url from '../../helpers/development.json';
import { getPerMCoinPrice } from "../../store/user/action";
import { useDispatch, useSelector } from "react-redux";
import { handleNValue } from "../../helpers/handleNValue";

const SellcoinPage = () => {
  const [alertMessage, setalertMessage] = useState(false);
  const [UserCoins, setUserCoins] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch();
  const { perMCoinPrice } = useSelector((state) => state.userStore);



  const schema = Yup.object({
    coin: Yup.number().typeError('Please add numeric value').min(1, "Amount must be greater than 0").test("A", "You don't have enough M-Coin!", val => Number(val || 0) <= Number(UserCoins || 0)).required("This field is required.")
  });

  const handleSellCoin = async (values, action) => {
    try {
      setIsLoading(true)
      const { data } = await AxiosInstance.post(`/mcoin/sell`, {
        "coins": values.coin
      });
      getUserDetails()
      toast.success(data?.message || "Coin Sell Successfully!")
      action.resetForm()
    } catch (error) {
      customErrorMessage(error?.response?.data?.message || error || "Something went wrong!")
    } finally {
      setIsLoading(false)
    }
  }
  const getUserDetails = async () => {
    try {
      setIsLoading(true)
      const response = await AxiosInstance.get(url.getUserDetails);
      localStorage.setItem("user", JSON.stringify(response.data.data))
      const Coins = JSON.parse(localStorage.getItem('user')).mCoins
      setUserCoins(Coins)
    } catch (error) {
      customErrorMessage(error?.response?.data?.message || error || "Something went wrong!")
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getUserDetails()
    const Coins = JSON.parse(localStorage.getItem('user')).mCoins
    setUserCoins(Coins)
  }, []);



  const { touched, values, errors, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      coin: "",
    },
    validationSchema: schema,
    onSubmit: async (values, action) => { handleSellCoin(values, action) }
  });
  const Calculate = (number) => {
    return errors?.coin?.length > 0 ? 0 : (number * 40)
  }
  const calculatePercents = () => {
    return (values.coin / UserCoins) * 100
  }

  useEffect(() => {
    dispatch(getPerMCoinPrice())
  }, [dispatch])

  return (
    <div className="mt-4 mb-4 page_main dashboard  ">
      <Container fluid>
        <h1>
          Sell - M Coin
          <span className="font14Blk"></span>
        </h1>

        <div className="divBg_white mb-3 ">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Sell M Coin</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 py-5 ">
              <Form className="pb-4 mb-4  formArea" onSubmit={handleSubmit}>
                <div className="row flex-column">

                  <div className="mt-0 d-flex mb-4">
                    <div>
                      <h5 className="fw-normal">
                        Total M Coin:
                        <span className="fw-bold"> {UserCoins && UserCoins}</span>
                      </h5>
                    </div>

                  </div>

                  <div className="col-12 ">
                    <Form.Group className="mb-4 inputOuter_ ">
                      <Form.Label className="defLabel_">Coin to sell</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Number of coin to sell"
                        name="coin"
                        className="defInput_ "
                        value={values.coin}
                        onChange={e => setFieldValue("coin", handleNValue(e))}
                        onBlur={handleBlur}
                      />
                      {errors.coin && touched.coin && (
                        <span style={{ color: 'red', fontSize: "12px" }}>{errors.coin}</span>
                      )}
                    </Form.Group>
                  </div>


                  <div className="col-12 ">
                    <Form.Group className="mb-2 inputOuter_ position-relative">
                      <Form.Label className="defLabel_">Price per Coin</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="40.00"
                        name="price"
                        className="defInput_ "
                        disabled
                        value={perMCoinPrice.data}
                      />
                      <div className="customposition">
                        <p>GBP</p>
                      </div>

                    </Form.Group>
                  </div>
                  <div className="mt-0 d-flex mb-3">
                    <div>
                      <span className="fw-bold">Total</span> : {Calculate(values.coin)} GBP
                    </div>
                  </div>
                </div>
                <div className="d-inline-block ">
                  <button type="button" to={"/"} onClick={() => errors?.coin?.length === undefined && values.coin.length > 0 ? setalertMessage(true) : ""} className="btnSell btnBuySM">
                    Sell
                    <svg
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.730469 6.79565H15.7185" stroke="white" />
                      <path
                        d="M9.34521 1L15.7186 6.91254L9.34521 12.841"
                        stroke="white"
                      />
                    </svg>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {isLoading && <div className="d-flex align-items-center justify-content-center h-100 w-100" style={{ width: '100%', position: 'fixed', top: '0', left: '0', backgroundColor: '#4f4f4f85', zIndex: '999' }}>
          <Spinner size="md" variant="light" />
        </div>}
      </Container>
      <Modal
        show={alertMessage}
        onHide={() => setalertMessage(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalComman"
      >

        <Form className="" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="d-flex-coloum text-center">
              <h3 className="mt-5 mb-3">Are You Sure</h3>
              <h5 className="mb-4">you want to sell <span>{calculatePercents().toFixed(2)}%</span> Coins at the price of <span>40 GBP </span>?</h5>
              <div className="mb-5">
                <button type="button" className="btn_White lm" onClick={() => setalertMessage(false)}>
                  No
                </button>
                <button type="submit" className="btn_Orange lm ms-2" onClick={() => setalertMessage(false)}>
                  Yes
                </button>
              </div>
            </div>

          </Modal.Body>

        </Form>
      </Modal>
    </div>
  );
};

export default SellcoinPage;
