import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSearchParams } from 'react-router-dom';
import AxiosInstance from '../../helpers/AxiosRequest';
import url from '../../helpers/development.json';
import { customErrorMessage } from '../../helpers/response';
import cancelpng from "../publicPages/assets/images/cancel.png";

const OpayoFailureHandler = () => {

    const [params] = useSearchParams();
    const [transactionId, setTransactionId] = useState(null);

    useEffect(() => {
        const crypt = params.get('crypt').substring(1);
        decryptData(crypt)
    }, [])

    const decryptData = async (crypt) => {
        try {
            const response = await AxiosInstance.post(url.decryptData, { crypt: crypt })
            const splitedArray = response.data.data.decryptedText.split("&");
            const findKeys = ["VPSTxId", "Status", "TxAuthNo", "3DSecureStatus", "Amount"];
            const transactionObj = {}
            findKeys.forEach((current) => {
                splitedArray.map((ele) => {
                    if (ele.includes(current)) {
                        return transactionObj[current] = ele.split('=')?.[1]
                    }
                })
            })
            const _transactionId = transactionObj.VPSTxId.substring(1, transactionObj.VPSTxId.length-1)
            setTransactionId(_transactionId)
        } catch (error) {
            console.log(error)
            customErrorMessage("Something went wrong!");
        }
    }

    const loginHandler = () => {
        window.location.href= "/";
    }
    return (
        <div className="login_parent">
            <Container fluid>
                <Row className="height100vh_">
                    <Col lg={12} md={12} sm={12} className="p-0">
                        <div className="rightForm">
                            <div className="payment-bgheight">
                                <div className="d-flex align-items-center justify-content-center h-100 ">
                                    <div className="d-flex flex-column payment-custombox">
                                        <div className="text-center payment-customsuccess payment-customspace">
                                            <img src={cancelpng} style={{height: "85px"}} />
                                        </div>
                                        <div className="">
                                            <div className="payment-topHalf">
                                                <h1>Oops!</h1>
                                                <p><strong>Transaction Number :</strong><span> {transactionId}</span></p>
                                                <p className='payment-p'>Some reason your transaction is failed. Please contact to your site administrator.</p>
                                            </div>
                                            <div className="bottomHalf text-center">
                                                <button className="payment-button" onClick={loginHandler} id="alertMO">Back to Home</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OpayoFailureHandler