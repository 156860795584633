import React, { useState ,useRef } from "react";
import { Form } from "react-bootstrap";
import { Breadcrumb, Layout } from "antd";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import url from "../../../helpers/development.json";
import AxiosInstance from "../../../helpers/AxiosRequest";
import { SendSuccessResponse , customErrorMessage } from "../../../helpers/response";
import { uploadImageWithSignUrl } from "../../../helpers/UploadImageWiths3";


const PageForm = () => {
  const { Content } = Layout;
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({});
  const [file, setFile] = useState({});
  const financialFile = useRef();
  const [, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      if (values === "") {
        setErrorMessage("");
      }
      event.stopPropagation();
    } else {
      if (values === "" || values === null || values === undefined) {
        setErrorMessage("Please Provide all information");
      } else {
        setIsLoading(true);
        const ext = file.type.split("/");
        const params = {
          ext: ext[1],
          path: "Brochure",
          Body: file,
          ContentType: "application/pdf"
        };
        const location = await uploadImageWithSignUrl(params.Body, params.ContentType , params.path, params.ext);
        setIsSubmitting(true);

        const response = await AxiosInstance.post(url.uploadBrochure, {
          brochureURL: location
        });
        if (response.status === 200 && response.data.statusText === "Success") {
          setValues(response.data.data);
          SendSuccessResponse("Brochure uploaded successfully!");
          setIsLoading(false);
          setIsSubmitting(false);
        } else {
          customErrorMessage("Something went wrong!");
          setIsLoading(false);
          setIsSubmitting(false);
        }
      }
    }
  };

  const handleFileSelect = (e) => {
    financialFile.current.click();
    if (e.target.files) {
      const allowedExtensions = /(\.pdf|\.doc|\.docx|\.XLS)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        setErrorMessage(
          "Invalid file type. Please upload a PDF, DOC, DOCX, or XLS file."
        );
				setFile({});
      } else if (((e.target.files[0].size) / 1024 ) > 5120) {
				setErrorMessage("File size must be less then 5 MB");
				setFile({});
			} else {
        setFile(e.target.files[0]);
        setErrorMessage("");
      }
    }
  };

  return (
    <Content className="site-layout-background">
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
        items={[
          {
            title: <a href="/admin/dashboard">Home</a>,
          },
          {
            title: "Setting"
          }
        ]}
      >
      </Breadcrumb>     
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
      >
        <hr className="lineTheme" />
        <h5 className="fw-bold fs-5 mt-2">SETTINGS</h5>
        <div className="site-card-border-less-wrapper center p-2 ">
          <div className="col-3 mb-3">
            <label>
              Upload Brochure
              <span className="fontred">*</span>
            </label>
            <div id="file-upload-form" className="uploader">
              <input
                id="file-upload"
                type="file"
                accept=".pdf,.doc,.docx,.XLS,"
                required
                ref={financialFile}
                onChange={handleFileSelect}
              />
              <label htmlFor="file-upload" id="file-drag">
                { file?.name ? (file.name) :
                  (
                    <div id="start">
                      <svg
                        width="47"
                        height="34"
                        viewBox="0 0 47 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.5002 0C19.8433 0 16.1667 1.36508 13.3786 4.09391C11.305 6.12336 10.0481 8.64049 9.51495 11.2657C4.10438 12.2928 0 16.9175 0 22.4995C0 28.8329 5.27865 33.9995 11.75 33.9995H36.7826C42.4166 33.9995 47 29.5136 47 23.9995C47 18.7955 42.9037 14.5594 37.7086 14.0933C37.7324 10.4738 36.4189 6.84693 33.6055 4.09337C30.8202 1.36669 27.1571 0 23.5002 0ZM23.5002 3.00016C26.3806 3.00016 29.2329 4.04819 31.4348 6.20327C33.9792 8.69353 35.0557 12.0765 34.6599 15.3122C34.605 15.7387 34.7393 16.1677 35.0294 16.4903C35.3195 16.8129 35.7371 16.9984 36.1761 16.9995H36.7826C40.7713 16.9995 43.9346 20.0955 43.9346 23.9993C43.9346 27.9031 40.7712 30.999 36.7826 30.999H11.75C6.93535 30.999 3.06533 27.211 3.06533 22.4992C3.06533 18.0492 6.523 14.4241 10.9518 14.0303C11.2992 13.9972 11.6246 13.8496 11.874 13.6107C12.1238 13.3722 12.2827 13.0572 12.3248 12.7182C12.6067 10.3406 13.6818 8.03074 15.5498 6.20245C17.7496 4.04987 20.6198 3.00016 23.5002 3.00016ZM23.5002 12.0001C22.9892 12.0001 22.7191 12.1609 22.4625 12.3907L16.8427 17.3907C16.1938 17.9801 16.2052 18.9368 16.7469 19.5158C17.2885 20.0947 18.3237 20.1366 18.9182 19.6096L21.9675 16.891V26.4998C21.9675 27.3282 22.6535 27.9999 23.5002 27.9999C24.3469 27.9999 25.0329 27.3282 25.0329 26.4998V16.891L28.0822 19.6096C28.6766 20.1369 29.6805 20.0655 30.2535 19.5158C30.8572 18.9368 30.7467 17.9237 30.1577 17.3907L24.5379 12.3907C24.2343 12.1183 24.0112 12.0001 23.5002 12.0001Z"
                          fill="black"
                        />
                      </svg>
                      <div className="mt-2">Click to Upload</div>
                    </div>
                  )
                }
              </label>
            </div>
          </div>
          <div className="w-100 m-0" ></div>
          <div>
            <button
              type="submit"
              className="my-submit-button"
              id="sign-in-button"
              disabled={isSubmitting || isLoading}
            >
              {isLoading ?
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                </> : 
                ''
              }
              Submit
            </button>
            <button type="button" onClick={()=>setFile({})} color="" variant="" className="my-reset-button ms-2">
              Reset
            </button>
          </div>
        </div>
      </Form>
    </Content>
  );
};

export default PageForm;