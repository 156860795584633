import React, { useState, Fragment, useEffect } from "react";
import { Breadcrumb, Layout, Form, Input } from "antd";
import { Button } from "reactstrap";
import AxiosInstance from '../../helpers/AxiosRequest';
import { customMessage } from "../../helpers/response";
import url from "../../helpers/development.json";
import { Link } from "react-router-dom";
import _ from "lodash";

const AdminProfile = () => {
  const { Content } = Layout;
  const [, setIsLoading] = useState(false);
  const [ values , setValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [form] = Form.useForm();
  const [isFormSubmitting] = useState(false);
  const [, setInitialValues] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);


  useEffect(() => {
    getUserDetails().then((data) => {
      setInitialValues(data);
      form.setFieldsValue(data);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
 
  const handleFormChange = () => {
    setIsFormModified(true);
  }

  const handleUpdateButtonClick = () => {
    setIsFormModified(false);
  }

  const isFormUnmodified = _.isEqual(values, form.getFieldsValue());
  

  const getUserDetails = async () => {
    try {
      const response = await AxiosInstance.get(url.AdmingetAdminDetails);
      if (response.status === 200 && response.data.statusText === "Success") {
        setValues(response.data.data);
        form.setFieldsValue(response.data.data);
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const onSubmit = async (data) => {
    try {
      setValidated(true);
      setIsLoading(true);
      const response = await AxiosInstance.post(url.AdminupdateProfile, data);
      if (response.status === 200 && response.data.statusText === "Success") {
        setIsLoading(false);
        customMessage("Profile updated successfully!");
        setIsFormModified(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const onFinish = (values) => {
    const data = {
      name: values.name,
      phone_number: values.phone_number,
      address: values.address
    }
    onSubmit(data);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Fragment>
      <Content className="site-layout-background ">
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
          items={[
            {
              title: <a href="/admin/dashboard">Home</a>,
            },
            {
              title: "Update Admin Profile"
            }
          ]}
        >
        </Breadcrumb>
        <hr className="lineTheme" />
        <div className="page-card-view ant-table-thead">
          <h5 className="fw-bold fs-5 mt-2 mb-3 text-uppercase">Update Admin Profile</h5>

          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 3
            }}
            wrapperCol={{
              span: 16
            }}
            initialValues={{
              remember: false,
            }}
            validated={validated}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onValuesChange={handleFormChange} // handle form change
          >
            <Form.Item label="Full Name" name="name" rules={[{ required: true, message: 'Please input a valid full name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Phone Number" name="phone_number" rules={[{ required: true, message: 'Please input a valid phone number!', pattern: new RegExp(/^[0-9]+$/) }]}>
              <Input minLength={8} maxLength={15} />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="Address" name="address" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 16, }}>
              <Button
                type="submit"
                className="my-submit-button mt-5"
                disabled={!isFormModified || isFormSubmitting}
              >
                Update
              </Button>
            </Form.Item>
          </Form>

        </div>
      </Content>

    </Fragment >
  )
};

export default AdminProfile;
