import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Header from "./common/Header";
import Footer from "./common/Footer";
import icSociety from "./assets/images/icSociety.png";
import icEvent from "./assets/images/icEvent.png";
import icVenues from "./assets/images/icVenues.png";
import IcPlatform from "./assets/images/IcPlatform.png";
import laptopImg from "./assets/images/laptopImg.png";
import Brochure from "../../assets/Meridiem_Brochure.pdf"
import Lottie from "lottie-react";
import bannerAnimation from "./assets/animation.json";
import bannerAnimationMobile from "./assets/animationMobile.json";
import url from "../../helpers/development.json";
import AxiosInstance from "../../helpers/AxiosRequest";
import { useState } from 'react';

const LandingPage = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const [downloadURL, setDownloadURL] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        setTimeout(bannerAnimation, 8000);

        function bannerAnimation() {
            document.getElementById("bannerAnimation").classList.add("d-none");
        }
    }, []);

    useEffect(() => {
        setTimeout(heroBanner, 8000);

        function heroBanner() {
            document.getElementById("heroBanner").classList.add("d-block");
        }
    }, []);

    const getBrochureURL = async () => {
        const response = await AxiosInstance.get(url.getBrochure);
        if (response.status === 200 && response.data.statusText === "Success") {
            setDownloadURL(response.data.data.brochureURL);
        }
    }

    useEffect(() => {
        getBrochureURL();
    }, []);

    return (
        <>
            <Header />

            <div className='middlePart'>
                {/* Animation Banner */}
                <div className='animationBanner' id='bannerAnimation'>
                    <Lottie animationData={bannerAnimation} className="bannerAnimation d-none d-md-block" loop={false} duration={8000} />
                    <Lottie animationData={bannerAnimationMobile} className="bannerAnimation d-block d-md-none" loop={false} />
                </div>

                {/* Hero Banner */}
                <div className='heroBanner' id='heroBanner'>
                    <div className='bgBlkTransparent d-flex align-items-center justify-content-center flex-column'>
                        <div className='container'>
                            <div className='logoAnimation text-center mb-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288.1 144.7">
                                    <g fill='#CA9E67'>
                                        <polygon className="st0" points="178.4,66.1 178.4,65.9 178.2,66.1" />
                                        <polygon className="st0" points="112.2,66.1 112.4,66.1 112.2,65.9   " />
                                        <polygon className="st0" points="123,56.8 112.2,46.7 112.2,50.7 121,58.9   " />
                                        <polygon className="st0" points="169.6,58.9 178.4,50.7 178.4,46.7 167.6,56.8   " />
                                        <polygon className="st0" points="112.2,65.9 112.4,66.1 116.7,66.1 112.2,61.9   " />
                                        <polygon className="st0" points="173.9,66.1 178.2,66.1 178.4,65.9 178.4,61.9   " />
                                        <path className="st0" d="M178.4,35.1v-4l-24.7,23l-6.2-5.8l31-28.8v-4l-33.1,30.8l-6.2-5.8L178.4,4V0l-33.1,30.8L112.2,0v4l31,28.8l-6.2,5.8l-24.7-23v4l31,28.8l-6.2,5.8l-24.7-23v4l33.1,30.8L178.4,35.1z M145.3,50.3l6.2,5.8l-6.2,5.8l-6.2-5.8L145.3,50.3z" />
                                    </g>
                                    <g fill='#fff'>
                                        <path className="st1" d="M0,106.2h2.7L18.2,129l15.5-22.8h2.7v38.5h-2.9v-33.5l-15.2,22.2H18L2.8,111.3v33.5H0V106.2z" />
                                        <path className="st1" d="M50.2,106.2h27.4v2.6H53.1V124h22.1v2.6H53.1v15.5h24.8v2.6H50.2V106.2z" />
                                        <path className="st1" d="M89.6,106.2h16.2c4.7,0,8.5,1.5,10.8,3.8c1.8,1.8,2.9,4.4,2.9,7.2v0.1c0,6.4-4.8,10.1-11.3,11l12.7,16.5h-3.6   l-12.3-16h-0.1H92.4v16h-2.9V106.2z M105.4,126.2c6.4,0,11.2-3.2,11.2-8.8v-0.1c0-5.2-4.1-8.4-11.1-8.4H92.4v17.3H105.4z" />
                                        <path className="st1" d="M132.2,106.2h2.9v38.5h-2.9V106.2z" />
                                        <path className="st1" d="M149.4,106.2h12.8c12.1,0,20.5,8.3,20.5,19.1v0.1c0,10.8-8.4,19.3-20.5,19.3h-12.8V106.2z M162.2,142.1   c10.7,0,17.5-7.3,17.5-16.5v-0.1c0-9.1-6.8-16.6-17.5-16.6h-10v33.2H162.2z" />
                                        <path className="st1" d="M195.3,106.2h2.9v38.5h-2.9V106.2z" />
                                        <path className="st1" d="M212.4,106.2h27.4v2.6h-24.5V124h22.1v2.6h-22.1v15.5h24.8v2.6h-27.7V106.2z" />
                                        <path className="st1" d="M251.8,106.2h2.7l15.5,22.8l15.5-22.8h2.7v38.5h-2.9v-33.5L270,133.5h-0.2l-15.2-22.2v33.5h-2.8V106.2z" />
                                    </g>
                                </svg>
                            </div>
                            <p className='text-center'>
                                The Meridiem Trading Platform<br /> An alternative collective investment <br /> platform for investors, entrepreneurs, <br /> and venture capitalists.
                            </p>
                            <div className='m-auto d-table mb-5'>
                                <Nav.Link as={Link} to={"/membership-page"} className='btn siteThemeBtn'>APPLY FOR MEMBERSHIP</Nav.Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second Banner */}
                <div className='col-12 col-lg-12 d-none d-md-block'>
                    <div className='secondBanner'></div>
                </div>
                {/*  */}

                {/* Section society and event */}
                <div className='societyAndEvent'>
                    <div className='row g-0 align-items-stretch'>
                        <div className='col-12 col-lg-6 d-flex justify-content-center whitePattern' style={{ overflowX: "hidden" }}>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9 position-relative h-100'>
                                <div className='societyPart'>
                                    <div className='content'>
                                        <div className='partIcon'><img src={icSociety} alt="icSociety" /></div>
                                        <h2 className='fontBlack34' style={{ textTransform: 'uppercase' }}>Diminishing returns on the stock market.</h2>
                                        <p className='font18Black'>
                                            In 1985 the FTSE 100 had an annual return of 41% and has been dropping year on year,
                                        </p>
                                        <p className='font18Black'>
                                            In 2022 an annual return of 1%. Stock market valuations can be over priced and at times highly volatile.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-12 d-block d-md-none p-0'>
                            <div className='secondBanner'></div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9 position-relative h-100'>
                                <div className='eventPart'>
                                    <div className='content'>
                                        <div className='partIcon'><img src={icEvent} alt="icEvent" /></div>
                                        <h2 className='fontWhite34' style={{ textTransform: 'uppercase' }}>Venture capital funds</h2>
                                        <p className='font18White'>In comparison venture capital funds worldwide, recorded in the first quarter of 2021, an IRR of 19.8%. An above average return.</p>
                                        <p className='font18White'>Private equity remains outside of the stock market bubble and better valued share price.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Our Venues */}
                <div className='vanuePart'>
                    <div className='row g-0 align-items-stretch h-100'>
                        <div className='col-12 col-lg-6 order-last order-lg-first'>
                            <div className='vanueImg'>
                                {/* <img src={venuesImg} /> */}
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 order-first order-lg-last redPattern'>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9 position-relative h-100'>
                                <div className='vanueDetails'>
                                    <div className='content'>
                                        <div className='partIcon'><img src={icVenues} alt="icVenues" /></div>
                                        <h2 className='fontBlack34' style={{ textTransform: 'uppercase' }}>On the Meridiem Platform</h2>
                                        <p className='font18Black fontBold'>Invest collectively with other members.</p>
                                        <p className='font18Black fontBold'>Invest in venture backed companies or companies with a yearly profit of £500,000.</p>
                                        <p className='font18Black fontBold'>Trade these shares amongst other members.</p>
                                        <p className='font18Black fontBold'>Buy, sell or hold <span role='button' className='text-decoration-underline' onClick={() => navigate("/mcoin")}>Mcoins</span> backed by these shares.</p>
                                        <div className='d-table mt-5'>
                                            <Nav.Link as={Link} className='btn siteBlackBtn' to={"/membership-page"}>Subscribe</Nav.Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Platform Part */}
                <div className='platformPart'>
                    <div className='row g-0 align-items-stretch h-100 yellowPattern'>
                        <div className='col-12 col-lg-6 d-flex justify-content-center'>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9 position-relative h-100'>
                                <div className='leftPart'>
                                    <div className='content'>
                                        <div className='partIcon'><img src={IcPlatform} alt="IcPlatform" /></div>
                                        <h2 className='fontBlack34' style={{ textTransform: 'uppercase' }}>The Meridiem Trading Platform</h2>
                                        <p className='font18Black'>Collective investment schemes give investors of moderate means</p>
                                        <p className='font18Black'>The same advantage as the large capitalist and pension funds in obtaining greater returns and diminishing risks by pooling their liquidity with other members and spreading their risk.</p>
                                        <p className='font18Black'>As a member you can also submit an investment opportunity to raise liquidity for your project.</p>
                                        <div className='d-table mt-5'>
                                            {/* <Button className='btn siteBlackBtn' download href={downloadURL !== "" ? downloadURL : "#"} target="_blank">DOWNLOAD OUR BROCHURE</Button> */}
                                            <Button className='btn siteBlackBtn' download="Brochure.pdf" href={Brochure} target="_blank">DOWNLOAD OUR BROCHURE</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='rightPart'>
                                <div className='content'>
                                    <div><img src={laptopImg} className="img-fluid" alt="laptopImg" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Last BlankPart */}
                <div className='lastBG'></div>

            </div>

            <Footer />



        </>
    )
}

export default LandingPage