import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPageAdmin = () => {
    return (
        <> 
            <div className='pageNotFound'>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className='d-flex align-items-center justify-content-center flex-column' style={{height:'100vh'}}>
                        <h1 className='error-head' style={{color: "#c99e67"}}>404</h1>
                        <p>The page you are looking for can not be found</p>
                        <Link to={'/admin/auth/login'} className="btn_OrangeNew" style={{background: "#c99e67",border: "1px solid #c99e67"}}>Back To Home</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ErrorPageAdmin;