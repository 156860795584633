import React, { useState, useEffect, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Table,
    Form,
    Modal,
    Button,
    Badge,
    Nav,
    Spinner,
    Breadcrumb,
} from "react-bootstrap";
import bitcoin from "../../../assets/images/bitcoinIcon.png";
import property from "../../../assets/images/icProperty.png";
import Commodity from "../../../assets/images/icCommodity.png";
import mCoin from "../../../assets/images/mCoin.png";
import { Link, NavLink } from "react-router-dom";
// import Marquee from "react-marquee-master";
// import { useDispatch } from "react-redux";
// import { getPosts } from "../../../../src/";
import icCrypto from "../../../assets/images/icCrypto.png";
import icProperty from "../../../assets/images/icProperty.png";
import icCommodity from "../../../assets/images/icCommodity.png";
import icBonds from "../../../assets/images/icBonds.png";
import icShares from "../../../assets/images/icShares.png";
import Web3 from 'web3';
import moment from 'moment';


// import {
//     errorResponse,
//     successResponse,
// } from "../../helpers/response";
// import url from "../../Development.json";
// import Http from '../../security/Http'
// import VerifyButton from "../Verify";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserMCoinList } from "../../../store/user/action";
import PaginationComponent from "../../common/PaginationComponent";


const status = {
    upcoming: "upcoming",
    marketing: "marketing",
    fund: "fund",
    voting: "voting",
    result: "result",
    unknown: "unknown"
}
const MCoinList = () => {
    const dispatch = useDispatch();

    // const user = useMemo(() => localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null, [])
    const { userMCoinList } = useSelector((state) => state.userStore);

    const initFilter = { page: 1, limit: 10 }
    const [filter, setFilter] = useState(initFilter);
    const totalPages = useMemo(() => {
        return Math.ceil((userMCoinList?.metaData?.total / filter.limit) || 1)
    }, [userMCoinList, filter.limit]);

    useEffect(() => {
        dispatch(getUserMCoinList({ payload: filter }))
    }, [dispatch, filter])

    return (
        <>
            <div className="mt-4 mb-4 page_main dashboard">
                <Container fluid>
                    <h1>
                        My M-Coins
                        <span className="font14Blk"></span>
                    </h1>
                    <div className="divBg_white mb-3 ">
                        <div>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>My M-Coins</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Col md={12} lg={12}>
                            <div className="">
                                <div className="tableSM">
                                    <Table responsive striped borderless>
                                        <thead>
                                            <tr>
                                                <th>No of M Coin</th>
                                                <th className="text-center">Investment Title</th>
                                                <th className="text-center">Opportunity Title</th>
                                                <th className="text-center">Amount</th>
                                                {/* <th></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userMCoinList.loading ?
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="d-flex align-items-center justify-content-center py-5 my-5 h-100 w-100">
                                                            <Spinner size="md" variant="dark" />
                                                        </div>
                                                    </td>
                                                </tr> :
                                                userMCoinList.data.length === 0 ?
                                                    <tr>
                                                        <th className="text-center py-5" colSpan={4}>No M-Coins Found!</th>
                                                    </tr> :
                                                    userMCoinList.data?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{item.no_of_token}</td>
                                                            <td className="text-center">{item.investment.investment_Title}</td>
                                                            <td className="text-center">{item.opportunity.investment_title}</td>
                                                            <td className="text-center">{item.paymentLog.amount}</td>
                                                            {/* <td>
                                                                <div className="d-flex align-items-center">
                                                                    <NavLink
                                                                        to={`/opportunity/detail/${item._id}`}
                                                                        className="btn_Black viewMore"
                                                                    >
                                                                        View More
                                                                    </NavLink>

                                                                </div>
                                                            </td> */}
                                                        </tr>
                                                    ))}
                                        </tbody>
                                    </Table>
                                    <PaginationComponent
                                        selectedPage={filter.page}
                                        handlePageChange={p => setFilter(prev => ({ ...prev, page: p }))}
                                        totalPages={totalPages}
                                    />
                                </div>
                            </div>
                        </Col>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default MCoinList;
