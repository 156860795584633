import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Breadcrumb, Layout } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDividendList } from "../../../store/admin/action";
import { Spinner } from "react-bootstrap";
import CommonPagination from "../../common/CommonPagination";
import moment from "moment";
import { ViewIcon } from "../../common/Icons";

const formatDate = (date) => (date ? moment(date).format("YYYY/MM/DD") : "");
const DividendHistoryList = () => {
  const { Content } = Layout;
  const navigate = useNavigate()

  const timeoutRef = useRef(null);
  const dispatch = useDispatch();
  const initFilter = { page: 1, limit: 10, search: "" };
  const [filter, setFilter] = useState(initFilter);
  const [search, setSearch] = useState("");
  const { dividendList } = useSelector((state) => state.adminStore);

  const totalPage = useMemo(
    () =>
      Math.ceil((dividendList?.metaData?.total || 0) / filter.limit) || 1,
    [dividendList, filter]
  );

  useEffect(() => {
    dispatch(getDividendList({ payload: filter }));
  }, [dispatch, filter]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(
      () => setFilter((prev) => ({ ...prev, search: value, page: 1 })),
      700
    );
  };

  return (
    <>
      <Fragment>
        <Content className="site-layout-background">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
            items={[
              {
                title: <NavLink to="/admin/dashboard">Home</NavLink>,
              },
              {
                title: "Dividend History"
              }
            ]}
          >
          </Breadcrumb>

          <hr className="lineTheme" />
          <div className="page-card-view">
            <div className="card-header-part">
              <div className="card-header-action">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold fs-5 mt-2">DIVIDEND HISTORY</h5>
                  <div className="form-group mb-3 mr-3">
                    <input
                      type="text"
                      className="form-control rounded-0"
                      id="search"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="table-part table-style-1">
              <div className="table-responsive">
                <Table bordered>
                  <thead className="fontsize mb-0">
                    <tr>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Investment Title
                          {/* <div className="d-flex flex-column" onClick={() => handleSortingChange('email')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div> */}
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Opportunity Title</div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Dividend Amount (£)</div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">E Community Wallet (£)</div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Distributed Amount (£)</div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Distribution Date</div>
                      </th>
                      <th className="text-center" style={{ width: "20px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dividendList.loading ? (
                      <tr>
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                            <Spinner size="md" variant="dark" />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {dividendList.data.length > 0 ? (
                          dividendList.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.investment?.investment_Title || "N/A"}</td>
                              <td>{item?.opportunity?.investment_title || "N/A"}</td>
                              <td>{item?.amount || 0}</td>
                              <td>{item?.percentage_amount || 0}</td>
                              <td>{item?.rest_amount || 0}</td>
                              <td>{formatDate(item?.dividend_date)}</td>
                              <td className="d-flex justify-content-center">
                                <button
                                  color=""
                                  className="viewbutton"
                                  onClick={() => navigate(`/admin/dividend-history-list/${item._id}`)}
                                >
                                  <ViewIcon />
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                                No data found!
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="PaginationLink">
            <CommonPagination
              filter={filter}
              setFilter={setFilter}
              totalPage={totalPage}
            />
          </div>
        </Content>
      </Fragment>
    </>
  );
};

export default DividendHistoryList;