import React, { useState, Fragment, useEffect, useRef } from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Spinner,
} from "reactstrap";
import { Form, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Breadcrumb, Layout } from "antd";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import doc from "../../../assets/admin/images/doc.png";
import docx from "../../../assets/admin/images/docx.png";
import xls from "../../../assets/admin/images/xls.png";
import xlsx from "../../../assets/admin/images/xlsx.png"
import pdf1 from "../../../assets/admin/images/pdf1.png"
import AxiosInstance from "../../../helpers/AxiosRequest";
import url from "../../../helpers/development.json";
import { customMessage, customErrorMessage } from "../../../helpers/response";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CheckmarkIcon, CloseIcon, EditIcon2, ViewIcon } from "../../common/Icons";

const tabStatus = {
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
}
const Index = () => {
  const { Content } = Layout;
  const [modal, setModal] = useState(false);
  const [viewmodal, setViewModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({});
  const [OpportunityDetails, setOpportunityDetails] = useState({})
  const [selectedTab, setTab] = useState(tabStatus.Pending);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1)
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [approvesModal, setApprovedModal] = useState(false);
  const [rejected, setRejected] = useState(false)
  const [opportunityData, setOpportunityData] = useState({});
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [isChecked, setIsChecked] = useState({
    Pending: [],
    Approved: [],
    Rejected: [],
  });

  useEffect(() => {
    if (selectedTab) fetchData(selectedTab)
  }, [selectedTab, limit, activePage, search]);


  const toggle1 = () => {
    setViewModal(!viewmodal);
  };

  const handleCheckBox = (id, _tab) => {
    setIsChecked((prevState) => {
      const isPresent = prevState[_tab].find((_id) => _id === id);
      return {
        ...prevState,
        [_tab]: isPresent
          ? prevState[_tab].filter((_id) => _id !== id)
          : [...prevState[_tab], id],
      };
    });
  };

  const fetchData = async (opportunity_type) => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.post(url.OpportunityList, { opportunity_type, search: search, page: activePage, limit: limit });
      if (response.status === 200 && response.data.statusText === "Success") {
        setValues(response.data.data.data);
        setData(response.data.data.data);
        setIsLoading(false);
        setTotalPages(Math.ceil(response.data.data.metadata.total / limit));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getFileIcon = (fileName) => {
    const extension = fileName && fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return pdf1;
      case "doc":
        return doc;
      case "docx":
        return docx;
      case "xls":
        return xls;
      case "xlsx":
        return xlsx;
      default:
        return pdf1;
    }
  };

  const handleSortingChange = (accessor) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...data].sort((a, b) => {
        return (
          a[sortField]?.toString()?.localeCompare(b[sortField]?.toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setData(sorted);
    }
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleLimitChange = (event) => {
    const newLimit = Number(event.target.value);
    setLimit(newLimit);
    setActivePage(1);
  };

  const onFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(prev => ({ ...prev, [name]: value }));
  };
  const onDateChange = (name, date) => setValues(prev => ({ ...prev, [name]: date }));


  const validateForm = () => {
    const errors = {};
    if (!values.investment_Title) {
      errors.investment_Title = 'Investment title is required';
    }
    if (!values.investment_Amount) {
      errors.investment_Amount = 'Investment amount is required';
    }
    if (!values.marketig_start_date) {
      errors.marketig_start_date = 'Start date is required';
    }
    if (!values.marketing_end_date) {
      errors.marketing_end_date = 'End date is required';
    } else if (values.marketing_end_date < values.marketig_start_date) {
      errors.marketing_end_date = 'End Date must be after start Date.';
    }
    if (!values.fund_raise_start_date) {
      errors.fund_raise_start_date = 'Start date is required';
    } else if (values.fund_raise_start_date < values.marketing_end_date) {
      errors.fund_raise_start_date = 'start Date must be after marketing end date ';
    }
    if (!values.fund_raise_end_date) {
      errors.fund_raise_end_date = 'End date is required';
    } else if (values.fund_raise_end_date < values.fund_raise_start_date) {
      errors.fund_raise_end_date = 'End date must be after start Date';
    }
    if (!values.voting_start_date) {
      errors.voting_start_date = 'Start date is required';
    } else if (values.voting_start_date < values.fund_raise_end_date) {
      errors.voting_start_date = 'start Date must be after fund_raise end date ';
    }
    if (!values.voting_end_date) {
      errors.voting_end_date = 'End date is required';
    } else if (values.voting_end_date < values.voting_start_date) {
      errors.voting_end_date = 'End date must be after start Date';
    }
    if (!values.result_date) {
      errors.result_date = 'Result date is required';
    } else if (values.result_date < values.voting_end_date) {
      errors.result_date = 'start Date must be after voting end date ';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const onSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const response = await AxiosInstance.post(url.CreateInvestmentRound, {
      opportunity_id: isChecked.Approved,
      investment_Title: values.investment_Title,
      investment_Amount: values.investment_Amount,
      marketig_start_date: moment(values.marketig_start_date).format('YYYY-MM-DD'),
      marketing_end_date: moment(values.marketing_end_date).format('YYYY-MM-DD'),
      fund_raise_start_date: moment(values.fund_raise_start_date).format('YYYY-MM-DD'),
      fund_raise_end_date: moment(values.fund_raise_end_date).format('YYYY-MM-DD'),
      voting_start_date: moment(values.voting_start_date).format('YYYY-MM-DD'),
      voting_end_date: moment(values.voting_end_date).format('YYYY-MM-DD'),
      result_date: moment(values.result_date).format('YYYY-MM-DD')
    });
    if (response.status === 200 && response.data.statusText === "Success") {
      setIsLoading(false);
      customMessage("Investment round created successfully!");
      setIsChecked({
        Pending: [],
        Approved: [],
        Rejected: [],
      })
      setModal(false);
    } else {
      setIsLoading(false);
      customErrorMessage("Something went wrong!");
    }
  };

  const handleApprove = (data) => {
    setApprovedModal(!approvesModal);
    setOpportunityData(data)
  }


  const handleApproveClick = async (_id, status) => {
    const response = await AxiosInstance.post(url.updateOpportunityType, {
      id: _id,
      opportunityType: "Approved"
    });
    if (response.status === 200 && response.data.statusText === "Success") {

      customMessage("Opportunity approved successfully!");
      setApprovedModal(false)
      setTab(tabStatus.Approved);
      fetchData("Approved");
    } else {
      customErrorMessage("Something went wrong!");
    }
  }

  const handleReject = (data) => {
    setRejected(!approvesModal);
    setOpportunityData(data)
  }

  const handleRejectClick = async (_id) => {
    const response = await AxiosInstance.post(url.updateOpportunityType, {
      id: _id,
      opportunityType: "Rejected"
    });
    if (response.status === 200 && response.data.statusText === "Success") {
      customMessage("Opportunity rejected successfully!");
      setRejected(false)
      setTab(tabStatus.Rejected);
      fetchData("Rejected");
    } else {
      customErrorMessage("Something went wrong!");
    }
  }

  return (
    <Fragment>
      <Content className="site-layout-background">
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
          items={[
            {
              title: <a href="/admin/dashboard">Home</a>,
            },
            {
              title: "Opportunity"
            }
          ]}
        >
        </Breadcrumb>

        <hr className="lineTheme" />
        <div className="page-card-view">
          <div className="card-header-part mb-2">
            <div className="card-header-action ">
              <div className="d-flex justify-content-between text-align-items-center">
                <h5 className="fw-bold fs-5 mt-2">OPPORTUNITY</h5>
                <div className="form-group mb-0 mr-3">
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="search"
                    placeholder="Search Investment Title"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setActivePage(1)
                    }}
                    value={search}
                  />
                </div>
              </div>
            </div>
          </div>
          {console.log('qqqqq', isChecked?.Approved?.length)}
          <div className="table-part table-style-1 tabCustom position-relative">
            {selectedTab === tabStatus.Approved ? <div className="proposalBtnAbs">
              <div className="d-flex align-items-center">

                <span>Select minimum 2 opportunities and maximum 6 to create an investment round.</span>

                <button
                  type="button"
                  className="my-submit-button"
                  onClick={() => isChecked.Approved.length < 2 || isChecked.Approved.length > 6 ? null : toggle()}
                  disabled={isChecked.Approved.length < 2 || isChecked.Approved.length > 6}
                >
                  Create Investment Round
                </button>
              </div>
            </div> : ''}

            <div className="table-part table-style-1">
              <div className="table-responsive customnav">
                <Tabs
                  defaultActiveKey={selectedTab}
                  id="uncontrolled-tab-example"
                  className="mb-2 border-0"
                  activeKey={selectedTab}
                  onSelect={(key) => setTab(key)}
                >
                  <Tab eventKey={tabStatus.Pending} title={tabStatus.Pending}></Tab>

                  <Tab eventKey={tabStatus.Approved} title={tabStatus.Approved}></Tab>

                  <Tab eventKey={tabStatus.Rejected} title={tabStatus.Rejected}></Tab>
                </Tabs>

                <Table bordered className="mb-0">
                  <thead className="mb-0">
                    <tr>
                      <th>
                        <div className="d-flex justify-content-between align-items-center"> Opportunity Title
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('investment_title')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Company
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('company_name')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Inv. Type
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('investment_type')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Category
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('investment_category')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Createdby
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('createdby')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Turnover Yearly (£)
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('turnover_average_yearly')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Gross Profit (£)
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('gross_profit_yearly')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Raise (£)
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('looking_to_raise')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Benefit
                          <div className="d-flex flex-column">
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Return
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('receiving_in_return')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    {isLoading ? (
                      <tr>
                        <td colSpan={11}>
                          <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                            <Spinner size="md" variant="dark" />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data && data.length > 0 ? (
                          data.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <div className="form-check checkboxlable mb-0">
                                  <input
                                    className="form-check-input checkboxlable"
                                    type="checkbox"
                                    name="createInvestmentCheck"
                                    onChange={() => {
                                      handleCheckBox(row._id, selectedTab)
                                    }}
                                    checked={isChecked[selectedTab].includes(row._id)}
                                  />

                                  <label
                                    className="form-check-label checkboxlable"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {row.investment_title}
                                  </label>
                                </div>
                              </td>
                              <td>{row.company_name}</td>
                              <td>{row.investment_type}</td>
                              <td>{row.investment_category}</td>
                              <td> {row.createdby}</td>
                              <td>{row.turnover_average_yearly}</td>
                              <td>{row.gross_profit_yearly}</td>
                              <td>{row.looking_to_raise}</td>
                              <td>{ }</td>
                              <td>{row.receiving_in_return}</td>
                              <td>
                                <div className="d-flex">
                                  <span className="me-2 ms-2">
                                    <button
                                      color=""
                                      className="viewbutton"
                                      onClick={() => { toggle1(); setOpportunityDetails(row) }}
                                    >
                                      <ViewIcon />
                                    </button>
                                  </span>

                                  {selectedTab !== tabStatus.Approved &&
                                    <span className="me-2 ms-2">
                                      <button color="" className="checkbutton" onClick={() => handleApprove(row)}>
                                        <CheckmarkIcon />
                                      </button>
                                    </span>
                                  }

                                  <span className="me-2 ms-2">
                                    <Link
                                      to={`/admin/opportunity/${row._id}`}
                                      className="editbutton"
                                    >
                                      <EditIcon2 />
                                    </Link>
                                  </span>

                                  {selectedTab !== tabStatus.Rejected &&
                                    <span className="me-2 ms-2">
                                      <button color="" className="deletebutton" onClick={() => handleReject(row)}>
                                        <CloseIcon />
                                      </button>
                                    </span>
                                  }
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={11}>
                              <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                                No data found!
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

        </div>

        {!isLoading && (
          <div className="PaginationLink">
            <Pagination>
              <PaginationItem disabled={activePage === 1}>
                <PaginationLink previous onClick={() => handlePageChange(activePage - 1)} />
              </PaginationItem>
              {pages.map((page) => (
                <PaginationItem key={page} active={activePage === page}>
                  <PaginationLink onClick={() => handlePageChange(page)}>{page}</PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={activePage === totalPages}>
                <PaginationLink next onClick={() => handlePageChange(activePage + 1)} />
              </PaginationItem>
              <select
                className="form-select ms-2 selectboder"
                aria-label="Default select example"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </Pagination>
          </div>
        )}

      </Content>

      <Modal isOpen={modal} toggle={toggle} size="mg" centered>
        <ModalHeader toggle={toggle}>
          <h5 className="fw-bold fs-5">CREATE INVESTMENT ROUND</h5>{" "}
        </ModalHeader>
        <ModalBody>
          <Form ref={formRef} onSubmit={onSubmit} noValidate >
            <Row>
              <Col >
                <Form.Group>
                  <Form.Label>Investment Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="investment_Title"
                    // defaultValue={data && data.data && data.data.length > 0 ? data.data[0].investment_Title : ''}
                    onChange={onFormChange}
                    isInvalid={errors.investment_Title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.investment_Title}
                  </Form.Control.Feedback>
                </Form.Group>

              </Col>
              <Col>

                <Form.Group>
                  <Form.Label>Investment Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="investment_Amount"
                    // defaultValue={data && data.data && data.data.length > 0 ? data.data[0].investment_Amount : ''}
                    onChange={onFormChange}
                    isInvalid={errors.investment_Amount}
                    pattern="[0-9]*"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.investment_Amount}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <h6 className="mb-0 fw-bold fs-5 py-3">Marketing</h6>
              <Col>
                <Form.Group>
                  <Form.Label> Start Date</Form.Label>
                  {/* <Form.Control
                    type="date"
                    name="marketig_start_date"
                    value={values.marketig_start_date || ''}
                    onChange={onFormChange}
                    isInvalid={errors.marketig_start_date}
                    min={new Date().toISOString().split("T")[0]}
                  /> */}
                  <div className="customDatePicker">
                    <DatePicker
                      selected={values.marketig_start_date}
                      onChange={(date) => onDateChange("marketig_start_date", date)}
                      name="marketig_start_date"
                      minDate={new Date()}
                      maxDate={values.marketing_end_date ? moment(values.marketing_end_date).add(-1, 'days').toDate() : ""}
                      dateFormat="dd/M/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className="form-control w-100"
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.marketig_start_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label> End Date</Form.Label>
                  <div className="customDatePicker">
                    <DatePicker
                      selected={values.marketing_end_date}
                      onChange={(date) => onDateChange("marketing_end_date", date)}
                      name="marketing_end_date"
                      minDate={moment(values.marketig_start_date).add(1, 'days').toDate()}
                      maxDate={values.fund_raise_start_date ? moment(values.fund_raise_start_date).add(-1, 'days').toDate() : ""}
                      dateFormat="dd/M/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className="form-control w-100"
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.marketing_end_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <h6 className="mb-0 fw-bold fs-5 py-2 mt-2">Fund Raise</h6>
              <Col>
                <Form.Group>
                  <Form.Label> Start Date</Form.Label>
                  <div className="customDatePicker">
                    <DatePicker
                      selected={values.fund_raise_start_date}
                      onChange={(date) => onDateChange("fund_raise_start_date", date)}
                      name="fund_raise_start_date"
                      minDate={moment(values.marketing_end_date).add(1, 'days').toDate()}
                      maxDate={values.fund_raise_end_date ? moment(values.fund_raise_end_date).add(-1, 'days').toDate() : ""}
                      dateFormat="dd/M/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className="form-control w-100"
                    />
                  </div>
                  <Form.Control.Feedback type="invalid" >
                    {errors.fund_raise_start_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <div className="customDatePicker">
                    <DatePicker
                      selected={values.fund_raise_end_date}
                      onChange={(date) => onDateChange("fund_raise_end_date", date)}
                      name="fund_raise_end_date"
                      minDate={moment(values.fund_raise_start_date).add(1, 'days').toDate()}
                      maxDate={values.voting_start_date ? moment(values.voting_start_date).add(-1, 'days').toDate() : ""}
                      dateFormat="dd/M/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className="form-control w-100"
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.fund_raise_end_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <h6 className="mb-0 fw-bold fs-5 py-2 mt-2">Voting</h6>
              <Col>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <div className="customDatePicker">
                    <DatePicker
                      selected={values.voting_start_date}
                      onChange={(date) => onDateChange("voting_start_date", date)}
                      name="voting_start_date"
                      minDate={moment(values.fund_raise_end_date).add(1, 'days').toDate()}
                      maxDate={values.voting_end_date ? moment(values.voting_end_date).add(-1, 'days').toDate() : ""}
                      dateFormat="dd/M/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className="form-control w-100"
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.voting_start_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <div className="customDatePicker">
                    <DatePicker
                      selected={values.voting_end_date}
                      onChange={(date) => onDateChange("voting_end_date", date)}
                      name="voting_end_date"
                      minDate={moment(values.voting_start_date).add(1, 'days').toDate()}
                      maxDate={values.result_date ? moment(values.result_date).add(-1, 'days').toDate() : ""}
                      dateFormat="dd/M/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className="form-control w-100"
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.voting_end_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <h6 className="mb-0 fw-bold fs-5 py-2 mt-2">Result</h6>
              <Col>
                <Form.Group>
                  <Form.Label>Result Date</Form.Label>
                  <div className="customDatePicker">
                    <DatePicker
                      selected={values.result_date}
                      onChange={(date) => onDateChange("result_date", date)}
                      name="result_date"
                      minDate={moment(values.voting_end_date).add(1, 'days').toDate()}
                      dateFormat="dd/M/yyyy"
                      placeholderText="dd/mm/yyyy"
                      className="form-control w-100"
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.result_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="my-submit-button" onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2 ' />
            </>
              : ''
            }
            {isLoading ? 'loading...' : 'Submit'}
          </Button>
          <Button color="" className="my-reset-button ms-2" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={viewmodal} onH size="mg" centered>
        <ModalHeader toggle={toggle1}>
          <h5 className="fw-bold fs-5">OPPORTUNITY DETAILS</h5>{" "}
        </ModalHeader>
        <ModalBody>
          <Table className="modeltable mb-0" bordered responsive>
            <tr>
              <th>Opportunity Title</th>
              <td>{OpportunityDetails?.investment_title ? OpportunityDetails.investment_title : ""}</td>
            </tr>

            <tr>
              <th>Company Name</th>
              <td>{OpportunityDetails?.company_name ? OpportunityDetails.company_name : ""}</td>
            </tr>

            <tr>
              <th>Investment type</th>
              <td>{OpportunityDetails?.investment_type ? OpportunityDetails.investment_type : ""}</td>
            </tr>

            <tr>
              <th>Investment Category</th>
              <td>{OpportunityDetails?.investment_category ? OpportunityDetails.investment_category : ""}</td>
            </tr>

            <tr>
              <th>Created by</th>
              <td>{OpportunityDetails?.createdby ? OpportunityDetails.createdby : ""}</td>
            </tr>

            <tr>
              <th>Turnover Yearly</th>
              <td>{OpportunityDetails?.turnover_average_yearly ? OpportunityDetails.turnover_average_yearly : ""}</td>
            </tr>

            <tr>
              <th>Gross Profit Yearly</th>
              <td>{OpportunityDetails?.gross_profit_yearly ? OpportunityDetails.gross_profit_yearly : ""}</td>
            </tr>

            <tr>
              <th>looking to Raise</th>
              <td>{OpportunityDetails?.looking_to_raise ? OpportunityDetails.looking_to_raise : ""}</td>
            </tr>

            <tr>
              <th>Receiving Return</th>
              <td>{OpportunityDetails?.receiving_in_return ? OpportunityDetails.receiving_in_return : ""}</td>
            </tr>

            <tr>
              <th>Assets Value</th>
              <td>{OpportunityDetails?.valuation_of_the_asset ? OpportunityDetails.valuation_of_the_asset : ""}</td>
            </tr>

            <tr>
              <th>key Risk</th>
              <td>{OpportunityDetails?.please_highlight_key_risks ? OpportunityDetails.please_highlight_key_risks : ""}</td>
            </tr>

            <tr>
              <th>Company Website</th>
              <td>{OpportunityDetails?.company_website ? OpportunityDetails.company_website : ""}</td>
            </tr>

            <tr>
              <th>Financial info</th>
              <td>
                <Button className='downloadBtn' download href={OpportunityDetails?.company_informationURL !== "" ? OpportunityDetails.company_informationURL : "#"} target="_blank">
                  <img alt="file-icon" className="me-2" src={getFileIcon(OpportunityDetails?.company_informationURL)} style={{ width: "35px" }} />
                </Button>
              </td>
            </tr>

            <tr>
              <th>UK SEIS/EIS Qualified</th>
              <td>{OpportunityDetails?.UK_SEIS_EIS_Qualified ? "Yes" : "No"}</td>
            </tr>

            <tr>
              <th>Company Info</th>
              <td>{OpportunityDetails?.company_information ? OpportunityDetails.company_information : ""}</td>
            </tr>

            <tr>
              <th>Document</th>
              <td>
                <Button className='downloadBtn' download href={OpportunityDetails?.featuresURL !== "" ? OpportunityDetails.featuresURL : "#"} target="_blank">
                  <img alt="file-icon" className="me-2" src={getFileIcon(OpportunityDetails?.featuresURL)} style={{ width: "35px" }} />
                </Button>
              </td>
            </tr>
          </Table>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      <Modal
        isOpen={approvesModal}
        toggle={handleApprove}
        size="lg"
        centered
      >
        <Form className="loginForm claimForm">
          <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center">
            <div className="p-5">
              <h5 className="mb-4">Are you sure you want to approve
                opportunity?</h5>
              <div className="">
                <button type="button" className="btn_Orange lm ms-2" onClick={() => handleApproveClick(opportunityData._id)}>
                  Yes
                </button>

                <button onClick={() => setApprovedModal(false)} type="button" className="btn_White lm" style={{ marginLeft: 20 }}>
                  No
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        isOpen={rejected}
        toggle={handleApprove}
        size="lg"
        centered
      >
        <Form className="loginForm claimForm">
          <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center">
            <div className="p-5">
              <h5 className="mb-4">Are you sure you want to reject
                opportunity?</h5>
              <div className="">
                <button type="button" className="btn_Orange lm ms-2" onClick={() => handleRejectClick(opportunityData._id)}>
                  Yes
                </button>

                <button onClick={() => setRejected(false)} type="button" className="btn_White lm" style={{ marginLeft: 20 }}>
                  No
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default Index;
