import { useEffect, useState } from "react";
import LogoBig from "./assets/images/logoBig.png";
import { Container, Row, Col, Form, Button, Modal, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AxiosInstance from "../../helpers/AxiosRequest";
import url from '../../helpers/development.json';
import { customErrorMessage } from "../../helpers/response";
const monthlyAmount = process.env.REACT_APP_MONTHLY_AMOUNT;
const annualAmount = process.env.REACT_APP_ANNUAL_AMOUNT;
const opayoFormUrl = process.env.REACT_APP_OPAYO_FORM_URL;

const MembershipForm = () => {
    const [amount, setAmount] = useState(annualAmount);
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({});
    const [investorType, setInvestorType] = useState('');
    const [investorModal, setInvestorModal] = useState(false);
    const [hasAuthority, setHasAuthority] = useState("yes");
    const [familyOfficeName, setFamilyOfficeName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [cryptField, setCryptField] = useState('');
    const [formUrl, setFormUrl] = useState(null);

    const onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
    };

    const handleInvestorType = (e) => {
        const value = e.target.value;
        setInvestorType(value);
        value === 'Family Office' && setInvestorModal(true);
    }

    const handleClose = () => {
        if (familyOfficeName.length === 0) {
            setErrorMessage("Name is required");
        } else {
            setErrorMessage("");
            setInvestorModal(false);
        }
    }

    useEffect(() => {
        cryptGenerator()
        setFormUrl(opayoFormUrl)
    }, [investorType, values.addressline1, values.addressline2, values.postcode, amount])

    const cryptGenerator = () => {
        if (investorType && values.addressline1 !== '' && values.addressline2 !== '' && values.postcode !== '' && amount) {
            verifyDetails()
        }
    }

    const verifyDetails = async () => {
        const vendorTxCode = `subscriptionTransaction-${Math.floor(Math.random() * 100000)}`;
        const singup = JSON.parse(localStorage.getItem("signupUser"));
        const splitName = singup.name.split(" ");

        const crypt = `VendorData=Testing Data&Description=Membership subscription&Currency=GBP&VendorTxCode=${vendorTxCode}&amount=${amount}&BillingSurname=${splitName[1]}&BillingFirstnames=${splitName[0]}&BillingAddress1=${values.addressline1}&BillingCity=${values.addressline2}&BillingPostCode=${values.postcode}&BillingCountry=GB&DeliverySurname=${splitName[1]}&DeliveryFirstnames=${splitName[0]}&DeliveryAddress1=${values.addressline1}&DeliveryCity=${values.addressline2}&DeliveryPostCode=${values.postcode}&DeliveryCountry=GB&ApplyAVSCV2=1&Apply3DSecure=1&SuccessURL=${window.location.origin}/payment-response&FailureURL=${window.location.origin}/payment-failure&CustomerEMail=${singup.email}&VendorEMail=support@meridiem.club`;

        const membershipReq = {
            investorType,
            amount,
            annualAmount,
            ...values,
        }
        localStorage.setItem("membershipReq", JSON.stringify(membershipReq))
        try {
            const response = await AxiosInstance.post(url.cryptData, { crypt: crypt })
            setCryptField(response.data.data.ciphertext.toUpperCase())
        } catch (error) {
            customErrorMessage(error?.response?.data?.message || "Something went wrong!");
        }

    }

    const submitFormData = (event) => {
        setValidated(true);
        setIsLoading(true)
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            document.getElementById("paymentOpayoForm").submit();
        }
    }

    return (
        <>
            <div className="login_parent membershipForm">
                <Container fluid>
                    <Row className="height100vh_">
                        <Col lg={6} md={12} sm={12} className="p-0 d-none d-md-none d-lg-block">
                            <div className="leftSlider">
                                <div className="logo text-center">
                                    <img src={LogoBig} alt="" />
                                </div>
                                <div className="d-flex align-itmes-center flex-column contentSlide">
                                    <div className="bottomContent text-center">
                                        <p>
                                            The Mcoin economics is reliant on profitable assets, therefore members<br />
                                            should invest in a majority of companies with a strong asset balance sheet<br />
                                            and likely profitable business model.
                                        </p>
                                        <h4>
                                            Your investment can go down as well as up.<br />
                                            Only invest funds you can afford to lose.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="p-0">
                            <div className="rightForm">
                                <div className="d-flex flex-column justify-content-center col-10 col-xl-8 mx-auto h-100 gap50">
                                    <h4 className="loginTitle">Membership</h4>
                                    <Form noValidate method="post" className="loginForm" validated={validated}
                                        id="paymentOpayoForm"
                                        onSubmit={submitFormData}
                                        action={formUrl}
                                    >

                                        <Form.Group className="mb-4 inputOuter_">
                                            <Form.Label className="defLabel_">
                                                Investor Type <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Select
                                                className="defInput_"
                                                value={investorType}
                                                name="investor_type"
                                                required
                                                onChange={handleInvestorType}
                                            >``
                                                <option value="" defaultValue>Select</option>
                                                <option value="Entrepreneur">Entrepreneur</option>
                                                <option value="Venture Capitalist">Venture Capitalist</option>
                                                <option value="Individual Sophisticated Investor">Individual Sophisticated Investor</option>
                                                <option value="High Net Worth Individual">High net worth individual</option>
                                                <option value="Retail Investor">Retail Investor</option>
                                                <option value="Not an Investor">Not an Investor</option>
                                                <option value="Family Office">Family office</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-4 inputOuter_" controlId="formBasicPassword">
                                            <Form.Label className="defLabel_">
                                                Address Line 1 <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="addressline1"
                                                placeholder="Address Line 1"
                                                className="mb-3 inputs defInput_"
                                                required
                                                onChange={onFormChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Address.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group
                                            className="mb-4 inputOuter_" >
                                            <Form.Label className="defLabel_">
                                                City<span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="addressline2"
                                                placeholder="Enter City"
                                                className="mb-3 inputs defInput_"
                                                required
                                                onChange={onFormChange}
                                                maxLength={15}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-4 inputOuter_" controlId="formBasicPassword">
                                            <Form.Label className="defLabel_">
                                                Postcode <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="postcode"
                                                placeholder="Enter Postcode"
                                                className="mb-3 inputs defInput_"
                                                required
                                                onChange={onFormChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Post code.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-4 inputOuter_ customRadioBtn">
                                            <Form.Label className="defLabel">Membership Type</Form.Label>
                                            <div>
                                                <div className="radioInput mb-2 form-check form-check-inline grayBG">
                                                    <input name="same" type="radio" id="Annual" className="form-check-input" value="Annual" onChange={() => setAmount(annualAmount)} required checked={amount === annualAmount} />
                                                    <label title="" htmlFor="Annual" className="form-check-label"><span className="font20Bold">Annual</span><br />£{annualAmount}</label>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a any one.
                                                    </Form.Control.Feedback>
                                                </div>
                                                <div className="radioInput form-check form-check-inline grayBG">
                                                    <input name="same" type="radio" id="Monthly" className="form-check-input" value="Monthly" onChange={() => setAmount(monthlyAmount)} required checked={amount === monthlyAmount} />
                                                    <label title="" htmlFor="Monthly" className="form-check-label"><span className="font20Bold">Monthly</span><br />£{monthlyAmount}</label>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a membership type.
                                                    </Form.Control.Feedback>
                                                </div>
                                            </div>
                                            <p className="font12BLk">Cancel at any time with 1 month prior notice.</p>
                                        </Form.Group>

                                        <input type="hidden" name="VPSProtocol" value="3.00" />
                                        <input type="hidden" name="TxType" value="PAYMENT" />
                                        <input type="hidden" name="Vendor" value="beirisoltd" />
                                        <input type="hidden" name="Crypt" value={`@${cryptField}`} />

                                        <Button type="submit" className='btn_Black'>Submit Application
                                            {isLoading ? <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                            </>
                                                : ''
                                            }
                                        </Button>

                                        <div className="text-center mt-4">
                                            <NavLink to="/" className="linkBlk">Back to Home</NavLink>
                                        </div>

                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Modal show={investorModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered className="otpModel">
                <Modal.Header className="" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Family Office
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-3">
                        <div className="col-12">
                            <Form.Group className="mb-4 inputOuter_">
                                <Form.Label className="defLabel_">
                                    Please add name of family office <span className="requireStar">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Please add name of family office"
                                    className="defInput_"
                                    name="familyOfficeName"
                                    value={familyOfficeName}
                                    onChange={(e) => setFamilyOfficeName(e.target.value)}
                                    required
                                />
                                {errorMessage.length > 0 && familyOfficeName.length <= 0 && (
                                    <span className="text-danger">
                                        {errorMessage}
                                    </span>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group className="mb-4 customRadioBtn">
                                <Form.Label className="defLabel_ d-block">
                                    Do you have full authority to make decisions on their behalf?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    label="Yes"
                                    name="same"
                                    type="radio"
                                    id="yes"
                                    value="yes"
                                    className="radioInput mb-0"
                                    checked={hasAuthority === "yes"}
                                    onChange={(e) => setHasAuthority(e.target.value)}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="same"
                                    type="radio"
                                    id="no"
                                    value="no"
                                    checked={hasAuthority === "no"}
                                    onChange={(e) => setHasAuthority(e.target.value)}
                                    className="radioInput mb-0"
                                />
                            </Form.Group>
                        </div>
                        {hasAuthority === "no" &&
                            <div className="col-12 font14Red text-danger">
                                Please change your status to <strong>Individual Sophisticated Investor</strong>.
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn_White" onClick={handleClose}>Cancel</Button>
                    <Button type="button" className='btn_Orange' onClick={handleClose} disabled={hasAuthority === "no"}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default MembershipForm;