import React, { useCallback, useEffect, useState } from "react";
import { Container, Form, Breadcrumb, Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import AxiosInstance from "../../helpers/AxiosRequest";
import { customErrorMessage } from "../../helpers/response";

const ExitInvestmentAmountList = () => {
  const [list, setList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const limit = 10;

  const handleList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post("/exitinvestmentamount/list", {
        page: page,
        limit: limit,
      });
      setList(data.data.data);
      setTotalDataCount(data.data.metadata.total);
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  }, [page, limit]);

  useEffect(() => {
    handleList();
  }, [handleList]);

  return (
    <div className="mt-4 mb-4 page_main dashboard  ">
      <Container fluid>
        <h1>
          Exit Investment Amount List
          <span className="font14Blk"></span>
        </h1>

        <div className="divBg_white mb-3 ">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="breadcrumb-item" to={"/dashboard"}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Exit Investment Amount List
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="tableSM">
            <Table responsive striped borderless>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Assets Name</th>
                  <th className="text-center">User Name</th>
                  <th className="text-center">Investment Amount</th>
                  <th className="text-center">Percentage</th>
                  <th className="text-center">Exit Amount</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={12}>
                      <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                        <Spinner size="md" variant="dark" />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {list.length > 0 ? (
                      list.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.opportunities?.company_name}</td>
                            <td>{data?.investmentRound?.investment_Title}</td>
                            <td className="text-center">{data.user.name}</td>
                            <td className="text-center">
                              {data?.investmentlogs?.amount}
                            </td>
                            <td className="text-center">{data.percent}</td>
                            <td className="text-center">
                              {data.exit_investment_amount}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <td colSpan={12}>
                        <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                          No data found!
                        </div>
                      </td>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ExitInvestmentAmountList;
