import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const CommonPagination = ({ filter, setFilter, totalPage }) => {
    const handleLimitChange = (e) => setFilter(prevFilter => ({ ...prevFilter, limit: Number(e.target.value), page: 1 }))
    return (
        <>
            {Number(totalPage || 1) > 1 ? (
                <Pagination>
                    <PaginationItem
                        disabled={filter.page === 1}
                    >
                        <PaginationLink
                            previous
                            onClick={() => setFilter(prevFilter => ({ ...prevFilter, page: prevFilter.page - 1 }))}
                        />
                    </PaginationItem>
                    {[...Array(totalPage)].map((_, i) => (
                        <PaginationItem key={i + 1} disabled={filter.page === i + 1} active={filter.page === i + 1}>
                            <PaginationLink
                                onClick={() => setFilter(prevFilter => ({ ...prevFilter, page: i + 1 }))}
                            >{i + 1}</PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem disabled={filter.page === totalPage}>
                        <PaginationLink next
                            onClick={() => setFilter(prevFilter => ({ ...prevFilter, page: prevFilter.page + 1 }))}
                        />
                    </PaginationItem>
                    <select
                        className="form-select ms-2 selectboder"
                        aria-label="Default select example"
                        value={filter.limit}
                        onChange={handleLimitChange}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </Pagination>
            ) : null}
        </>
    )
}

export default CommonPagination