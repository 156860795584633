import React, { useEffect } from 'react';
import { useLocation } from "react-router";
import Header from "./Header";
import Footer from "./Footer";

const TermsAndConditions = () => {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    return (
        <>
            <Header />

            <div className='middlePart bgWhite'>
                <div className="container py-5">
                    <div className="fontBlack34Bold mb-5">Terms and conditions</div>
                    <p className="font18Black mb-4">
                        Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern The Meridiem’s relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.
                    </p>
                    <p className="font18Black mb-4">
                        The term Meridiem (Beiriso LTD)) or ‘us’ or ‘we’ refers to the owner of the website whose registered office is Latif House, First Way,  HA9 0JD. Our company registration number is Registered in England No: 07266676. The term ‘you’ refers to the user or viewer of our website. The use of this website is subject to the following terms of use: The content of the pages of this website is for your general information and use only. It is subject to change without notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                    </p>
                    <p className="font18Black mb-4">
                        Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements. This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions. All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website. Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.
                    </p>
                    <p className="font18Black mb-4">
                        From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s). Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.
                    </p>
                    <div className="fontBlack20Bold mb-4 text-uppercase">Refund, transfers, and cancellation</div>
                    <p className="font18Black mb-4">
                        <strong>Refund Policy</strong><br />
                        If you are not satisfied with our service after purchase of your membership, we shall give you a full refund if you have cancelled within 30days from the date of purchase. If you cancel within 6 months you will be refunded the remaining months left on your membership. If you have consumed from the bar from the credit provided to you upon subscribing, the total amount consumed will be deducted from the refund due.<br /><br />
                        Your membership is non intra transferable.
                    </p>

                </div>

                {/* Last BlankPart */}
                <div className='lastBG'></div>
                
            </div>

            <Footer />

        </>
    )
}

export default TermsAndConditions