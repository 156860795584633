import { useRoutes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
// import { Web3Modal } from "@web3modal/react";
// import { configureChains, createClient, WagmiConfig } from "wagmi";
// import { arbitrum, mainnet, polygon } from "wagmi/chains";
import AddBG from "./routes/AddBG";
import routes from "./routes/routes";

import "react-toastify/dist/ReactToastify.css";
import "../src/assets/scss/fonts.scss";
import "../src/custom.scss";
import "../src/assets/scss/style.scss";
import "../src/assets/scss/app.scss";
import "../src/assets/scss/login.scss";
import "../src/assets/scss/styleNew.scss";
import "../src/pages/publicPages/assets/scss/styleWebsite.scss";
import "../src/pages/protectedPages/assets/scss/styleWebsite.scss";
import "../src/assets/scss/developer.scss";
import "../src/assets/admin/scss/App.scss";
import "../src/pages/protectedPages/opportunity/opportunity.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useMemo } from "react";
// import MessagingService from "./services/MessagingService";
import MessagingService from "./firebase-service/messaging-service";
import { getNotifications } from "./store/user/action";
import { useDispatch } from "react-redux";

function App() {
  const routing = useRoutes(routes());
  const user = useMemo(
    () =>
      localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
    []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    MessagingService.onMessage(MessagingService.messaging, async (payload) => {
      if (payload.data.type.includes("success")) {
        toast.success(payload.notification.title);
      } else if (payload.data.type.includes("error")) {
        toast.error(payload.notification.title);
      } else if (payload.data.type.includes("info")) {
        toast.success(payload.notification.title);
      } else {
        toast.error(payload.notification.title);
      }
      if (user && user.name) {
        try {
          console.log("here");
          //   setPage(1);
          //   setHasMore(true);
          //   await getNotifications();
          dispatch(getNotifications({ page: 1 }));
        } catch (error) {
          console.error("Error fetching notifications", error);
        }
      }
    });
  }, [user]);
  return (
    <>
      <AddBG />
      {routing}
      <ToastContainer
        icon={false}
        position={"top-center"}
        newestOnTop={true}
        pauseOnHover
        bodyClassName={"customToastBody111"}
        hideProgressBar={true}
      />
    </>
  );
}

export default App;
