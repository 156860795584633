import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  Badge,
  DropdownItem,
  Button,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
import { Breadcrumb, Layout } from "antd";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getInvestmentRounds } from "../../../store/admin/action";
import { ViewIcon } from "../../common/Icons";
import { Form, Spinner, Tab, Tabs } from "react-bootstrap";
import CommonPagination from "../../common/CommonPagination";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../../helpers/AxiosRequest";
import { customErrorMessage } from "../../../helpers/response";
import { handleNValue } from "../../../helpers/handleNValue";
import { toast } from "react-toastify";

const formatDate = (date) => (date ? moment(date).format("YYYY/MM/DD") : "");
const investmentStatusClasss = {
  upcoming: "BadgeclassMarketing",
  marketing: "Badgeclassinactive",
  fund: "BadgeclassFundRaise",
  voting: "BadgeclassVoting",
  result: "Badgeclassactive",
  unknown: "Badgeclass",
};

const _investmentStatus = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
  invested: "invested",
  dividend: "dividend",
};
const status = {
  upcoming: "upcoming",
  marketing: "marketing",
  fund: "fund",
  voting: "voting",
  result: "result",
  unknown: "unknown",
};

const InvestmentRound = () => {
  const { Content } = Layout;
  const navigate = useNavigate();
  const [showExitInvestmentModal, setExitInvestmentModal] = useState({
    show: false,
    id: null,
    opportunities: [],
  });
  const toggleExitInvestment = (id, opportunities, investment_Title) =>
    setExitInvestmentModal({
      show: !showExitInvestmentModal.show,
      id,
      opportunities,
      investment_Title,
    });
  const [exitmodal, setexitModal] = useState(false);
  const toggle1 = () => setexitModal(!exitmodal);

  const timeoutRef = useRef(null);
  const dispatch = useDispatch();
  const initFilter = { invetment_type: "", page: 1, limit: 10, search: "" };
  const [filter, setFilter] = useState(initFilter);
  const [search, setSearch] = useState("");
  const [investmentDetail, setInvestmentDetail] = useState(null);
  const { investmentRounds } = useSelector((state) => state.adminStore);

  const totalPage = useMemo(
    () =>
      Math.ceil((investmentRounds?.metaData?.total || 0) / filter.limit) || 1,
    [investmentRounds, filter]
  );

  useEffect(() => {
    dispatch(getInvestmentRounds({ payload: filter }));
  }, [dispatch, filter]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(
      () => setFilter((prev) => ({ ...prev, search: value, page: 1 })),
      700
    );
  };

  const navigateToDetail = (obj) => {
    const mark =
      obj.status === status.result &&
      obj.investmentStatus === _investmentStatus.approved
        ? "false"
        : "true";
    navigate(`/admin/investment-round-details?mark=${mark}`, { state: obj });
  };
  return (
    <Fragment>
      <Content className="site-layout-background">
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <Breadcrumb.Item>
            <Link to="/admin/dashboard">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Investment Round</Breadcrumb.Item>
        </Breadcrumb>

        <hr className="lineTheme" />
        <div className="page-card-view">
          <div className="card-header-part mb-2">
            <div className="card-header-action">
              <div className="d-flex justify-content-between align-items-center">
                <h5 class="fw-bold fs-5 mt-2">INVESTMENT ROUND</h5>
                <div className="form-group mb-0 mr-3">
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="table-part">
            <div className="table-responsive">
              <Table bordered className="mb-0">
                <thead className="fontsize mb-0">
                  <tr>
                    <th>
                      <span className="d-flex justify-content-between align-items-center">
                        Title
                        <SortingBtn onClick={null} />
                      </span>
                    </th>
                    <th>
                      <span className="d-flex justify-content-between align-items-center">
                        Investment Amount (£)
                        <SortingBtn onClick={null} />
                      </span>
                    </th>
                    <th>
                      <span className="d-flex justify-content-between align-items-center">
                        Marketing Date
                        <SortingBtn onClick={null} />
                      </span>
                    </th>
                    <th>
                      <span className="d-flex justify-content-between align-items-center">
                        Fund Raise Date
                        <SortingBtn onClick={null} />
                      </span>
                    </th>
                    <th>
                      <span className="d-flex justify-content-between align-items-center">
                        Voting Date
                        <SortingBtn onClick={null} />
                      </span>
                    </th>
                    <th>
                      <span className="d-flex justify-content-between align-items-center">
                        Result Date
                        <SortingBtn onClick={null} />
                      </span>
                    </th>
                    <th>
                      <span className="d-flex justify-content-between align-items-center">
                        Status
                        <SortingBtn onClick={null} />
                      </span>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {investmentRounds.loading ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                          <Spinner size="md" variant="dark" />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {investmentRounds.data.length > 0 ? (
                        investmentRounds.data.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.investment_Title || ""}</td>
                            <td>{item?.investment_Amount || 0}</td>
                            <td>
                              Start : {formatDate(item?.marketig_start_date)}
                              <br />
                              End : {formatDate(item?.marketing_end_date)}
                            </td>
                            <td>
                              Start : {formatDate(item?.fund_raise_start_date)}
                              <br />
                              End : {formatDate(item?.fund_raise_end_date)}
                            </td>
                            <td>
                              Start : {formatDate(item?.voting_start_date)}
                              <br />
                              End : {formatDate(item?.voting_end_date)}
                            </td>
                            <td>{formatDate(item?.result_date)}</td>
                            {/* <td>
                                                        <div className="d-flex justify-content-center">
                                                            <span className="me-2 ms-2">
                                                                <button
                                                                    color=""
                                                                    className="viewbutton"
                                                                    onClick={() => setInvestmentDetail(item)}
                                                                >
                                                                    <ViewIcon />
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </td> */}
                            <td>
                              <span className="ms-2">
                                <Badge
                                  className={`${
                                    investmentStatusClasss[item?.status]
                                  }`}
                                  color=""
                                >
                                  {item?.status}
                                </Badge>
                              </span>
                            </td>
                            <td>
                              {/* <Link to="/investmentRound/form"
                                                        className="btn my-submit-button addclubbutton ms-3 mb-2">Read More
                                                    </Link> */}
                              <UncontrolledDropdown group>
                                <Button color="">Please Select</Button>
                                <DropdownToggle caret color="" />
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => navigateToDetail(item)}
                                  >
                                    View Detail{" "}
                                  </DropdownItem>
                                  {item.investmentStatus == "invested" && (
                                    <DropdownItem
                                      onClick={() =>
                                        toggleExitInvestment(
                                          item._id,
                                          item.opportunities,
                                          item.investment_Title
                                        )
                                      }
                                    >
                                      Exit Investment
                                    </DropdownItem>
                                  )}
                                  {/* <DropdownItem onClick={toggle1}>
                                    Exit Investment
                                  </DropdownItem> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                              No data found!
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
                {/* <tbody>
                                    <tr>
                                        <td>Crypotocurrency market</td>
                                        <td>10,000,00</td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 16 Feb 2023 - 10:00 <br />End : 28 Feb 2023 - 10:00
                                        </td>
                                        <td>05 Mar 2023 - 10:00</td>
                                        <td>
                                            <span className="ms-2">
                                                <Badge className="Badgeclass" color="">
                                                    Approved
                                                </Badge>
                                            </span>
                                        </td>
                                        <td> <Link to="/investmentRound/form"
                                            className="btn my-submit-button addclubbutton ms-3 mb-2">Read More
                                        </Link>

                                            <UncontrolledDropdown group >
                                                <Button color="">Please Select</Button>
                                                <DropdownToggle caret color="" />
                                                <DropdownMenu >
                                                    <DropdownItem >Edit </DropdownItem>
                                                    <DropdownItem>Approve</DropdownItem>
                                                    <DropdownItem >Reject</DropdownItem>
                                                    <DropdownItem onClick={toggle}>Add Dividend</DropdownItem>
                                                    <DropdownItem onClick={toggle1}>Exit Assets</DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Crypotocurrency market</td>
                                        <td>10,000,00</td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 16 Feb 2023 - 10:00 <br />End : 28 Feb 2023 - 10:00
                                        </td>
                                        <td>05 Mar 2023 - 10:00</td>
                                        <td>
                                            <span className="ms-2">
                                                <Badge className="Badgeclassinactive" color="">
                                                    Rejected
                                                </Badge>
                                            </span>
                                        </td>
                                        <td> <Link to="/investmentRound/form"
                                            className="btn my-submit-button addclubbutton ms-3 mb-2">Read More
                                        </Link>

                                            <UncontrolledDropdown group >
                                                <Button color="">Please Select</Button>
                                                <DropdownToggle caret color="" />
                                                <DropdownMenu >
                                                    <DropdownItem >Edit </DropdownItem>
                                                    <DropdownItem>Approve</DropdownItem>
                                                    <DropdownItem >Reject</DropdownItem>
                                                    <DropdownItem onClick={toggle}>Add Dividend</DropdownItem>
                                                    <DropdownItem onClick={toggle1}>Exit Assets</DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Crypotocurrency market</td>
                                        <td>10,000,00</td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 16 Feb 2023 - 10:00 <br />End : 28 Feb 2023 - 10:00
                                        </td>
                                        <td>05 Mar 2023 - 10:00</td>
                                        <td>
                                            <span className="ms-2">
                                                <Badge className="BadgeclassMarketing" color="">
                                                    Marketing
                                                </Badge>
                                            </span>
                                        </td>
                                        <td> <Link to="/investmentRound/form"
                                            className="btn my-submit-button addclubbutton ms-3 mb-2">Read More
                                        </Link>

                                            <UncontrolledDropdown group >
                                                <Button color="">Please Select</Button>
                                                <DropdownToggle caret color="" />
                                                <DropdownMenu >
                                                    <DropdownItem >Edit </DropdownItem>
                                                    <DropdownItem>Approve</DropdownItem>
                                                    <DropdownItem >Reject</DropdownItem>
                                                    <DropdownItem onClick={toggle}>Add Dividend</DropdownItem>
                                                    <DropdownItem onClick={toggle1}>Exit Assets</DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Crypotocurrency market</td>
                                        <td>10,000,00</td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 16 Feb 2023 - 10:00 <br />End : 28 Feb 2023 - 10:00
                                        </td>
                                        <td>05 Mar 2023 - 10:00</td>
                                        <td>
                                            <span className="ms-2">
                                                <Badge className="BadgeclassFundRaise" color="">
                                                    Fund Raise
                                                </Badge>
                                            </span>
                                        </td>
                                        <td> <Link to="/investmentRound/form"
                                            className="btn my-submit-button addclubbutton ms-3 mb-2">Read More
                                        </Link>

                                            <UncontrolledDropdown group >
                                                <Button color="">Please Select</Button>
                                                <DropdownToggle caret color="" />
                                                <DropdownMenu >
                                                    <DropdownItem >Edit </DropdownItem>
                                                    <DropdownItem>Approve</DropdownItem>
                                                    <DropdownItem >Reject</DropdownItem>
                                                    <DropdownItem onClick={toggle}>Add Dividend</DropdownItem>
                                                    <DropdownItem onClick={toggle1}>Exit Assets</DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Crypotocurrency market</td>
                                        <td>10,000,00</td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 15 Jan 2023 - 10:00 <br />End : 30 Jan 2023 - 10:00
                                        </td>
                                        <td>
                                            Start : 16 Feb 2023 - 10:00 <br />End : 28 Feb 2023 - 10:00
                                        </td>
                                        <td>05 Mar 2023 - 10:00</td>
                                        <td>
                                            <span className="ms-2">
                                                <Badge className="BadgeclassVoting" color="">
                                                    Voting
                                                </Badge>
                                            </span>
                                        </td>
                                        <td> <Link to="/investmentRound/form"
                                            className="btn my-submit-button addclubbutton ms-3 mb-2">Read More
                                        </Link>

                                            <UncontrolledDropdown group >
                                                <Button color="">Please Select</Button>
                                                <DropdownToggle caret color="" />
                                                <DropdownMenu >
                                                    <DropdownItem >Edit </DropdownItem>
                                                    <DropdownItem>Approve</DropdownItem>
                                                    <DropdownItem >Reject</DropdownItem>
                                                    <DropdownItem onClick={toggle}>Add Dividend</DropdownItem>
                                                    <DropdownItem onClick={toggle1}>Exit Assets</DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                </tbody> */}
              </Table>
            </div>
          </div>
        </div>

        <div className="PaginationLink">
          <CommonPagination
            filter={filter}
            setFilter={setFilter}
            totalPage={totalPage}
          />
        </div>
      </Content>

      <Modal isOpen={exitmodal} toggle={toggle1} size="mg" centered>
        <ModalHeader toggle={toggle1}>
          <h5 className="fw-bold fs-5">EXIT INVESTMENT</h5>{" "}
        </ModalHeader>
        <ModalBody>
          <Table className="modeltable mb-0" bordered responsive>
            <tr>
              <td>
                <div class="col-sm-12 mb-2">
                  <label>
                    Assets Name :<b>crypto</b>
                  </label>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="col-sm-12 mb-2">
                  <label>
                    Assets Value<span className="fontred">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-0 position-relative"
                    placeholder="10000"
                    name="USDC"
                  />
                  <div className=" position-absolute usdcexit">USDC</div>
                </div>
              </td>
            </tr>
          </Table>
        </ModalBody>
        <ModalFooter>
          <ModalFooter>
            <Button color="" className="my-reset-button ms-2" onClick={toggle1}>
              Cancel
            </Button>
            <Button color="" className="my-submit-button" onClick={toggle1}>
              Submit
            </Button>
          </ModalFooter>
        </ModalFooter>
      </Modal>

      {/* ==== add divident popup ==== */}
      {showExitInvestmentModal.show && (
        <ExitInvestmentPopup
          isOpen={showExitInvestmentModal.show}
          toggle={() => toggleExitInvestment(null, [], "")}
          investmentId={showExitInvestmentModal}
        />
      )}
    </Fragment>
  );
};

const ExitInvestmentPopup = ({ isOpen, toggle, investmentId }) => {
  const schema = Yup.object({
    category: Yup.string().nullable().required("This field is required."),
    assets: Yup.string().nullable().required("This field is required."),
    reason: Yup.string().nullable().required("This field is required."),
  });

  const formik = useFormik({
    initialValues: {
      category: investmentId.opportunities[0].investment_category,
      reason: "",
      assets: investmentId.investment_Title,
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const { data } = await AxiosInstance.post(
          `/admin/exitinvestment/create`,
          {
            category_name: values.category,
            reason: values.reason,
            investment_id: investmentId.id,
          }
        );
        toast.success(
          data?.message || "ExitInvestment request added successfully!"
        );
        resetForm();
        toggle();
      } catch (error) {
        customErrorMessage(
          error?.response?.data?.message || error || "Something went wrong!"
        );
        console.log("Error making payment:", error);
      }
    },
  });

  const closeModal = () => (formik.isSubmitting ? null : toggle());

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="mg" centered>
      <ModalHeader toggle={closeModal}>
        <h5 className="fw-bold fs-5">ADD EXITINVESTMENT</h5>{" "}
      </ModalHeader>
      <ModalBody>
        <div class="col-sm-12 mb-2">
          <label>
            Category<span className="fontred">*</span>
          </label>
          <input
            type="text"
            class="form-control rounded-0 position-relative"
            placeholder=""
            name="category"
            // onChange={(e) => formik.setFieldValue("category", e.target.value)}
            disabled
            value={investmentId.opportunities[0].investment_category}
            // onBlur={formik.handleBlur}
          />
          {formik.errors.category && formik.touched.category ? (
            <Form.Text className="text-danger">
              {formik.errors.category}
            </Form.Text>
          ) : null}
        </div>
        <div class="col-sm-12 mb-2">
          <label>
            Assets<span className="fontred">*</span>
          </label>
          <input
            type="text"
            class="form-control rounded-0 position-relative"
            placeholder=""
            name="assets"
            disabled
            value={investmentId.investment_Title}
          />
          {formik.errors.assets && formik.touched.assets ? (
            <Form.Text className="text-danger">
              {formik.errors.assets}
            </Form.Text>
          ) : null}
        </div>
        <div class="col-sm-12 mb-2">
          <label>
            Reason<span className="fontred">*</span>
          </label>
          <input
            type="text"
            class="form-control rounded-0 position-relative"
            placeholder=""
            name="reason"
            onChange={(e) => formik.setFieldValue("reason", e.target.value)}
            value={formik.values.reason}
            onBlur={formik.handleBlur}
          />
          {formik.errors.reason && formik.touched.reason ? (
            <Form.Text className="text-danger">
              {formik.errors.reason}
            </Form.Text>
          ) : null}
        </div>
        {/* </td>
                    </tr>
                </Table> */}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={formik.isSubmitting}
          className="my-reset-button ms-2"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          type="button"
          disabled={formik.isSubmitting}
          className="my-submit-button"
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? "Please wait..." : "Submit"}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

const InvestroundDetailPopup = ({ investmentDetail, onHide }) => {
  return (
    <Modal
      show={investmentDetail === null ? false : true}
      onHide={onHide}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="fw-bold fs-5">INVESTMENT ROUND DETAILS</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="modeltable mb-0" bordered responsive>
          <tr>
            <th>Investment Title</th>
            <td>{investmentDetail?.investment_Title || ""}</td>
          </tr>

          <tr>
            <th>Investment Amount</th>
            <td>{investmentDetail?.investment_Amount || ""}</td>
          </tr>

          <tr>
            <th>Marketing Start Date</th>
            <td>{formatDate(investmentDetail?.marketig_start_date)}</td>
          </tr>

          <tr>
            <th>Marketing End Date</th>
            <td>{formatDate(investmentDetail?.marketing_end_date)}</td>
          </tr>

          <tr>
            <th>Fund Raise Start Date</th>
            <td>{formatDate(investmentDetail?.fund_raise_start_date)}</td>
          </tr>

          <tr>
            <th>Fund Raise End Date</th>
            <td>{formatDate(investmentDetail?.fund_raise_end_date)}</td>
          </tr>

          <tr>
            <th>Voting Start Date</th>
            <td>{formatDate(investmentDetail?.voting_start_date)}</td>
          </tr>

          <tr>
            <th>Voting End Date</th>
            <td>{formatDate(investmentDetail?.voting_end_date)}</td>
          </tr>

          <tr>
            <th>Result Date</th>
            <td>{formatDate(investmentDetail?.result_date)}</td>
          </tr>
        </Table>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

const SortingBtn = ({ onClick }) => (
  <span role="button" onClick={onClick} className="d-flex flex-column">
    <svg
      className="mb-1"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
    >
      <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
    >
      <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
    </svg>
  </span>
);

export default InvestmentRound;
