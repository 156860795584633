import React, { useMemo, useState } from "react";
import { Breadcrumb, Layout } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import AxiosInstance from "../../../helpers/AxiosRequest";
import { customErrorMessage } from "../../../helpers/response";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { handleNValue } from "../../../helpers/handleNValue";
import ReactDatePicker from "react-datepicker";

const _investmentStatus = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
  invested: "invested",
  dividend: "dividend",
};
const status = {
  upcoming: "upcoming",
  marketing: "marketing",
  fund: "fund",
  voting: "voting",
  result: "result",
  unknown: "unknown",
};
const formatDate = (date) => (date ? moment(date).format("DD MMM YYYY") : "");
const InvestmentDetails = () => {
  const { Content } = Layout;
  const location = useLocation();
  const navigate = useNavigate();
  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const mark = queryParams.get("mark");

  const _disable = useMemo(() => {
    return location.state.status === status.result &&
      location.state.investmentStatus === _investmentStatus.approved
      ? false
      : true;
  }, [location]);

  const [showDividendModal, setShowDividendModal] = useState(false);

  const handleMarkAsInvested = async (e) => {
    try {
      e.target.innerHTML =
        '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait';
      e.target.disabled = true;
      const { data } = await AxiosInstance.get(
        `/admin/investment/markInvested/${location.state._id}`
      );
      navigate(`/admin/investment-round-details?mark=${"true"}`, {
        state: location.state,
      });
      toast.success(data.message);
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
      console.error("Error making payment:", error);
    } finally {
      e.target.innerHTML = "Marked Invested";
      e.target.disabled = false;
    }
  };

  return (
    <Content className="site-layout-background">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/admin/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/admin/investment-rounds">Investment Round</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item> Investment Details</Breadcrumb.Item>
      </Breadcrumb>
      <hr className="lineTheme" />
      <div className="site-card-border-less-wrapper">
        <div className="card-header-part d-flex justify-content-between">
          <h5 className="fw-bold fs-5 mt-2">INVESTMENT DETAILS</h5>
          {location.state?.investmentStatus == "approved" && (
            <button
              onClick={(e) => handleMarkAsInvested(e)}
              className="btn_Orange"
            >
              Mark as Invested
            </button>
          )}
          {location.state?.investmentStatus == "invested" && (
            <button
              onClick={() => setShowDividendModal(true)}
              className="btn_Orange"
            >
              Mark as Dividend
            </button>
          )}
        </div>
        <Row>
          <Col xl={6} lg={12}>
            <h4 className="fw-bold ms-3 mt-3">
              {location.state?.investment_Title}
              <span className="ms-2">
                <Badge className="Badgeclass text-capitalize" color="">
                  {location.state.investmentStatus}
                </Badge>
              </span>
            </h4>
            <Table className="investmentroundtable" responsive>
              <tr>
                <th>Highest Voted Opportunity</th>
                <td>{"-"}</td>
              </tr>
              <tr>
                <th>Investment Amount</th>
                <td>{location.state?.investment_Amount || "00"}</td>
              </tr>
              <tr>
                <th>Marketing</th>
                <td>
                  Start : {formatDate(location.state?.marketig_start_date)} -
                  End : {formatDate(location.state?.marketing_end_date)}
                </td>
              </tr>
              <tr>
                <th>Fund Raise</th>
                <td>
                  Start : {formatDate(location.state?.fund_raise_start_date)} -
                  End : {formatDate(location.state?.fund_raise_end_date)}
                </td>
              </tr>
              <tr>
                <th>Voting</th>
                <td>
                  Start :{formatDate(location.state?.voting_start_date)} - End :{" "}
                  {formatDate(location.state?.voting_end_date)}
                </td>
              </tr>
              <tr>
                <th>Result</th>
                <td>{formatDate(location.state?.result_date)}</td>
              </tr>
              <tr>
                <th>Details</th>
                {/* <td>Link to details</td> */}
                <td>-</td>
              </tr>
            </Table>
          </Col>
          <Col xl={6} lg={12}>
            <h4 className="fw-bold ms-3 mt-3">Opportunities</h4>
            <Table className="investmentroundtable" responsive>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Type</th>
                  <th>Company Name</th>
                  <th>Turnover Yearly</th>
                </tr>
              </thead>
              <tbody>
                {location.state.opportunities.map((item, i) => (
                  <tr>
                    <td>{item.investment_title}</td>
                    <td>{item.investment_type}</td>
                    <td>{item.company_name}</td>
                    <td>{item.turnover_average_yearly}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <AddDividendPopup
        isOpen={showDividendModal}
        toggle={setShowDividendModal}
        location={location}
      />
    </Content>
  );
};

const AddDividendPopup = ({ isOpen, toggle, location }) => {
  const schema = Yup.object({
    amount: Yup.string().nullable().required("This field is required."),
    date: Yup.date().nullable().required("This field is required."),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      date: "",
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const selectedDate = moment(values.date);
        const currentDate = moment();
        const combinedDate = selectedDate.set({
          hour: currentDate.get("hour"),
          minute: currentDate.get("minute"),
          second: currentDate.get("second"),
        });

        const formattedDate = combinedDate.format("YYYY-MM-DD HH:mm:ss");

        const { data } = await AxiosInstance.post(
          `/admin/investment/markDividend/${location.state._id}`,
          {
            amount: values.amount,
            date: formattedDate,
          }
        );
        toast.success(data?.message || "Dividend added successfully!");
        resetForm();
        toggle();
      } catch (error) {
        customErrorMessage(
          error?.response?.data?.message || error || "Something went wrong!"
        );
        console.log("Error", error);
      }
    },
  });

  const closeModal = () => (formik.isSubmitting ? null : toggle());

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="mg" centered>
      <ModalHeader toggle={closeModal}>
        <h5 className="fw-bold fs-5">ADD DIVIDEND</h5>{" "}
      </ModalHeader>
      <ModalBody>
        <div class="col-sm-12 mb-2">
          <label>
            Dividend Amount<span className="fontred">*</span>
          </label>
          <input
            type="text"
            class="form-control rounded-0 position-relative"
            placeholder=""
            name="amount"
            onChange={(e) => formik.setFieldValue("amount", handleNValue(e))}
            value={formik.values.amount}
            onBlur={formik.handleBlur}
          />
          {formik.errors.amount && formik.touched.amount ? (
            <Form.Text className="text-danger">
              {formik.errors.amount}
            </Form.Text>
          ) : null}
        </div>
        <div class="col-sm-12 mb-2">
          <label>
            Dividend Date<span className="fontred">*</span>
          </label>
          <div className="customDatePicker">
            <ReactDatePicker
              selected={formik.values.date}
              onChange={(date) => formik.setFieldValue("date", date)}
              name="marketig_start_date"
              // minDate={new Date()}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              showTimeInput
              placeholderText="dd/mm/yyyy"
              className="form-control w-100"
              timeIntervals={15} // Optional: Set the time interval if needed
              timeCaption="Time"
            />
          </div>
          {formik.errors.date && formik.touched.date ? (
            <Form.Text className="text-danger">{formik.errors.date}</Form.Text>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={formik.isSubmitting}
          className="my-reset-button ms-2"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          type="button"
          disabled={formik.isSubmitting}
          className="my-submit-button"
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? "Please wait..." : "Submit"}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default InvestmentDetails;
