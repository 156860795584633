import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  NavLink,
  Form,
  Breadcrumb,
  Modal,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AxiosInstance from "../../helpers/AxiosRequest";
import { toast } from "react-toastify";
import { customErrorMessage } from "../../helpers/response";


const BuyassetsPage = () => {
  const [list, setList] = useState({})
  const [alertMessage, setalertMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const limit = 10;
  const totalPages = useMemo(() => {
    return Math.ceil((totalDataCount / limit) || 1)
  }, [totalDataCount, limit]);


  const handleBuyAndCancel = async (event, id) => {
    try {
      setIsLoading(true)
      const { data } = await AxiosInstance.get(`/assets/${event === "Buy" ? "buy" : "cancel"}/${id}`);
      handleList()
      toast.success(data?.message || "Asset Sell Successfully!")
    } catch (error) {
      customErrorMessage(error?.response?.data?.message || error || "Something went wrong!")
    } finally {
      setIsLoading(false)
    }
  }

  const handleList = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await AxiosInstance.post("assets/list", {
        page: page,
        limit: limit,
      });
      setList(data.data.data);
      setTotalDataCount(data.data.metadata.total);
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
    } finally {
      setIsLoading(false)
    }
  }, [page, limit]);

  useEffect(() => {
    handleList()
  }, [handleList])

  return (
    <div className="mt-4 mb-4 page_main dashboard  ">
      <Container fluid>
        <h1>
          Buy - Assets
          <span className="font14Blk"></span>
        </h1>

        <div className="divBg_white mb-3 ">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Buy Assets</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="tableSM">
            <Table responsive striped borderless>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Assets Name</th>
                  <th>Category</th>
                  <th className="text-center">Inv. Amount </th>
                  <th className="text-center">Remaing Amount</th>
                  <th className="text-center">Inv %</th>
                  <th className="text-center">Buying Price</th>
                  <th className="text-center">Dividend</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {list.length > 0 && list.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.investmentRound.investment_Title} </td>
                    <td>{data.category_name}</td>
                    <td className="text-center">{data.total_sell_value}</td>
                    <td className="text-center">{data.remaining_price}</td>
                    <td className="text-center">{data.percent_to_sell}%</td>
                    <td className="text-center">{data.price}</td>
                    <td className="text-center">5%</td>
                    <td>
                      <div className="d-inline-block float-end p-0">
                        {!data.currentUser  ? <button onClick={() => handleBuyAndCancel("Buy", data._id)} className="btnBuy btnBuySM" disabled={isLoading}>
                          {!data.currentUser  && <svg
                            width="17"
                            height="14"
                            viewBox="0 0 17 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1.26489 7H16.253" stroke="white" />
                            <path
                              d="M7.63842 12.841L1.26489 6.91254L7.63842 1"
                              stroke="white"
                            />
                          </svg>}
                          {data.currentUser ? "Cancel" : "Buy"}
                        </button> :
                          <button onClick={() => handleBuyAndCancel("Cancel", data._id)} className="btnCancel btnBuySM" disabled={isLoading}>
                            Cancel
                          </button>}
                      </div>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>
      <Modal
        show={alertMessage}
        onHide={() => setalertMessage(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalComman"
      >
        <Form className="">
          <Modal.Body>
            <div className="d-flex-coloum text-center">
              <h3 className="mt-5 mb-3">Are You Sure</h3>
              <h5 className="mb-4">
                you want to sell <span>10%</span> at this amount?
              </h5>
              <div className="mb-5">
                <button
                  type="button"
                  className="btn_White lm"
                  onClick={() => setalertMessage(false)}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn_Orange lm ms-2"
                  onClick={() => setalertMessage(false)}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </div>
  );
};

export default BuyassetsPage;
