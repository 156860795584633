import React from "react";
import { NavLink } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import footerLogo from "../assets/images/logoFooter.png";
import icLinkedin from "../assets/images/icLinkedin.png";

function Footer() {
    return (
        <>
            <footer className="borderTopWhite">
                <Container style={{ padding: "0 30px" }}>
                    <Row>
                        <div className="col-12 col-md-12 col-lg">
                            <div className="footerLogo mb-4">
                                <img src={footerLogo} alt=""/>
                            </div>
                            <div className="footerSocial">
                                <a href={process.env.REACT_APP_LINKEDIN_LINK} className="" target="_blank" rel="noreferrer">
                                    <img src={icLinkedin} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-auto">
                            <div className="mt-md-4 d-flex justify-content-between d-flex flex-wrap d-md-none">
                                <div className="col-12 col-md mb-2 mb-md-0"><NavLink to="/privacy-policy" className="footerLink text-uppercase">PRIVACY policy</NavLink></div>
                                <div className="col-12 col-md"><NavLink to="/terms-and-conditions" className="footerLink text-uppercase">TERMS & CONDITIONS</NavLink></div>
                                <div className="col-12 col-md mb-2 mb-md-0"><NavLink to="/faq" className="footerLink text-uppercase">Faq</NavLink></div>
                            </div>
                            <div className="mt-4 mt-md-0">
                                <span  className="font13WhiteBold text-uppercase">Meridiem, 16-18 Procter Street, WC1V 6NX</span><br />
                                <a href="mailto:info@meridiem.club" className="link13Golden" target="_blank" rel="noreferrer">info@meridiem.club</a>
                            </div>
                                <div className="col-12 col-md mb-2 mb-md-0"><NavLink to="/privacy-policy" className="footerLink text-uppercase">PRIVACY policy</NavLink></div>
                                <div className="col-12 col-md"><NavLink to="/terms-and-conditions" className="footerLink text-uppercase">TERMS & CONDITIONS</NavLink></div>
                                <div className="col-12 col-md mb-2 mb-md-0"><NavLink to="/faq" className="footerLink text-uppercase">Faq</NavLink></div>
                         
                            
                        </div>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default Footer;
