import React, { useState } from "react";
import LogoBig from "./assets/images/logoBig.png";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import AxiosInstance from "../../helpers/AxiosRequest";
import url from "../../helpers/development.json";
import { customErrorMessage, SendSuccessResponse } from "../../helpers/response";

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({});

    const onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setIsLoading(true);
            const response = await AxiosInstance.post(url.login, values);
            if (response.status === 200 && response.data.statusText === "Success") {
                localStorage.setItem("user", JSON.stringify(response.data.data))
                if (response.data.data.membership_Status) {
                    localStorage.setItem("accessToken", response.data.data.token);
                    setIsLoading(false);
                    SendSuccessResponse(response);
                    window.location.href = '/update-profile';
                } else {
                    const userId = response.data.data._id;
                    localStorage.setItem("signupUser", JSON.stringify(response.data.data))
                    localStorage.setItem("userId", response.data.data._id)
                    setIsLoading(false)
                    window.location.href = "/membership/?payment=" + window.btoa(userId);
                }
            } else {
                setIsLoading(false);
                customErrorMessage(response.data.message)
            }
        }
    }

    return (
        <>
            <div className="login_parent">
                <Container fluid>
                    <Row className="height100vh_">
                        <Col
                            lg={6}
                            md={12}
                            sm={12}
                            className="p-0 d-none d-md-none d-lg-block"
                        >
                            <div className="leftSlider">
                                <Link to="/">
                                    <div className="logo text-center">
                                        <img src={LogoBig} alt="" />
                                    </div>
                                </Link>
                                <div className="d-flex align-itmes-center flex-column contentSlide">
                                    <div className="bottomContent text-center">
                                        <p>
                                            The Mcoin economics is reliant on profitable assets, therefore members<br />
                                            should invest in a majority of companies with a strong asset balance sheet<br />
                                            and likely profitable business model.
                                        </p>
                                        <h4>
                                            Your investment can go down as well as up.<br />
                                            Only invest funds you can afford to lose.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="p-0">
                            <div className="rightForm">
                                <div className="d-flex flex-column justify-content-center col-10 col-xl-8 mx-auto h-100 gap80">
                                    <h4 className="loginTitle">Login</h4>
                                    <Form
                                        noValidate
                                        className="loginForm"
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form.Group
                                            className="mb-4 inputOuter_"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="defLabel_">
                                                Email address
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter email"
                                                className="inputs defInput_"
                                                name="email"
                                                required
                                                onChange={onFormChange}
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group
                                            className="mb-4 inputOuter_"
                                            controlId="formBasicPassword"
                                        >
                                            <Form.Label className="defLabel_">Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                className="mb-3 inputs defInput_"
                                                onChange={onFormChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid password.
                                            </Form.Control.Feedback>

                                            <div style={{ textAlign: "right" }}>
                                                <NavLink className="linkBlk" to="/forgot-password">
                                                    Forget Password?
                                                </NavLink>
                                            </div>
                                        </Form.Group>


                                        <Button type="submit" className='btn_Black'>
                                            {isLoading ? <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                            </>
                                                : ''
                                            }
                                            {isLoading ? 'loading...' : 'Login'}
                                        </Button>

                                        <div className="text-center registerNow mt-4">
                                            <span className="clrBlack">Don’t have an account? </span>
                                            <NavLink to="/signup" className="linkBlk">
                                                Sign Up Now
                                            </NavLink>
                                        </div>

                                        <div className="text-center mt-4">
                                            <NavLink to="/" className="linkBlk">Back to Home</NavLink>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Login;