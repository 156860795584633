import { Modal } from "react-bootstrap";
const PrivacyPolicyModel = (props) =>{
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className="otpModel"
        >
            <Modal.Header className="" closeButton >
            <Modal.Title id="contained-modal-title-vcenter">
            Privacy Policy
            </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <p className="font18Black mb-4">
                        Beiriso Limited’s (trading as Meridiem) Privacy Policy, has been written in accordance with the Data Protection Act 2018 and the EU 2016/679 General Data Protection Regulation (GDPR).
                    </p>

                    <p className="font18Black mb-4">
                        Meridiem Limited respects your privacy and is committed to protecting your personal data. We want to be transparent with you about how we collect and use your personal data in your dealings with us and your use of our Services (“Services”) and tell you about your privacy rights and how the law protects you.
                    </p>

                    <p className="font18Black mb-0">With that in mind, this Privacy Policy is designed to describe:</p>
                    <p className="font18Black mb-0">Your rights relating to your personal data</p>
                    <p className="font18Black mb-0">Complaints</p>
                    <p className="font18Black mb-0">Marketing communications preferences</p>
                    <p className="font18Black mb-0">What personal data we collect</p>
                    <p className="font18Black mb-0">How we use your Personal data and why</p>
                    <p className="font18Black mb-0">What our “legal basis” is</p>
                    <p className="font18Black mb-0">What happens when you do not provide necessary personal data</p>
                    <p className="font18Black mb-0">Personal data and Third-Party Sources</p>
                    <p className="font18Black mb-0">How we use cookies and other tracking or profiling technologies</p>
                    <p className="font18Black mb-0">Who we share your personal data with</p>
                    <p className="font18Black mb-0">Data transfers</p>
                    <p className="font18Black mb-0">How we keep your personal data secure</p>
                    <p className="font18Black mb-0">How long we store your personal data</p>
                    <p className="font18Black mb-0">Third party links</p>
                    <p className="font18Black mb-4">This Privacy Policy aims to give you information on how Meridiem Limited collects and processes your personal data through your use of our Services, and any data you may provide to us.</p>
                    <p className="font18Black mb-4">We will post any modifications or changes to this Privacy Policy on our website: www.meridiem.club</p>

                    <div className="fontBlack20Bold text-uppercase">Who we are</div>
                    <p className="font18Black mb-4">
                        We are Beiriso Limited with registered address , Latif House,First Way, London, HA9 0JD.
                    </p>

                    <div className="fontBlack20Bold text-uppercase">How to contact us</div>
                    <p className="font18Black mb-4">
                        Please email: <a href="mailto:privacy@meridiem.club" className="link18Black">privacy@meridiem.club</a>
                    </p>

                    <div className="fontBlack20Bold text-uppercase">Your rights relating to your personal data</div>
                    <p className="font18Black mb-4">
                        Under certain circumstances, by law you have the right to:
                    </p>

                    <ul className="list">
                        <li className="font18Black">
                            Request access to your personal data. This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                        </li>
                        <li className="font18Black">
                            Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.
                        </li>
                        <li className="font18Black">
                            Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us to continue to process it. You also have the right to ask us to delete or remove your personal data where you have exercised your right to object to processing (see below).
                        </li>
                        <li className="font18Black">
                            Object to the processing of your personal data. This right exists where we are relying on a Legitimate Interest as the legal basis for our processing and there is something about your particular situation, which makes you want to object to processing on this ground. You also have the right to object where we are processing your Personal data for direct marketing purposes.
                        </li>
                        <li className="font18Black">
                            Request the restriction of processing of your personal data. This enables you to ask us to suspend the processing of personal data about you, for example if you want us to establish its accuracy or the reason for processing it.
                        </li>
                        <li className="font18Black">
                            Request the transfer of your personal data. We will provide to you, or a third party you have chosen, your Personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for
                            us to use or where we used the information to perform a contract with you.
                        </li>
                        <li className="font18Black">
                            Withdraw consent. This right only exists where we are relying on consent to process your personal data (“Consent Withdrawal”). If you withdraw your consent, we may not be able to provide you with access to the certain specific functionalities of our Services. We will advise you if this is the case at the time you withdraw your consent.
                        </li>
                        <li className="font18Black">
                            Withdraw consent. This right only exists where we are relying on consent to process your personal data (“Consent Withdrawal”). If you withdraw your consent, we may not be able to provide you with access to the certain specific functionalities of our Services. We will advise you if this is the case at the time you withdraw your consent.
                        </li>
                    </ul>

                    <p className="font18Black mb-4">
                        How to exercise your rights:
                    </p>
                    <p className="font18Black mb-4">
                        If you want to exercise any of the rights described above, please contact us using the contact details shown under “Who We Are and How to Contact Us”.
                    </p>
                    <p className="font18Black mb-4">
                        Typically, you will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, except in relation to Consent Withdrawal, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive, or, we may refuse to comply with your request in these circumstances.
                    </p>
                    <p className="font18Black mb-4">
                        We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal data (or to exercise any of your other rights). This is a security measure to ensure that Personal data is not disclosed to any person who has no right to receive it. We may also © Meridiem Limited 2022. All rights reserved. contact you to ask you for further information in relation to your request to speed up our response.
                    </p>
                    <p className="font18Black mb-4">
                        We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
                    </p>

                    <div className="fontBlack20Bold text-uppercase">Complaints</div>
                    <p className="font18Black mb-4">
                        If you would like to make a complaint regarding this Privacy Policy or our practices in relation to your personal data, please contact us at: <a href="mailto:privacy@meridiem.club" className="link18Black">privacy@meridiem.club</a>
                    </p>

                    <p className="font18Black mb-4">
                        If you feel that your complaint has not been adequately resolved, please note that the Data Protection Act 2018 gives you the right to contact the Information Commissioner’s Office, the UK supervisory authority for data protection issues (www.ico.org.uk).
                    </p>

                    <p className="font18Black mb-4">
                        Marketing Communications preference<br />
                        You can ask us to stop sending you marketing messages at any time by opting out on the Membership Application Form, following the opt-out links on any marketing message sent to you and/or by contacting us at any time using the contact details shown under “Who we are and how to contact us”.
                    </p>                    

                    <p className="font18Black mb-4">
                         Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of membership applications, restaurant reservations and other purchases or Services where we require your personal data to complete your order.
                    </p>

                    <p className="font18Black mb-4">
                        What personal data we collect<br />
                        All the personal data we collect, both from you and from third parties about you, is outlined in the table below.
                    </p>
                        
                    <p className="font18Black mb-4">
                        The information we may collect may relate to: Members, guests of Members, applicants and other potential or prospective Members, suppliers to the Club, or employees of the Club.
                    </p>

                    <p className="font18Black mb-4">
                        Before you read this table, it might be useful to explain what “personal data” is. Personal information means any information relating to an identified or identifiable natural person; an identifiable person is one who can be directly or indirectly identified by reference to an identifier such as name, an identification number, location data, online identifier or to one or more factors specific to the physical physiological, genetic, mental, economic, cultural, or social identity of that person. It does not include ‘anonymous data’ (i.e. information where the identity of individual has been permanently removed).
                    </p>

                    <p className="font18Black mb-4">
                        © Meridiem Limited 2022. All rights reserved.
                    </p>

                    <p className="font18Black mb-4">
                        However, it does include ‘indirect identifiers’ or ‘pseudonymous data’ (i.e. information which alone does not identify an individual but, when combined with certain additional and reasonably accessible information, could be attributed to a particular person).
                    </p>

                    <p className="font18Black mb-4">
                        Category of personal data collected. What this means Identity data First name, surname, marital status, title, date of birth and gender.
                    </p>
                        



                        <p className="font18Black mb-4">Contact data Your home address, work address, billing address, email address and telephone numbers.</p>
                        <p className="font18Black mb-4">Financial data Your payment card details. Note, that we pass any financial data we receive</p>
                        <p className="font18Black mb-4">from you immediately to our third-party payment processors. We do not store any of your financial data.</p>
                        <p className="font18Black mb-4">Transaction data Details about payments to and from you in respect of food, beverage and other services you have purchased from us.</p>
                        <p className="font18Black mb-4">Marketing and communications data</p>
                        <p className="font18Black mb-4">Your preferences in receiving marketing from us and your communication preferences. Behavioural data Inferred or assumed information relating to your behaviour and interests based on your online activity and use of our services.</p>
                        <p className="font18Black mb-4">Technical data Internet Protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system, and platform and</p>
                        <p className="font18Black mb-4">Other technology on the devices you use to access this website or use our services.</p>
                        <p className="font18Black mb-4">Allergy data Information about food allergies, intolerances and/or preferences you have.</p>
                        <p className="font18Black mb-4">Aggregated data We also collect, use and share “aggregated data” such as statistical or demographic data for any purpose. Aggregated Data may be derived from your Personal 
                        data, but once in aggregated form it will not be considered personal data as this data does not directly or indirectly reveal your identity. For example, we may aggregate your behavioural data to calculate the percentage of users accessing a specific Service feature.
                        </p>
                        <p className="font18Black mb-4">However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy Policy.</p>
                        
                        <p className="font18Black mb-4">How we use your personal data and why ?</p>
                        <p className="font18Black mb-4">We will only use your Personal data for the purposes for which we collected it as listed below, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.</p>
                        
                        <p className="font18Black mb-4">Meridiem Limited 2022. All rights reserved.</p>
                        <p className="font18Black mb-4">If we need to use your personal data for an unrelated purpose, we will update this Privacy Policy and we will explain the legal basis which allows us to do so.</p>
                        <p className="font18Black mb-4">What is our “legal basis” for processing</p>
                        <p className="font18Black mb-4">your personal data?</p>
                        <p className="font18Black mb-4">In respect of each of the purposes for which we use your personal data, most commonly, we will rely on one of the following legal bases:</p>
                        <p className="font18Black mb-4">Where we need to perform a contract, we are about to enter into or have entered into with you (“contractual necessity”).</p>
                        <p className="font18Black mb-4">Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests (“legitimate interests”). More detail about the specific legitimate interests pursued in respect of each purpose we use your personal data for is set out in the table below.</p>
                        <p className="font18Black mb-4">A legitimate interest assessment has been completed.</p>
                        <p className="font18Black mb-4">Where we need to comply with a legal or regulatory obligation (“compliance withlaw”).</p>
                        <p className="font18Black mb-4">Where we have your specific consent to carry out the processing for the purpose in question (“consent”).</p>
                        <p className="font18Black mb-4">Generally, we do not rely on your consent as a legal basis for using your personal data other than in the context of direct marketing communications.</p>
                        <p className="font18Black mb-4">We have set out below, in a table format, the legal bases we rely on in respect of the relevant purposes for which we use your personal data.</p>
                        <p className="font18Black mb-4">Purpose Category(ies) of personal data involved Why do we do this Our legal basis for this use of data Membership applications.</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Marketing and communications data</p>
                        <p className="font18Black mb-4">To process Membership applications and elect Members Contractual necessity</p>

                        <p className="font18Black mb-4">CCTV/ security</p>
                        <p className="font18Black mb-4">Identity data Internal & External Security</p>
                        <p className="font18Black mb-4">Legitimate interests We have a legitimate interest in protecting our members and the general public, and providing security for those using our club and it’s services.</p>
                        <p className="font18Black mb-4">Reservations / bookings</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Transaction data</p>
                        <p className="font18Black mb-4">To secure and manage Member reservations, and to settle bills upon consumption</p>
                        <p className="font18Black mb-4">Contractual necessity</p>
                        <p className="font18Black mb-4">© Meridiem Limited 2022. All rights reserved.</p>
                        <p className="font18Black mb-4">Marketing · Identity data.</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Marketing and communications data</p>
                        <p className="font18Black mb-4">To send you newsletters and event information which we think may be of interest to you.</p>
                        <p className="font18Black mb-4">Consent</p>
                        <p className="font18Black mb-4">We obtain opt-in consent to send marketing communications to you.</p>
                        <p className="font18Black mb-4">To process payments for gift cards</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Financial data</p>
                        <p className="font18Black mb-4">Transaction data</p>
                        <p className="font18Black mb-4">To process and deliver your order.</p>
                        <p className="font18Black mb-4">Contractual necessity</p>
                        <p className="font18Black mb-4">To process advance payments for bookings</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Financial data</p>
                        <p className="font18Black mb-4">Transaction data</p>
                        <p className="font18Black mb-4">To process your order to secure a reservation or to pay, or part pay, for a reservation in</p>
                        <p className="font18Black mb-4">advance and allocate such payments correctly.</p>
                        <p className="font18Black mb-4">Contractual necessity</p>
                        <p className="font18Black mb-4">To improve our services</p>
                        <p className="font18Black mb-4">Behavioural data</p>
                        <p className="font18Black mb-4">Technical data</p>
                        <p className="font18Black mb-4">To monitor use of our Services to improve our Services, to help operate our Services</p>
                        <p className="font18Black mb-4">more efficiently, and to improve your experience.</p>
                        <p className="font18Black mb-4">Legitimate interests We have a legitimate commercial interest in ensuring that our sites operate as efficiently as possible and that we improve our sites based on information</p>
                        <p className="font18Black mb-4">we learn from our users’ use of our sites.</p>
                        <p className="font18Black mb-4">To provide your order to you and record your food allergies, intolerances and</p>
                        <p className="font18Black mb-4">Preferences.  Allergy data To ensure that we take into account information about</p>
                        <p className="font18Black mb-4">your food allergies, intolerances and/or preferences when preparing and delivering your order to you. Explicit consent We obtain your explicit consent to use data relating to your food allergies and intolerances. Legitimate interests We have a legitimate interest in taking your food preferences into account when preparing and delivering your order to you, to ensure that you are satisfied with the services you receive from us.</p>
                        <p className="font18Black mb-4">What happens when you do not provide</p>
                        <p className="font18Black mb-4">necessary personal data?</p>
                        <p className="font18Black mb-4">Where we need to process your personal data either to comply with law, or to perform the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with the functionalities of the Services).</p>
                        <p className="font18Black mb-4">In this case, we may have to stop you using our services but we will notify you if this is the case at the time.</p>
                        <p className="font18Black mb-4">Meridiem Limited 2022. All rights reserved.</p>
                        <p className="font18Black mb-4">Personal data from third party sources</p>
                        <p className="font18Black mb-4">In addition to the personal data that we collect directly from you (as described in the section immediately above this one), we also collect certain aspects of your personal data from third party sources. These sources are broken down in the table below, together with a description of whether they are publicly available or not. Third Party data source Publicly available?</p>
                        <p className="font18Black mb-4">Category(ies) or other types of personal data received</p>
                        <p className="font18Black mb-4">Online booking platforms when in effect No · Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Transaction data</p>
                        <p className="font18Black mb-4">Analytics providers No · Behavioural data</p>
                        <p className="font18Black mb-4">Technical data</p>
                        <p className="font18Black mb-4">How we use cookies & other tracking or profiling technologies</p>
                        <p className="font18Black mb-4">We may collect information using “cookies”. What are cookies?</p>
                        <p className="font18Black mb-4">Cookies are small data files stored on the hard drive of your computer or mobile device by a website. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our sites.</p>
                        <p className="font18Black mb-4">We use two broad categories of cookies:</p>
                        <p className="font18Black mb-4">First party cookies – served directly by us to your computer or mobile device; and</p>
                        <p className="font18Black mb-4">Third party cookies – served by our partners or service providers on our sites.</p>
                        <p className="font18Black mb-4">Cookies we use:</p>
                        <p className="font18Black mb-4">Our sites use the following types of cookies for the purposes set out below:</p>
                        <p className="font18Black mb-4">Type of cookie</p>
                        <p className="font18Black mb-4">Purpose Essential cookies These cookies are essential to provide you with services available through our sites and To enable you to use some of its features. Functionality cookies These cookies allow our sites to remember choices you make when you use our sites,</p>
                        <p className="font18Black mb-4">such as remembering your language preferences. The purpose of these cookies is to</p>
                        <p className="font18Black mb-4">provide you with a more personal experience and to avoid you having to re-enter your</p>
                        <p className="font18Black mb-4">preferences</p>
                        <p className="font18Black mb-4">every time you visit our sites.</p>
                        <p className="font18Black mb-4">Meridiem Limited 2022. All rights reserved.</p>
                        <p className="font18Black mb-4">Analytics and performance cookies.</p>
                        <p className="font18Black mb-4">These cookies are used to collect information about traffic to our sites and how users use our sites. The information gathered via these cookies does not “directly” identify any individual visitor. However, it may render such visitors “indirectly identifiable”.</p>
                        <p className="font18Black mb-4">This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access our sites. The information collected is aggregated and anonymous. It includes the number of visitors to our sites, the  websites that referred them to our sites, the pages they visited on our sites, what time of day they visited our sites, whether they have visited our Sites before, and other similar information. We use this information to help operate our sites more efficiently, to gather broad demographic information and to monitor the level of activity on our sites. We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is
                        only used to improve how our sites work. You can find out more information about Google Analytics cookies here:</p>
                        <p className="font18Black mb-4" style={{wordBreak:"break-all"}}>https://developers.google.com/analytics/resources/concepts/gaConceptsCookies</p>
                        <p className="font18Black mb-4">You can find out more about how Google protects your data here:</p>
                        <p className="font18Black mb-4" style={{wordBreak:"break-all"}}>www.google.com/analytics/learn/privacy.html</p>
                        <p className="font18Black mb-4">Social media cookies</p>
                        <p className="font18Black mb-4">These cookies are used when you share information using a social media sharing button or “like” button on our sites or you link your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social network will record that you have done this.</p>
                        <p className="font18Black mb-4">Disabling cookies:</p>
                        <p className="font18Black mb-4">You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings”, “help” “tools” or “edit” facility). Many browsers are set to accept cookies until you change your settings.</p>
                        <p className="font18Black mb-4">If you do not accept our cookies, you may experience some inconvenience in your use of our sites. For example, our sites not remembering your language setting.</p>
                        <p className="font18Black mb-4">Further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them. Visit www.allaboutcookies.org and www.youronlinechoices.com.</p>
                        <p className="font18Black mb-4">You can also prevent the use of Google Analytics relating to your use of our sites by downloading and installing the browser plugin available via this link: http://tools/google.com/dlpage/gaoptout?hl=en=GB.</p>
                        <p className="font18Black mb-4">Who we share your personal data with</p>
                        <p className="font18Black mb-4">The table below describes who we share your Personal data with, what we share and why we share it.</p>
                        <p className="font18Black mb-4">© Meridiem Limited 2022. All rights reserved.</p>
                        <p className="font18Black mb-4">Recipients Category(ies) of Personal data</p>
                        <p className="font18Black mb-4">We Share</p>
                        <p className="font18Black mb-4">Why we share it</p>
                        <p className="font18Black mb-4">Membership platform</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Transaction data</p>
                        <p className="font18Black mb-4">To process Membership applications, and elections and store Member data including payment history.</p>
                        <p className="font18Black mb-4">Payment systems · Financial data To process payments via third party platforms in a PCI compliant manner.</p>
                        <p className="font18Black mb-4">Reservations platforms</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Our service providers provide us with IT systems that help manage our bookings and reservations process.</p>
                        <p className="font18Black mb-4">Marketing digital platforms</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Our service providers administer our marketing communications with Members who have opted-in to receive such communications from us.</p>
                        <p className="font18Black mb-4">Meridiem  Limited</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Transaction data</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">Transaction data</p>
                        <p className="font18Black mb-4">We share only the essential details with third party couriers when delivering physical content to you from time to time.</p>
                        <p className="font18Black mb-4">Printing companies</p>
                        <p className="font18Black mb-4">Identity data</p>
                        <p className="font18Black mb-4">Contact data</p>
                        <p className="font18Black mb-4">We share only the essential details with third party printers when we print physical content that requires your name and address (letters/envelopes) to you from time to time.</p>
                        <p className="font18Black mb-4">Data transfers</p>
                        <p className="font18Black mb-4">As you can see from the above, we share your personal data with our affiliated companies and certain external third parties.</p>
                        <p className="font18Black mb-4">We endeavour to ensure that people to whom we provide personal data hold it subject to appropriate safeguards and controls. Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is afforded to it by ensuring safeguards are implemented (explained below).</p>
                        <p className="font18Black mb-4">How we keep your personal data secure</p>
                        <p className="font18Black mb-4">We have put in place appropriate technical and organisational security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. We store all the personal information you provide on secure password and firewall</p>
                        <p className="font18Black mb-4">© Meridiem Limited 2022. All rights reserved. protected servers.</p>
                        <p className="font18Black mb-4">We limit access to your personal data to those employees and other staff who have a business need to have such access. All such people are subject to a contractual duty of confidentiality. We have put in place procedures to deal with any actual or suspected personal data breach. In the event of any such breach, we have systems in place to work with applicable regulators. In addition, in certain circumstances (e.g., where we are legally required to do so) we may notify you of breaches affecting your personal data.</p>
                        <p className="font18Black mb-4">How long we store your personal data</p>
                        <p className="font18Black mb-4">We will only retain your personal data for so long as we reasonably need to use it for the purposes set out above in “How we use your personal data and why”, unless a longer retention period is required by law (for example for regulatory purposes).</p>
                        <p className="font18Black mb-4">Category of Personal data</p>
                        <p className="font18Black mb-4">Retention period Identity Data Where you have not opted in to receive marketing communications from us or for your data to be held on our customer database, your Identity data will be deleted after two (2) years from the date of your Membership termination. Should you wish to reinstate your Membership you will need to provide proof of your membership number and payment of your joining fee for these aspects to be considered upon re-joining.</p>
                        <p className="font18Black mb-4">Contact Data Where you have not opted in to receive marketing communications from us or for your data to be held on our customer database, your contact data will be deleted after two (2) years from the date of your Membership termination. Should you wish. to reinstate your Membership, you will need to provide proof of your membership number and payment of your joining fee for these aspects to be considered upon re-joining.</p>
                        <p className="font18Black mb-4">Financial Data We pass any financial data we receive from you immediately to our third-party payment processors. We do not store any of your financial data.</p>
                        <p className="font18Black mb-4">Transactions Data We store transaction data for a period of two (2) years from the date of your Membership termination. Should you wish to reinstate your Membership you will need to provide proof of your membership number and payment of your joining fee for these aspects to be considered upon re-joining. Marketing and Communications Data We store marketing and communications data until you unsubscribe from our marketing communications.</p>
                        <p className="font18Black mb-4">Behavioural Data We anonymise behavioural data almost immediately following collection. We then retain behavioural data in anonymised form for thirty-eight (38) months.</p>
                        <p className="font18Black mb-4">© Meridiem Limited 2022. All rights reserved.</p>
                        <p className="font18Black mb-4">Technical Data We anonymise technical data almost immediately following collection. We</p>
                        <p className="font18Black mb-4">Then retain technical data in anonymised form for thirty-eight (38) months.</p>
                        <p className="font18Black mb-4">Allergy Data We store allergy data for a period of two (2) years from the date of your</p>
                        <p className="font18Black mb-4">Membership termination.</p>
                        <p className="font18Black mb-4">15 Third party links</p>
                        <p className="font18Black mb-4">Our sites may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share your personal data. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our site, we encourage you to read the privacy policy of every site you visit.vΩ</p>                       
            </Modal.Body>
        </Modal>
    );
}

export default  PrivacyPolicyModel