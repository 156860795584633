import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Container,
    Nav,
    Navbar,
    NavDropdown,
    Form,
    Modal,
    Button,
    Spinner,
} from "react-bootstrap";
// import Logo from "../../assets/images/logo.png";
import logoNew from "../../assets/images/logoNew.png";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { NotificationIcon } from "./Icons";
import profilePhoto from "../../assets/images/userIcon.png";
import AxiosInstance from "../../helpers/AxiosRequest";
import url from "../../helpers/development.json"
import { successResponse, customErrorMessage, customMessage } from "../../helpers/response";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { initialState, userSetState } from "../../store/user/slice";
import { getNotifications } from "../../store/user/action";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
// import { Web3Button } from '@web3modal/react'

function Header() {
    const [resetPswdModal, setResetPswdModal] = useState(false);
    const [reservationModal, setReservationModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [notificationNo,] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [countryCode, setCountryCode] = useState();
    const [phoneNumber, setPhoneNumber] = useState({ iso2: 'gb', dialCode: '+44', phone: '' });
    const params = useParams();
    const [, setSelectedAddress] = useState();
    const [isNotifyAll, setIsNotifyAll] = useState(false);
    const [timeError, setTimeError] = useState()
    const [user, setUser] = useState({
        _id: "",
        name: "",
        email: "",
        membership_type: "",
        profileImgURL: ""
    });
    const [address, setAddress] = useState(
        window.ethereum?.selectedAddress
    );
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [club, setClub] = useState('');

    const addressChanged = useCallback((address) => {
        setSelectedAddress(address);
    }, []);
    const dispatch = useDispatch();
    const { notifications } = useSelector((state) => state.userStore);
    const [page, setPage] = useState(1);
    const containerRef = useRef();
    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (values.from && values.to) {
            const error = validateTimes(values.from, values.to);
            setTimeError(error);
        }
    }, [values.from, values.to])

    useEffect(() => {
        if (values.newPassword !== values.confirmPassword) {
            setErrorMessage("New Password and Confirm password does not match");
        } else {
            setErrorMessage("")
        }
    }, [values.newPassword, values.confirmPassword])

    useEffect(() => {
        if (user._id.length > 0) {
            const eventName = `accountsChanged`;

            if (!isMetaMaskInstalled()) {
                return;
            }

            const listener = ([selectedAddress]) => {
                setAddress(selectedAddress);
                addressChanged(selectedAddress);
            };

            window.ethereum.on(eventName, listener);

            return () => {
                window.ethereum.removeListener(eventName, listener);
            };
        }
    }, [addressChanged, user]);

    useEffect(() => {
        dispatch(getNotifications({ page: page }))
    }, [page])

    const loadmore = () => {
        setPage(page + 1);
    };

    const handleScroll = () => {
        const container = containerRef.current;
        if (
            container.scrollTop + container.clientHeight >= container.scrollHeight &&
            notifications.hasMore
        ) {
            loadmore();
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        container?.addEventListener("scroll", handleScroll);
        return () => {
            container?.removeEventListener("scroll", handleScroll);
        };
    }, [notifications, notifications.hasMore, containerRef]);

    const navigate = useNavigate();

    const getUserDetails = async () => {
        try {
            const response = await AxiosInstance.get(url.getUserDetails);
            if (response.status === 200 && response.data.statusText === "Success") {
                setUser({
                    _id: response.data.data._id,
                    name: response.data.data.name,
                    email: response.data.data.email,
                    membership_type: response.data.data.membership_type,
                    profileImgURL: response.data.data.profileImgURL
                });
            }
        } catch (error) {
            return error;
        }
    };

    const connectWallet = async () => {
        const selectedAddress = await readAddress();

        setAddress(selectedAddress);
        addressChanged(selectedAddress);
    };

    const isMetaMaskInstalled = () => {
        return Boolean(window.ethereum);
    }

    const readAddress = async () => {
        const method = "eth_requestAccounts";

        const accounts = await window.ethereum.request({
            method
        });

        return accounts[0];
    }

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        navigate("/login");
    }

    const onFormChange = (event) => {
        const { name, value } = event.target;
        setValues((prevValues) => ({ ...prevValues, [name]: value }));

        if (name === "from" || name === "to") {
        }
    };

    const handleSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (values.newPassword === values.confirmPassword) {
                setErrorMessage("");
                setIsLoading(true);
                const response = await AxiosInstance.post(url.resetPassowrd, {
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword
                });
                if (response.status === 200 && response.data.statusText === "Success" && response.data.data) {
                    setIsLoading(false);
                    customMessage("Password changed successfully!");
                    setResetPswdModal(false);
                } else {
                    setIsLoading(false);
                    customErrorMessage("Old password is incorrect!");
                }
            } else {
                setErrorMessage("New Password and Confirm password does not match");
            }
        }
    }

    const onReservationSubmit = async (data) => {
        setIsLoading(true);
        const response = await AxiosInstance.post(url.createReservation, { ...data, country_code: countryCode, phone_number: phoneNumber.phone });
        if (response.status === 200 && response.data.statusText === "Success") {
            setIsLoading(false);
            successResponse(response);
            setReservationModal(false);
            setIsLoading(false);
            navigate("/update-profile")
        } else {
            setIsLoading(false);
            customErrorMessage("Something went wrong!");
        }
    }

    const handleReservationSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            if (phoneNumber.phone === "" || phoneNumber.phone === null || phoneNumber.phone === undefined) {
                setPhoneNumberError("Please Provide a valid mobile number");
            }
            event.stopPropagation();
        } else {
            if (phoneNumber.phone === "" || phoneNumber.phone === null || phoneNumber.phone === undefined) {
                setPhoneNumberError("Please Provide a valid mobile number");
            } else {
                setPhoneNumberError("");
                const error = validateTimes(values.from, values.to);
                if (error !== null) {
                    setErrorMessage(error);
                } else {
                    let data = {
                        // user_id: params.id,
                        number_of_guest: values.number_of_guest,
                        email: values.email,
                        booking_date: values.booking_date,
                        club: club,
                        phone_number: values.phone_number,
                        isNotifyAll: isNotifyAll,
                        from: values.from,
                        to: values.to
                    };
                    await onReservationSubmit(data);
                    setPhoneNumber("");
                    setValidated(false);
                    form.reset();
                }
            }
        }
    };


    const handlePhoneNumber = (value, country) => {
        const regex = /^[0-9]*$/;
        if (regex.test(value.phone) && value.phone.length <= 15) {
            setPhoneNumber(value);
            setCountryCode(value.dialCode);
            setPhoneNumberError('');
        } else {
            setPhoneNumberError('Please enter a valid phone number.');
        }
    };

    const validateTimes = (fromTime, toTime) => {
        const fromDateTime = new Date(`2000-01-01T${fromTime}:00`);
        const toDateTime = new Date(`2000-01-01T${toTime}:00`);
        if (toDateTime <= fromDateTime) {
            return "End time must be after start time.";
        }
        return null;
    }

    const handleClub = (e) => {
        const value = e.target.value;
        setClub(value);
    }

    const [expanded, setExpanded] = useState(false);
    const expnd = () => setExpanded(expanded ? false : "expanded")

    const handleMarkAllAsRead = async () => {
        try {
            const id = [];
            for (let i = 0; i < notifications.data.length; i++) {
                if (notifications.data[i].isRead === false) {
                    id.push(notifications.data[i]._id);
                }
            }
            await AxiosInstance.post("/notification/updateNotification", { id: id });
            const updatedNotifications = notifications.data.filter(
                (notification) => notification.isRead
            );
            dispatch(
                userSetState([
                    { key: "notifications.loading", value: false },
                    { key: "notifications.data", value: updatedNotifications },
                    { key: "notifications.count", value: 0 },
                ])
            );

            dispatch(getNotifications({ page: 1 }))
            //   setNotgetNotificationsfications);
            //   getNotifications();
            //   setNotificationCount(0);
            //   setPaggetNotifications
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.message);
            console.log(err);
        }
    };

    const handleUpdateNotification = async (notificationId) => {
        try {
            const id = [notificationId];
            await AxiosInstance.post("/notification/updateNotification", { id: id });
            const updatedNotifications = notifications.data.map((notification) => {
                if (notification._id === notificationId && !notification.isRead) {
                    return { ...notification, isRead: true };
                }
                return notification;
            });

            // Dispatch the updated notifications and other state changes
            dispatch(
                userSetState([
                    { key: "notifications.loading", value: false },
                    { key: "notifications.count", value: initialState.notifications.count - 1 },
                    { key: "notifications.data", value: updatedNotifications },
                ])
            );

            dispatch(getNotifications({ page: 1 }))
            //   setNotifications(updatedNotifications);
            //   getNotifications();
            //   setPage(1);
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.message);
            console.log(err);
        }
    };

    return (
        <>
            <div className=" text-center" style={{ background: "orange", color: "black", fontSize: "16px", padding: "10px" }}>Site is currently in BETA</div>

            <Navbar expand="xxl" className="navbarCustom" expanded={expanded}>
                <Container fluid className="kkkk">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={expnd} />
                    <NavLink to="/dashboard" className="navbar-brand">
                        <img src={logoNew} alt="Meridiem Logo" />
                    </NavLink>
                    <Navbar.Collapse id="basic-navbar-nav" className="navbarMob">
                        <Nav className="ms-auto">
                            <NavLink
                                to={'/dashboard'}
                                // onClick={expnd}
                                className="nav-link  mb-2 mb-xl-0">
                                DASHBOARD
                            </NavLink>
                            <NavLink
                                to={'/exit-investment-list'}
                                // onClick={expnd}
                                className="nav-link  mb-2 mb-xl-0">
                                EXIT INVESTMENT
                            </NavLink>


                            {/* <Web3Button
                                role={"button"}
                                href="#"
                                onClick={() => {
                                    connectWallet()
                                    expnd()
                                }}
                            >
                                {address ? `${address.substring(0, 4)}...${address.substring(37, 41)}` : "Connect Wallet"}</Web3Button> */}


                            {/* <NavLink
                                to={"#"}
                                onClick={expnd}
                                className="nav-link  mb-2 mb-xl-0">
                                CLUB
                            </NavLink>
                            <NavLink
                                to={"#"}
                                onClick={expnd}
                                className="nav-link  mb-2 mb-xl-0">
                                LABOUR POOL
                            </NavLink>
                            <NavLink
                                role={"button"}
                                onClick={() => {
                                    setReservationModal(true)
                                    expnd()
                                }}
                                className="nav-link text-uppercase  mb-2 mb-xl-0"
                            >
                                Reservations
                            </NavLink> */}

                            <NavLink
                                to={"/faq"}
                                onClick={expnd}
                                className="nav-link  mb-2 mb-xl-0">
                                FAQ
                            </NavLink>
                            {/* ========= */}

                            {/* ---------- */}
                            {/* ------ */}
                            <NavLink to="/create-opportunity" className="nav-link">
                                <button
                                    type="button"
                                    className="btn_Orange sm"
                                >
                                    SEEK FUNDING
                                </button>
                            </NavLink>
                            <NavDropdown title={
                                <div className="profileImg">
                                    <img
                                        className="thumbnail-image"
                                        src={user.profileImgURL ? user.profileImgURL : profilePhoto}
                                        alt=""
                                    />
                                    <div className="avatarTitle">
                                        {user.name}
                                        <br />
                                        <span>
                                            {user.membership_type}
                                        </span>
                                        <br />
                                        <span>
                                            member
                                        </span>
                                    </div>
                                </div>
                            }
                                id="avatarDropdown"
                                className="nav-link position-relative profilemenu d-none d-xxl-block">
                                <NavDropdown.Item as={Link} to="/update-profile" style={{ margin: "8px" }}> Profile</NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/membership-detail" onClick={expnd} style={{ margin: "8px" }}>
                                    Membership Details
                                </NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/my-investment" onClick={expnd} style={{ margin: "8px" }}>My Investment</NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/my-opportunities" onClick={expnd} style={{ margin: "8px" }}>My Opportunity</NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/my-mcoins" onClick={expnd} style={{ margin: "8px" }}>My M Coins</NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/my-dividends" onClick={expnd} style={{ margin: "8px" }}>My Dividends</NavDropdown.Item>

                                <NavDropdown.Item to="#" as={Link} style={{ margin: "8px" }}
                                    onClick={() => setResetPswdModal(true)} >
                                    Change Password
                                </NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/help&support" onClick={expnd} style={{ margin: "8px" }}>Help & Support</NavDropdown.Item>

                                <div style={{ padding: "1px" }}>
                                    <NavDropdown.Item
                                        onClick={() => handleLogout()}
                                        className="btn_Black logout"
                                        style={{ background: "#000", color: "#fff", margin: "0 auto", width: "100%" }}>Logout
                                    </NavDropdown.Item>
                                </div>

                            </NavDropdown>

                            <NavDropdown
                                onClick={() => {
                                    // if (page === 1) {
                                    //   dispatch(getNotifications({page: page}));
                                    //   dispatch(
                                    //     userSetState([
                                    //         { key: "notifications.loading", value: false },
                                    //         { key: "notifications.hasMore", value: true },
                                    //     ])
                                    // );
                                    // } else {
                                    //     dispatch(
                                    //         userSetState([
                                    //             { key: "notifications.loading", value: false },
                                    //             { key: "notifications.hasMore", value: true },
                                    //         ])
                                    //     );
                                    //   setPage(1);
                                    // }
                                    dispatch(getNotifications({ page: page }));
                                }}
                                title={
                                    <div >
                                        {(notifications.count > 0) ?
                                            <span className="notificationNumber">{notifications.count}+</span>
                                            : ' '}
                                        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9243 24.5C9.69671 24.5 7.84429 22.8603 7.51531 20.7257H3.6105C2.06234 20.7257 0.849609 19.5724 0.849609 18.1003C0.849609 17.1554 1.35706 16.6378 1.80538 16.1814C2.21715 15.7616 2.6429 15.3278 2.94608 14.429C3.01919 13.9619 3.54384 10.5634 3.6234 8.59199C3.80509 4.0542 7.45188 0.5 11.9254 0.5C14.124 0.5 16.1861 1.34292 17.7321 2.87305C19.2545 4.37956 20.1393 6.41007 20.2253 8.58984C20.307 10.5591 20.8306 13.9695 20.9048 14.443C21.2122 15.346 21.6455 15.7723 22.0648 16.1857C22.5035 16.6174 23.0002 17.107 23.0002 17.9865C23.0002 19.4962 21.7616 20.7246 20.2393 20.7246H16.3345C16.0044 22.8603 14.152 24.5 11.9243 24.5ZM8.90328 20.7257C9.21506 22.1044 10.4514 23.1374 11.9243 23.1374C13.3973 23.1374 14.6336 22.1044 14.9454 20.7257H8.90328ZM11.9243 1.86262C8.18618 1.86262 5.13824 4.84236 4.98557 8.64568C4.89849 10.8373 4.30932 14.5407 4.2846 14.6964C4.27922 14.7308 4.27062 14.7662 4.25987 14.7995C3.85778 16.0376 3.23421 16.6722 2.77944 17.135C2.35907 17.5623 2.21393 17.7298 2.21393 18.0992C2.21393 18.8186 2.81384 19.362 3.6105 19.362H20.2382C21.008 19.362 21.6348 18.7446 21.6348 17.9865C21.6348 17.6923 21.4993 17.543 21.1058 17.1564C20.6392 16.6969 20.0006 16.0687 19.5899 14.8167C19.5781 14.7823 19.5695 14.7469 19.5641 14.7114C19.5394 14.5547 18.9524 10.8351 18.861 8.6446C18.7105 4.84128 15.6636 1.86262 11.9243 1.86262Z" fill="white"></path></svg>
                                    </div>
                                }
                                className="nav-link position-relative p-0 d-none d-xxl-block">
                                {/* <NavDropdown.Item eventKey="4.1" className="title fw-bold">Notifications</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavLink to="#" className="dropdown-item">
                                    Notification One
                                </NavLink>
                                <NavLink to="#" className="dropdown-item">
                                    Notification Two
                                </NavLink>
                                <NavLink to="#" className="dropdown-item">
                                    Notification Three
                                </NavLink>
                                <NavLink to="#" className="dropdown-item">
                                    Notification Four
                                </NavLink> */}
                                <div className="w-100">
                                    <div className="title fw-bold w-100 text-center">
                                        Notifications
                                    </div>
                                    {notifications && notifications.count > 0 && (
                                        <div
                                            onClick={() => {
                                                dispatch(
                                                    userSetState([
                                                        { key: "notifications.loading", value: false },
                                                        { key: "notifications.hasMore", value: true },
                                                    ])
                                                );
                                                handleMarkAllAsRead();
                                            }}
                                            className="text-end m-2"
                                            style={{ width: "95%", cursor: "pointer" }}
                                        >
                                            Mark all as read
                                        </div>
                                    )}
                                    {notifications && notifications.data.length === 0 && (
                                        <div className="text-center p-5">
                                            <svg width="68" height="65" viewBox="0 0 68 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M44.8456 7.89099C44.9844 7.59199 45.1 7.29299 45.1925 6.97099C45.5394 5.65999 45.3775 4.30299 44.7069 3.12999C44.0363 1.95699 42.9263 1.12899 41.6313 0.78399C40.3131 0.41599 38.9488 0.59999 37.7925 1.26699C36.6131 1.93399 35.7575 3.01499 35.4106 4.32599C35.3181 4.64799 35.2719 4.96999 35.2488 5.26899C24.1025 4.62499 13.65 11.801 10.6206 22.933L5.69501 40.942L1.60189 45.358C1.04689 45.956 0.861885 46.784 1.07001 47.566C1.30126 48.348 1.90251 48.946 2.68876 49.153L6.75876 50.257L15.6388 52.649L20.2869 53.891L33.075 57.341L37.7231 58.583L47.2506 61.159L50.65 62.079C50.8581 62.148 51.0663 62.171 51.2513 62.171C51.8525 62.171 52.4075 61.941 52.8469 61.527C53.4481 60.975 53.6794 60.17 53.5175 59.365L52.2225 53.5L57.1481 35.468C57.7263 33.375 57.9806 31.282 57.9806 29.212C57.9806 20.265 52.9163 11.939 44.8456 7.89099Z" fill="#E2E7EB" />
                                                <path d="M24.2412 59.71L16.425 57.594C16.6331 58.123 16.8875 58.652 17.165 59.158C18.7144 61.849 21.2119 63.781 24.2181 64.586C25.2356 64.862 26.2762 65 27.2937 65C29.3056 65 31.2944 64.471 33.075 63.459C33.5837 63.16 34.0925 62.838 34.5319 62.47L26.6462 60.354L24.2412 59.71Z" fill="#E2E7EB" />
                                                <path d="M7.77159 11.4675C8.22715 11.9804 8.8654 12.2403 9.50134 12.2403C10.0471 12.2403 10.5952 12.0494 11.0345 11.6607L18.9687 4.66409C19.9261 3.82229 20.0117 2.36639 19.1653 1.41649C18.3166 0.464294 16.8551 0.379193 15.9023 1.22329L7.96815 8.21989C7.01078 9.06169 6.92521 10.5176 7.77159 11.4675Z" fill="#E2E7EB" />
                                                <path d="M63.6046 14.0895C63.2092 12.8843 61.9165 12.2242 60.6885 12.6175C59.4745 13.0108 58.8108 14.3103 59.2085 15.5178L62.5015 25.5435C62.8207 26.5141 63.7249 27.1305 64.6984 27.1305C64.9366 27.1305 65.1771 27.0937 65.4176 27.0155C66.6317 26.6222 67.2954 25.3227 66.8976 24.1152L63.6046 14.0895Z" fill="#E2E7EB" />
                                            </svg>
                                            <p style={{ whiteSpace: "nowrap" }} className="mt-3">
                                                No Notification Yet
                                            </p>
                                        </div>
                                    )}
                                </div>
                                {/* <NavDropdown.Divider /> */}
                                {notifications && notifications.data.length > 0 && (
                                    <div
                                        ref={containerRef}
                                        className="notifications-container"
                                        onScroll={handleScroll}
                                    >
                                        <InfiniteScroll
                                            dataLength={notifications.data.length}
                                            next={loadmore}
                                            hasMore={notifications.hasMore}
                                        >
                                            {notifications.data.map((data, index) => {
                                                return (
                                                    <NavDropdown.Item
                                                        key={index}
                                                        className="mb-1 py-2"
                                                        style={{
                                                            backgroundColor:
                                                                data.isRead === false ? "#ededed" : "white",
                                                            whiteSpace: "wrap",
                                                        }}
                                                    >
                                                        <div
                                                            // onClick={() => {
                                                            //     if (data.isRead === false) {
                                                            //         //   setHasMore(true);
                                                            //         handleUpdateNotification(data._id);
                                                            //     }
                                                            //     if (data?.property) {
                                                            //         navigate(
                                                            //             `/property-details/${data.property}`
                                                            //         );
                                                            //     }
                                                            // }}
                                                        >
                                                            <div className="date">{data.title}</div>
                                                            <div>
                                                                {moment(data.createdAt).format(
                                                                    "DD MMM YYYY, hh:mm:s"
                                                                )}
                                                            </div>
                                                        </div>
                                                    </NavDropdown.Item>
                                                );
                                            })}
                                        </InfiniteScroll>
                                    </div>
                                )}
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="d-xl-block d-xxl-none mobileView">

                        <NavDropdown
                            title={
                                <div className="profileImg">
                                    <img
                                        className="thumbnail-image"
                                        src={profilePhoto}
                                        alt="user pic"
                                    />
                                </div>
                            }
                            id="avatarDropdown"
                            align="end"
                        >
                            <div>
                                <div className="dropdown-item" href="#">
                                    <NavLink className="dropdown-item" to="#">
                                        {user.name}
                                    </NavLink>
                                </div>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/update-profile" style={{ margin: "8px" }}> Profile</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/membership-detail" style={{ margin: "8px" }}>
                                    Membership Details
                                </NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="#" style={{ margin: "8px" }}>My Investment</NavDropdown.Item>

                                <NavDropdown.Item to="#" as={Link} style={{ margin: "8px" }}
                                    onClick={() => setResetPswdModal(true)} >
                                    Change Password
                                </NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/help&support" style={{ margin: "8px" }}>Help & Support</NavDropdown.Item>

                                <div style={{ padding: "1px" }}>
                                    <NavDropdown.Item
                                        onClick={() => handleLogout()}
                                        className="btn_Black logout"
                                        style={{ background: "#000", color: "#fff", margin: "0 auto", width: "100%" }}>Logout
                                    </NavDropdown.Item>
                                </div>
                            </div>
                        </NavDropdown>
                        <NavDropdown
                            title={
                                <>
                                    {(notificationNo > 0) ?
                                        <span className="notificationNumber">{notificationNo}+</span>
                                        : ' '}                                    <NotificationIcon />
                                </>
                            }
                            className="nav-link position-relative notificationItems p-2"
                        >
                            <div className="title">Notifications</div>
                            <NavLink to="#" className="dropdown-item">
                                Notification One
                            </NavLink>
                            <NavLink to="#" className="dropdown-item">
                                Notification Two
                            </NavLink>
                            <NavLink to="#" className="dropdown-item">
                                Notification Three
                            </NavLink>
                            <NavLink to="#" className="dropdown-item">
                                Notification Four
                            </NavLink>
                        </NavDropdown>
                    </div>
                </Container>
            </Navbar>
            {/* ====== reservationModal ===== */}
            <Modal
                show={reservationModal}
                onHide={() => { setReservationModal(false); setIsNotifyAll(false); setIsLoading(false); setPhoneNumber("") }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="claimModal"
            >
                <Modal.Header className="p-0 pt-1 mb-2" closeButton>
                    <div className="py-2 ps-4 otpModel">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Reservations
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Form className="loginForm claimForm"
                    noValidate
                    validated={validated}
                    onSubmit={handleReservationSubmit}
                >
                    <Modal.Body>
                        <div className="col-12 mx-auto">

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="club"
                                    >
                                        <Form.Label className="defLabel_">
                                            Club<span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Select aria-label="Default select example" name="club" className="inputs defInput_" required value={club} onChange={handleClub}>
                                            <option value="" defaultValue>Select</option>
                                            <option value="HOLBORN">HOLBORN</option>
                                            <option value="FARRINGDON">FARRINGDON</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="booking_date"
                                    >
                                        <Form.Label className="defLabel_">
                                            Booking Date<span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            // id='date'
                                            type="date"
                                            name="booking_date"
                                            placeholder="01 Apr 2023 - 11:00:00 AM"
                                            className="inputs defInput_"
                                            min={new Date().toISOString().split("T")[0]}
                                            required
                                            onChange={onFormChange}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please select a date that is in the future.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="from"
                                    >
                                        <Form.Label className="defLabel_">
                                            From Time  <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            name="from"
                                            placeholder="Enter  your arrival time"
                                            className="inputs defInput_"
                                            required
                                            onChange={onFormChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid time.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_" controlId="to"
                                    >
                                        <Form.Label className="defLabel_">
                                            To Time <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            name="to"
                                            placeholder=""
                                            className="inputs defInput_"
                                            required
                                            onChange={onFormChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid time.
                                        </Form.Control.Feedback>
                                        {timeError && <span style={{ marginTop: "0.25rem", fontSize: ".875em", color: "#dc3545" }}
                                        > {timeError}
                                        </span>}
                                    </Form.Group>
                                </div>

                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="number_of_guest"
                                    >
                                        <Form.Label className="defLabel_">
                                            Number of Guest <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            min={1}
                                            name="number_of_guest"
                                            placeholder=""
                                            className="inputs defInput_"
                                            required
                                            onChange={onFormChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Number Of guest.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                            Mobile Number <span className="requireStar">*</span>
                                        </Form.Label>
                                        <ReactIntlTelInput
                                            className="mb-3 inputs defInput_ customIntlTel"
                                            value={phoneNumber}
                                            required
                                            maxLength={15}
                                            onChange={handlePhoneNumber}
                                        />
                                        {phoneNumberError.length > 0 && <span style={{ marginTop: "0.25rem", fontSize: ".875em", color: "#dc3545" }} >
                                            {phoneNumberError}
                                        </span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="email"
                                    >
                                        <Form.Label className="defLabel_">
                                            Email ID <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Enter Email "
                                            className="inputs defInput_"
                                            required
                                            onChange={onFormChange}
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-2 d-flex align-items-center gap-1 mt-2"
                                    >
                                        <input
                                            type="checkbox"
                                            onClick={() => setIsNotifyAll(!isNotifyAll)}
                                            id="notification"
                                            style={{ height: "30px", width: "30px" }}
                                        />
                                        <label className="fSize-14 fWeight-500 text-center">
                                            Notify other members
                                        </label>
                                    </Form.Group>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer className="">
                        <button
                            onClick={() => { setReservationModal(false); setIsNotifyAll(false); setIsLoading(false) }}
                            type="button"
                            className="btn_WhiteNew"
                        >
                            Cancel
                        </button>
                        <Button type="submit" className='btn_OrangeNew' disabled={(phoneNumberError.length > 0) || (errorMessage.length > 0) || (timeError)}>
                            {isLoading ? <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2 ' />
                            </>
                                : ''
                            }
                            {isLoading ? 'loading...' : 'Reservations'}
                        </Button>


                    </Modal.Footer>
                </Form>
            </Modal>
            {/* ========= reset password modal ========= */}
            <Modal
                show={resetPswdModal}
                onHide={() => setResetPswdModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="claimModal"
            >
                <Modal.Header className="p-0 pt-1 mb-2" closeButton>
                    <div className="py-2 ps-4 mb-2">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Change Password
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Form
                    noValidate
                    className="claimForm"
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 mx-auto">
                                <Form.Group className="mb-4 inputOuter_">
                                    <Form.Label className="defLabel_">
                                        Old Password <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Old Password"
                                        className="defInput_"
                                        name="oldPassword"
                                        required
                                        onChange={onFormChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter old password
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 inputOuter_">
                                    <Form.Label className="defLabel_">
                                        New Password <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="New Password"
                                        className="defInput_"
                                        name="newPassword"
                                        required
                                        onChange={onFormChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter new password
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 inputOuter_">
                                    <Form.Label className="defLabel_">
                                        Confirm Password <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm Password"
                                        className="defInput_"
                                        name="confirmPassword"
                                        required
                                        onChange={onFormChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter confirm password
                                    </Form.Control.Feedback>
                                    {errorMessage.length > 0 && (
                                        <span className="text-danger">
                                            {errorMessage}
                                        </span>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="">
                        <button
                            onClick={() => setResetPswdModal(false)}
                            type="button"
                            className="btn_White lm"
                            disabled={errorMessage.length > 0}
                        >
                            Cancel
                        </button>
                        <Button type="submit" className='btn_Orange lm saveBtn'>
                            {isLoading ? <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                            </>
                                : ''
                            }
                            {isLoading ? 'loading...' : 'Reset Password'}
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>
            {/* =========== create proposal modal ============ */}
            {/* <CreateProposalModal
                _modal={createPropModal}
                _setModal={setCreateProplModal}
            /> */}
        </>
    );
}

export default Header;