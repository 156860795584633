import React, { Fragment, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import { Navigate, Outlet } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


function Master(props) {
    const navigate = useNavigate();
    const isLogin = localStorage.getItem("accessToken") || false;

    const { ptitle, children } = props;

    useEffect(() => {
        document.title = ptitle;
    }, [ptitle]);

    useEffect(() => {
        if (!isLogin) {
            navigate('/login');
        }
    }, [isLogin]);


    return (
        <Fragment>
            <div className="frontEndLayout">
                <Header />
                {children}
                <Footer />
            </div>
        </Fragment>
    );
}

export default Master;