import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  NavLink,
  Form,
  Breadcrumb,
  Table, Col, Row, Nav, Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInvestmentList } from "../../store/user/action";
import { CategoryType } from "../../helpers/enum";
import PaginationComponent from "../common/PaginationComponent";

const MyInvestment = () => {
  const dispatch = useDispatch();
  const { userInvestmentList } = useSelector((state) => state.userStore);
  const initFilter = { invetment_type: "", page: 1, limit: 10, search: "" }
  const [filter, setFilter] = useState(initFilter);
  const totalPages = useMemo(() => {
    return Math.ceil((userInvestmentList?.metaData?.total / filter.limit) || 1)
  }, [userInvestmentList, filter.limit]);

  useEffect(() => {
    dispatch(getUserInvestmentList({ payload: filter }))
  }, [dispatch, filter])

  return (
    <>
      <div className="mt-4 mb-4 page_main dashboard  ">
        <Container fluid>
          <h1>
            My Investments
            <span className="font14Blk"></span>
          </h1>

          <div className="divBg_white mb-3 ">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>My investments</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Row className="justify-content-between">
              <Col xl={6} lg={12} md={12} sm={12} className="mb-4">
                <div className="myInvestment sectionItem h-100">
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div className="row text-center boxParent g-1">
                      <div className="col-12 col-md">
                        <div className="boxItemGolden p-1">
                          <span className="bigtitle d-block me-2">
                            Total invested
                          </span>
                          <span className="bigcontent me-2">
                            {userInvestmentList?.stats?.total_invested ? `£${userInvestmentList?.stats?.total_invested}` : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md">
                        <div className="boxItemGolden p-1">
                          <span className="bigtitle  d-block me-2">
                            Number of rounds
                          </span>
                          <span className="bigcontent me-2">
                            {userInvestmentList?.stats?.number_of_rounds || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="boxItemGolden p-1">
                          <span className="bigtitle  d-block me-2">
                            Total dividend
                          </span>
                          <span className="bigcontent me-2">
                            {userInvestmentList?.stats?.total_dividend ? `£${userInvestmentList?.stats?.total_dividend}` : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="boxItemGray p-1">
                          <span className="bigtitle  d-block me-2">
                            Last dividend
                          </span>
                          <span className="bigcontent me-2">
                            {userInvestmentList?.stats?.last_dividend ? `£${userInvestmentList?.stats?.last_dividend}` : "-"}
                          </span>
                        </div>
                      </div>
                      <div className=" g-1 d-flex flex-wrap justify-content-between text-center">
                        <div className="col-12 col-md-4">
                          <Form.Group className=" inputOuter_">
                            <Form.Select
                              aria-label="Default select example"
                              className=" custinput"
                              name="investment_type"
                              // onChange={onFormChange}
                              required
                            >
                              <option value="" disabled selected>Select Category</option>
                              {Object.values(CategoryType).map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-12 col-md-4">
                          <Nav.Item>
                            <Nav.Link
                              as={Link}
                              to={'/my-dividends'}
                              className="btn_Orange claimBtn mt-1 w-100 fw-bold"
                            >
                              Dividend transaction history
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={5} lg={12} md={12} sm={12} className="mb-4">
                {Object.values(CategoryType).map((type, i) => {
                  const _array = [{ clr: 'orangebd', perc: 50 }, { clr: 'bluebd', perc: 75 }, { clr: 'lightblack', perc: 40 }, { clr: 'lightgreen', perc: 100 }, { clr: 'lightorange', perc: 90 }]
                  return (
                    <div key={i} className="">
                      <div className="progress">
                        <div
                          className={`${_array[i % _array.length].clr} fw-bold`}
                          role="progressbar"
                          style={{ width: `${_array[i % _array.length].perc}%` }}
                          aria-valuenow={_array[i % _array.length].perc}
                          aria-valuemin="0"
                          aria-valuemax="100">
                          {type}
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className="">My total investment summary</div>
              </Col>
            </Row>
            <div className="investmentTable">
              <Table responsive striped borderless>
                <thead>
                  <tr className="">
                    {/* <th></th> */}
                    <th className="text-center">Total Dividend income</th>
                    <th className="text-center">Asset title</th>
                    <th className="text-center">Invested Value (£)</th>
                    <th className="text-center">Invested Value (%)</th>
                    <th className="text-center">Current Asset value (£)</th>
                    {/* <th className="text-center">Current Asset value %</th> */}
                  </tr>
                </thead>
                <tbody>
                  {userInvestmentList.loading ?
                    <tr>
                      <td className="text-center fw-bold py-4" colSpan={5}>
                        <div className="d-flex align-items-center justify-content-center py-5 my-5 h-100 w-100">
                          <Spinner size="md" variant="dark" />
                        </div>
                      </td>
                    </tr> :
                    userInvestmentList.data.length === 0 ?
                      <tr>
                        <th className="text-center py-5" colSpan={5}>No Investment Found!</th>
                      </tr> :
                      userInvestmentList.data.map((item, i) => (
                        <tr key={i}>
                          {/* <td className="">
                            <div className="d-flex justify-content-start align-items-center mt-3 ms-2">
                              <span className="catgbigSymbol me-1">
                                <img src={icCryptobig} />
                              </span>
                              <span className="fs-6 fw-bold">Crypto</span>
                            </div>
                          </td> */}
                          <td className="text-center align-middle fs-4 fw-bold" >{item?.dividend_amount || 0}</td>
                          <td className="text-center fw-bold">{item.investment_Title}</td>
                          <td className="text-center fw-bold">{item.investment_Amount}</td>
                          <td className="text-center fw-bold">{item?.percent || 0}</td>
                          <td className="text-center fw-bold">0</td>
                          {/* <td className="text-center fw-bold">
                      <div className="hr mt-2" style={{ color: "#489F36" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 22.0055L16.2858 8.31851L23.8049 15.1541L38.859 1.33984" stroke="#489F36" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="hr mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>

                      <div className="lasttd mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </td> */}
                        </tr>
                      ))}

                  {/* <tr>
                    <td className="">
                      <div className="d-flex justify-content-start align-items-center mt-3 ms-2">
                        <span className="commoditybigSymbol me-1">
                          <img src={icPropertyBig} />
                        </span>
                        <span className="fs-6 fw-bold">Property</span>
                      </div>
                    </td>
                    <td className="align-middle fs-4 fw-bold" >£84,500</td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">BTC</div>
                      <div className="hr mt-2">LINK</div>
                      <div className="lasttd mt-2">ETH</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">xxx</div>
                      <div className="hr mt-2">xxx</div>
                      <div className="lasttd mt-2">xxx</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">10</div>
                      <div className="hr mt-2">15</div>
                      <div className="lasttd mt-2">15</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr  mt-2">XXX</div>
                      <div className="hr mt-2">XXX</div>
                      <div className="lasttd mt-2">XXX</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2" style={{ color: "#489F36" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 22.0055L16.2858 8.31851L23.8049 15.1541L38.859 1.33984" stroke="#489F36" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="hr mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>

                      <div className="lasttd mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="">
                      <div className="d-flex justify-content-start align-items-center mt-3 ms-2">
                        <span className="propertybigSymbol me-1">
                          <img src={icCommoditybig} />
                        </span>
                        <span className="fs-6 fw-bold">Commodity</span>
                      </div>
                    </td>
                    <td className="align-middle fs-4 fw-bold" >£84,500</td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">BTC</div>
                      <div className="hr mt-2">LINK</div>
                      <div className="lasttd mt-2">ETH</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">xxx</div>
                      <div className="hr mt-2">xxx</div>
                      <div className="lasttd mt-2">xxx</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">10</div>
                      <div className="hr mt-2">15</div>
                      <div className="lasttd mt-2">15</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr  mt-2">XXX</div>
                      <div className="hr mt-2">XXX</div>
                      <div className="lasttd mt-2">XXX</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2" style={{ color: "#489F36" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 22.0055L16.2858 8.31851L23.8049 15.1541L38.859 1.33984" stroke="#489F36" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="hr mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>

                      <div className="lasttd mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="">
                      <div className="d-flex justify-content-start align-items-center mt-3 ms-2">
                        <span className="bondbigSymbol me-1">
                          <img src={icBondsbig} />
                        </span>
                        <span className="fs-6 fw-bold">Bonds</span>
                      </div>
                    </td>
                    <td className="align-middle fs-4 fw-bold" >£84,500</td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">BTC</div>
                      <div className="hr mt-2">LINK</div>
                      <div className="lasttd mt-2">ETH</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">xxx</div>
                      <div className="hr mt-2">xxx</div>
                      <div className="lasttd mt-2">xxx</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">10</div>
                      <div className="hr mt-2">15</div>
                      <div className="lasttd mt-2">15</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr  mt-2">XXX</div>
                      <div className="hr mt-2">XXX</div>
                      <div className="lasttd mt-2">XXX</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2" style={{ color: "#489F36" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 22.0055L16.2858 8.31851L23.8049 15.1541L38.859 1.33984" stroke="#489F36" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="hr mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>

                      <div className="lasttd mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="">
                      <div className="d-flex justify-content-start align-items-center mt-3 ms-2">
                        <span className="sharesbigSymbol me-1">
                          <img src={icSharesbig} />
                        </span>
                        <span className="fs-6 fw-bold">Shares</span>
                      </div>
                    </td>
                    <td className="align-middle fs-4 fw-bold" >£84,500</td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">BTC</div>
                      <div className="hr mt-2">LINK</div>
                      <div className="lasttd mt-2">ETH</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">xxx</div>
                      <div className="hr mt-2">xxx</div>
                      <div className="lasttd mt-2">xxx</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2">10</div>
                      <div className="hr mt-2">15</div>
                      <div className="lasttd mt-2">15</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr  mt-2">XXX</div>
                      <div className="hr mt-2">XXX</div>
                      <div className="lasttd mt-2">XXX</div>
                    </td>
                    <td className="text-center fw-bold">
                      <div className="hr mt-2" style={{ color: "#489F36" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 22.0055L16.2858 8.31851L23.8049 15.1541L38.859 1.33984" stroke="#489F36" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="hr mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>

                      <div className="lasttd mt-2" style={{ color: "#E3614F" }}>30%
                        <svg className="ms-5" xmlns="http://www.w3.org/2000/svg" width="40" height="23" viewBox="0 0 40 23" fill="none">
                          <path d="M0.69104 1.4397L16.2858 15.1268L23.8049 8.29121L38.859 22.1054" stroke="#E3614F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
              <PaginationComponent
                selectedPage={filter.page}
                handlePageChange={p => setFilter(prev => ({ ...prev, page: p }))}
                totalPages={totalPages}
              />

            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MyInvestment;
