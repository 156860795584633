import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "./admin/slice";
import userSlice from "./user/slice";

export default configureStore({
  reducer: {
    adminStore: adminSlice,
    userStore: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
