import React, { useEffect } from 'react';
import { useLocation } from "react-router";
import Header from "./common/Header";
import Footer from "./common/Footer";

const Farringdon = () => {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    return (
        <>
            <Header />

            <div className='middlePart'>
                
                <div className='py-5 d-flex align-items-center justify-content-center' style={{backgroundColor:"#E2614E", height:"800px", letterSpacing: "0px"}}>
                    <div>
                        <h3 className='text-center'>Farringdon Page</h3>
                        <h1 className='text-center'>Coming Soon</h1>
                    </div>
                </div>

            </div>

            <Footer />

        </>
    )
}

export default Farringdon