import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AxiosInstance from '../../helpers/AxiosRequest';
import url from '../../helpers/development.json';
import { customErrorMessage, SendSuccessResponse } from '../../helpers/response';
import "./../../../src/paymentResponsePage.scss";
import successpng from "../publicPages/assets/images/iccheck.png";

const OpayoResponseHandler = () => {

    const [params] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true)
    const [transactionId, setTransactionId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const crypt = params.get('crypt').substring(1);
        decryptData(crypt)
    }, [])

    const loginHandler = () => {
        // window.location.href ="/login";
        navigate("/")
    }

    const decryptData = async (crypt) => {
        try {
            const response = await AxiosInstance.post(url.decryptData, { crypt: crypt })
            const splitedArray = response.data.data.decryptedText.split("&");
            const findKeys = ["VPSTxId", "Status", "TxAuthNo", "3DSecureStatus", "Amount", "CardType", "Last4Digits", "ExpiryDate"];
            const transactionObj = {}
            findKeys.forEach((current) => {
                splitedArray.map((ele) => {
                    if (ele.includes(current)) {
                        return transactionObj[current] = ele.split('=')?.[1]
                    }
                })
            })
            const _transactionId = transactionObj.VPSTxId.substring(1, transactionObj.VPSTxId.length - 1)
            setTransactionId(_transactionId)

            const signupData = JSON.parse(localStorage.getItem("signupUser"));
            const membershipReq = JSON.parse(localStorage.getItem("membershipReq"));

            if (transactionObj.Status === "OK") {
                const user = await AxiosInstance.post(url.retrieveUser, {
                    userId: localStorage.getItem("userId")
                })
                if (user.status === 200 && user.data.statusText === "Success") {
                    await AxiosInstance.post(url.applyForMembership, {
                        membershipReq: signupData.investorType,
                        address: membershipReq.addressline1,
                        city: membershipReq.addressline2,
                        pincode: membershipReq.postcode,
                        investor_type: membershipReq.investorType,
                        membership_type: membershipReq.amount === membershipReq.annualAmount ? "Annual" : "Monthly",
                        user_id: signupData._id,
                        fullName: signupData.name,
                        familyOfficeName: signupData.familyOfficeName,
                        payment_id: transactionObj.TxAuthNo,
                        payment_type: "Payment",
                        payment_mode: "Card",
                        plan_type: "Payment",
                        fee: membershipReq.amount,
                        tx_id: _transactionId,
                        amount: membershipReq.amount,
                        status: true,
                        subscription: membershipReq.amount === membershipReq.annualAmount ? "Annual" : "Monthly",
                        cardNumber: transactionObj.Last4Digits,
                        expiryDate: transactionObj.ExpiryDate,
                        cardType: transactionObj.CardType
                    }).then((response) => {
                        if (response.data.success) {
                            localStorage.removeItem('userId');
                            localStorage.removeItem('signupUser');
                            localStorage.removeItem('membershipReq');
                            setIsLoading(false)
                            SendSuccessResponse("Your membership is activated!");
                            navigate("/")
                        }
                    }).catch((error) => {
                        setIsLoading(false)
                        customErrorMessage(error?.response?.data?.message || error?.message || "Something went wrong!");
                    });
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
            customErrorMessage(error?.response?.data?.message || error?.message || "Something went wrong!");
        }
    }
    return (
        <div className="login_parent">
            <Container fluid>
                <Row className="height100vh_">
                    <Col lg={12} md={12} sm={12} className="p-0">
                        <div className="rightForm">
                            <div className="payment-bgheight">
                                <div className="d-flex align-items-center justify-content-center h-100 ">
                                    <div className="d-flex flex-column payment-custombox">
                                        <div className="text-center payment-customsuccess payment-customspace">
                                            <img src={successpng} style={{ height: "85px" }} alt="success" />
                                        </div>
                                        <div className="">
                                            <div className="payment-topHalf">
                                                <h1>Payment Successful!</h1>
                                                <p><strong>Transaction Number :</strong><span> {transactionId}</span></p>
                                                <p>You will be redirect to login page in sometime.</p>
                                                {
                                                    isLoading ? <Spinner as="span" animation="border" size="lg" className="mb-4" style={{ display: "block !important", }} /> : ''
                                                }
                                            </div>
                                            <div className="bottomHalf text-center">
                                                <button className="payment-button" onClick={loginHandler} id="alertMO">Login</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}

export default OpayoResponseHandler