import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  ModalHeader,
  ModalBody,
  Modal,
  Badge,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Breadcrumb, Layout } from "antd";
import { Link } from "react-router-dom";
import AxiosInstance from "../../../helpers/AxiosRequest";
import url from "../../../helpers/development.json";
import { customErrorMessage, SendSuccessResponse } from "../../../helpers/response";
import { Spinner } from "react-bootstrap";

const AdminIndex = () => {
  const { Content } = Layout;
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1)
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    fetchData();
    getUserDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit, activePage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.post(url.AdminGetUserList, {
        search: search, page: activePage, limit: limit
      });
      if (response.status === 200 && response.data.statusText === "Success") {
        setData(response.data.data.data);
        setIsLoading(false);
        setTotalPages(Math.ceil(response.data.data.metadata.total / limit))
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await AxiosInstance.get(url.AdmingetAdminDetails);
      if (response.status === 200 && response.data.statusText === "Success") {
        setCurrentUser(response.data.data)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSortingChange = (accessor) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...data].sort((a, b) => {
        return (
          a[sortField]?.toString()?.localeCompare(b[sortField]?.toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setData(sorted);
    }
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleLimitChange = (event) => {
    const newLimit = Number(event.target.value);
    setLimit(newLimit);
    setActivePage(1);
  };

  const deleteUser = async (_id) => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.post(url.AdminDeleteUser, { _id: _id });
      if (response.status === 200 && response.data.statusText === "Success") {
        SendSuccessResponse("User deleted successfully!");
        setDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      setIsLoading(false);
      customErrorMessage("Something went wrong!")
      return error;
    }
  };

  const toggle = (data) => {
    setModal(!modal);
    setUserData(data);
  };

  const handleDeleteUser = (data) => {
    setUserData(data);
    setDeleteModal(!deleteModal);
  }

  return (
    <>
      <Fragment>
        <Content className="site-layout-background">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
            items={[
              {
                title: <a href="/admin/dashboard">Home</a>,
              },
              {
                title: "Users"
              }
            ]}
          >
          </Breadcrumb>

          <hr className="lineTheme" />
          <div className="page-card-view">
            <div className="card-header-part">
              <div className="card-header-action">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold fs-5 mt-2">USER LIST</h5>
                  <div className="form-group mb-3 mr-3">
                    <input
                      type="text"
                      className="form-control inputCustom"
                      placeholder="Search"
                      onChange={(e) => { setSearch(e.target.value); setActivePage(1) }}
                      value={search}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="table-part table-style-1">
              <div className="table-responsive">
                <Table bordered>
                  <thead className="fontsize mb-0">
                    <tr>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Full Name
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('name')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Email
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('email')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Phone Number
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('phone_number')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">City
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('city')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Investor Role
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('investor_type')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">Membership Type
                          <div className="d-flex flex-column" onClick={() => handleSortingChange('membership_type')}>
                            <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                              <path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="black" />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th>Action</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                            <Spinner size="md" variant="dark" />
                          </div>
                        </td>
                      </tr>
                    ) : data?.length === 0 ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                            No User Details Found.
                          </div>
                        </td>
                      </tr>
                    ) : (
                      data.map((row, index) => (
                        row?.type !== "Admin" ?
                          <tr key={index}>
                            <td>{row?.name ? row?.name : '-'}</td>
                            <td>{row?.email ? row?.email : '-'}</td>
                            <td>{row?.phone_number ? row?.phone_number : '-'}</td>
                            <td>{row?.city ? row?.city : '-'}</td>
                            <td>{row?.investor_type ? row?.investor_type : '-'}</td>
                            <td>
                              {row.membership_type}
                              <span className="ms-2">
                                <Badge className={row.payment_details ? (row.payment_details[0]?.status ? 'Badgeclassactive' : 'Badgeclassinactive') : ''} color="">
                                  {row.payment_details ? row.payment_details[0]?.status : '-'}
                                  {row.payment_details ? (row.payment_details[0]?.status ? 'Active' : 'Inactive') : '-'}
                                </Badge>
                              </span>
                            </td>
                            <td>
                              <div className="d-flex">
                                <span className="me-2 ms-2">
                                  <button
                                    color=""
                                    className="viewbutton"
                                    onClick={() => toggle(row)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 576 512"
                                    >
                                      <path
                                        d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                                        fill="#fff"
                                      />
                                    </svg>
                                  </button>{" "}
                                </span>

                                {currentUser?._id !== row?._id ? <span className="me-2 ms-2">
                                  <button
                                    color=""
                                    className="deletebutton"
                                    onClick={() => handleDeleteUser(row)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      style={{ width: "13px" }}
                                    >
                                      <path
                                        d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                                        fill="#fff"
                                      />
                                    </svg>
                                  </button>
                                </span> : ''}
                              </div>
                            </td>
                          </tr> : null
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="PaginationLink">
            <Pagination>
              <PaginationItem disabled={activePage === 1}>
                <PaginationLink previous onClick={() => handlePageChange(activePage - 1)} />
              </PaginationItem>
              {pages.map((page) => (
                <PaginationItem key={page} active={activePage === page}>
                  <PaginationLink onClick={() => handlePageChange(page)}>{page}</PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={activePage === totalPages}>
                <PaginationLink next onClick={() => handlePageChange(activePage + 1)} />
              </PaginationItem>
              <select
                className="form-select ms-2 selectboder"
                aria-label="Default select example"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </Pagination>
          </div>
        </Content>

        <Modal isOpen={modal} toggle={toggle} size="lg" centered>
          <ModalHeader toggle={toggle}>
            <h5 className="fw-bold fs-5">USER DETAILS</h5>{" "}
          </ModalHeader>
          <ModalBody>
            <Table className="modeltable mb-0" bordered responsive>
              <tr>
                <th>Full Name</th>
                <td>{userData?.name ? userData?.name : "-"}</td>
              </tr>

              <tr>
                <th>Email</th>
                <td>{userData?.email ? userData?.email : "-"}</td>
              </tr>
              <tr>
                <th>Phone No</th>
                <td>{userData?.phone_number ? userData?.phone_number : "-"}</td>
              </tr>

              <tr>
                <th>Bio</th>
                <td>{userData?.bio ? userData?.bio : "-"}</td>
              </tr>

              <tr>
                <th>Address</th>
                <td>{userData?.address ? userData?.address : "-"}</td>
              </tr>

              <tr>
                <th>Country</th>
                <td>{userData?.country ? userData?.country : "-"}</td>
              </tr>

              <tr>
                <th>State</th>
                <td>{userData?.state ? userData?.state : "-"}</td>
              </tr>

              <tr>
                <th>City</th>
                <td>{userData?.city ? userData?.city : "-"}</td>
              </tr>

              <tr>
                <th>Post Code</th>
                <td>{userData?.pincode ? userData?.pincode : "-"}</td>
              </tr>

              <tr>
                <th>Investor Type</th>
                <td>{userData?.investor_type ? userData?.investor_type : "-"}</td>
              </tr>

              <tr>
                <th>KYC Status</th>
                <td>
                  {userData?.verify_status
                    ? userData.verify_status
                    : "KYC Not started yet"}
                </td>
              </tr>

              {/* <tr>
              <th>Is Active</th>
              <td>-</td>
            </tr> */}
            </Table>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={deleteModal}
          toggle={handleDeleteUser}
          size="lg"
          centered
        >
          <Form className="loginForm claimForm">
            <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center">
              <div className="p-5">
                <h5 className="mb-4">Are you sure you want to delete user?</h5>
                <div className="">
                  <button type="button" className="btn_Orange lm ms-2" onClick={() => deleteUser(userData._id)}>
                    Yes
                  </button>

                  <button onClick={() => setDeleteModal(false)} type="button" className="btn_White lm" style={{ marginLeft: 20 }}>
                    No
                  </button>
                </div>
              </div>
            </div>

          </Form>

        </Modal>
      </Fragment>
    </>
  );
};

export default AdminIndex;