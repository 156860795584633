// Admin Pages
import AdminLogin from "../pages/admin/AdminLogin";
import AdminDashboard from "../pages/admin/Dashboard";
import AdminProfile from "../pages/admin/AdminProfile";
import AdminForgotPassword from "../pages/admin/ForgotPassword";
import AdminIndex from "../pages/admin/user/Index";
import AdminEmail from "../pages/admin/Email";
import AdminChangePassword from "../pages/admin/ChangePassword";
import AdminCreatePassword from "../pages/admin/Createpassword";
import PageForm from "../pages/admin/setting/PageForm";
import Index from "../pages/admin/Opportunity";
import OpportunityUpdate from "../pages/admin/Opportunity/PageForm";
import InvestmentRound from "../pages/admin/investment-round/InvestmentRound";
import InvestmentDetails from "../pages/admin/investment-round/InvestmentDetails";
import ExitinvestmentlistPage from "../pages/admin/exit-investment/ExitInvestmentList";
import McoinDistributionList from "../pages/admin/mcoin-distribution/McoinDistributionList";
import DividendHistoryList from "../pages/admin/dividend-history/DividendHistoryList";
import DashboardGraph from "../pages/admin/DashboardGraph";
import DividendHistoryDetail from "../pages/admin/dividend-history/DividendHistoryDetail";

const privateRoutes = [
    {
        path: "dashboard",
        exact: true,
        auth: true,
        element: <AdminDashboard title="Admin Dashboard" />
    },
    {
        path: "profile",
        exact: true,
        auth: true,
        element: <AdminProfile title="Admin Profile" />
    },
    {
        path: "users",
        exact: true,
        auth: true,
        element: <AdminIndex title="Admin Users" />
    },
    {
        path: "change-password",
        exact: true,
        auth: true,
        element: <AdminChangePassword title="Admin Change Password" />
    },
    {
        path: "opportunities",
        exact: true,
        auth: true,
        element: <Index title="Opportunities" />
    },
    {
        path: "opportunity/:id",
        exact: true,
        auth: true,
        element: <OpportunityUpdate title="Update Opportunity" />
    },
    {
        path: "setting",
        exact: true,
        auth: true,
        element: <PageForm title="Admin Setting" />
    },
    {
        path: "investment-rounds",
        exact: true,
        auth: true,
        element: <InvestmentRound title="Investment Round" />
    },
    {
        path: "investment-round-details",
        exact: true,
        auth: true,
        element: <InvestmentDetails title="Investment Details" />
    },
    {
        path: "exit-investment-list",
        exact: true,
        auth: true,
        element: <ExitinvestmentlistPage title="ExitInvestment List" />
    },
    {
        path: "mcoin-distribution-list",
        exact: true,
        auth: true,
        element: <McoinDistributionList title="MCOIN Distribution" />
    },
    {
        path: "dividend-history-list",
        exact: true,
        auth: true,
        element: <DividendHistoryList title="Dividend History" />
    },
    {
        path: "dividend-history-list/:_id",
        exact: true,
        auth: true,
        element: <DividendHistoryDetail title="Dividend History" />
    },
]

const publicRoutes = [
    {
        path: "login",
        exact: true,
        auth: false,
        element: <AdminLogin title="Admin Login" />
    },
    {
        path: "forgot-password",
        exact: true,
        auth: false,
        element: <AdminForgotPassword title="Admin ForgotPassword" />
    },
    {
        path: "check-email/:token",
        exact: true,
        auth: false,
        element: <AdminEmail title="Admin Check Email" />
    },
    {
        path: "create-password/:token/:id",
        exact: true,
        auth: false,
        element: <AdminCreatePassword title="Admin Create Password" />
    }
]



export { privateRoutes, publicRoutes };