import { toast } from "react-toastify";
import { adminSetState } from "./slice";
import url from "../../helpers/development.json";
import qs from "query-string";
import AxiosInstance from "../../helpers/AxiosRequest";

export const getInvestmentRounds = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      adminSetState([
        { key: "investmentRounds.loading", value: true },
        { key: "investmentRounds.data", value: [] },
      ])
    );
    const { data } = await AxiosInstance.post(`${url.investmentList}`, { ...payload });
    dispatch(
      adminSetState([
        { key: "investmentRounds.loading", value: false },
        { key: "investmentRounds.data", value: data.data.data },
        { key: "investmentRounds.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      adminSetState([
        { key: "investmentRounds.loading", value: false },
        { key: "investmentRounds.data", value: [] },
      ])
    );
  }
};


export const getOpportunityList = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      adminSetState([
        { key: "opportunityList.loading", value: true },
        { key: "opportunityList.data", value: [] },
      ])
    );
    const { data } = await AxiosInstance.post(`${url.OpportunityList}`, { ...payload });
    dispatch(
      adminSetState([
        { key: "opportunityList.loading", value: false },
        { key: "opportunityList.data", value: data.data.data },
        { key: "opportunityList.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      adminSetState([
        { key: "opportunityList.loading", value: false },
        { key: "opportunityList.data", value: [] },
      ])
    );
  }
};

export const getMcoinList = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      adminSetState([
        { key: "mCoinList.loading", value: true },
        { key: "mCoinList.data", value: [] },
      ])
    );
    const { data } = await AxiosInstance.post(`admin/investment/mtokens`, { ...payload });
    dispatch(
      adminSetState([
        { key: "mCoinList.loading", value: false },
        { key: "mCoinList.data", value: data.data.data },
        { key: "mCoinList.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      adminSetState([
        { key: "mCoinList.loading", value: false },
        { key: "mCoinList.data", value: [] },
      ])
    );
  }
};

export const getDividendList = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      adminSetState([
        { key: "dividendList.loading", value: true },
        { key: "dividendList.data", value: [] },
      ])
    );
    const { data } = await AxiosInstance.post(`admin/investment/dividends`, { ...payload });
    dispatch(
      adminSetState([
        { key: "dividendList.loading", value: false },
        { key: "dividendList.data", value: data.data.data },
        { key: "dividendList.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      adminSetState([
        { key: "dividendList.loading", value: false },
        { key: "dividendList.data", value: [] },
      ])
    );
  }
};

export const getDistributionHistoryList = ({ payload, _id }) => async (dispatch) => {
  try {
    dispatch(
      adminSetState([
        { key: "distributionHistoryList.loading", value: true },
        { key: "distributionHistoryList.data", value: [] },
      ])
    );
    const { data } = await AxiosInstance.post(`admin/investment/dividend/${_id}`, { ...payload });
    dispatch(
      adminSetState([
        { key: "distributionHistoryList.loading", value: false },
        { key: "distributionHistoryList.data", value: data.data.data },
        { key: "distributionHistoryList.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      adminSetState([
        { key: "distributionHistoryList.loading", value: false },
        { key: "distributionHistoryList.data", value: [] },
      ])
    );
  }
};