import React, { useEffect } from "react";
import { useLocation } from "react-router";

const NewTermsPage = () => {
  
  const location = useLocation();
  const monthlyAmount = process.env.REACT_APP_MONTHLY_AMOUNT;
  const annualAmount = process.env.REACT_APP_ANNUAL_AMOUNT;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className="middlePart bgWhite">
        <div className="container py-5">
          <div className="fontBlack34Bold mb-5">Terms and conditions</div>
          <p className="font18Black mb-4">
            Investment opportunities are not offers to the public. Investors
            must be eligible Meridiem members. Please seek independent advice as
            required as Meridiem does not give investment or tax advice.
          </p>
          <p className="font18Black fw-bold mb-4">
            Member Terms – Last updated Jan 2023- Our terms and conditions are
            subject to change.
          </p>
          <p className="font18Black fw-bold mb-4">
            These Member Terms are entered into between Meridiem and any member,
            as defined below:
          </p>
          <p className="font18Black fw-bold mb-4">Meridiem</p>
          <p className="font18Black fw-bold mb-4">
            Meridiem, Latif house first way, Wembley, ha9 0jd with the company
            number, 07266676.
          </p>{" "}
          <p className="font18Black fw-bold mb-4">
            Member: any person who wishes to subscribe yearly or monthly to
            Meridiem’s membership.
          </p>{" "}
          <p className="font18Black fw-bold mb-4">
            Opportunity or Company: a company or fund that has made a Pitch
            available to the members through the Website operated by Meridiem.
          </p>{" "}
          <p className="font18Black fw-bold mb-4">
            These terms apply to all members made on Meridiem
            <br></br>These terms may be updated from time to time so members
            should check the terms and conditions each time an investment is
            made via the collective investment round.
          </p>{" "}
          <p className="font18Black fw-bold mb-4">
            The definitions contained in Schedule 3 apply to these Member Terms.
          </p>{" "}
          <p className="fs-3 fw-bold mb-4">1. INCORPORATION OF OTHER TERMS AND ACCESS TO INVESTMENT OPPORTUNITY</p>{" "}
          <p className="font18Black fw-bold mb-4">1.1. By agreeing to these Members Terms, the Member acknowledges that they have also read, understood and agreed to:</p>{" "}
          <p className="font18Black fw-bold mb-4">1.1.1. the Meridiem Privacy Policy;</p>{" "}
          <p className="font18Black fw-bold mb-4">1.1.2. the registration form;</p>{" "}
          <p className="font18Black fw-bold mb-4">1.1.3. the Website terms of use;</p>{" "}
          <p className="font18Black fw-bold mb-4">1.1.4. the risk warnings and disclaimers on all opportunity pages after registration and login on Meridiem;</p>
          <p className="font18Black fw-bold mb-4">1.1.5. the Investor Nominee Terms set out in Schedule 1 of these Members Terms; and</p>
          <p className="font18Black fw-bold mb-4">1.1.6. any legal agreement presented on an opportunity (which may be with the Company), specific to a particular Investment that a member applies to invest in, including without limitation, an advance subscription agreement or other convertible instrument on a convertible pitch page; the applicable bond instrument on a mini-bond pitch page; or any applicable prospectus or information sheet/note that may be presented to members.</p>
          <p className="font18Black fw-bold mb-4">1.2. Investor Terms will be negotiated and agreed before any funds are transferred out of the treasury.</p>
          <p className="font18Black fw-bold mb-4">1.3. In order to use the Merdiem platform, the Investor acknowledges that they must successfully complete Meridiem's on-boarding process, including the Investor Assessment Questionnaire where applicable, and the Investor agrees that Meridiem will rely on responses and confirmations given as part of the on-boarding process, which form part of the terms on which Meridiem provides services to the members.</p>
          <p className="font18Black fw-bold mb-4">1.4. Investments are not offered to or open to the public in any jurisdiction and investors' agreement to these terms and conditions signifies they agree that the offer was not open to the public in any jurisdiction and that they are only able to invest in an investment product after becoming a member of Meridiem. Registration and agreement to these Investor Terms allows membership, which Meridiem may terminate alongside and in accordance with this agreement.</p>
          <p className="fs-3 fw-bold mb-4"> 2. NOMINEE</p>
          <p className="font18Black fw-bold mb-4">2.1. Notwithstanding anything to the contrary in these Investor Terms, unless indicated differently on an opportunity page, the legal title of the shares in the Company will be held by Meridiem Nominees Limited (UK company number (both, the "Nominee"), as displayed on the Investors certificate, with the beneficial interest being held by the Investor.</p>
          <p className="font18Black fw-bold mb-4">2.2. The Investor appoints Meridiem Limited where the shares are held by Meridiem Nominees Limited, on the terms of the Investor Nominee Terms in Schedule 1 to these Investor Terms to administer the holding. Any provisions and terms in these Investor Terms impacted by such a nominee structure, including without limitation, references to orders, subscription, purchase, shares, shareholding and shareholder shall be interpreted accordingly to give effect to the nominee structure. In these circumstances, the nominee company shall be the legal owner of shares in the Company and registered on the share register of the company rather than the Investor. </p>
          <p className="font18Black fw-bold mb-4">2.3. In the event of a Listing:</p>
          <p className="font18Black fw-bold mb-4">2.4.1. the Investor consents to the creation of a Meridiem general account into which the shares will be deposited and which will be accessible via the Investor’s Meridiem portfolio;</p>
          <p className="font18Black fw-bold mb-4">2.5. Further to clause 2.4 the Investor acknowledges and agrees that, if Meridiem deem it necessary for security or compliance purpose, any proceeds of sale or withdrawals from its account shall be paid to Meridiem’s client account before releasing any or all payments to the Investor. </p>
          <p className="fs-3 fw-bold mb-4"> 3. REGISTRATION PROCESS</p>
          <p className="font18Black fw-bold mb-4">3.1. In registering on the Website (the "Registration Process") the Member represents, warrants and undertakes that:</p>
          <p className="font18Black fw-bold mb-4">3.1.1. they are an individual who is at least 18 years old; and</p>
          <p className="font18Black fw-bold mb-4">3.1.2. they are legally entitled to invest in the investments offered.</p>
          <p className="font18Black fw-bold mb-4">3.2. The Member acknowledges that any investment opportunity is only available in a country or jurisdiction where it is lawful to access or receive investment offers and to make investments and in circumstances where it is lawful for the Investor to receive the offers for investment on Meridiem and to make investments and where no local or national restrictions exist applicable to the Investor which would make viewing Pitches or investing unlawful. The Investor acknowledges that Pitches are not offers to the public in the United States or other countries where such an offer may be unlawful or require the Company or Meridiem to be registered under such country’s securities laws or otherwise.</p>
          <p className="font18Black fw-bold mb-4">3.3. During the Registration Process, the Investor must provide, and undertakes to provide Meridiem with:</p>
          <p className="font18Black fw-bold mb-4">3.3.1. their full legal name;</p>
          <p className="font18Black fw-bold mb-4">3.3.2. their current address;</p>
          <p className="font18Black fw-bold mb-4">3.3.3. their valid and regularly checked email address;</p>
          <p className="font18Black fw-bold mb-4">3.3.4. any other information requested by Meridiem;</p>
          <p className="font18Black fw-bold mb-4">and undertakes to keep the same up to date and notify Meridiem of any changes. 
<br></br>A verification process is done online by a third party provider and not by ourselves in house. </p>
          <p className="font18Black fw-bold mb-4">3.4 Any email address supplied under clause 3.3.3 above will be verified by means of a verification email as part of the Registration Process. Temporary or otherwise artificial email addresses may result in your account being suspended or terminated; investments cancelled;</p>
          <p className="font18Black fw-bold mb-4">3.5. The act of complying with clause 3.3 above, shall constitute express written confirmation from the Member to Meridiem that the email address he/she has provided to Meridiem may be used for the purpose of receiving notices or communications from Meridiem and any Company in electronic form and to Meridiem or any Company making information available on a website, and requesting that Meridiem provide a copy of this confirmation to the Company.
</p>
          <p className="font18Black fw-bold mb-4">3.6. The member may only invest in an investment for he/she’s self in his/her own name only and shall ensure that all orders for the investment made through the Website are made exclusively on his/her own behalf.</p>
          <p className="fs-3 fw-bold mb-4"> 4. CLIENT CATEGORISATION</p>
          <p className="font18Black fw-bold mb-4">4.1. Investors must classify themselves as either a (i) certified ‘high net worth investor’, (ii) certified ‘sophisticated investor’, (iii) self-certified as a ‘sophisticated investor’ or (iv) certified restricted investor.</p>
          <p className="font18Black fw-bold mb-4">4.2. The Investor acknowledges that Meridiem will not supply confirmations of any orders, and/or resulting transactions, and that the investment confirmation email (as outlined below and referred to as the "Cooling Off Email") shall be sufficient and adequate reporting of the service of arranging the reception and transmission of orders and the arranging of resulting transactions, provided by Meridiem.</p>
          <p className="fs-3 fw-bold mb-4">5. REMUNERATION</p>
          <p className="font18Black fw-bold mb-4">5.1. Meridiem currently only benefits from the yearly membership of £{annualAmount} and monthly membership of £{monthlyAmount}.</p>
          <p className="font18Black fw-bold mb-4">5.2. Purchases made at our clubs from our drinks and food menus.</p>
          <p className="font18Black fw-bold mb-4">5.3. Meridiem does not benefit from the investment transaction. If the investors wish to commission Meridiem to sell their shares, then Meridiem will take a sales commission from a successful sale. </p>
          <p className="font18Black fw-bold mb-4">5.4. The Member acknowledges that ancillary charges or fees may be payable to third parties in connection with the Investment, and acknowledges that such charges or fees are not associated with these terms. The Member warrants to Meridiem that it shall pay such fees or charges and shall indemnify Meridiem against any loss, liability, cost or expense resulting from the same.</p>
          <p className="font18Black fw-bold mb-4"><i>5.5 In the event of a nominee Listing, the Investor acknowledges that Meridiem is not currently charging extra for these services but they may do in the future. If they decide to charge, they will notify all members 6months before implementing this charge, if investors wish to continue as members, investors agree that Meridiem could potentially charge an administration fee of 0.02% per month of the value of the Investors’ assets which are listed, subject to a minimum amount of £10 per annum (“Administration Fee”).</i></p>
          <p className="fs-3 fw-bold mb-4">6. INVESTMENT PROCESS</p>
          <p className="font18Black fw-bold mb-4">6.1. Once the member has subscribed, they will be entitled to access the dashboard and view the investment opportunities listed in the investment round. Once they are fully satisfied with the listed investment opportunity, they can proceed with contributing a minimum amount of £500, once the funds are in the treasury it is not revocable. Funds will be returned automatically to the investor if the minimum target is not met or if no investment opportunity was voted on.</p>
          <p className="font18Black fw-bold mb-4"><i>6.2 Where a share price is indicated on the Pitch, any such revocable order shall be in multiples of the indicated share price which may be subject to alteration in accordance with clause 6.15 below.</i></p>
          <p className="font18Black fw-bold mb-4">6.3 The date of the Offer Period may be updated from time to time and Meridiem reserves the right to end Opportunity investments early or extend the Offer Period in its absolute discretion.</p>
          <p className="font18Black fw-bold mb-4">6.4 The subscription or purchase agreement for the investment is between the Investor and Company and Offering Sellers (where applicable) such that the offer from the Investor is to the Company or Offering Sellers and not to Meridiem. The Company may accept or reject any order up until expiry of the period set out in the Cooling Off Email as set out in clause 6.6 below.</p>
          <p className="font18Black fw-bold mb-4">6.5 Where the investment is for the purchase of shares from Offering Sellers, unless otherwise specified on the Opportunity listing or in the Cooling Off Email:</p>
          <p className="font18Black fw-bold mb-4">6.5.1. The Offering Sellers and the Company are parties to the contract to invest;</p>
          <p className="font18Black fw-bold mb-4">6.5.2. the Company will give the Warranties set out in the Cooling Off Email relating to the business;</p>
          <p className="font18Black fw-bold mb-4">6.5.3. the Offering Sellers (or Meridiem Nominees on their behalf) will give warranties relating to their ownership of the shares only.</p>
          <p className="font18Black fw-bold mb-4">6.6. The Investor shall put in place payment arrangements to ensure that the Subscription or Purchase Price and the Investment Fee is paid in accordance with clause 6.13 below. This may require the Investor to agree to a payment service provider’s terms and conditions or otherwise provide satisfactory evidence of payment to Meridiem. It is the Investor’s responsibility to ensure that any such payment arrangements are established and maintained and that monies are transferred in accordance with clause 6.13 below.</p>
          <p className="font18Black fw-bold mb-4">6.7. If an Opportunity is Successful, the Company will instruct Meridiem to circulate a copy of the Company's proposed articles of association or relevant constitutional documents, shareholder’s agreement and convertible instrument (if applicable) to each Investor by email titled “Review your investment in” (the “Cooling Off Email”), and to request that each Investor either cancel their order by informing Meridiem by email (investments@meridiem.club) within the time period specified in the email if they no longer wish to proceed with the Investment.</p>
          <p className="font18Black fw-bold mb-4">6.8. The Investor acknowledges that it is their responsibility to carefully review the Cooling Off Email and any attached documents.</p>
          <p className="font18Black fw-bold mb-4">6.9. If Meridiem receives no cancellation request (either by email or by other means of written communication) from the Investor within the time period specified in the Cooling Off Email, the Investor will be deemed to have confirmed their order and the Company or Offering Sellers will accept their order. Such an order will become a legally binding contract to invest between the Company and the Investor upon expiry of the time period set out in the Cooling Off Email, with completion of the investment conditional upon the Company receiving payment from the Investor and subject to the completion conditions set out in clause 6.11 below.</p>
          <p className="font18Black fw-bold mb-4">6.10. If for any reason the Cooling Off Email is not received by an Investor or an Investor’s response to the Cooling Off Email is not received by Meridiem (whether this is known or notified to Meridiem or not), otherwise than as a result of fraud or gross negligence by Meridiem, Meridiem shall not be liable to the Investor or the Company for any losses, claims or damages suffered by the Investor, and Meridiem shall be entitled to proceed on the assumption that the Investor has received the email and wishes to proceed with the Investment.</p>
          <p className="font18Black fw-bold mb-4">6.11. Subject to clause 6.11, the contract to invest between the Investor and the Company or the Offering Sellers is subject to the following completion conditions:</p>
          <p className="font18Black fw-bold mb-4">6.11.1 where a minimum target has been identified on the Pitch, at least 90% of the initial target investment amount set out on the Pitch shall be received by the Company from Investors unless Meridiem determines, in its absolute discretion, that the investment amount confirmed or received is adequate in the circumstances. </p>
          <p className="font18Black fw-bold mb-4">6.11.2 the Warranties being true and there being no actual or contemplated material change to the Company or the investment round, either before or after the expiry of the Cooling Off Email and prior to the issue of shares to Investors (whether change is material to be determined by Meridiem in its sole discretion);</p>
          <p className="font18Black fw-bold mb-4">6.12 Meridiem (and not the Company or Investor) has absolute discretion to determine whether the conditions set out in clause 6.12 above are satisfied at any time during the completions process prior to the issue or transfer of shares to the Investor by the Company or the Offering Sellers. If Meridiem determines a condition is not satisfied, Meridiem may in its absolute discretion:</p>       
          <p className="font18Black fw-bold mb-4">6.12.1 recirculate the Cooling Off Email to Investors, to include, as required by Meridiem, the articles of association, relevant constitutional documents, shareholder’s agreement and convertible instrument (if applicable) of the Company alongside a disclosure statement detailing the failed condition. This email shall also request that each Investor inform Meridiem by email within the time specified in the email if they no longer wish to proceed with the Investment. If Meridiem receives no response from the Investor within the stated time period, the Investor will be deemed to have confirmed his order in the same manner as clause 6.7 and subject to clauses 6.11 and 6.12; or</p>  
          <p className="font18Black fw-bold mb-4">6.12.2 determine that the investment opportunity is cancelled, either before or after the expiry of the time period in the Cooling Off Email. In these circumstances, if the time period set out in the Cooling Off Email to Investors has expired, the contract to invest shall not complete and there shall be no legally binding contract. Clause 6.15 below shall apply in these circumstances.</p>  
          <p className="font18Black fw-bold mb-4">6.13. If a Pitch is Successful, when the Investor places an order to subscribe for or purchase shares in a Company, and subject to non-revocation at expiry of the Cooling Off Email, an agreement shall then subsist between the Investor and the Company and the Offering Sellers (if applicable), or a third party on behalf of the Company, to transfer the Subscription Price in the case of a subscription for new shares in a Company or the Purchase Price in the case of a purchase of shares from the Offering Sellers for the relevant Investment (the "Subscription or Purchase Price") to the Company. Shares, debt securities or units in the Company will be issued or transferred to the Nominee (or where appropriate, the Investor) by the Company or the Offering Sellers and the Subscription or Purchase Price will be transferred to the account of the Company or the Offering Sellers following the end of the Offer Period and the Investment Fee will be transferred to Meridiem. If the Pitch is not Successful or any completion condition set out in clause 6.9 or 6.12 is not satisfied, then no such agreement between the Investor and the Company shall arise.</p>  
          <p className="font18Black fw-bold mb-4">6.14. If the Pitch is not Successful or the order not completed for any reason, the Investor's order will not be transferred to another Pitch or Company, and no substitute service will be provided. The Investor confirms that should a Company not ultimately attain the stated desired target level of investment as set out in its Pitch, as a result of withdrawals after the expiry of the Offer Period, or failure by other Investors to transfer the Subscription or Purchase Price, neither the Company nor Meridiem is required to inform the Investor of this failure, and the Investor may still be required to purchase the shares he/she ordered, provided that the conditions in clause 6.9 and 6.11 are met. If the conditions in clause 6.7 or 6.11 are not satisfied for any reason, the agreement for investment between the Investor and Company (and the Offering Sellers, if applicable) will not complete and Meridiem will use its reasonable endeavours to arrange for the Company to cancel the Investment made by the Investor and, if payment has already been made, require the Company or Offering Sellers to return the Subscription or Purchase Price to the Investor. The Investor consents to Meridiem releasing such information as is reasonably necessary, to the Company to allow such return of the Subscription or Purchase Price, and the Investor undertakes to co-operate with Meridiem and the Company, including in relation to any transaction fees or charges, to facilitate the cancellation of the Investment and the return of the Subscription or Purchase Price.</p>  
          <p className="font18Black fw-bold mb-4">6.15 The Subscription or Purchase Price shall be the amount indicated by the Investor as part of their revocable order on the Pitch, less any fees (and stamp duty where applicable and stated on the Pitch or in the Cooling Off Email) due in accordance with these Investor Terms unless adjusted in accordance with this term. In the event of any required adjustment (including, without limitation, as a result of a change of valuation or error), an updated share price (or price per share, which includes any applicable stamp duty) shall be supplied in the Cooling Off Email or in Legal Review or EU Legal Review, and the Subscription or Purchase Price shall be revised down to the nearest whole share multiple. Where an Investor has pre-authorised any payment through a third party payment provider, the payment that is taken shall also be revised down to the new Subscription or Purchase Price.</p>  
          <p className="font18Black fw-bold mb-4">6.16 The terms relating to the provision of any rewards for investment advertised on the Pitch shall constitute part of the agreement formed between the Investor and the Company pursuant to clause 6.9. Meridiem shall not be responsible for the provision of such rewards and shall not be liable for any delay or failure of the Company in the provision of such rewards.</p>  
          <p className="font18Black fw-bold mb-4">6.17 The Warranties are made by the Company to the Investor. Meridiem accepts no responsibility for enforcing any Warranties. Any Investor who seeks to enforce any of the Warranties shall bear all costs incurred in connection with such enforcement.</p>  
          <p className="font18Black fw-bold mb-4">6.18 At any time prior to the expiry of the Cooling Off Email, Meridiem may cancel any order of Investment made by the Investor that Meridiem deems, in its absolute discretion, to be malicious or otherwise detrimental to Company, Offering Sellers or Meridiem. This includes, without limitation, Investments placed for the purpose of disrupting or causing the closure of the Company’s Pitch where the Investor has no intention of paying the Subscription or Purchase Price. The Investor shall indemnify Meridiem for any loss, liability, cost or expense incurred by Meridiem in connection with the removal of an Investment pursuant to this clause.</p>  
          <p className="font18Black fw-bold mb-4">6.19 In the event that the Investor is connected with the Company, he/she shall be deemed to have waived any right he/she may have to cancel his/her Investment pursuant to clause 6.6. In this clause 6.19, “connected with” means being:</p>  
          <p className="font18Black fw-bold mb-4">6.19.1 a director of the Company;</p>  
          <p className="font18Black fw-bold mb-4">6.19.2 a person named in the “Team” section of the Company’s Pitch; or</p>  
          <p className="font18Black fw-bold mb-4">6.19.3 a spouse, Civil Partner, parent, child, sibling of any person included in the categories set out at clauses 6.19.1 and/or 6.19.2.</p>  
          <p className="fs-3 fw-bold mb-4"> 7. INVESTMENTS AND NEXT OF KIN</p>  
          <p className="font18Black fw-bold mb-4">7.1 Investors are encouraged to ensure that arrangements are put in place for their next of kin to be informed of their order and the Meridiem process, and that instructions are provided to enable the Investor's order to be withdrawn before it is converted to an irrevocable order on the occurrence of the Investor's death, insolvency or incapacity.</p>  
          <p className="fs-3 fw-bold mb-4">8. COMPANY ARTICLES, BOND INSTRUMENT OR OTHER DOCUMENTATION</p>  
          <p className="font18Black fw-bold mb-4">8.1 The Investor acknowledges that, as a consequence of them becoming a shareholder or beneficial owner of a Company, they shall be subject to the provisions of the Company’s articles of association or other constitutional documents of the Company (which constitute an agreement between each of the Company’s shareholders and between the shareholders and the Company itself), convertible instrument (if applicable), bond instrument or any other documents indicated to the Investor in the Legal Review, EU Legal Review or Cooling Off Email (together, “the Constitutional Documents”).</p>  
          <p className="font18Black fw-bold mb-4">8.2 The Constitutional Documents will include certain restrictions on the shares or other securities and obligations will attach to such shares or other securities.</p>  
          <p className="font18Black fw-bold mb-4">8.3 The articles of association will be in substantially the form notified to the Investor by Meridiem in the negotiation period referred to in clause 6.7 above and/or as set out on the Pitch and may be subject to other documentation or disclosures.</p>  
          <p className="fs-3 fw-bold mb-4">9. REGULATION AND LIABILITY</p>  
          <p className="font18Black fw-bold mb-4">9.1 The Investor acknowledges that Meridiem's affiliates, and/or the proprietors, officers or employees of Meridiem and/or such affiliates may consider expressing interest or subscribing for or purchasing shares in a Company. If the Investor becomes aware of this, they agree not to rely upon the same in making a decision whether to invest in a Company, and confirms that any decision by them to invest in an Company is not based upon any representation, information, action, omission or otherwise of Merdiem, its subsidiaries or affiliates or the proprietors or employees of Meridiem, its subsidiaries or its affiliates.</p>  
          <p className="font18Black fw-bold mb-4">9.2 The Investor acknowledges that Meridiem approves each Pitch as a financial promotion / marketing and communication (unless an exemption applies, which will be stated in a blue box on the Pitch) but does not provide advice or any form of recommendation regarding the suitability or quality of the Investment. The Investor acknowledges that the approval of the Pitch as a financial promotion / marketing and communication by Meridiem, or the investment in a Company by any person referred to in clause 9.1 is not an indication of approval of the Pitch generally, and the Investor confirms that it shall take no inference from or make any reference to the same.</p> 
           <p className="font18Black fw-bold mb-4">9.3 The Investor acknowledges and accepts that the Website includes a forum which may be a part of an Company's Pitch which is intended as a service to Companies to put them in contact with Investors, and also that Meridiem's investigation of the Companies and the content of their Pitches is limited as set out in the Due Diligence Charter, and accordingly Meridiem makes no warranty or representation and assumes no liability in respect of the Meridiem or the content of their Pitches or posts on the forum. The Investor must make their own assessment of the viability, accuracy and prospects of the Companies, their Pitches, and any relevant investment propositions and should consult their professional advisers should they require any assistance in making such an assessment or should the Investor require any services whatsoever in connection with Meridiem. In particular, the attention of the Investor is drawn to the disclaimer, risk warning and regulatory notice on each Pitch.</p>  
           <p className="font18Black fw-bold mb-4">9.4 The Investor warrants, represents and undertakes to Meridiem that (i) the Investor has categorised himself or herself correctly under clause 4.1 above; (ii) shall comply with any terms and conditions associated with the use of the forums on the Website, and in particular undertakes not to post any illegal, defamatory or inappropriate material or advice to invest and acknowledges that Meridiem will in its absolute discretion have the power to determine whether posts by Investors breach this clause 9.4 or are otherwise inappropriate and may be removed by Meridiem.</p>  
           <p className="font18Black fw-bold mb-4">9.5 The Investor acknowledges that Meridiem does not provide the Investor with any advice or recommendations in relation to investments. Nevertheless, it is typically considered prudent for Investors to consider spreading their risk over multiple investments and Meridiem encourages this approach.</p>  
           <p className="font18Black fw-bold mb-4">9.6 The Investor acknowledges that in approving the Pitch as a financial promotion / marketing and communication, Meridiem has concluded that the Pitch, taken as a whole in the context of the above, is fair, clear and not misleading. The Investor acknowledges that Meridiem has reviewed any factual statements included within the Pitch and obtained evidence of their accuracy from the Company. The Investor acknowledges Meridiem has completed, or will complete the steps outlined in the Due Diligence Charter (as may be amended from time to time). However, the Investor's attention is drawn to the fact that the evidence is obtained from the Company itself and has not been audited by Meridiem, which means that it may contain inaccuracies, be incomplete or be a forgery.</p> 
           <p className="font18Black fw-bold mb-4">9.7 The Investor acknowledges that Meridiem has checked that aspirational statements contained within the Pitch are phrased appropriately in light of their speculative nature. However, the Investor acknowledges that the Company is likely to be a start-up company and as such may have high ambitions which may be unachievable and exaggerated. The Investor acknowledges that Meridiem may approve statements that convey those ambitions even where it does not believe, or does not have a view on whether it is likely, that they will be fully realised and the Investor acknowledges that Meridiem encourages Investors to consider the information provided in the context it is being provided.</p> 
           <p className="font18Black fw-bold mb-4">9.8 The Investor acknowledges that Meridiem makes no representation, warranty or undertaking relating to any claims made by Companies, including, without limitation, that the Company and the Investment will qualify for or be subject to any tax benefits such as EIS and SEIS or that these tax benefits are pending approval of HMRC. Investor acknowledges and agrees that tax benefits may change or be disqualified and shall not hold Meridiem liable for any loss arising as a result of a tax benefit (including, without limitation EIS or SEIS) not applying to an Investment, including without limitation in circumstances where tax has been ‘clawed back’ from an Investor by HMRC.</p> 
           <p className="font18Black fw-bold mb-4">9.9 The Investor acknowledges that tax treatment depends on the individual circumstances of each Investor and may be subject to change in future.</p> 
           <p className="font18Black fw-bold mb-4">9.10 Nothing in this Agreement shall exclude or limit liability for death or personal injury resulting from the negligence of either party or their agents or employees nor for fraud by or on behalf of either party. Nothing in this Agreement shall limit any liability to the extent that liability may not be excluded or limited by any applicable law or regulation.</p> 
           <p className="font18Black fw-bold mb-4">9.11 With the exception of clause 9.10 above, Meridiem's liability (which shall include Meridiem’s affiliate or group companies, including Meridiem Ltd; and directors, officers and employees of Meridiem Limited, Meridiem Limited , Meridiem Nominees Limited and their directors and officers) in contract, tort, negligence, pre-contract or other representations or otherwise arising out of this agreement or the performance of its obligations under this agreement shall be limited in aggregate to the lesser of (a) the total amount invested in the Pitch by Investor on Meridiem up to the date of the event leading to the claim; or (b) £1,000 or the equivalent in euros.</p> 
           <p className="font18Black fw-bold mb-4">9.12 Meridiem shall not be liable in contract, tort (including negligence), pre-contract or other representations (other than fraudulent or negligent misrepresentations) or otherwise under this Agreement for: (a) any economic losses (including loss of revenues, profits, contracts, business or anticipated savings); or (b) any special, indirect or consequential losses; whether or not such losses were known to the parties at the commencement of this Agreement.</p> 
           <p className="fs-3 fw-bold mb-4">10. TERMINATION</p> 
           <p className="font18Black fw-bold mb-4">10.1 Subject to clause 10.2, the Investor may terminate this agreement on 7 days' written notice to Meridiem.</p> 
           <p className="font18Black fw-bold mb-4">10.2 If an Investor has an outstanding or incomplete order for investment in any Company which has not been resolved in accordance with clause 6, the Investor may only terminate this Agreement if they have (i) served written notice by email on Meridiem; and (ii), has withdrawn his/her order from the ongoing Pitch either via the Pitch, or by responding to the confirmation email confirming his/her withdrawal. If an Investor has an outstanding or incomplete order for investment, which has become binding in accordance with these terms, the Investor cannot terminate this agreement.</p> 
           <p className="font18Black fw-bold mb-4">10.3 Meridiem may terminate this agreement at any time in the event that:</p> 
           <p className="font18Black fw-bold mb-4">10.3.1 the Investor breaches these Investor Terms; or</p> 
           <p className="font18Black fw-bold mb-4">10.3.2 Meridiem suspects that the Investor has been involved in any criminal or otherwise improper activities,</p> 
           <p className="font18Black fw-bold mb-4">10.4 If Meridiem terminates this agreement in accordance with clause 10.4 whilst the Investor has placed an order that has not been completed by the issue of shares in the relevant Company, Meridiem reserves the right to inform the Company of the termination and take such steps as are necessary to ensure that the Investor's order is not completed.</p> 
           <p className="font18Black fw-bold mb-4">10.5 Clauses 6, 7, 8, 9, 10.5, 10.6, 12, 16, 17 and 18 shall survive termination of this agreement and if an Investor has made an application to invest or has invested in an Company through Meridiem then any clause in this agreement that is required in order to administer that investment in accordance with these terms, the Investor Nominee Terms or regulatory requirements shall also survive termination.</p> 
           <p className="fs-3 fw-bold mb-4">11. EARLY DRAWDOWN OF FUNDS</p> 
           <p className="font18Black fw-bold mb-4">11.1 This clause applies where the Investor has entered into a binding subscription agreement with the Company and transferred the applicable Subscription Price ("Pre-Committed Investment") to the Company prior to the Cooling Off email referred to in clause 6.7 being sent.</p> 
           <p className="font18Black fw-bold mb-4">11.2 Where this clause applies, the Investor:</p> 
           <p className="font18Black fw-bold mb-4">11.2.1 agrees that the Company may draw down and spend the Pre-Committed Investment at any time after the receipt of such Pre-Committed Investment and that there is no guarantee that any further funds will be raised via the Pitch;</p> 
           <p className="font18Black fw-bold mb-4">11.2.2 waives any right he or she may have to cancel the Pre-Committed Investment in accordance with clause 6.6 or otherwise;</p> 
           <p className="font18Black fw-bold mb-4">11.2.3 agrees that completion of the Pre-Committed Investment shall not be subject to the conditions set out in clause 6.11 and that Investor may be subject to additional risks of investment which are outside of Meridiem’s control;</p> 
           <p className="font18Black fw-bold mb-4">11.2.4 agrees that, in the event that the Pitch is cancelled for any reason, the Company shall be solely responsible for issuing the shares in respect of the Pre-Committed Investment; and</p> 
           <p className="font18Black fw-bold mb-4"> 11.2.5 acknowledges that the delay between receipt of the Pre-Committed Investment and the issue of shares in respect of such Pre-Committed Investment may prejudice any tax relief to which the Investor may otherwise be entitled and agrees to take professional tax advice as required.</p>
           <p className="fs-3 fw-bold mb-4">12. COMPLAINTS AND QUERIES</p> 
           <p className="font18Black fw-bold mb-4">12.1. Should an Investor have any complaints or queries about the services provided by Meridiem Limited or this agreement, they should contact Meridiem on 0208 795 3684  or by writing to Meridiem at the LATIF HOUSE, FIRST WAY</p> 
           <p className="font18Black fw-bold mb-4">12.1.3. Communications under 12.1 shall be in the English language.</p> 
           <p className="font18Black fw-bold mb-4"><i>12.2. Should an Investor have any complaints or queries about the services provided by Meridiem or this agreement, they should complete the following questionnaire. Investors could also contact Meridiem by writing at 16 Procter Street WC1V 6NX OR support@Meridiem.club.</i></p> 
           <p className="fs-3 fw-bold mb-4">13. WAIVER</p> 
           <p className="font18Black fw-bold mb-4">No failure or delay by a party to exercise any right or remedy provided under this agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it preclude or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or any other right or remedy.</p> 
           <p className="fs-3 fw-bold mb-4">14. NO PARTNERSHIP OR AGENCY</p> 
           <p className="font18Black fw-bold mb-4">Nothing in this agreement is intended to, or shall be deemed to, establish any partnership or joint venture between any of the parties, constitute any party the agent of another party, nor authorise any party to make or enter into any commitments for or on behalf of any other party.</p> 
           <p className="fs-3 fw-bold mb-4">15. ASSIGNMENT AND VARIATION</p> 
           <p className="font18Black fw-bold mb-4">15.1 The provisions of this agreement shall not be assigned, transferred, mortgaged, charged or otherwise encumbered by the Investor without the written consent of Meridiem and the Company. Meridiem may assign this agreement without restriction subject to compliance with applicable law and regulation.</p> 
           <p className="font18Black fw-bold mb-4">15.2 No variation of this agreement shall be effective unless it is in writing and signed by the parties (or their authorised representatives) and the Company. If any clause is deemed invalid or unenforceable, it shall not impact upon the remainder of this agreement which shall remain in force.</p> 
           <p className="fs-3 fw-bold mb-4">16. NOTICES</p> 
           <p className="font18Black fw-bold mb-4">16.1 Any notice or other communication required to be given to a party or the Company under or in connection with this agreement shall be in writing and shall be delivered by hand or sent by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or (in any other case) its principal place of business or residential address, or sent by email to the email address notified to the other party in accordance with this agreement. The email address for the service of notices on Meridiem is legal@Meridiem.cub.</p> 
           <p className="font18Black fw-bold mb-4">16.2 Any notice or communication shall be deemed to have been received if delivered by hand, on signature of a delivery receipt or at the time the notice is left at the proper address, or if sent by email, at 9.00 am on the next working day after transmission, or otherwise at 9.00 am on the second Business Day after posting or at the time recorded by the delivery service.</p> 
           <p className="fs-3 fw-bold mb-4">17. THIRD PARTY RIGHTS</p> 
           <p className="font18Black fw-bold mb-4">This agreement does not confer any rights on any person or party pursuant to the Contracts (Rights of Third Parties) Act 1999 other than the parties to it, except that the Company will be entitled to enforce all its rights and benefits under this agreement at all times as if party to this agreement.</p> 
           <p className="fs-3 fw-bold mb-4">18. GOVERNING LAW AND JURISDICTION</p> 
           <p className="font18Black fw-bold mb-4">18.1 This agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.</p> 
           <p className="font18Black fw-bold mb-4">18.2 The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes or claims).</p> 
           <p className="font18Black fw-bold mb-4">18.3 These terms can be translated from time to time. In the event of conflict between the English text and a translation, the English version shall prevail.</p> 
           <p className="font18Black fw-bold mb-4">See schedule 1, here.</p> 
           <p className="font18Black fw-bold mb-4">See schedule 2, here.</p> 
           <p className="font18Black fw-bold mb-4">See schedule 3, here.</p> 
           <p className="font18Black fw-bold mb-4">Risk warning<br></br>
Investing in start-ups and early-stage businesses involves risks, including illiquidity, lack of dividends, loss of investment and dilution, and it should be done only as part of a diversified portfolio. Meridiem is targeted exclusively at investors who are sufficiently sophisticated to understand these risks and make their own investment decisions. You will only be able to invest via Meridiem once you are registered as sufficiently sophisticated. Please click here to read the full Risk Warning.</p> 
           <p className="font18Black fw-bold mb-4">This page has been approved by Meridiem. Opportunity for investment are not offers to the public and investments can only be made by members of Meridiem.com on the basis of information provided in the pitches by the companies concerned. Further restrictions and Meridiem's limitation of liability are set out in the Investor Terms and Conditions.</p> 
           <p className="font18Black fw-bold mb-4">Investment opportunities are not offers to the public and investors must be eligible Meridiem members. Please seek independent advice as required as Meridiem does not give investment or tax advice.</p>
        </div>
      </div>
    </>
  );
}

export default NewTermsPage;
