import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Header from "./common/Header";
import Footer from "./common/Footer";
import enquireImg from "./assets/images/enquireImg.png";
import icMcoin from "./assets/images/icMcoin.png";
import icBook from "./assets/images/icBook.png";
import icBrochure from "./assets/images/icBrochure.png";
import url from "./../../helpers/development.json";
import AxiosInstance from './../../helpers/AxiosRequest';
import { successResponse, customErrorMessage } from "./../../helpers/response";
import TermsandConditionModal from "./common/TermsandConditionModal";
import PrivacyPolicyModel from "./common/PrivacyPolicyModel"



const Enquire = () => {
    const [values, setValues] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modaltermsand, setModalTermsand] = useState(false);
    const [modalprivacy, setModalPrivacy] = useState(false);
    const [isTermsAndConApply, setIsTermsAndCondApply] = useState(false);

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

    const onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
    };

    const onCheckboxChange = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = async (event) => {
        setIsValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            if (values === "") {
                setErrorMessage("")
            }
            event.stopPropagation();
        } else {
            if (values === "" || values === null || values === undefined) {
                setErrorMessage("Please Provide all information");
            } else {
                setIsLoading(true);
                setIsSubmitting(true);
                const response = await AxiosInstance.post(url.enquireInformation, {
                    name: values.name,
                    email: values.email,
                    phone_number: values.phone_number,
                    message: values.message,
                    clubs: values.clubs,
                    online_platform: values.online_platform,
                    brochure: values.brochure
                });
                if (response.status === 200 && response.data.statusText === "Success") {
                    successResponse(response);
                    setIsLoading(false);
                    setIsSubmitting(false);
                    // window.location.reload();
                    // form.reset();
                } else {
                    setIsLoading(false);
                    customErrorMessage("Something went wrong");
                }
            }
        }
    };

    return (
        <>
            <Header />

            <div className='middlePart'>

                {/* Section Enquire */}
                <div className='enquirePart'>
                    <div className='row g-0 align-items-stretch h-100'>
                        <div className='col-12 col-lg d-flex justify-content-center align-items-center'>
                            <div className='col-11 col-md-10 col-lg-10 col-xl-10 col-xxl-8'>
                                <div className='content px0'>
                                    <h2 className='fontBlack34Bold text-start'>Enquire</h2>
                                    <p className='font18Black'>For further general information, if you wish to book a visit to our club prior to joining, or a copy of our brochure, or to arrange a tour of our online platform, please fill in your details below;</p>
                                    <p className='font13Black mt-5'>Click to help us direct your enquiry</p>
                                    <div className='d-flex g-1 align-items-start checkboxCustom'>
                                        <div className='col d-flex align-items-center flex-wrap flex-md-nowrap text-center text-md-start'>
                                            <input id="visitclub" type="checkbox" name="clubs" value="visitclub" onChange={onCheckboxChange} />
                                            <label for="visitclub" className='flex-wrap flex-md-nowrap'>
                                                <div className='icon'><img src={icMcoin} alt="icMcoin" /></div>
                                                <p className='font18Black ms-0 ms-md-2 mb-0'>Book a visit<br />to one of our<br />clubs</p>
                                            </label>
                                        </div>
                                        <div className='col d-flex align-items-center flex-wrap flex-md-nowrap text-center text-md-start'>
                                            <input id="platform" type="checkbox" name="online_platform" onChange={onCheckboxChange} />
                                            <label for="platform" className='flex-wrap flex-md-nowrap'>
                                                <div className='icon'><img src={icBook} alt="icBook" /></div>
                                                <p className='font18Black ms-0 ms-md-2 mb-0'>Book a tour<br />of our online<br />platform</p>
                                            </label>
                                        </div>
                                        <div className='col d-flex align-items-center flex-wrap flex-md-nowrap text-center text-md-start'>
                                            <input id="brochure" type="checkbox" name="brochure" onChange={onCheckboxChange} />
                                            <label for="brochure" className='flex-wrap flex-md-nowrap'>
                                                <div className='icon'><img src={icBrochure} alt="icBrochure" /></div>
                                                <p className='font18Black ms-0 ms-md-2 mb-0'>Send me a<br />brochure</p>
                                            </label>
                                        </div>
                                    </div>

                                    <div className='enquireForm mt-5'>
                                        <Form validated={isValidated} noValidate onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="" name="name" className='fieldColored' onChange={onFormChange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="" name="email" className='fieldColored' onChange={onFormChange}
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid email.
                                                </Form.Control.Feedback>
                                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control type="text" placeholder="" name="phone_number" className='fieldColored' onChange={onFormChange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a number.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>What would you like to find out?</Form.Label>
                                                <Form.Control as="textarea" name="message" rows={10} className='fieldColored' onChange={onFormChange} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a enquire.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-5" controlId="formBasicCheckbox">
                                                <div class="form-check">
                                                    <input type="checkbox" id="formBasicCheckbox" className="form-check-input" onChange={() => setIsTermsAndCondApply(!isTermsAndConApply)} />
                                                    <label title="" for="" className="form-check-label flex-wrap">
                                                        <span className='me-1'>I’ve read and agree to our</span>
                                                        <Nav.Link as={Link} className='link13Black d-inline'onClick={() => setModalTermsand(true)} style={{ cursor: "pointer" }}>Terms and Conditions</Nav.Link>
                                                        <span className='mx-1'> and </span>
                                                        <Nav.Link as={Link} className='link13Black d-inline'onClick={() => setModalPrivacy(true)} style={{ cursor: "pointer" }}>Privacy Policy</Nav.Link>
                                                        <span>. *</span>
                                                    </label>
                                                </div>
                                            </Form.Group>

                                            <Button type="submit" className='btn siteBlackBtn' disabled={ !isTermsAndConApply || isSubmitting || isLoading}>
                                                {isLoading ? <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                                </>
                                                    : ''
                                                }
                                                SUBMIT ENQUIRY
                                            </Button>
                                        </Form>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-auto d-none d-xl-block'>
                            <img src={enquireImg} className="img-fluid" alt="enquireImg" />
                        </div>
                    </div>
                </div>


                {/* Last BlankPart */}
                <div className='lastBG'></div>



            </div>

            <Footer />

            <TermsandConditionModal show={modaltermsand} onHide={() => setModalTermsand(false)} />
            <PrivacyPolicyModel show={modalprivacy} onHide={() => setModalPrivacy(false)} />

        </>
    )
}

export default Enquire