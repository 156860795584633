import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    opportunityList: {
      data: [],
      metaData: null,
      loading: false,
    },
    investmentDetail: {
      data: null,
      loading: false,
    },
    opportunityDetails: {
      data: null,
      loading: false,
    },
    userInvestmentList: {
      data: [],
      metaData: null,
      stats: null,
      loading: false,
    },
    userMCoinList: {
      data: [],
      metaData: null,
      loading: false,
    },
    userDividendList: {
      data: [],
      metaData: null,
      loading: false,
    },
    _userDetails: {
      data: null,
      loading: false
    },
    perMCoinPrice: {
      data: 0,
      loading: false
    },
    investmentStats: {
      data: null,
      loading: false
    },
    assetsList: {
      data: [],
      loading: false
    },
    notifications: {
      data: [],
      loading: false,
      count: 0,
      // page: 1,
      hasMore: true
    }
  },
  reducers: {
    userSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const initialState = userSlice.getInitialState();

export const { userSetState } = userSlice.actions;

export default userSlice.reducer;
