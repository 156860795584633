import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router";
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Header from "./common/Header";
import Footer from "./common/Footer";
import icService1 from "./assets/images/icService1.png";
import icService2 from "./assets/images/icService2.png";
import icService3 from "./assets/images/icService3.png";
import icService4 from "./assets/images/icService4.png";
const monthlyAmount = process.env.REACT_APP_MONTHLY_AMOUNT;
const annualAmount = process.env.REACT_APP_ANNUAL_AMOUNT;


const MembershipPage = () => {
    const navigate = useNavigate();

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Header />
            <div className='middlePart'>
                {/* Section Our membershipPart */}
                <div className='membershipPart'>
                    <div className='row g-0 align-items-stretch h-100'>
                        <div className='col-12 col-lg-6 d-flex justify-content-center'>
                            <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9 position-relative h-100'>
                                <div className='membershipDetails'>
                                    <div className='content'>
                                        <h2 className='fontBlack34Bold'>Membership</h2>
                                        <p className='font18Black'>To become a member, you must register for membership to the club.</p>
                                        <p className='font18Black'>Membership is paid monthly or yearly. This will give you access to the online platform, where you can raise funds for your project, invest collectively in other projects, trade shares, sell, buy and hold <span role='button' className='text-decoration-underline' onClick={() => navigate("/mcoin")}>Mcoins</span>. </p>
                                        <p className='font18Black'>If you wish to discontinue your membership but have invested and own shares from previous investment rounds, these assets will remain in your ownership, but you cannot participate in future investment rounds, trade shares, raise liquidity or buy and sell <span role='button' className='text-decoration-underline' onClick={() => navigate("/mcoin")}>MCOINS</span>.</p>
                                        <div className='d-table mt-5'>
                                            <Nav.Link as={Link} className='btn siteBlackBtn' to={"/enquire"}>ENQUIRE</Nav.Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='membershipImg'></div>
                        </div>
                    </div>
                </div>

                {/* Section membership Service Part */}
                <div className='membershipServicePart'>
                    <div className='container'>
                        <div className='membershipIncludePart'>
                            <h2 className='fontBlack34Bold text-center m-0'>Extra benefits of being a member</h2>
                            <div className='services mt-5'>
                                <div className='row g-0 align-items-stretch'>
                                    <div className='col-12 col-md-12 col-lg-6'>
                                        <div className='servicePart'>
                                            <div className='text-center'><img src={icService1} className="img-fluid icon" alt="icService1" /></div>
                                            <h3 className='font34LightBlk text-center text-uppercase py-5'>ACCESS TO ALL CLUBS</h3>
                                            <p className='font18Black text-center'>You can access all clubs in the UK and abroad.</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-12 col-lg-6'>
                                        <div className='servicePart' style={{ borderRight: "0" }}>
                                            <div className='text-center'><img src={icService2} className="img-fluid icon" alt="icService2" /></div>
                                            <h3 className='font34LightBlk text-center text-uppercase py-5 text-uppercase'>Access to our network</h3>
                                            <p className='font18Black text-center'>You will have the opportunity to meet, and connect with like-minded people.</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-12 col-lg-6'>
                                        <div className='servicePart' style={{ borderBottom: "0" }}>
                                            <div className='text-center'><img src={icService3} className="img-fluid icon" alt="icService3" /></div>
                                            <h3 className='font34LightBlk text-center text-uppercase py-5'>ACCESS TO<br /> EXCLUSIVE EVENTS</h3>
                                            <p className='font18Black text-center'>To our own events and seminars as well as third party organisers such as the financial times.</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-12 col-lg-6'>
                                        <div className='servicePart' style={{ borderRight: "0", borderBottom: "0" }}>
                                            <div className='text-center'><img src={icService4} className="img-fluid icon" alt="icService4" /></div>
                                            <h3 className='font34LightBlk text-center text-uppercase py-5'>POTENTIAL INCOME</h3>
                                            <p className='font18Black text-center'>Invest collectively with other members into stocks<br />Buy, sell or hold Mcoins backed by stocks</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='py-5'>
                                    <h3 className='font34LightBlk text-center text-uppercase mb-5'>MEMBERSHIP FEES</h3>
                                    <h3 className='font34LightBlk text-center text-uppercase mb-5'>
                                        £{monthlyAmount} monthly
                                        <br />
                                        £{annualAmount} yearly.
                                    </h3>
                                    <p className='font18Black text-center'>Cancel at any time with 1 month prior notice.</p>
                                    <div className='mx-auto text-center'>
                                        <Nav.Link as={Link} to={"/signup"} className='btn d-inline-block siteThemeBtn'>BECOME A MEMBER</Nav.Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Last BlankPart */}
                <div className='lastBG'></div>
            </div>
            <Footer />
        </>
    )
}

export default MembershipPage