import React, { useState, useEffect, useMemo } from "react";
import {
    Container,
    Col,
    Table,
    Spinner,
    Breadcrumb,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import icCrypto from "../../../assets/images/icCrypto.png";
import { useDispatch, useSelector } from "react-redux";
import { opportunityGetOpportunityList } from "../../../store/user/action";
import PaginationComponent from "../../common/PaginationComponent";

const MyOpportunities = () => {
    const dispatch = useDispatch();

    const { opportunityList } = useSelector((state) => state.userStore);

    const initFilter = { opportunity_type: "", page: 1, limit: 10, search: "" }
    const [filter, setFilter] = useState(initFilter);
    const totalPages = useMemo(() => {
        return Math.ceil((opportunityList?.metaData?.total / filter.limit) || 1)
    }, [opportunityList, filter.limit]);

    useEffect(() => {
        dispatch(opportunityGetOpportunityList({ payload: filter }))
    }, [dispatch, filter])

    return (
        <>
            <div className="mt-4 mb-4 page_main dashboard">
                <Container fluid>
                    <h1>
                        My Opportunity
                        <span className="font14Blk"></span>
                    </h1>
                    <div className="divBg_white mb-3 ">
                        <div>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>My Opportunity</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Col md={12} lg={12}>
                            <div className="">
                                {opportunityList.loading ?
                                    <div className="d-flex align-items-center justify-content-center py-5 my-5 h-100 w-100">
                                        <Spinner size="md" variant="dark" />
                                    </div> :
                                    <div className="tableSM">
                                        <Table responsive striped borderless>
                                            <thead>
                                                <tr>
                                                    <th>Title (Click on each row for more information)</th>
                                                    <th>Company</th>
                                                    <th>Category</th>
                                                    <th className="text-center">Seeking funds £</th>
                                                    <th className="text-center">Gross profit PA £</th>
                                                    <th className="text-center">Number of votes</th>
                                                    <th className="text-center">Risk factor</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {opportunityList.data.length === 0 ? (
                                                    <tr>
                                                        <th className="text-center py-5" colSpan={8}>No Opportunity Found!</th>
                                                    </tr>
                                                ) :
                                                    opportunityList.data?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {item.investment_title}
                                                                <br />
                                                                <span>Proposal created by {item.createdby}</span>
                                                            </td>
                                                            <td>{item.company_name}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="catgSymbol me-1">
                                                                        <img src={icCrypto} />
                                                                    </span>
                                                                    {item.investment_category}
                                                                </div>
                                                            </td>
                                                            <td className="text-center">30,000</td>
                                                            <td className="text-center">{item.gross_profit_yearly}</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-center">Medium</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <NavLink
                                                                        to={`/opportunity/detail/${item._id}`}
                                                                        className="btn_Black viewMore"
                                                                    >
                                                                        View More
                                                                    </NavLink>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                        <PaginationComponent
                                            selectedPage={filter.page}
                                            handlePageChange={p => setFilter(prev => ({ ...prev, page: p }))}
                                            totalPages={totalPages}
                                        />
                                    </div>}
                            </div>
                        </Col>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default MyOpportunities;
