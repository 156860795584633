import React, { useEffect, useState ,useRef , useCallback } from "react";
import { Button, Form, Card } from "antd";
import { useLocation, useParams } from "react-router-dom";
import Logo from "../../assets/admin/images/Logo_white.png";
import { Link } from 'react-router-dom';
import AxiosInstance from '../../helpers/AxiosRequest';
import { customErrorMessage, customMessage } from "../../helpers/response";
import url from "../../helpers/development.json";

const AdminEmail = () => {
  const [userData, setUserData] = useState([]);
    const [minutes] = useState(localStorage.getItem('minutes') || 0);
    const { state } = useLocation();
    const { token } = useParams();
    const initalSend = sessionStorage.getItem('resent')
    const initialTimer = sessionStorage.getItem("timer") ?? 60;
    const timeoutId = useRef(null);
    const [timer, setTimer] = useState(initialTimer);
    const [isResend, setIsResend] = useState(initalSend);

  useEffect(() => {
    if (token) {
      fetchUserData(token);
    }
    if (state && state.token) {
      fetchUserData(state.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countTimer = useCallback(() => {
    if (timer <= 0) {
        sessionStorage.setItem("timer", 0);
        setIsResend(true)
        sessionStorage.setItem('resent', true)
    } else {
        setTimer(timer - 1);
        sessionStorage.setItem("timer", timer-1);
    }
}, [timer]);

useEffect(() => {
  timeoutId.current = window.setTimeout(countTimer, 1000);
  return () => window.clearTimeout(timeoutId.current);
}, [timer, countTimer]);

  const fetchUserData = async (token) => {
    const response = await AxiosInstance.post(url.AdminverifyLink, { token: token });
    if (response.status === 200 && response.data.statusText === "Success") {
      setUserData(response.data.data);
    } else {
      customErrorMessage("Invalid or expired link!");
    }
  }


  const linkClick = () => {
    window.open("https://mail.google.com/mail/u/0/#inbox");
  }


  const handleResend = async (event) => {
      const response = await AxiosInstance.post(url.AdminforgotPassword, { email: userData.email });
      if (response.status === 200 && response.data.statusText === "Success") {
        customMessage("We sent a password reset link to " + userData.email);
        sessionStorage.setItem('resent', false)
            setIsResend(false);
            setTimer(60)
            initialTimer.current = 60
      } else {
        customErrorMessage("Email not found!");
      }
    }
  

  return (
    <div className="d-flex justify-content-center flex-column align-items-center forheight">
      <div className="loginBox customHEAD">
        <div className="text-center mb-3 ">
          <img src={Logo} alt=""/>
        </div>
        <Card className="login-form "
            bordered={true} 
            title="Check Your Email"
         >
        
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 32,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <div className="d-flex justify-content-center text-center mb-3">
                <h6 className="password-text">We sent a password reset link to {userData.email}</h6>
            </div>

              <Button
               onClick={(e) => linkClick()}
                type="primary"
                htmlType="submit"
              className="login-button w-100"
            >
              Open Email Link
            </Button>


            <div className="mt-3 mb-3 fontsize text-center">
              <span className="linkBlk">
                Didn't receive the email?
              </span>
              {
                timer > 0 && !isResend ?
                  <>
                    ({`0${minutes}`}: {timer < 10 ? `0${timer}` : timer})
                  </>
                  :
                  (timer > 0 && isResend ?
                    <>
                      ({`0${minutes}`}: {timer < 10 ? `0${timer}` : timer})
                    </> :

                    <button type="button" className="linkBlk" onClick={() => handleResend()} >
                      Click to resend
                    </button>
                  )
              }
            </div>

            <Link to="/admin/auth/login" className="text-center"><h6 class="mt-4 mb-1 fontsizeset">Back to Login </h6></Link>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default AdminEmail;
