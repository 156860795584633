import { toast } from "react-toastify";
import { initialState, userSetState, userSlice } from "./slice";
import url from "../../helpers/development.json";
import qs from "query-string";
import AxiosInstance from "../../helpers/AxiosRequest";

export const opportunityGetOpportunityList = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "opportunityList.loading", value: true },
        { key: "opportunityList.data", value: [] },
        { key: "opportunityList.metaData", value: null },
      ])
    );
    const { data } = await AxiosInstance.post(`${url.opportunityGetOpportunityList}`, { ...payload });
    dispatch(
      userSetState([
        { key: "opportunityList.loading", value: false },
        { key: "opportunityList.data", value: data.data.data },
        { key: "opportunityList.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "opportunityList.loading", value: false },
        { key: "opportunityList.data", value: [] },
        { key: "opportunityList.metaData", value: null },
      ])
    );
  }
};

export const investmentGetDetail = () => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "investmentDetail.loading", value: true },
        { key: "investmentDetail.data", value: null },
      ])
    );
    const { data } = await AxiosInstance.get(`${url.investmentGetDetail}`);
    dispatch(
      userSetState([
        { key: "investmentDetail.loading", value: false },
        { key: "investmentDetail.data", value: data.data.data },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "investmentDetail.loading", value: false },
        { key: "investmentDetail.data", value: null },
      ])
    );
  }
};

export const getOpportunityDetails = ({ _id }) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "opportunityDetails.loading", value: true },
        { key: "opportunityDetails.data", value: null },
      ])
    );
    const { data } = await AxiosInstance.get(`${url.opportunityDetails}/${_id}`);
    dispatch(
      userSetState([
        { key: "opportunityDetails.loading", value: false },
        { key: "opportunityDetails.data", value: data.data },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "opportunityDetails.loading", value: false },
        { key: "opportunityDetails.data", value: null },
      ])
    );
  }
};

export const getUserInvestmentList = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "userInvestmentList.loading", value: true },
        { key: "userInvestmentList.data", value: [] },
        { key: "userInvestmentList.stats", value: null },
      ])
    );
    const { data } = await AxiosInstance.post(`/investment/list`, { ...payload });
    dispatch(
      userSetState([
        { key: "userInvestmentList.loading", value: false },
        { key: "userInvestmentList.data", value: data.data.data },
        { key: "userInvestmentList.metaData", value: data.data.metadata },
        { key: "userInvestmentList.stats", value: data.data.stats },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "userInvestmentList.loading", value: false },
        { key: "userInvestmentList.data", value: [] },
        { key: "userInvestmentList.stats", value: null },
      ])
    );
  }
};

export const getUserMCoinList = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "userMCoinList.loading", value: true },
        { key: "userMCoinList.data", value: [] },
        { key: "userMCoinList.metaData", value: null },
      ])
    );
    const { data } = await AxiosInstance.post(`/investment/mtokens`, { ...payload });
    console.log('data', data);
    dispatch(
      userSetState([
        { key: "userMCoinList.loading", value: false },
        { key: "userMCoinList.data", value: data.data.data },
        { key: "userMCoinList.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "userMCoinList.loading", value: false },
        { key: "userMCoinList.data", value: [] },
        { key: "userMCoinList.metaData", value: null },
      ])
    );
  }
};

export const getUserDividendList = ({ payload }) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "userDividendList.loading", value: true },
        { key: "userDividendList.data", value: [] },
        { key: "userDividendList.metaData", value: null },
      ])
    );
    const { data } = await AxiosInstance.post(`/investment/dividends`, { ...payload });
    console.log('data', data);
    dispatch(
      userSetState([
        { key: "userDividendList.loading", value: false },
        { key: "userDividendList.data", value: data.data.data },
        { key: "userDividendList.metaData", value: data.data.metadata },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "userDividendList.loading", value: false },
        { key: "userDividendList.data", value: [] },
        { key: "userDividendList.metaData", value: null },
      ])
    );
  }
};

export const getInvestmentStats = () => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "investmentStats.loading", value: true },
        { key: "investmentStats.data", value: null },
      ])
    );
    const { data } = await AxiosInstance.get(`/investment/stats`);
    dispatch(
      userSetState([
        { key: "investmentStats.loading", value: false },
        { key: "investmentStats.data", value: data.data.data },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "investmentStats.loading", value: false },
        { key: "investmentStats.data", value: null },
      ])
    );
  }
};

export const getPerMCoinPrice = () => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "perMCoinPrice.loading", value: true },
        { key: "perMCoinPrice.data", value: 0 },
      ])
    );
    const { data } = await AxiosInstance.get(`/mcoin/coin-price`);
    dispatch(
      userSetState([
        { key: "perMCoinPrice.loading", value: false },
        { key: "perMCoinPrice.data", value: data.data || 0 },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "perMCoinPrice.loading", value: false },
        { key: "perMCoinPrice.data", value: 0 },
      ])
    );
  }
};

export const getAssetsList = ({ category }) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "assetsList.loading", value: true },
        { key: "assetsList.data", value: [] },
      ])
    );
    const { data } = await AxiosInstance.post(`/assets/investment`, { category });
    console.log('getAssetsList', data);
    dispatch(
      userSetState([
        { key: "assetsList.loading", value: false },
        { key: "assetsList.data", value: data.data },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "assetsList.loading", value: false },
        { key: "assetsList.data", value: [] },
      ])
    );
  }
};

// ==== user profile ====

export const _getUserDetails = () => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "_userDetails.loading", value: true },
        { key: "_userDetails.data", value: null },
      ])
    );
    const { data } = await AxiosInstance.get(`${url.getUserDetails}`);
    dispatch(
      userSetState([
        { key: "_userDetails.loading", value: false },
        { key: "_userDetails.data", value: data.data },
      ])
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "_userDetails.loading", value: false },
        { key: "_userDetails.data", value: null },
      ])
    );
  }
};

// const getUserDetails = async () => {
//   try {
//       const response = await AxiosInstance.get(url.getUserDetails);
//       if (response.status === 200 && response.data.statusText === "Success") {
//           setValues(response.data.data);
//           if (response.data.data.profileImgURL) {
//               setProfileImgURL(response.data.data.profileImgURL);
//           }
//           setInitialValues(response.data.data);
//           setInvestorType(response.data.data.investor_type);
//           setFamilyOfficeName(response.data.data.familyOfficeName);
//           setIdentityAccessKey(response.data.data.identityAccessKey);
//           setBio(response.data.data.bio);
//           const data1 = await handleSelectCountry(response.data.data.country);
//           await handleStates(data1, response.data.data.state, response.data.data.country);
//           setSelectedCity(response.data.data.city);
//       }
//   } catch (error) {
//       return error;
//   }
// };

//Notification

export const getNotifications = ({page}) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "notifications.loading", value: true },
        // { key: "notifications.data", value: [] },
      ])
    );
    const { data } = await AxiosInstance.get(`/notification/getNotificationByUser?page=${page}&limit=20`);
    if(page === 1) {
      dispatch(
        userSetState([
          { key: "notifications.loading", value: false },
          { key: "notifications.data", value: data.data.data },
          { key: "notifications.count", value: data.data.unreadbleCount },
          { key: "notifications.hasMore", value: data.data.data.length < 20 ? false : true },
        ])
      );
    } else {
      dispatch(
        userSetState([
          { key: "notifications.loading", value: false },
          { key: "notifications.data", value: [ ...initialState.notifications.data ,  ...data.data.data] },
          { key: "notifications.count", value: data.data.unreadbleCount },
          { key: "notifications.hasMore", value: data.data.data.length < 20 ? false : true },
        ])
      );
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!")
    console.error(error);
    dispatch(
      userSetState([
        { key: "notifications.loading", value: false },
        { key: "notifications.data", value: [] },
      ])
    );
  }
}
