import { Container, Row, Col } from "react-bootstrap";
import LogoBig from "./assets/images/logoBig.png";
import { NavLink, useLocation, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AxiosInstance from "../../helpers/AxiosRequest";
import url from "../../helpers/development.json";
import { customErrorMessage, customMessage } from "../../helpers/response";

const CheckEmail = () => {

    const [userData, setUserData] = useState([]);
    const [minutes,] = useState(localStorage.getItem('minutes') || 0);
    const { state } = useLocation();
    const { token } = useParams();
    const initalSend = sessionStorage.getItem('resent')
    const initialTimer = sessionStorage.getItem("timer") ?? 60;
    const timeoutId = useRef(null);
    const [timer, setTimer] = useState(initialTimer);
    const [isResend, setIsResend] = useState(initalSend);

    useEffect(() => {
        if (token) {
            fetchUserData(token);
        }
        if (state && state.token) {
            fetchUserData(state.token);
        }
    }, []);

    const countTimer = useCallback(() => {
        if (timer <= 0) {
            sessionStorage.setItem("timer", 0);
            setIsResend(true)
            sessionStorage.setItem('resent', true)
        } else {
            setTimer(timer - 1);
            sessionStorage.setItem("timer", timer-1);
        }
    }, [timer]);

    useEffect(() => {
        timeoutId.current = window.setTimeout(countTimer, 1000);
        return () => window.clearTimeout(timeoutId.current);
    }, [timer, countTimer]);

    const fetchUserData = async (token) => {
        const response = await AxiosInstance.post(url.verifyLink, { token: token });
        if (response.status === 200 && response.data.statusText === "Success") {
            setUserData(response.data.data);
        } else {
            customErrorMessage("Invalid or expired link!");
        }
    }

    const linkClick = () => {
        window.open("https://mail.google.com/mail/u/0/#inbox");
    }

    const handleResend = async () => {
        const response = await AxiosInstance.post(url.forgotPassword, { email: userData.email });
        if (response.status === 200 && response.data.statusText === "Success") {
            customMessage("We sent a password reset link to " + userData.email);
            sessionStorage.setItem('resent', false)
            setIsResend(false);
            setTimer(60)
            initialTimer.current = 60
        } else {
            customErrorMessage("Email not found!");
        }
    }

    return (
        <>
            <div className="login_parent">
                <Container fluid>
                    <Row className="height100vh_">
                        <Col
                            lg={6}
                            md={12}
                            sm={12}
                            className="p-0 d-none d-md-none d-lg-block"
                        >
                            <div className="leftSlider">
                                <div className="logo text-center">
                                    <img src={LogoBig} alt="" />
                                </div>
                                <div className="d-flex align-itmes-center flex-column contentSlide">
                                    <div className="bottomContent text-center">
                                        <p>
                                            The Mcoin economics is reliant on profitable assets, therefore members<br />
                                            should invest in a majority of companies with a strong asset balance sheet<br />
                                            and likely profitable business model.
                                        </p>
                                        <h4>
                                            Your investment can go down as well as up.<br />
                                            Only invest funds you can afford to lose.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="p-0">
                            <div className="rightForm">
                                <div className="d-flex flex-column justify-content-center col-10 col-xl-8 mx-auto h-100 gap80">
                                    <h4 className="loginTitle">Check Your Email</h4>
                                    <div className="d-flex justify-content-center">
                                        <div style={{ width: "60%" }} className="formGuide">
                                            We sent a password reset link to {userData.email}
                                        </div>
                                    </div>
                                    <button
                                        onClick={(e) => linkClick()}
                                        type="button"
                                        className="btn_Black"
                                    >
                                        Open Email Link
                                    </button>


                                    <div className="text-center registerNow">
                                        <span className="clrOrange">
                                            Didn't receive the email?
                                        </span>

                                        {
                                            timer > 0 && !isResend ? 
                                                <>
                                                    ({`0${minutes}`}: {timer < 10 ? `0${timer}` : timer})
                                                </>
                                            :
                                            (timer > 0 && isResend ? 
                                                <>
                                                    ({`0${minutes}`}: {timer < 10 ? `0${timer}` : timer})
                                                </> : 

                                                <button type="button" className="clrOrange_" onClick={() => handleResend()} >
                                                    Click to resend
                                                </button>
                                            )
                                        }
                                    </div>
                                    <div className="text-center registerNow">
                                        <NavLink to="/login" className="clrOrange_">
                                            Back to Login
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default CheckEmail;