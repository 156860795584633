import { Col, Form, Modal, Row } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { handleNValue } from "../../../helpers/handleNValue";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import AxiosInstance from "../../../helpers/AxiosRequest";

const stripePromise = loadStripe(
    'pk_test_51LUclsJHbvJwtxo9VhtCTdmfYxZYvgyIH196C9OCBMfovNt8EhIq15593smEfO4unwvAJGAyJ7hmE5Bd4BlLkfeH00cJmLl3xZ'
);

const AddBalancePopup = ({ show, onHide, getUserDetails, msg = "", _setInvestPopup = null }) => {
    const handleClosePopup = () => {
        onHide()
        // formik.resetForm()
    }

    return (
        <Modal
            show={show}
            onHide={handleClosePopup}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="claimModal"
        >
            <Modal.Header className="p-0 pt-1 mb-2" closeButton>
                <div className="py-2 ps-4">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {msg || "Add Balance"}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Elements stripe={stripePromise}>
                <AddCardForm
                    onHide={onHide}
                    getUserDetails={getUserDetails}
                    handleClosePopup={handleClosePopup}
                    _setInvestPopup={_setInvestPopup}
                />
            </Elements>
        </Modal>
    )
}

const AddCardForm = ({ onHide, getUserDetails, handleClosePopup, _setInvestPopup }) => {
    const _status = {
        Pending: "Pending",
        Succeeded: "Succeeded",
        Failed: "Failed"
    }
    const [amount, setAmount] = useState("")
    const stripe = useStripe();
    const elements = useElements();
    const initialError = { amount: null, cardNumber: null, cardExpiry: null, cardCvc: null };
    const [_error, _setError] = useState(initialError);
    const [cardInputComplete, setCardInputComplete] = useState({
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false
    });
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        let errors = {};
        // Validate amount
        if (!amount || amount === "0" || amount === 0 || amount === undefined || amount === null || amount === "") {
            errors.amount = "Please enter a valid amount!";
        }

        // Validate card inputs
        if (!cardInputComplete.cardNumber) {
            errors.cardNumber = "Please enter your card number!";
        }
        if (!cardInputComplete.cardExpiry) {
            errors.cardExpiry = "Please enter your card's expiry date!";
        }
        if (!cardInputComplete.cardCvc) {
            errors.cardCvc = "Please enter your card's CVC!";
        }

        return errors;
    };

    const checkPaymentStatus = async (_id = null) => {
        // const transaction_id = _id || localStorage.getItem("transaction_id")
        const { data } = await AxiosInstance.get(`/stripe/status/${_id}`);
        return data.data
    }

    const checkStatusRecursively = (_id) => {
        return new Promise((resolve, reject) => {
            const check = async () => {
                try {
                    const response = await checkPaymentStatus(_id);
                    if (response.status === _status.Pending) {
                        setTimeout(check, 2000);
                    } else {
                        resolve(response); // Resolve the promise when done
                    }
                } catch (error) {
                    reject(error); // Reject the promise on error
                }
            };
            check();
        });
        // return { status: _status.Succeeded }
    };


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);

            const formErrors = validateForm();
            _setError(formErrors);

            if (Object.keys(formErrors).length > 0) {
                setLoading(false);
                return false;
            }

            if (!stripe || !elements) return false;

            const cardElement = elements.getElement(CardNumberElement);
            const { error, token } = await stripe.createToken(cardElement)

            if (error) {
                toast.error(error.message)
                return false;
            }

            const { data } = await AxiosInstance.post(
                `/stripe/charge`,
                {
                    "token": token.id,
                    "amount": Number(amount),
                    "return_url": `${process.env.REACT_APP_API_URL}/stripe/webhook`,

                });

            const response = await checkStatusRecursively(data.data.transaction_id);

            // Process the response after checkStatusRecursively resolves
            if (response.status === _status.Succeeded) {
                toast.success("Balance added successfully");
            } else if (response.status === _status.Failed) {
                toast.error(response?.failed_reason || "Payment Failed!");
            }

            resetForm();
            getUserDetails();
            onHide();
            if (_setInvestPopup !== null) _setInvestPopup()
        } catch (error) {
            console.log('error', error.message);
            toast.error(error?.response?.data?.message || error?.message || "Something went wrong!");
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        _setError(initialError);
        setLoading(false);
        setAmount("")
        // elements.getElement(CardElement).clear();
    };

    const handleCardInputChange = (inputType, event) => {
        _setError(prev => ({ ...prev, [inputType]: event?.error?.message || null }));
        setCardInputComplete(prev => ({ ...prev, [inputType]: event.complete }));
    };
    const handleInputChange = (e) => {
        setAmount(handleNValue(e));
        _setError(prev => ({ ...prev, amount: null }));
    };


    const _disable = useMemo(() => {
        const allInputsComplete = cardInputComplete.cardNumber && cardInputComplete.cardExpiry && cardInputComplete.cardCvc;
        const anyInputError = _error.cardNumber || _error.cardExpiry || _error.cardCvc;
        return !stripe || loading || !allInputsComplete || anyInputError;
    }, [stripe, loading, cardInputComplete, _error]);


    const style = {
        base: {
            iconColor: '#666EE8',
            color: '#31325F',
            fontWeight: '300',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '14px',
            '::placeholder': {
                color: '#CFD7E0',
                fontWeight: '300',
                fontSize: '14px',
            },
        },
        invalid: {
            color: '#E25950',
        },
        focus: {
            boxShadow: '0 0 0 0.25rem rgba(244, 121, 68, 0.118)',
        },
    }

    return (
        <Form onSubmit={handleFormSubmit} className="loginForm claimForm">
            <Modal.Body>
                <div className="mx-auto">
                    <Form.Group
                        className="mb-3 inputOuter_"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="defLabel_">
                            Amount <span className="requireStar">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="0"
                            className="inputs defInput_"
                            name="amount"
                            value={amount}
                            onChange={e => handleInputChange(e)}
                        />
                        {_error.amount && (
                            <Form.Text className="text-danger">{_error.amount}</Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group
                        className="mb-3 inputOuter_"
                        controlId="formBasicEmail"
                    >
                        {/* <CardElement onChange={handleCardInputChange} /> */}
                        <Form.Label className="">
                            Card Number <span className="requireStar">*</span>
                        </Form.Label>
                        <CardNumberElement
                            options={{ style: style }}
                            onChange={e => handleCardInputChange("cardNumber", e)}
                        />
                        {_error.cardNumber && <Form.Text className="text-danger">{_error.cardNumber}</Form.Text>}
                    </Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3 inputOuter_"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="">
                                    Expiry <span className="requireStar">*</span>
                                </Form.Label>
                                <CardExpiryElement
                                    options={{ style: style }}
                                    onChange={e => handleCardInputChange("cardExpiry", e)}
                                />
                                {_error.cardExpiry && <Form.Text className="text-danger">{_error.cardExpiry}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3 inputOuter_"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="">
                                    CVV <span className="requireStar">*</span>
                                </Form.Label>
                                <CardCvcElement
                                    options={{ style: style }}
                                    onChange={e => handleCardInputChange("cardCvc", e)}
                                />
                                {_error.cardCvc && <Form.Text className="text-danger">{_error.cardCvc}</Form.Text>}
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer className="">
                <button
                    onClick={handleClosePopup}
                    type="button"
                    className="btn_White"
                    disabled={loading}
                >
                    Cancel
                </button>
                <button
                    disabled={_disable}
                    type="submit"
                    className="btn_Black">
                    {loading ? "Please wait..." : 'Submit'}
                </button>
            </Modal.Footer>
        </Form>
    )
}

export default AddBalancePopup;