import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Form, Button, Spinner, Modal, Breadcrumb } from "react-bootstrap";
import DocProof from "../../assets/images/DocProof.png";
import { CheckmarkIcon, EditIcon } from "../common/Icons";
import thumb from '../../assets/images/thumb.jpg';
import AxiosInstance from "../../helpers/AxiosRequest";
import url from '../../helpers/development.json';
import { customErrorMessage, customMessage, successResponse } from "../../helpers/response";
import { useRef } from "react";
import VerifyButton from "@passbase/button/react";
import axios from "axios";
import { InvestorType } from "../../helpers/enum";
import { handleNValue } from "../../helpers/handleNValue";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { uploadImageWithSignUrl } from "../../helpers/UploadImageWiths3";
import AddBalancePopup from "./paymentComponent/AddBalancePopup";
import { Link, NavLink } from "react-router-dom";

const stripePromise = loadStripe(
    'pk_test_51LUclsJHbvJwtxo9VhtCTdmfYxZYvgyIH196C9OCBMfovNt8EhIq15593smEfO4unwvAJGAyJ7hmE5Bd4BlLkfeH00cJmLl3xZ'
);
const UpdateProfile = () => {
    const [investorType, setInvestorType] = useState('');
    const [investorModal, setInvestorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [textBoxStatus, setTextBoxStatus] = useState(true);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [hasAuthority, setHasAuthority] = useState("yes");
    const [familyOfficeName, setFamilyOfficeName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [identityAccessKey, setIdentityAccessKey] = useState("");
    const [bio, setBio] = useState("");
    const [profileImgURL, setProfileImgURL] = useState(thumb);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [file, setFile] = useState({})
    const profileImg = useRef();
    const [countries, setCountries] = useState();
    const [states, setStates] = useState();
    const [cities, setCities] = useState();
    const [addBalance, setAddBalancePopup] = useState({ show: false });
    const [withdrawalPopup, setWithdrawalPopup] = useState({ show: false });
    const lastEight = initialValues.membership_id ? initialValues.membership_id.slice(-10) : null;

    const onFormChange = (e, updatedAt) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
    };

    const getCountries = async () => {
        const response = await axios.get("https://api.countrystatecity.in/v1/countries", {
            headers: {
                "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_API_KEY
            }
        });
        if (response.status === 200) {
            setCountries(response.data);
        }
    }

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    useEffect(() => {
        setTimeout(() => {
            if (identityAccessKey && values && values.verify_status === "Processing") {
                getUserDetails();
            }
        }, [100000]);
    }, [values]);

    const getUserDetails = async () => {
        try {
            const response = await AxiosInstance.get(url.getUserDetails);
            if (response.status === 200 && response.data.statusText === "Success") {
                setValues(response.data.data);
                if (response.data.data.profileImgURL) {
                    setProfileImgURL(response.data.data.profileImgURL);
                }
                setInitialValues(response.data.data);
                setInvestorType(response.data.data.investor_type);
                setFamilyOfficeName(response.data.data.familyOfficeName);
                setIdentityAccessKey(response.data.data.identityAccessKey);
                setBio(response.data.data.bio);
                const data1 = await handleSelectCountry(response.data.data.country);
                await handleStates(data1, response.data.data.state, response.data.data.country);
                setSelectedCity(response.data.data.city);
            }
        } catch (error) {
            return error;
        }
    };

    const handleSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        if (!familyOfficeName && investorType === "Family Office") {
            return setInvestorModal(true);
        }
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            let data = {
                name: values.name,
                address: values.address,
                pincode: values.pincode,
                bio: bio,
                country: selectedCountry,
                state: selectedState,
                city: selectedCity,
                familyOfficeName: familyOfficeName,
            }
            if (investorType === "") {
                data = { ...data, investor_type: values.investor_type };
            } else {
                data = { ...data, investor_type: investorType };
            }
            setIsLoading(true);
            const response = await AxiosInstance.post(url.updateProfile, data);
            if (response.status === 200 && response.data.statusText === "Success") {
                setValues(response.data.data);
                setInitialValues(response.data.data);
                setIsLoading(false);
                setTextBoxStatus(true);
                customMessage("Details updated successfully!");
            } else {
                setIsLoading(false);
                setTextBoxStatus(false);
                customErrorMessage("Something went wrong!");
            }
        };
    }

    const buttonEditHandle = async () => {
        setTextBoxStatus((textBoxStatus === true) ? false : true);
    }

    const handleClose = () => {
        setInvestorModal(false);
    }

    const handleSubmitFamilyOffice = () => {
        if (familyOfficeName.length === 0) {
            setErrorMessage("Name is required")
        } else {
            setErrorMessage("")
            setInvestorModal(false);
        }
    }

    const handleInvestorType = (e) => {
        const value = e.target.value;
        setInvestorType(value);
        value === 'Family Office' && setInvestorModal(true);
    }

    const handleSelectCountry = async (country) => {
        setSelectedCountry(country);
        if (countries !== undefined) {
            const getSelectedCountry = countries.find((item) => item.name === country);
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${getSelectedCountry.iso2}/states`, {
                headers: {
                    "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_API_KEY
                }
            });
            if (response.status === 200) {
                setStates(response.data);
                return response.data;
            }
        }
    }

    const handleStates = async (states, state, country) => {
        setSelectedState(state);
        const getSelectedCountry = countries.find((item) => item.name === country);
        const getSelectedState = states.find((item) => item.name === state);
        const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${getSelectedCountry.iso2}/states/${getSelectedState.iso2}/cities`, {
            headers: {
                "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_API_KEY
            }
        });
        if (response.status === 200) {
            setCities(response.data);
        }
    }

    const handleSelectState = async (state, country) => {
        setSelectedState(state);
        if (countries !== undefined && states !== undefined) {
            const getSelectedCountry = countries.find((item) => item.name === country);
            const getSelectedState = states.find((item) => item.name === state);
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${getSelectedCountry.iso2}/states/${getSelectedState.iso2}/cities`, {
                headers: {
                    "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_API_KEY
                }
            });
            if (response.status === 200) {
                setCities(response.data);
            }
        }
    }

    const handleChange = (e) => {
        if (e.target.value.length === 100) {
            customErrorMessage("Bio shouldn't exceed 100 characters");
        }
        setBio(e.target.value);
    }

    const handleFileSelect = (e) => {
        profileImg.current.click();
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    const handleClick = async () => {
        try {
            setIsImageLoading(true);
            const ext = file.type.split("/");
            console.log(ext);
            const dir = `Users/ProfileImges/${values._id}.${ext[1]}`;
            // const data = await uploadToS3(file, dir);
            const imageUrl = await uploadImageWithSignUrl(file, 'image/png', 'Users/ProfileImges', ext[1]);
            // const response = await AxiosInstance.post(url.uploadS3Image, {
            //     profileImgURL: data
            // });
            if (imageUrl) {
                const data = {
                    name: values.name,
                    address: values.address,
                    pincode: values.pincode,
                    bio: bio,
                    country: selectedCountry,
                    state: selectedState,
                    city: selectedCity,
                    familyOfficeName: familyOfficeName,
                    investor_type: "",
                    profileImgURL: imageUrl
                }
                await AxiosInstance.post(url.updateProfile, data);
                setProfileImgURL(imageUrl);
                setFile({});
                setIsImageLoading(false);
                // successResponse(response);
            } else {
                setFile({});
                setIsImageLoading(false);
                // customErrorMessage(response.message);
            }
        } catch (error) {
            throw error;
        }
    }

    const handleverifyFinish = async (identityAccessKey) => {
        try {
            setIdentityAccessKey(identityAccessKey);
            await AxiosInstance.post(url.userPassbaseIdentityKey, {
                identityAccessKey: identityAccessKey
            });
            window.location.reload();
        } catch (error) {
            throw error
        }
    }

    return (
        <>
            <div className="mt-4 mb-4 page_main updateProfilePage">
                <Container fluid>
                    <div>
                        <h1>Profile</h1>
                    </div>
                    <Row>
                        <Col md={12} lg={12}>
                            <div className="divBg_white">
                                <div>
                                    <Breadcrumb>
                                        <Breadcrumb.Item>
                                            <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 p-1 rounded bgLightGray profileSection">
                                    <div className="d-flex gap-4 align-items-center flex-wrap">
                                        <div className="position-relative p-3 profileImage d-flex flex-column align-items-center">
                                            <img
                                                src={file && file.name ? URL.createObjectURL(file) : profileImgURL}
                                                alt=""
                                                className="rounded-circle"
                                                height={"150px"}
                                                width={"150px"}
                                            />
                                            <input type="file" ref={profileImg} onChange={handleFileSelect} style={{ display: "none" }} />
                                            {file.name ?
                                                <Button
                                                    onClick={handleClick}
                                                    className="d-flex justify-content-center align-items-center position-absolute rounded-circle iconBg"
                                                    style={isImageLoading ? { top: "53%" } : { top: "62%" }}
                                                    disabled={isImageLoading}
                                                >
                                                    <CheckmarkIcon />
                                                </Button>
                                                : <Button
                                                    onClick={handleFileSelect}
                                                    className="d-flex justify-content-center align-items-center position-absolute rounded-circle iconBg"
                                                >
                                                    <EditIcon className="" />
                                                </Button>
                                            }
                                            {isImageLoading ?
                                                <span><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2 mt-2 me-2' />Uploading ...</span>
                                                : ""
                                            }
                                        </div>
                                        <div className="d-flex flex-column profileContent">
                                            <h6 className="mb-0 memberName">
                                                <span>
                                                    {initialValues.name}
                                                </span>
                                            </h6>

                                            <span className="mb-1 memberId">Member ID : {lastEight}
                                            </span>
                                            <span className="mb-2 memberBio">
                                                {initialValues.bio}
                                            </span>

                                            {values &&
                                                <>
                                                    {values.verify_status && values.verify_status !== "declined" ? `Identity Verify Status: ${values.verify_status}` :
                                                        <>
                                                            <VerifyButton
                                                                className="mb-1"
                                                                apiKey={process.env.REACT_APP_VERIFY_USER_KEY}
                                                                onSubmitted={(identityAccessKey) => { }}
                                                                onFinish={(identityAccessKey) => {
                                                                    handleverifyFinish(identityAccessKey)
                                                                }}
                                                                onError={(errorCode) => { }}
                                                                onStart={() => { }}
                                                                prefillAttributes={{
                                                                    email: initialValues.email,
                                                                }}
                                                            />
                                                            {
                                                                values.verify_status === "declined" && <span className="text-warning">
                                                                    Your previous verification was declined so please verify again.
                                                                </span>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="d-flex align-items-end mb-3">
                                            <h6 className="mb-0 fw-bold">Wallet Balance:</h6>
                                            <h6 className="mb-0">{parseFloat(initialValues?.userWallet).toFixed(2) || "00"}</h6>
                                        </div>
                                        <Button
                                            onClick={() => setAddBalancePopup(prev => ({ ...prev, show: true }))}
                                            className="btn_Orange">
                                            Add Balance
                                        </Button>
                                        <Button
                                            onClick={() => setWithdrawalPopup(prev => ({ ...prev, show: true }))}
                                            className="mt-2 btn_Black2">
                                            Withdrawal
                                        </Button>
                                    </div>
                                </div>
                                <div className="mt-4 personalDetail">
                                    <div className="d-flex align-items-center mb-4 pb-1 heading">
                                        <h6 className="mb-0 me-3">Personal Detail</h6>
                                        {
                                            textBoxStatus ?
                                                <button type="button" className="btn_Black editBtn" onClick={(e) => buttonEditHandle()}>
                                                    Edit
                                                </button> : <></>
                                        }
                                    </div>
                                    <Form
                                        noValidate
                                        className="pb-4 mb-4 border-bottom formArea"
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Full Name <span className="requireStar">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        defaultValue={initialValues.name}
                                                        type="text"
                                                        placeholder="Full Name"
                                                        className="defInput_"
                                                        name="name"
                                                        required
                                                        disabled={textBoxStatus}
                                                        onChange={onFormChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid Full Name.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Email ID
                                                    </Form.Label>
                                                    <Form.Control
                                                        defaultValue={initialValues.email}
                                                        type="email"
                                                        placeholder="Email ID"
                                                        className="defInput_"
                                                        style={{ color: "#b3b3b3" }}
                                                        name="email"
                                                        disabled={textBoxStatus}
                                                        // onChange={onFormChange}
                                                        readOnly
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid email.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Phone Number
                                                    </Form.Label>
                                                    <Form.Control
                                                        defaultValue={initialValues.phone_number}
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        className="defInput_"
                                                        style={{ color: "#b3b3b3" }}
                                                        name="phone_number"
                                                        disabled={textBoxStatus}
                                                        pattern="[0-9]+"
                                                        // onChange={onFormChange}
                                                        readOnly
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid mobile number.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Bio
                                                    </Form.Label>
                                                    <Form.Control
                                                        maxLength={100}
                                                        as="textarea"
                                                        defaultValue={initialValues.bio}
                                                        className="defInput_ defTextarea_"
                                                        rows={4}
                                                        name="bio"
                                                        disabled={textBoxStatus}
                                                        onChange={e => handleChange(e)}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Address <span className="requireStar">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        defaultValue={initialValues.address}
                                                        className="defInput_ defTextarea_"
                                                        style={{ color: "#b3b3b3" }}
                                                        rows={4}
                                                        name="address"
                                                        disabled={textBoxStatus}
                                                        // onChange={onFormChange}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Country <span className="requireStar">*</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        className="defInput_"
                                                        value={selectedCountry}
                                                        name="country"
                                                        disabled={textBoxStatus}
                                                        onChange={(e) => handleSelectCountry(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" defaultValue disabled>Select Country</option>
                                                        {
                                                            countries && countries.map((country, index) => {
                                                                return <option key={index} value={country.name}>{country.name}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>

                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        State <span className="requireStar">*</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        className="defInput_"
                                                        value={selectedState}
                                                        name="state"
                                                        disabled={textBoxStatus}
                                                        onChange={e => handleSelectState(e.target.value, selectedCountry)}
                                                        required
                                                    >
                                                        <option value="" defaultValue disabled>Select State</option>
                                                        {
                                                            states && states.map((state, index) => {
                                                                return <option key={index} value={state.name}>{state.name}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>

                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        City <span className="requireStar">*</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        className="defInput_"
                                                        value={selectedCity}
                                                        name="city"
                                                        disabled={textBoxStatus}
                                                        onChange={e => setSelectedCity(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" defaultValue disabled>Select City</option>
                                                        {
                                                            cities && cities.map((city, index) => {
                                                                return <option key={index} value={city.name}>{city.name}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>

                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Post Code <span className="requireStar">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        defaultValue={initialValues.pincode}
                                                        type="text"
                                                        placeholder="Post Code"
                                                        className="defInput_"
                                                        name="pincode"
                                                        disabled={textBoxStatus}
                                                        onChange={onFormChange}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                <Form.Group className="mb-4 inputOuter_">
                                                    <Form.Label className="defLabel_">
                                                        Investor Type <span className="requireStar">*</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        className="defInput_"
                                                        value={investorType}
                                                        name="investor_type"
                                                        disabled={textBoxStatus}
                                                        required
                                                        onChange={handleInvestorType}
                                                    >
                                                        <option defaultValue>Select</option>
                                                        {Object.values(InvestorType).map((type) => (
                                                            <option key={type} value={type}>
                                                                {type}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>

                                        </div>
                                        <div>
                                            {!textBoxStatus ?
                                                <Button variant="" type="submit" className='btn_Orange me-2'>
                                                    {isLoading ?
                                                        <>
                                                            <Spinner size="sm" className='me-2' />
                                                        </>
                                                        : ''
                                                    }
                                                    Update
                                                </Button>
                                                : <></>
                                            }
                                            {!textBoxStatus ?
                                                <Button variant="" type="button" className='btn_Black2' onClick={() => { getUserDetails(); setTextBoxStatus(true); setIsLoading(false); }}>
                                                    Cancel
                                                </Button>
                                                : <></>
                                            }
                                        </div>
                                    </Form>
                                </div>
                                <div className="kycDetail d-none">
                                    <div className="d-flex align-items-center mb-4 pb-1 heading">
                                        <h6 className="mb-0 me-3">KYC Details</h6>
                                        <button type="button" className="btn_Black editBtn">
                                            Edit
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-4">
                                            <div className="item">
                                                <span className="title d-block">Document Type</span>
                                                <span className="detail">Passport</span>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-4">
                                            <div className="item">
                                                <span className="title">Proof of Identity</span>
                                                <div className="d-flex justify-content-center align-item-center rounded position-relative p-3 bgLightBlue">
                                                    <img
                                                        src={DocProof}
                                                        className="docImg"
                                                        height={"95px"}
                                                        alt=""
                                                    />
                                                    <a
                                                        className="position-absolute pt-1 pe-2 viewBtn"
                                                        href="/#"
                                                    >
                                                        View
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                            <div className="item">
                                                <span className="title">Proof of Address</span>
                                                <div className="d-flex justify-content-center align-item-center rounded position-relative p-3 bgLightBlue">
                                                    <img
                                                        src={DocProof}
                                                        className="docImg"
                                                        height={"95px"}
                                                        alt=""
                                                    />
                                                    <a
                                                        className="position-absolute pt-1 pe-2 viewBtn"
                                                        href="/#"
                                                    >
                                                        View
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={investorModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered className="otpModel">
                <Modal.Header className="" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Family Office
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-3">
                        <div className="col-12">
                            <Form.Group className="mb-4 inputOuter_">
                                <Form.Label className="defLabel_">
                                    Please add name of family office <span className="requireStar">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Please add name of family office"
                                    className="defInput_"
                                    name="familyOfficeName"
                                    value={familyOfficeName}
                                    onChange={(e) => setFamilyOfficeName(e.target.value)}
                                    required
                                />
                                {errorMessage.length > 0 && familyOfficeName.length <= 0 && (
                                    <span className="text-danger">
                                        {errorMessage}
                                    </span>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group className="mb-4 customRadioBtn">
                                <Form.Label className="defLabel_ d-block">
                                    Do you have full authority to make decisions on their behalf?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    label="Yes"
                                    name="same"
                                    type="radio"
                                    id="yes"
                                    value="yes"
                                    className="radioInput mb-0"
                                    checked={hasAuthority === "yes"}
                                    onChange={(e) => setHasAuthority(e.target.value)}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="same"
                                    type="radio"
                                    id="no"
                                    value="no"
                                    checked={hasAuthority === "no"}
                                    onChange={(e) => setHasAuthority(e.target.value)}
                                    className="radioInput mb-0"
                                />
                            </Form.Group>
                        </div>
                        {hasAuthority === "no" &&
                            <div className="col-12 font14Red text-danger">
                                Please change your status to <strong>Individual Sophisticated Investor</strong>.
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn_WhiteNew" onClick={handleClose}>Cancel</Button>
                    <Button type="button" className='btn_OrangeNew' onClick={handleSubmitFamilyOffice} disabled={hasAuthority === "no"}>Submit</Button>
                </Modal.Footer>
            </Modal>
            {/* ======== */}
            <AddBalancePopup
                show={addBalance.show}
                onHide={() => setAddBalancePopup(prev => ({ ...prev, show: false }))}
                getUserDetails={getUserDetails}
            />
            {/* ======== */}
            <WithdrawalPopup
                show={withdrawalPopup.show}
                onHide={() => setWithdrawalPopup(prev => ({ ...prev, show: false }))}
                getUserDetails={getUserDetails}
                bankAccountToken={values?.bankAccountToken || null}
            // bankAccountToken={null}
            />
        </>

    );
};

// ----------
const WithdrawalPopup = ({ show, onHide, getUserDetails, bankAccountToken }) => {
    const handleClosePopup = () => {
        onHide()
        // formik.resetForm()
    }

    return (
        <Modal
            show={show}
            onHide={handleClosePopup}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="claimModal"
        >
            <Modal.Header className="p-0 pt-1 mb-2" closeButton>
                <div className="py-2 ps-4">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {bankAccountToken ? "Withdrawal Balance" : "Add Bank Detail"}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Elements stripe={stripePromise}>
                <AddCardForm_2
                    onHide={onHide}
                    getUserDetails={getUserDetails}
                    handleClosePopup={handleClosePopup}
                    bankAccountToken={bankAccountToken}
                />
            </Elements>
        </Modal>
    )
}

const AddCardForm_2 = ({ onHide, getUserDetails, handleClosePopup, bankAccountToken }) => {
    const stripe = useStripe();
    const initDetails = {
        amount: "",
        routing_number: "", // 108800
        account_number: "",// "00012345",
        account_holder_name: "",//"kks"
    }
    const [details, setDetails] = useState(initDetails);
    const [loading, setLoading] = useState(false);

    const initialError = { amount: null, account_holder_name: null, account_number: null, routing_number: null };
    const [error, setError] = useState(initialError);

    const validateForm = (token) => {
        let errors = {};
        if (token) {
            if (!details.amount) {
                errors.amount = "Please enter a valid amount!";
            }
        } else {
            if (!details.account_holder_name) {
                errors.account_holder_name = "Please enter the account holder's name!";
            }
            if (!details.account_number) {
                errors.account_number = "Please enter a valid account number!";
            }
            if (!details.routing_number) {
                errors.routing_number = "Please enter a valid routing number!";
            }
        }

        return errors;
    };

    const handleInputChange = (field, value) => {
        setDetails(prev => ({ ...prev, [field]: value }));
        setError(prev => ({ ...prev, [field]: null }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            setLoading(true)

            const formErrors = validateForm(bankAccountToken);
            setError(formErrors);

            if (Object.keys(formErrors).length > 0) {
                setLoading(false);
                return;
            }

            if (bankAccountToken) {
                const { data } = await AxiosInstance.post(`/stripe/payout`,
                    { "amount": Number(details.amount) });
                toast.success(data.message)
            } else {
                // Create the account token
                const { token } = await stripe.createToken('bank_account', {
                    country: "GB",
                    currency: "gbp",
                    routing_number: details.routing_number,
                    account_number: details.account_number,
                    account_holder_name: details.account_holder_name,
                    account_holder_type: "individual"
                });
                if (!token?.id) {
                    throw new Error("Failed to create token");
                }
                const { data } = await AxiosInstance.post(`/stripe/addBankAccount`,
                    { "token": token.id });
                toast.success("Bank detail successfully added, please withdraw amount after some time.")
            }

            setLoading(false)
            setDetails(initDetails)
            onHide()
            getUserDetails()

        } catch (error) {
            console.error('Error creating account:', error);
            toast.error(error?.response?.data?.message || error?.message || "Something went wrong!");
        } finally {
            setLoading(false)
        }
    };

    return (
        <Form onSubmit={handleFormSubmit} className="loginForm claimForm">
            <Modal.Body>
                <div className="mx-auto">

                    {bankAccountToken ?
                        (<Form.Group
                            className="mb-3 inputOuter_"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="defLabel_">
                                Amount <span className="requireStar">*</span>
                            </Form.Label>
                            <Form.Control
                                placeholder="0"
                                className="inputs defInput_"
                                name="amount"
                                type="text"
                                value={details.amount}
                                onChange={(e) => handleInputChange("amount", handleNValue(e))}
                            />
                            {error.amount && (
                                <Form.Text className="text-danger">{error.amount}</Form.Text>
                            )}
                        </Form.Group>
                        ) : (
                            <>
                                <Form.Group
                                    className="mb-3 inputOuter_"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="defLabel_">
                                        Account Holder Name <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        placeholder=""
                                        className="inputs defInput_"
                                        name="account_holder_name"
                                        type="text"
                                        value={details.account_holder_name}
                                        onChange={(e) => handleInputChange("account_holder_name", e.target.value)}
                                    />
                                    {error.account_holder_name && (
                                        <Form.Text className="text-danger">{error.account_holder_name}</Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group
                                    className="mb-3 inputOuter_"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="defLabel_">
                                        Account Number <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        placeholder=""
                                        className="inputs defInput_"
                                        name="account_number"
                                        type="text"
                                        value={details.account_number}
                                        onChange={(e) => handleInputChange("account_number", handleNValue(e))}
                                    />
                                    {error.account_number && (
                                        <Form.Text className="text-danger">{error.account_number}</Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group
                                    className="mb-3 inputOuter_"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="defLabel_">
                                        Routing Number <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        placeholder="0"
                                        className="inputs defInput_"
                                        name="routing_number"
                                        type="text"
                                        value={details.routing_number}
                                        onChange={(e) => handleInputChange("routing_number", e.target.value)}
                                    />
                                    {error.routing_number && (
                                        <Form.Text className="text-danger">{error.routing_number}</Form.Text>
                                    )}
                                </Form.Group>
                            </>
                        )}
                </div>
            </Modal.Body>
            <Modal.Footer className="">
                <button
                    onClick={handleClosePopup}
                    type="button"
                    className="btn_White"
                    disabled={loading}
                >
                    Cancel
                </button>
                <button
                    disabled={loading}
                    type="submit"
                    className="btn_Black">
                    {loading ? "Please wait..." : 'Submit'}
                </button>
            </Modal.Footer>
        </Form>
    )
}

// ==========

// const AddBalancePopup = ({ show, onHide, getUserDetails }) => {
//     const handleClosePopup = () => {
//         onHide()
//         // formik.resetForm()
//     }

//     return (
//         <Modal
//             show={show}
//             onHide={handleClosePopup}
//             size="md"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//             className="claimModal"
//         >
//             <Modal.Header className="p-0 pt-1 mb-2" closeButton>
//                 <div className="py-2 ps-4">
//                     <Modal.Title id="contained-modal-title-vcenter">
//                         Add Balance
//                     </Modal.Title>
//                 </div>
//             </Modal.Header>
//             <Elements stripe={stripePromise}>
//                 <AddCardForm
//                     onHide={onHide}
//                     getUserDetails={getUserDetails}
//                     handleClosePopup={handleClosePopup}
//                 />
//             </Elements>
//         </Modal>
//     )
// }

// const AddCardForm = ({ onHide, getUserDetails, handleClosePopup }) => {
//     const _status = {
//         Pending: "Pending",
//         Succeeded: "Succeeded",
//         Failed: "Failed"
//     }
//     const [amount, setAmount] = useState("")
//     const stripe = useStripe();
//     const elements = useElements();
//     const initialError = { amount: null, cardNumber: null, cardExpiry: null, cardCvc: null };
//     const [_error, _setError] = useState(initialError);
//     const [cardInputComplete, setCardInputComplete] = useState({
//         cardNumber: false,
//         cardExpiry: false,
//         cardCvc: false
//     });
//     const [loading, setLoading] = useState(false);

//     const validateForm = () => {
//         let errors = {};
//         // Validate amount
//         if (!amount || amount === "0" || amount === 0 || amount === undefined || amount === null || amount === "") {
//             errors.amount = "Please enter a valid amount!";
//         }

//         // Validate card inputs
//         if (!cardInputComplete.cardNumber) {
//             errors.cardNumber = "Please enter your card number!";
//         }
//         if (!cardInputComplete.cardExpiry) {
//             errors.cardExpiry = "Please enter your card's expiry date!";
//         }
//         if (!cardInputComplete.cardCvc) {
//             errors.cardCvc = "Please enter your card's CVC!";
//         }

//         return errors;
//     };

//     const checkPaymentStatus = async (_id = null) => {
//         // const transaction_id = _id || localStorage.getItem("transaction_id")
//         const { data } = await AxiosInstance.get(`/stripe/status/${_id}`);
//         return data.data
//     }

//     const checkStatusRecursively = (_id) => {
//         return new Promise((resolve, reject) => {
//             const check = async () => {
//                 try {
//                     const response = await checkPaymentStatus(_id);
//                     if (response.status === _status.Pending) {
//                         setTimeout(check, 2000);
//                     } else {
//                         resolve(response); // Resolve the promise when done
//                     }
//                 } catch (error) {
//                     reject(error); // Reject the promise on error
//                 }
//             };
//             check();
//         });
//     };


//     const handleFormSubmit = async (event) => {
//         event.preventDefault();
//         try {
//             setLoading(true);

//             const formErrors = validateForm();
//             _setError(formErrors);

//             if (Object.keys(formErrors).length > 0) {
//                 setLoading(false);
//                 return false;
//             }

//             if (!stripe || !elements) return false;

//             const cardElement = elements.getElement(CardNumberElement);
//             const { error, token } = await stripe.createToken(cardElement)

//             if (error) {
//                 toast.error(error.message)
//                 return false;
//             }

//             const { data } = await AxiosInstance.post(
//                 `/stripe/charge`,
//                 {
//                     "token": token.id,
//                     "amount": Number(amount),
//                     "return_url": `${process.env.REACT_APP_API_URL}/stripe/webhook`,

//                 });

//             const response = await checkStatusRecursively(data.data.transaction_id);

//             // Process the response after checkStatusRecursively resolves
//             if (response.status === _status.Succeeded) {
//                 toast.success("Payment Successful");
//             } else if (response.status === _status.Failed) {
//                 toast.error(response?.failed_reason || "Payment Failed!");
//             }

//             resetForm();
//             getUserDetails();
//             onHide();
//         } catch (error) {
//             console.log('error', error.message);
//             toast.error(error?.response?.data?.message || error?.message || "Something went wrong!");
//         } finally {
//             setLoading(false);
//         }
//     };

//     const resetForm = () => {
//         _setError(initialError);
//         setLoading(false);
//         setAmount("")
//         // elements.getElement(CardElement).clear();
//     };

//     const handleCardInputChange = (inputType, event) => {
//         _setError(prev => ({ ...prev, [inputType]: event?.error?.message || null }));
//         setCardInputComplete(prev => ({ ...prev, [inputType]: event.complete }));
//     };
//     const handleInputChange = (e) => {
//         setAmount(handleNValue(e));
//         _setError(prev => ({ ...prev, amount: null }));
//     };


//     const _disable = useMemo(() => {
//         const allInputsComplete = cardInputComplete.cardNumber && cardInputComplete.cardExpiry && cardInputComplete.cardCvc;
//         const anyInputError = _error.cardNumber || _error.cardExpiry || _error.cardCvc;
//         return !stripe || loading || !allInputsComplete || anyInputError;
//     }, [stripe, loading, cardInputComplete, _error]);


//     const style = {
//         base: {
//             iconColor: '#666EE8',
//             color: '#31325F',
//             fontWeight: '300',
//             fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//             fontSize: '14px',
//             '::placeholder': {
//                 color: '#CFD7E0',
//                 fontWeight: '300',
//                 fontSize: '14px',
//             },
//         },
//         invalid: {
//             color: '#E25950',
//         },
//         focus: {
//             boxShadow: '0 0 0 0.25rem rgba(244, 121, 68, 0.118)',
//         },
//     }

//     return (
//         <Form onSubmit={handleFormSubmit} className="loginForm claimForm">
//             <Modal.Body>
//                 <div className="mx-auto">
//                     <Form.Group
//                         className="mb-3 inputOuter_"
//                         controlId="formBasicEmail"
//                     >
//                         <Form.Label className="defLabel_">
//                             Amount <span className="requireStar">*</span>
//                         </Form.Label>
//                         <Form.Control
//                             type="text"
//                             placeholder="0"
//                             className="inputs defInput_"
//                             name="amount"
//                             value={amount}
//                             onChange={e => handleInputChange(e)}
//                         />
//                         {_error.amount && (
//                             <Form.Text className="text-danger">{_error.amount}</Form.Text>
//                         )}
//                     </Form.Group>
//                     <Form.Group
//                         className="mb-3 inputOuter_"
//                         controlId="formBasicEmail"
//                     >
//                         {/* <CardElement onChange={handleCardInputChange} /> */}
//                         <Form.Label className="">
//                             Card Number <span className="requireStar">*</span>
//                         </Form.Label>
//                         <CardNumberElement
//                             options={{ style: style }}
//                             onChange={e => handleCardInputChange("cardNumber", e)}
//                         />
//                         {_error.cardNumber && <Form.Text className="text-danger">{_error.cardNumber}</Form.Text>}
//                     </Form.Group>
//                     <Row>
//                         <Col md={6}>
//                             <Form.Group
//                                 className="mb-3 inputOuter_"
//                                 controlId="formBasicEmail"
//                             >
//                                 <Form.Label className="">
//                                     Expiry <span className="requireStar">*</span>
//                                 </Form.Label>
//                                 <CardExpiryElement
//                                     options={{ style: style }}
//                                     onChange={e => handleCardInputChange("cardExpiry", e)}
//                                 />
//                                 {_error.cardExpiry && <Form.Text className="text-danger">{_error.cardExpiry}</Form.Text>}
//                             </Form.Group>
//                         </Col>
//                         <Col md={6}>
//                             <Form.Group
//                                 className="mb-3 inputOuter_"
//                                 controlId="formBasicEmail"
//                             >
//                                 <Form.Label className="">
//                                     CVV <span className="requireStar">*</span>
//                                 </Form.Label>
//                                 <CardCvcElement
//                                     options={{ style: style }}
//                                     onChange={e => handleCardInputChange("cardCvc", e)}
//                                 />
//                                 {_error.cardCvc && <Form.Text className="text-danger">{_error.cardCvc}</Form.Text>}
//                             </Form.Group>
//                         </Col>
//                     </Row>
//                 </div>
//             </Modal.Body>
//             <Modal.Footer className="">
//                 <button
//                     onClick={handleClosePopup}
//                     type="button"
//                     className="btn_White"
//                     disabled={loading}
//                 >
//                     Cancel
//                 </button>
//                 <button
//                     disabled={_disable}
//                     type="submit"
//                     className="btn_Black">
//                     {loading ? "Please wait..." : 'Submit'}
//                 </button>
//             </Modal.Footer>
//         </Form>
//     )
// }

export default UpdateProfile;