import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Card } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import url from "../../helpers/development.json";
import Logo from "../../assets/admin/images/Logo_white.png"
import AxiosInstance from '../../helpers/AxiosRequest';
import { customErrorMessage, customMessage } from "../../helpers/response";


const AdminCreatePassword = () => {
    const [isLoading,] = useState(false);
    const [validated, setValidated] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const { state } = useLocation();
    const navigate = useNavigate();
    const { token, id } = useParams();
    const [userData, setUserData] = useState({
        token: "",
        _id: ""
    });
    const [errormessage, setErrorMessage] = useState(false)
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    useEffect(() => {
        if (token) {
            verifyForgotPasswordLink(token);
        }
        if (state && state.token) {
            verifyForgotPasswordLink(state.token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (token && id) {
            setUserData({
                token: token,
                _id: id
            });
        }
        if (state && state._id && state.token) {
            setUserData({
                token: state.token,
                _id: state.id
            });
        }
    }, []);


    const verifyForgotPasswordLink = async (token) => {
        const response = await AxiosInstance.post(url.AdminverifyLink, { token: token });
        if (response.status === 200 && response.data.statusText === "Success") {
            setUserData(response.data.data);
            setErrorMessage("");
        } else {
            setErrorMessage("Invalid or expired link!");
            customErrorMessage("Invalid or expired link!");
        }
    }

    const validateInput = (event) => {
        const value = event.target.value;
        setPassword(value);
        if (!value) {
            setPasswordError("Password is required");
        } else if (value.length < 5 || value.length > 25) {
            setPasswordError("Password must be between 7 and 15 characters");
        } else {
            setPasswordError("");
        }
        if (confirmPassword && value !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
        } else {
            setConfirmPasswordError("");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else if (!password || !confirmPassword) {
            setPasswordError("Password is required");
            setConfirmPasswordError("Confirm password is required");
        } else if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
        } else {
            const response = await AxiosInstance.post(url.AdmincreateNewPassword, { token: userData.token, _id: userData._id, newPassword: confirmPassword });
            if (response.status === 200 && response.data.statusText === "Success") {
                customMessage("Password reset successfully!");
                navigate(`/admin/login`);
            } else {
                customErrorMessage("Invalid or expired link!");
            }
        }
        setValidated(true);
    };

    return (

        <div className="d-flex justify-content-center flex-column align-items-center forheight">
            <div className='loginBox'>
                <div className='text-center mb-3'><img src={Logo} alt="" /></div>
                <Card title="Create New Password" bordered={true} className='login-form'>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 32,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        validated={validated}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="password"
                            id="password"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 7,
                                    max: 15
                                }
                            ]}
                            help={passwordError}
                            validateStatus={passwordError ? "error" : ""}
                        >
                            <Input placeholder="Enter New Password" type="password" disabled={errormessage.length > 0} onChange={validateInput} value={password} />
                        </Form.Item>

                        <Form.Item
                            placeholder="Confirm Password"
                            className="inputs defInput_"
                            name="confirmPassword"
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                if (!e.target.value) {
                                    setConfirmPasswordError("Password is required");
                                } else if (e.target.value !== password) {
                                    setConfirmPasswordError("Passwords do not match");
                                } else {
                                    setConfirmPasswordError("");
                                }
                            }}
                            value={confirmPassword}
                            help={confirmPasswordError}
                            validateStatus={confirmPasswordError ? "error" : ""}
                        >
                            <Input placeholder="Enter Confirm Password" type="password" disabled={errormessage.length > 0} />
                        </Form.Item>

                        <div >
                            <Button type="primary" htmlType="submit" className='login-button w-100 mb-3' onClick={handleSubmit} disabled={errormessage.length > 0}>
                                {isLoading ? <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                </>
                                    : ''
                                }
                                {isLoading ? 'loading...' : 'Reset Password'}
                            </Button>
                            <Link to="/admin/auth/login" className="text-center"><h6 className="mt-3 mb-3 fontsizeset"><b>Back to Login</b></h6></Link>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>

    );
};

export default AdminCreatePassword;