import { React, useRef, useState, useEffect } from "react";
import { Breadcrumb, Layout } from "antd";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, CardHeader, Spinner } from "reactstrap";
import AxiosInstance from "../../../helpers/AxiosRequest";
import url from "../../../helpers/development.json";
import { customErrorMessage, SendSuccessResponse } from "../../../helpers/response";
import { CompanyType, CategoryType, InvestmentType } from "../../../helpers/enum";
import doc from "../../../assets/admin/images/doc.png";
import docx from "../../../assets/admin/images/docx.png";
import xls from "../../../assets/admin/images/xls.png";
import xlsx from "../../../assets/admin/images/xlsx.png"
import pdf1 from "../../../assets/admin/images/pdf1.png"
import { uploadImageWithSignUrl } from "../../../helpers/UploadImageWiths3";


const OpportunityUpdate = () => {
    const { Content } = Layout;
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [file, setFile] = useState({});
    const [newfile, setNewfile] = useState({});
    const financialFile = useRef();
    const otherFile = useRef();
    const [errorMessage, setErrorMessage] = useState("");
    const [, setErrMessage] = useState("");
    const [timestamp1, setTimestamp1] = useState();
    const [timestamp2, setTimestamp2] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState({});
    const formRef = useRef(null);
    const [numberErrMessage, setNumberErrMessage] = useState("");
    const [numberErrMessage2, setNumberErrMessage2] = useState("");
    const [numberErrMessage3, setNumberErrMessage3] = useState("");
    const [numberErrMessage4, setNumberErrMessage4] = useState("");
    const [numberErrMessage5, setNumberErrMessage5] = useState("");
    const [initialValues, setInitialValues] = useState({});
    const params = useParams();
    const [companywebsite, setCompanywebsite] = useState("");
    const [urlError, setUrlError] = useState("")

    useEffect(() => {
        if (params.id)
            fetchData();
    }, [params.id]);

    const navigate = useNavigate();

    const fetchData = async (_id) => {
        try {
            const response = await AxiosInstance.get(`${url.getOpportunityDetails}/${params.id}`);
            console.log(response);
            if (response.status === 200 && response.data.statusText === "Success") {
                const radio = response.data.data.UK_SEIS_EIS_Qualified ? "Yes" : "No"
                setValues({ ...response.data.data, UK_SEIS_EIS_Qualified: radio, ...response.data.data.key_features });
                setInitialValues({...response.data.data, UK_SEIS_EIS_Qualified: radio, ...response.data.data.key_features});
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const getFileIcon = (fileName) => {
        const extension = fileName && fileName.split(".").pop().toLowerCase();
        switch (extension) {
            case "pdf":
                return pdf1;
            case "doc":
                return doc;
            case "docx":
                return docx;
            case "xls":
                return xls;
            case "xlsx":
                return xlsx;
            default:
                return pdf1;
        }
    };

    const onFormChange = (e, updatedAt) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
        if (name === "turnover_average_yearly" && isNaN(value)) {
            setNumberErrMessage("Please enter only numbers in this field.")
        } else {
            setNumberErrMessage("");
        }
        if (name === "gross_profit_yearly" && isNaN(value)) {
            setNumberErrMessage2("Please enter only numbers in this field.");
        } else {
            setNumberErrMessage2("");
        }
        if (name === "looking_to_raise" && isNaN(value)) {
            setNumberErrMessage3("Please enter only numbers in this field");
        } else {
            setNumberErrMessage3("");
        }
        if (name === "receiving_in_return" && isNaN(value)) {
            setNumberErrMessage4("Please enter only numbers in this field");
        } else {
            setNumberErrMessage4("");
        }
        if (name === "valuation_of_the_asset" && isNaN(value)) {
            setNumberErrMessage5("Please enter only numbers in this field");
        } else {
            setNumberErrMessage5("");
        }
    };

    const onReset = () => {
        setValues({...initialValues})
        formRef.current.reset();
    };

    const getLocation = async (params) => {
        // const { Location } = await s3.upload(params).promise();
        const url = await uploadImageWithSignUrl(params.Body, params.ContentType , params.path, params.ext);
        return url
    }

    const getLocation1 = async (params) => {
        // const { Location } = await s3.upload(params).promise();
        // return Location
        const url = await uploadImageWithSignUrl(params.Body, params.ContentType , params.path, params.ext);
        return url
    }


    const onCheckboxChange = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setValues({ ...values, [name]: value });
    };

    const handleurl = (e) => {
        const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        if (regex.test(e.target.value)) {
            setUrlError("");
            setCompanywebsite(e.target.value);
        } else {
            setCompanywebsite("");
            setUrlError("Please enter valide URL");
        }
    }

    const handleSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            if (values === "") {
                setErrorMessage("");
            }
            event.stopPropagation();
        }
        else {
            setIsLoading(true);
            let location = initialValues.featuresURL;
            let location1 = initialValues.company_informationURL;
            if (file && newfile) {
                try {
                    const ext = file.name.substring(file.name.lastIndexOf(".") + 1);
                    const contentType = 'application/octet-stream';
                    const params = {
                        ext: ext,
                        path: "Opportunity",
                        Body: file,
                        ContentType: contentType,
                    };
                    location = await getLocation(params);
                } catch (error) {
                    console.error("Error uploading file:", error);
                }
                try {
                    const ext1 = newfile.name.substring(newfile.name.lastIndexOf(".") + 1);
                    const contentType1 = 'application/octet-stream';
                    const params1 = {
                        ext: ext1,
                        path: "Opportunity",
                        Body: newfile,
                        ContentType: contentType1,
                    };
                    location1 = await getLocation1(params1);
                } catch (error) {
                    console.error("Error uploading file:", error);
                }
            }

            setIsSubmitting(true);
            const response = await AxiosInstance.post(url.updateOpportunity, {
                _id: initialValues._id,
                investment_title: values.investment_title,
                company_name: values.company_name,
                investment_type: values.investment_type,
                createdby: initialValues.createdby,
                investment_category: values.investment_category,
                turnover_average_yearly: values.turnover_average_yearly,
                gross_profit_yearly: values.gross_profit_yearly,
                looking_to_raise: values.looking_to_raise,
                receiving_in_return: values.receiving_in_return,
                valuation_of_the_asset: values.valuation_of_the_asset,
                financial_info: values.financial_info,
                please_highlight_key_risks: values.please_highlight_key_risks,
                company_website: values.company_website,
                highlight_key_features: values.highlight_key_features,
                company_information: values.company_information,
                UK_SEIS_EIS_Qualified: values.UK_SEIS_EIS_Qualified === "Yes" ? true : false,
                free_cashflow_reports: values.free_cashflow_reports,
                yearly_percentage_growth: values.yearly_percentage_growth,
                shares_outstanding: values.shares_outstanding,
                annual_dividend: values.annual_dividend,
                potential_income: values.potential_income,
                UK_SEIS_EIS_HMRC: values.UK_SEIS_EIS_HMRC,
                marketcap_in_$_or: values.marketcap_in_$_or,
                average_ROE_return_on_equity: values.average_ROE_return_on_equity,
                if_Bond_investment_income_potential:
                    values.if_Bond_investment_income_potential,
                if_property_potential_asset_valuation:
                    values.if_property_potential_asset_valuation,
                commodity_potential_price_increase:
                    values.commodity_potential_price_increase,
                other: values.other,
                featuresURL: location,
                company_informationURL: location1,
            });
            if (response.status === 200 && response.data.statusText === "Success") {
                setValues(response.data.data);
                setInitialValues(response.data.data);
                setIsLoading(false);
                setIsSubmitting(false);
                SendSuccessResponse("Opportunity updated successfully!");
                navigate("/admin/opportunities")
            } else {
                customErrorMessage("Something went wrong!");
                setIsLoading(false);
                setIsSubmitting(false);
            }

        }
    };

    const handleFileSelect = (e) => {
        financialFile.current.click();
        if (e.target.files) {
            const allowedExtensions = /(\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
            if (!allowedExtensions.exec(e.target.files[0].name)) {
                setErrorMessage(
                    "Invalid file type. Please upload a PDF, DOC, DOCX, XLS, or XLSX file."
                );
                setFile({});
            } else if ((e.target.files[0].size / 1024) > 5120) {
                setErrorMessage("File size must be less than 5 MB");
                setFile({});
            } else {
                setTimestamp1(new Date().getTime());
                setFile(e.target.files[0]);
                setErrorMessage("");
            }
        }
    };

    const handleSelectFile = (e) => {
        otherFile.current.click();
        if (e.target.files) {
            const allowedExtensions = /(\.pdf|\.doc|\.docx|\.XLS|\.xlsx)$/i;
            if (!allowedExtensions.exec(e.target.files[0].name)) {
                setErrMessage(
                    "Invalid file type. Please upload a PDF, DOC, DOCX, or XLS file."
                );
                setNewfile({});
            } else if (((e.target.files[0].size) / 1024) > 5120) {
                setErrMessage("File size must be less then 5 MB");
                setNewfile({});
            } else {
                setTimestamp2(new Date().getTime());
                setNewfile(e.target.files[0]);
                setErrMessage("");
            }
        }
    };


    return (
        <Content className="site-layout-background">
            <Breadcrumb style={
                { margin: "16px 0" }
            }>
                <Breadcrumb.Item>
                    <Link to="/admin/dashboard">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/admin/opportunities">Opportunity</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Update
                </Breadcrumb.Item>
            </Breadcrumb>
            <hr className="lineTheme" />

            <div className="site-card-border-less-wrapper">
                <CardHeader className="card-header-part">
                    <h5 className="fw-bold fs-5 mt-2">OPPORTUNITY UPDATE
                    </h5>
                </CardHeader>
                <h5 className=""
                    style={
                        { fontSize: 15 }
                    }>
                </h5>

                <Form
                    className="m-1"
                    ref={formRef}
                    validated={validated}
                    noValidate
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <div class="row g-3">
                        <div class="col-3 mb-3">
                            <label>
                                Opportunity Title
                                <span className="fontred">*</span>
                            </label>
                            <input type="text" class="form-control rounded-0"
                                onChange={onFormChange}
                                name="investment_title"
                                required
                                defaultValue={initialValues.investment_title}
                                label="investment_title" />
                            <Form.Control.Feedback type="invalid">
                                Please provide a Investment Title.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Company Name<span className="fontred">*</span>
                            </label>
                            {/* <input type="text" class="form-control rounded-0"/> */}
                            <select class="form-select rounded-0" aria-label="Default select example"
                                onChange={onFormChange}
                                name="company_name"
                                required>
                                <option selected>{initialValues.company_name}</option>
                                {Object.values(CompanyType).map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                                Please provide a  Company Name.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Investment Type<span className="fontred">*</span>
                            </label>
                            {/* <input type="text" class="form-control rounded-0"/> */}
                            <select class="form-select rounded-0" aria-label="Default select example"
                                onChange={onFormChange}
                                name="investment_type"
                                required>
                                <option selected>{initialValues.investment_type}</option>
                                {Object.values(InvestmentType).map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                                Please provide a investment type.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Investment Category<span className="fontred">*</span>
                            </label>
                            {/* <input type="text" class="form-control rounded-0"/> */}
                            <select class="form-select rounded-0" aria-label="Default select example"
                                onChange={onFormChange}
                                name="investment_category"
                                required>
                                <option selected>{initialValues.investment_category}</option>
                                {Object.values(CategoryType).map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                                Please provide a Investment Category.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Turnover Average Yearly<span className="fontred">*</span>
                            </label>
                            <input type="text"
                                class="form-control rounded-0"
                                onChange={onFormChange}
                                name="turnover_average_yearly"
                                required
                                defaultValue={initialValues.turnover_average_yearly}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {numberErrMessage.length > 0 && (
                                <span className="text-danger">{numberErrMessage}</span>
                            )}
                            <Form.Control.Feedback type="invalid">
                                Please provide a turnover average yearly".
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Gross Profit Yearly(Before Tax)
                                <span className="fontred">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                onChange={onFormChange}
                                name="gross_profit_yearly"
                                pattern="[0-9]*"
                                title="Please enter only numbers in this field."
                                required
                                defaultValue={initialValues.gross_profit_yearly}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {numberErrMessage2.length > 0 && (
                                <span className="text-danger">{numberErrMessage2}</span>
                            )}
                            <Form.Control.Feedback >
                                Please provide a Gross Profit Yearly.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                How much are you looking to raise?
                                <span className="fontred">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                onChange={onFormChange}
                                name="looking_to_raise"
                                required
                                defaultValue={initialValues.looking_to_raise}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {numberErrMessage3.length > 0 && (
                                <span className="text-danger">{numberErrMessage3}</span>
                            )}
                            <Form.Control.Feedback type="invalid">
                                Please provide a looking to raise.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                What benefits are investors receiving in return?
                                <span className="fontred">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                onChange={onFormChange}
                                name="receiving_in_return"
                                required
                                defaultValue={initialValues.receiving_in_return}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {numberErrMessage4.length > 0 && (
                                <span className="text-danger">{numberErrMessage4}</span>
                            )}
                            <Form.Control.Feedback type="invalid">
                                Please provide a investors receiving in return.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                What is the valuation of the asset?
                                <span className="fontred">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                onChange={onFormChange}
                                name="valuation_of_the_asset"
                                required
                                defaultValue={initialValues.valuation_of_the_asset}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {numberErrMessage5.length > 0 && (
                                <span className="text-danger">{numberErrMessage5}</span>
                            )}
                            <Form.Control.Feedback type="invalid">
                                Please provide a valuation of the asset.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Please highlight key features<span className="fontred">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                onChange={onFormChange}
                                name="highlight_key_features"
                                required
                                defaultValue={initialValues.highlight_key_features}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a highlight key features.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Please highlight key risks<span className="fontred">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                onChange={onFormChange}
                                name="please_highlight_key_risks"
                                required
                                defaultValue={initialValues.please_highlight_key_risks}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a highlight key risks.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                Company Website<span className="fontred">*</span>
                            </label>
                            <input type="text"
                                class="form-control rounded-0"
                                onChange={handleurl}
                                required
                                defaultValue={initialValues.company_website}
                            />
                            {
                                urlError.length > 0 &&
                                <span style={{ marginTop: "0.25rem", fontSize: ".875em", color: "#dc3545" }}>
                                    {urlError}
                                </span>
                            }
                            <Form.Control.Feedback type="invalid">
                                Please provide a Company Website.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                File Upload
                                <span className="fontred">*</span>
                            </label>

                            <div id="file-upload-form" class="uploader position-relative">
                                <input id="file-upload" type="file" accept=".pdf,.doc,.docx,.XLS," ref={financialFile} onChange={handleFileSelect}
                                    defaultValue={initialValues.featuresURL} />

                                    <div className="position-absolute" style={{ top: 0, right: '5px'}}>
                                        {values.featuresURL ? (
                                            <>
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 352 512"
                                                        style={{ width: "13px" }}
                                                        onClick={() => {
                                                            setValues((prevState) => ({
                                                                ...prevState,
                                                            featuresURL : null
                                                            }))
                                                            setInitialValues((prevState) => ({
                                                                ...prevState,
                                                                featuresURL: null
                                                            }))
                                                            // SendSuccessResponse("Document Deleted Successfully!");
                                                        }}  
                                                    >
                                                        <path
                                                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                                            fill="#d60000"
                                                        />
                                                    </svg>
                                                </span>
                                            </>
                                        ): null}
                                    </div>

                                <label for="file-upload" id="file-drag">
                                    {file?.name ? (
                                        file.name
                                    ) : (
                                        <>
                                            {values.featuresURL ? (
                                                <div className="mb-2">
                                                    <a href={values.featuresURL} target="_blank" rel="noreferrer">
                                                        <img alt="file-icon" className="me-2" src={getFileIcon(initialValues?.featuresURL)} style={{ width: "45px" }} />

                                                        {values.featuresURL.split("/").pop()}
                                                    </a>
                                                </div>
                                            ) : <div id="start">
                                            <svg
                                                width="47"
                                                height="34"
                                                viewBox="0 0 47 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M23.5002 0C19.8433 0 16.1667 1.36508 13.3786 4.09391C11.305 6.12336 10.0481 8.64049 9.51495 11.2657C4.10438 12.2928 0 16.9175 0 22.4995C0 28.8329 5.27865 33.9995 11.75 33.9995H36.7826C42.4166 33.9995 47 29.5136 47 23.9995C47 18.7955 42.9037 14.5594 37.7086 14.0933C37.7324 10.4738 36.4189 6.84693 33.6055 4.09337C30.8202 1.36669 27.1571 0 23.5002 0ZM23.5002 3.00016C26.3806 3.00016 29.2329 4.04819 31.4348 6.20327C33.9792 8.69353 35.0557 12.0765 34.6599 15.3122C34.605 15.7387 34.7393 16.1677 35.0294 16.4903C35.3195 16.8129 35.7371 16.9984 36.1761 16.9995H36.7826C40.7713 16.9995 43.9346 20.0955 43.9346 23.9993C43.9346 27.9031 40.7712 30.999 36.7826 30.999H11.75C6.93535 30.999 3.06533 27.211 3.06533 22.4992C3.06533 18.0492 6.523 14.4241 10.9518 14.0303C11.2992 13.9972 11.6246 13.8496 11.874 13.6107C12.1238 13.3722 12.2827 13.0572 12.3248 12.7182C12.6067 10.3406 13.6818 8.03074 15.5498 6.20245C17.7496 4.04987 20.6198 3.00016 23.5002 3.00016ZM23.5002 12.0001C22.9892 12.0001 22.7191 12.1609 22.4625 12.3907L16.8427 17.3907C16.1938 17.9801 16.2052 18.9368 16.7469 19.5158C17.2885 20.0947 18.3237 20.1366 18.9182 19.6096L21.9675 16.891V26.4998C21.9675 27.3282 22.6535 27.9999 23.5002 27.9999C24.3469 27.9999 25.0329 27.3282 25.0329 26.4998V16.891L28.0822 19.6096C28.6766 20.1369 29.6805 20.0655 30.2535 19.5158C30.8572 18.9368 30.7467 17.9237 30.1577 17.3907L24.5379 12.3907C24.2343 12.1183 24.0112 12.0001 23.5002 12.0001Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <div className="mt-2">
                                                Upload, pitch deck, business plan, or any useful
                                                information
                                            </div>
                                    </div>}
                                        </>
                                    )}
                                </label>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a financial information.
                                </Form.Control.Feedback>
                            </div>
                            <div className="fSize-10 mt-1">
                                Supported upload formats: 5MB max in .DOCX or .PDF or .XLS
                            </div>
                        </div>

                        <div class="col-3 mb-3">
                            <tr>
                                <td>
                                    <label></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Control.Feedback type="invalid" >
                                        <span className="text-danger"> Please provide a Information.</span>
                                    </Form.Control.Feedback>                   <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault"  name="free_cashflow_reports"
                                            onChange={onCheckboxChange} checked={values.free_cashflow_reports}/>
                                        <label>Free cashflow reports
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="yearly_percentage_growth" checked={values.yearly_percentage_growth}/>
                                        <label>Yearly percentage growth</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" checked={values.shares_outstanding} onChange={onCheckboxChange}
                                            name="shares_outstanding" />
                                        <label>
                                            Shares outstanding</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="annual_dividend" checked={values.annual_dividend}/>
                                        <label>Annual dividend yield in percentage</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="potential_income" checked={values.potential_income}/>
                                        <label>Annual dividend potential income
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="UK_SEIS_EIS_HMRC" checked={values.UK_SEIS_EIS_HMRC} />
                                        <label>UK SEIS/EIS HMRC
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </div>

                        <div class="col-3 mb-3">
                            <tr>
                                <td>
                                    <label></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="marketcap_in_$_or" checked={values.marketcap_in_$_or}/>
                                        <label>Market cap in $ or £
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="average_ROE_return_on_equity" checked={values.average_ROE_return_on_equity}/>
                                        <label>Average ROE return on equity</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="if_Bond_investment_income_potential" checked={values.if_Bond_investment_income_potential} />
                                        <label> {" "}
                                            If Bond investment- income potential (interest and capital
                                            repayment)
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="if_property_potential_asset_valuation" checked={values.if_property_potential_asset_valuation} />
                                        <label>
                                            If property, potential asset valuation increase, potential
                                            rental income
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="commodity_potential_price_increase" checked={values.commodity_potential_price_increase} />
                                        <label>Commodity- potential price increase.</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="form-check checkboxlable mb-0">
                                        <input class="form-check-input checkboxlable" type="checkbox" id="flexCheckDefault" onChange={onCheckboxChange}
                                            name="other" checked={values.other}/>
                                        <label>Other
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </div>

                        <div class="col-3 mb-3">
                            <label class="form-label mb-0">UK SEIS/EIS Qualified</label>
                            <div className="customRadioBtn">
                                <div class="radioInput mb-2 form-check form-check-inline grayBG">
                                    <input
                                        type="radio"
                                        id="Yes"
                                        class="form-check-input"
                                        name="UK_SEIS_EIS_Qualified"
                                        value="Yes"
                                        checked={values.UK_SEIS_EIS_Qualified === "Yes"}
                                        onChange={onFormChange} />
                                    <label title="" for="Yes" class="form-check-label">
                                        <span className="font20Bold">YES</span><br /></label>
                                </div>
                                <div class="radioInput form-check form-check-inline grayBG">
                                    <input name="UK_SEIS_EIS_Qualified"
                                        type="radio" id="No" class="form-check-input" value="No" onChange={onFormChange}
                                        checked={values.UK_SEIS_EIS_Qualified === "No"} />
                                    <label title="" for="No" class="form-check-label">
                                        <span className="font20Bold">NO</span><br /></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <label class="form-label mb-0">Company Information<span className="fontred">*</span>
                            </label>
                            <textarea class="form-control rounded-0 customdescription" id="exampleFormControlTextarea1" name="company_information"
                                required
                                onChange={onFormChange}
                                defaultValue={initialValues.company_information}
                                row={5}
                                col={5}
                                style={{ minHeight: "70px" }}></textarea>
                            <Form.Control.Feedback type="invalid">
                                Please provide a  Company Information.
                            </Form.Control.Feedback>
                        </div>

                        <div class="col-3 mb-3">
                            <label>
                                File Upload
                                <span className="fontred">*</span>
                            </label>
                            <div id="file-upload-form" className="uploader position-relative">
                                <input
                                    id="new-file-upload"
                                    type="file"
                                    ref={otherFile}
                                    onChange={handleSelectFile} />

                                    <div className="position-absolute" style={{ top: 0, right: '5px'}}>
                                        {values.company_informationURL ? (
                                            <>
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 352 512"
                                                        style={{ width: "13px" }}
                                                        onClick={() => {
                                                            setValues((prevState) => ({
                                                                ...prevState,
                                                            company_informationURL : null
                                                            }))
                                                            setInitialValues((prevState) => ({
                                                                ...prevState,
                                                                company_informationURL: null
                                                            }))
                                                            // SendSuccessResponse("Document Deleted Successfully!");
                                                        }}  
                                                    >
                                                        <path
                                                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                                            fill="#d60000"
                                                        />
                                                    </svg>
                                                </span>
                                            </>
                                        ): null}
                                    </div>

                                    <label for="new-file-upload" id="file-drag-1">
                                        {newfile?.name ? (
                                            newfile.name
                                            
                                        ) : (
                                            <>
                                                {values.company_informationURL ? (
                                                    <div className="mb-2">
                                                        <a href={values.company_informationURL} target="_blank" rel="noreferrer">
                                                            <img alt="file-icon" className="me-2" src={getFileIcon(initialValues?.company_informationURL)} style={{ width: "40px" }} />
                                                            {values.company_informationURL.split("/").pop()}
                                                        </a>
                                                    </div>
                                                ) : <div id="start">
                                                    <svg
                                                        width="47"
                                                        height="34"
                                                        viewBox="0 0 47 34"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M23.5002 0C19.8433 0 16.1667 1.36508 13.3786 4.09391C11.305 6.12336 10.0481 8.64049 9.51495 11.2657C4.10438 12.2928 0 16.9175 0 22.4995C0 28.8329 5.27865 33.9995 11.75 33.9995H36.7826C42.4166 33.9995 47 29.5136 47 23.9995C47 18.7955 42.9037 14.5594 37.7086 14.0933C37.7324 10.4738 36.4189 6.84693 33.6055 4.09337C30.8202 1.36669 27.1571 0 23.5002 0ZM23.5002 3.00016C26.3806 3.00016 29.2329 4.04819 31.4348 6.20327C33.9792 8.69353 35.0557 12.0765 34.6599 15.3122C34.605 15.7387 34.7393 16.1677 35.0294 16.4903C35.3195 16.8129 35.7371 16.9984 36.1761 16.9995H36.7826C40.7713 16.9995 43.9346 20.0955 43.9346 23.9993C43.9346 27.9031 40.7712 30.999 36.7826 30.999H11.75C6.93535 30.999 3.06533 27.211 3.06533 22.4992C3.06533 18.0492 6.523 14.4241 10.9518 14.0303C11.2992 13.9972 11.6246 13.8496 11.874 13.6107C12.1238 13.3722 12.2827 13.0572 12.3248 12.7182C12.6067 10.3406 13.6818 8.03074 15.5498 6.20245C17.7496 4.04987 20.6198 3.00016 23.5002 3.00016ZM23.5002 12.0001C22.9892 12.0001 22.7191 12.1609 22.4625 12.3907L16.8427 17.3907C16.1938 17.9801 16.2052 18.9368 16.7469 19.5158C17.2885 20.0947 18.3237 20.1366 18.9182 19.6096L21.9675 16.891V26.4998C21.9675 27.3282 22.6535 27.9999 23.5002 27.9999C24.3469 27.9999 25.0329 27.3282 25.0329 26.4998V16.891L28.0822 19.6096C28.6766 20.1369 29.6805 20.0655 30.2535 19.5158C30.8572 18.9368 30.7467 17.9237 30.1577 17.3907L24.5379 12.3907C24.2343 12.1183 24.0112 12.0001 23.5002 12.0001Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                    <div className="mt-2">
                                                        Upload, pitch deck, business plan, or any useful
                                                        information
                                                    </div>
                                            </div>}
                                            </>
                                        )}
                                    </label>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a File.
                                </Form.Control.Feedback>
                            </div>
                            <div className="fSize-10 mt-1">
                                Supported upload formats: 5MB max in .DOCX or .PDF or .XLS
                            </div>
                        </div>
                    </div>

                    <Button
                        className="my-submit-button" type="submit"
                        onClick={onsubmit}
                        disabled={(errorMessage && errorMessage) || urlError || isSubmitting || isLoading || numberErrMessage || numberErrMessage2 || numberErrMessage3 || numberErrMessage4 || numberErrMessage5}
                    >
                        {isLoading ?
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                            </> :
                            ''
                        }
                        Update
                    </Button>
                    <Button color="" variant="" className="my-reset-button ms-2" onClick={onReset}>Reset</Button>
                    {/* </Form.Item> */}
                </Form>
            </div>

        </Content>
    );
};
export default OpportunityUpdate;
