import React, { useEffect, useMemo, useState } from "react";
import { Container, NavLink, Form, Breadcrumb, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { handleNValue } from "../../helpers/handleNValue";
import { useFormik } from "formik";
import { CategoryType } from "../../helpers/enum";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsList } from "../../store/user/action";
import * as yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import { customErrorMessage } from "../../helpers/response";
import AxiosInstance from "../../helpers/AxiosRequest";

const schema = yup.object({
  category: yup.string().required("This field is required").nullable(),
  assets: yup.string().required("This field is required").nullable(),
  sell_percent: yup
    .number()
    .min(1, "Must be greater than 0")
    .max(100, "Must be less than or equals to 100")
    .required("This field is required")
    .nullable(),
  price: yup
    .number()
    .min(1, "Must be greater than 0")
    // .max(100, "Must be less than or equals to 100")
    .required("This field is required")
    .nullable(),
})

const SellassetsPage = () => {
  const dispatch = useDispatch()
  const [alertMessage, setalertMessage] = useState(false);
  // const [selectedCategory, setSelectedCategory] = useState("");
  const { assetsList } = useSelector((state) => state.userStore);
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      category: "",
      assets: "",
      sell_percent: 0,
      price: 0,
      // ------
      _amount: 0
    },
    validationSchema: schema,
  });

  const handleSellAsset = async () => {
    try {
      const payload = {
        category_name: formik.values.category,
        investment_id: formik.values.assets,
        percent_to_sell: Number(formik.values.sell_percent || 0),
        price: Number(formik.values.price || 0)
      }
      setIsLoading(true)
      const { data } = await AxiosInstance.post(`/assets/sell`, payload);
      setalertMessage(false)
      toast.success(data?.message || "Asset Sell Successfully!")
      formik.resetForm()
    } catch (error) {
      customErrorMessage(error?.response?.data?.message || error || "Something went wrong!")
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = async (_field, _value) => {
    try {
      if (_field === "category") {
        dispatch(getAssetsList({ category: _value }));
        // ==== fields to reset ====
        await formik.setFieldValue(_field, _value);
        await formik.setFieldValue("assets", "");
        await formik.setFieldValue("sell_percent", 0);
        await formik.setFieldValue("price", 0);
      }
      if (_field === "assets") {
        const _amount = assetsList.data.length > 0 ? assetsList.data.find(item => item._id === _value)?.payments?.amount || 0 : 0
        await formik.setFieldValue(_field, _value)
        await formik.setFieldValue("_amount", _amount)
        // ==== fields to reset ====
        await formik.setFieldValue("sell_percent", 0);
        await formik.setFieldValue("price", 0);
      }
    } catch (err) { }
  }

  const assetsAmount = useMemo(() => {
    const _amount = Number(formik.values._amount) || 0;
    const sellPercent = Number(formik.values.sell_percent) || 0;

    const total = (sellPercent / 100) * _amount;
    const remaining = _amount - total;

    return { total: total ? total.toFixed(2) : 0, remaining: remaining ? remaining.toFixed(2) : 0 };
  }, [formik.values.sell_percent, formik.values._amount]);

  const sell = async () => {
    formik.setTouched({
      category: true,
      assets: true,
      sell_percent: true,
      price: true,
    });
    const errors = await formik.validateForm();
    if (
      _.has(errors, "category") ||
      _.has(errors, "assets") ||
      _.has(errors, "sell_percent") ||
      _.has(errors, "price")
    ) {
      return false;
    }
    setalertMessage(true)
  };

  return (
    <div className="mt-4 mb-4 page_main dashboard  ">
      <Container fluid>
        <h1>
          Sell - Assets
          <span className="font14Blk"></span>
        </h1>

        <div className="divBg_white mb-3 ">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Sell - Assets</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 py-5 ">
              <Form className="pb-4 mb-4  formArea">
                <div className="row flex-column">

                  <div className="col-12">
                    <Form.Group className="mb-4 inputOuter_">
                      <Form.Label className=" defLabel_">
                        Choose Category
                      </Form.Label>
                      <Form.Select
                        name="category"
                        value={formik.values.category}
                        onChange={e => handleChange("category", e.target.value)}
                        onBlur={formik.handleBlur}
                        className="defInput_"
                      >
                        {assetsList.loading ? (
                          <option value="" disabled selected>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="" disabled defaultValue>
                              Choose Category
                            </option>
                            {Object.values(CategoryType).map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}</>
                        )}
                      </Form.Select>
                      {formik.touched.category && formik.errors.category ? (
                        <Form.Text className="text-danger">
                          {formik.errors.category}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-12">
                    <Form.Group className="mb-4 inputOuter_">
                      <Form.Label className=" defLabel_">
                        Choose Assets
                      </Form.Label>
                      <Form.Select
                        disabled={formik.values.category === "" || assetsList.data.length === 0}
                        name="assets"
                        value={formik.values.assets}
                        onChange={e => handleChange("assets", e.target.value)}
                        // onChange={e => {
                        //   const _amount = assetsList.data.length > 0 ? assetsList.data.find(item => item._id === e.target.value)?.payments?.amount || 0 : 0
                        //   formik.setFieldValue("assets", e.target.value)
                        //   formik.setFieldValue("_amount", _amount)
                        // }}
                        onBlur={formik.handleBlur}
                        className="defInput_" >
                        {assetsList.loading ? (
                          <option value="" disabled selected>
                            Loading...
                          </option>
                        ) : (
                          assetsList.data.length === 0 ? (
                            <option value="" disabled selected>
                              No Assets Found!
                            </option>)
                            : (
                              <>
                                <option value="" disabled selected>
                                  Choose Assets
                                </option>
                                {assetsList.data.map((item, i) => (
                                  <option key={i} value={item._id}>
                                    {item.investment_Title}
                                  </option>
                                ))}</>
                            )
                        )}
                      </Form.Select>
                      {formik.touched.assets && formik.errors.assets ? (
                        <Form.Text className="text-danger">
                          {formik.errors.assets}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-12 ">
                    <Form.Group className="mb-2 inputOuter_ ">
                      <Form.Label className="defLabel_">
                        Sell Assets %
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="sell_percent"
                        value={formik.values.sell_percent}
                        onChange={e => formik.setFieldValue("sell_percent", handleNValue(e))}
                        onBlur={formik.handleBlur}
                        placeholder="Sell Assets"
                        className="defInput_ "
                      />
                      {formik.touched.sell_percent && formik.errors.sell_percent ? (
                        <Form.Text className="text-danger">
                          {formik.errors.sell_percent}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="mt-0 d-flex mb-3">
                    <div>
                      <span className="fw-bold">Total</span> : {assetsAmount.total}
                    </div>
                    <div className="ms-3">
                      <span className="fw-bold">Available after sell </span>:
                      {assetsAmount.remaining}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <Form.Group className="mb-4 inputOuter_ position-relative">
                      <Form.Label className="defLabel_">Price</Form.Label>
                      <Form.Control
                        type="text"
                        name="price"
                        value={formik.values.price}
                        onChange={e => formik.setFieldValue("price", handleNValue(e))}
                        onBlur={formik.handleBlur}
                        placeholder="Price"
                        className="defInput_ "
                      />
                      <div className="customposition">
                        <p>USDC</p>
                      </div>
                      {formik.touched.price && formik.errors.price ? (
                        <Form.Text className="text-danger">
                          {formik.errors.price}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </div>
                </div>

                <div className="d-inline-block ">
                  <button
                    type="button"
                    onClick={sell}
                    className="btnSell btnBuySM"
                    disabled={isLoading}
                  >
                    Sell
                    <svg
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.730469 6.79565H15.7185" stroke="white" />
                      <path
                        d="M9.34521 1L15.7186 6.91254L9.34521 12.841"
                        stroke="white"
                      />
                    </svg>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        show={alertMessage}
        onHide={() => setalertMessage(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalComman"
      >

        <Form className="">
          <Modal.Body>
            <div className="d-flex-coloum text-center">
              <h3 className="mt-5 mb-3">Are You Sure</h3>
              <h5 className="mb-4">you want to sell <span>{formik.values.sell_percent}%</span> at this amount?</h5>
              <div className="mb-5">
                <button type="button" className="btn_White lm" onClick={() => setalertMessage(false)}>
                  No
                </button>
                <button type="button" className="btn_Orange lm ms-2" onClick={() => handleSellAsset()} disabled={isLoading}>
                  Yes
                </button>
              </div>
            </div>

          </Modal.Body>

        </Form>
      </Modal>
    </div>
  );
};

export default SellassetsPage;
