import React, { Fragment, useRef, useState } from "react";
import { Breadcrumb, Layout, Form, Input, Spin } from "antd";
import { Button } from "reactstrap";
import AxiosInstance from '../../helpers/AxiosRequest';
import url from "../../helpers/development.json";
import { customErrorMessage, customMessage } from "../../helpers/response";

const AdminChangePassword = () => {
  const { Content } = Layout;
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async (data) => {
    try {
      setValidated(true)
      setIsLoading(true);
      const response = await AxiosInstance.post(url.AdminChangePassword, {
        password: data.password,
        newpassword: data.newpassword,
      });
      if (response.status === 200 && response.data.statusText === 'Success') {
        customMessage("Password changed successfully!");
        form.resetFields();
      } else {
        customErrorMessage(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const onReset = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Fragment>
      <Content className="site-layout-background ">
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
          items={[
            {
              title: <a href="/admin/dashboard">Home</a>,
            },
            {
              title: "Change Password"
            }
          ]}
        >
        </Breadcrumb>
        <div className="page-card-view ant-table-thead">
          <h5 className="fw-bold fs-5 mt-2 mb-3">CHANGE PASSWORD </h5>

          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 3,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: false,
            }}
            validated={validated}
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item label="Old Password" type="password" name="password" rules={[{ required: true, message: 'Please input your old password!' }]}>
              <Input type="password" />
            </Form.Item>
            <Form.Item label="New Password" type="password" name="newpassword" rules={[{ required: true, message: 'Please input your new password!' }]}>
              <Input type="password" />
            </Form.Item>
            <Form.Item label="Confirm Password" name="confirm_password" rules={[{ required: true, message: 'Please input your confirm password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newpassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('New password and confirm password do not match!');
              },
            }),
            ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 16,
              }}
            >
              <Button className="my-submit-button mt-5" disabled={isLoading}>
                {isLoading ? <Spin /> : 'Update'}
              </Button>
              <Button
                color=""
                variant=""
                className="my-reset-button ms-2 mt-5"
                disabled={isLoading}
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Fragment>
  );
};

export default AdminChangePassword;
