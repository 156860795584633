import React from 'react'

const PaginationComponent = ({ selectedPage, handlePageChange, totalPages }) => {
    return (
        <div className="pagination">
            {totalPages > 1 ? (
                <>
                    <button
                        onClick={() => selectedPage === 1 ? null : handlePageChange(selectedPage - 1)}
                        disabled={selectedPage === 1}
                        className="pagination-btn"
                    >
                        Prev
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`pagination-btn${selectedPage === index + 1 ? ' active' : ''}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        onClick={() => selectedPage === totalPages ? null : handlePageChange(selectedPage + 1)}
                        disabled={selectedPage === totalPages}
                        className="pagination-btn"
                    >
                        Next
                    </button>
                </>
            ) : null}
        </div>
    )
}

export default PaginationComponent