import React, { useState } from "react";
import { Spinner, Form, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/admin/images/Logo_white.png";
import { successResponse, customErrorMessage } from "../../helpers/response";
import AxiosInstance from "../../helpers/AxiosRequest";
import url from "../../helpers/development.json";
import "../../assets/admin/scss/App.scss";
import "../../assets/admin/scss/fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const AdminLogin = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({});

    const onSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setIsLoading(true);
            const response = await AxiosInstance.post(url.Adminlogin, values);
            if (response.status === 200 && response.data.statusText === "Success") {
                localStorage.setItem("adminAccessToken", response.data.data.token);
                setIsLoading(false);
                successResponse(response);
                window.location.href = "/admin/dashboard";
            } else {
                setIsLoading(false);
                customErrorMessage(response.data.message);
            }
        }
    }

    const onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    return (
        <div className="d-flex justify-content-center flex-column align-items-center forheight adminLayout ">
            <div className="loginBox">
                <div className="text-center mb-3">
                    <img src={Logo} alt="" />
                </div>
                <Card className="login-form">
                    <div className="p-4">
                        <h4 className="loginTitle">Admin Login</h4>
                        <Form
                            className="loginForm"
                            onSubmit={onSubmit}
                            validated={validated}
                            noValidate
                        >
                            <Form.Group
                                className="mb-4 inputOuter_"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="defLabel_">
                                    Email ID<span className="fontred">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Your Email ID"
                                    className="inputs defInput_"
                                    name="email"
                                    required
                                    onChange={onChange}
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Email.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-4 inputOuter_"
                                controlId="formBasicPassword"
                            >
                                <Form.Label className="defLabel_">
                                    Password<span className="fontred">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Enter Your Password"
                                    className="mb-3 inputs defInput_"
                                    required
                                    onChange={onChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Password
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="setpassword mb-3">
                                <Link
                                    to="/admin/auth/forgot-password"
                                    className="fontsizeset text-decoration-none"
                                >
                                    <h6 className="pb-2 text-end">
                                        <b>Forget Password?</b>
                                    </h6>
                                </Link>
                            </div>
                            <Button
                                type="submit"
                                className="login-button w-100"
                            >
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="mr-2"
                                        />
                                    </>
                                ) : (
                                    ""
                                )}
                                {isLoading ? "loading..." : "Login"}
                            </Button>
                        </Form>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default AdminLogin;
