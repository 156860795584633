import React, { useEffect, useState, useRef } from "react";
import LogoBig from "./assets/images/logoBig.png";
import { Container, Row, Col, Form, Button, Modal, Spinner, Nav } from "react-bootstrap";
import OTPInput from "otp-input-react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AxiosInstance from "../../helpers/AxiosRequest";
import url from "../../helpers/development.json";
import { customErrorMessage, customMessage, errorResponse, SendSuccessResponse } from "../../helpers/response";
import 'intl-tel-input/build/css/intlTelInput.css';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import TermsandConditionModal from "./common/TermsandConditionModal";
// import MessagingService from "../../services/MessagingService";
import MessagingService from "../../firebase-service/messaging-service";

const Signup = () => {
    const [otpModalShow, setOtpModalShow] = useState(false);
    const [OTP, setOTP] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({});
    const [countryCode, setCountryCode] = useState();
    const [phoneNumber, setPhoneNumber] = useState({ iso2: 'gb', dialCode: '+44', phone: '' });
    const [isResend, setIsResend] = useState(false);
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const [isTermsAndConApply, setIsTermsAndCondApply] = useState(false);
    const [, setErrorMessage] = useState("")
    const [modaltermsand, setModalTermsand] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [time, setTime] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fullName, setFullName] = useState("");
    const [fullNameError, setFullNameError] = useState("");
    const formRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (time === true) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                        setIsResend(true);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [seconds, minutes, time]);

    const onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
    };

    const checkIfEmailRegistered = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            if (values === "") {
                setErrorMessage("");
            }

            if (phoneNumber.phone === "" || phoneNumber.phone === null || phoneNumber.phone === undefined) {
                setPhoneNumberError("Please Provide a valid mobile number");
            } else {
                setPhoneNumberError("");
            }
            if (fullName === "" || fullName === null || fullName === undefined) {
                setFullNameError("Please enter a valid full name.");
            } else {
                setFullNameError("");
            }
            event.stopPropagation();
        } else {
            if (phoneNumber.phone === "" || phoneNumber.phone === null || phoneNumber.phone === undefined) {
                setPhoneNumberError("Please Provide a valid mobile number");
            } else {
                setIsSubmitting(true);
                setErrorMessage("");
                try {
                    const response = await AxiosInstance.post(url.userExists, { email: values.email });
                    if (response.status === 200 && response.data.statusText === "Success") {
                        sendOTP();
                    }
                } catch (err) {
                    errorResponse(err?.response);
                    setIsLoading(false);
                    setIsSubmitting(false);
                }

                // const response = await AxiosInstance.post(url.userExists, { email: values.email });
                // console.log("response", response);
                // // return false;
                // if (response.status === 200 && response.data.statusText === "Success") {
                //   sendOTP();
                // } else {
                //   errorResponse(response);
                //   setIsLoading(false);
                //   setIsSubmitting(false);
                // }
            }
        }
    }


    const sendOTP = async () => {
        const response = await AxiosInstance.post(url.sendOTP, {
            email: values.email
        })
        if (response.status === 200 && response.data.statusText === "Success") {
            setIsLoading(true);
            setIsSubmitting(false);
            customMessage(`OTP sent to email ${values.email}.`);
            setTime(true);
            setOtpModalShow(true);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setTime(false);
            setOtpModalShow(false);
        }
    }

    const verifyOTP = async (event) => {
        event.preventDefault();
        if (OTP !== "") {
            setIsLoading(true);
            const response = await AxiosInstance.post(url.verifyOTP, {
                email: values.email,
                otp: OTP
            });
            if (response.status === 200 && response.data.statusText === "Success") {
                const messagingToken = await MessagingService.getMessagingToken();
                const signUpObj = {
                    ...values,
                    phone_verification: true,
                    country_code: countryCode,
                    phone_number: phoneNumber.phone,
                    name: fullName,
                    notificationToken: messagingToken

                }
                const userSignup = await AxiosInstance.post(url.signup, signUpObj);

                if (userSignup.status === 200) {
                    localStorage.setItem("user", JSON.stringify(userSignup.data.data))
                    localStorage.setItem("signupUser", JSON.stringify(userSignup.data.data));
                    localStorage.setItem("userId", userSignup.data.data._id)
                    SendSuccessResponse("OTP verified !");
                    setOtpModalShow(false);
                    navigate("/membership");
                } else {
                    customErrorMessage("Something went wrong!")
                    setIsLoading(false)
                }

            } else {
                customErrorMessage("Incorrect OTP!");
                setIsLoading(false);
            }
        }
    }

    const resendOTP = (event) => {
        event.preventDefault();
        setIsResend(false);
        setIsLoading(false);
        setMinutes(2);
        setSeconds(0);
        setOTP("");
        sendOTP();
    }

    const handlePhoneNumber = (value, country) => {
        const regex = /^[0-9]*$/;
        const isValidPhoneNumber = regex.test(value.phone) && value.phone.length <= 15;
        if (isValidPhoneNumber) {
            setPhoneNumber(value);
            setCountryCode(value.dialCode);
            setPhoneNumberError('');
            return true;
        } else {
            setPhoneNumber("");
            setPhoneNumberError('Please enter a valid phone number.');
            return false;
        }
    };

    const handleFullName = (e) => {
        const regex = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
        if (regex.test(e.target.value)) {
            setFullNameError("");
            setFullName(e.target.value);
        } else {
            setFullName("");
            setFullNameError("Please enter a valid full name.");
        }
    }

    return (
        <>
            <div className="login_parent">
                <Container fluid>
                    <Row className="height100vh_">
                        <Col
                            lg={6}
                            md={12}
                            sm={12}
                            className="p-0 d-none d-md-none d-lg-block"
                        >
                            <div className="leftSlider">
                                <Link to="/">
                                    <div className="logo text-center">
                                        <img src={LogoBig} alt="" />
                                    </div>
                                </Link>
                                <div className="d-flex align-itmes-center flex-column contentSlide">
                                    <div className="bottomContent text-center">
                                        <p>
                                            Collective investment schemes on the blockchain.
                                        </p>
                                        <h4>
                                            A trusted society of investors,<br />
                                            entrepreneurs and venture capitalists.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="p-0">
                            <div className="rightForm">
                                <div className="d-flex flex-column justify-content-center col-10 col-xl-8 mx-auto h-100 gap50">
                                    <h4 className="loginTitle">Sign up</h4>
                                    <Form
                                        noValidate
                                        method="post"
                                        className="loginForm"
                                        validated={validated}
                                        onSubmit={checkIfEmailRegistered}
                                        ref={formRef}
                                    >
                                        <Form.Group
                                            className="mb-4 inputOuter_"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="defLabel_">
                                                Name <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Your Full Name"
                                                className="inputs defInput_"
                                                required
                                                onChange={handleFullName}
                                            />
                                            {
                                                fullNameError.length > 0 &&
                                                <span style={{ marginTop: "0.25rem", fontSize: ".875em", color: "#dc3545" }}>
                                                    {fullNameError}
                                                </span>
                                            }
                                        </Form.Group>

                                        <Form.Group
                                            className="mb-4 inputOuter_"
                                            controlId="formBasicPassword"
                                        >
                                            <Form.Label className="defLabel_">
                                                Mobile Number <span className="requireStar">*</span>
                                            </Form.Label>
                                            <ReactIntlTelInput
                                                className="inputs defInput_"
                                                value={phoneNumber}
                                                required
                                                onChange={handlePhoneNumber}
                                            />
                                            {
                                                phoneNumberError.length > 0 &&
                                                <span style={{ marginTop: "0.25rem", fontSize: ".875em", color: "#dc3545" }}>
                                                    {phoneNumberError}
                                                </span>

                                            }
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a phone number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-4 inputOuter_"
                                            controlId="formBasicPasswordv1"
                                        >
                                            <Form.Label className="defLabel_">
                                                Email ID <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="Your Email ID"
                                                className="mb-3 inputs defInput_"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                required
                                                validated={validated}
                                                onChange={onFormChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-4 inputOuter_"
                                            controlId="formBasicPasswordv2"
                                        >
                                            <Form.Label className="defLabel_">
                                                Password <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Your Password"
                                                className="mb-3 inputs defInput_"
                                                required
                                                onChange={onFormChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid password.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-5">
                                            <div className="form-check">
                                                <input type="checkbox" id="formBasicCheckbox" className="form-check-input" onChange={() => setIsTermsAndCondApply(!isTermsAndConApply)} />
                                                <label title="" className="form-check-label flex-wrap">
                                                    <span className='me-1' style={{ fontSize: "13.5px", letterSpacing: "0px" }}>Please tick to confirm you have read and agree to our membership's</span>
                                                    <Nav.Link className='link13Black d-inline' onClick={() => setModalTermsand(true)} style={{ cursor: "pointer" }}>terms & conditions</Nav.Link>
                                                    <span>. *</span>
                                                </label>
                                            </div>
                                        </Form.Group>

                                        <Button type="submit" className='btn_Black' id="sign-in-button" disabled={!isTermsAndConApply || phoneNumberError.length > 0 || isSubmitting || fullNameError.length > 0}
                                        >
                                            {isLoading ? <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                            </>
                                                : ''
                                            }
                                            {isLoading ? 'loading...' : 'Continue'}
                                        </Button>

                                        <div className="text-center registerNow mt-4">
                                            <span className="clrBlack">Already a member? </span>
                                            <NavLink to="/login" className="linkBlk">
                                                Login Now
                                            </NavLink>
                                        </div>

                                        <div className="text-center mt-4">
                                            <NavLink to="/" className="linkBlk">Back to Home</NavLink>
                                        </div>

                                    </Form>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* ====== modal ===== */}
            <Modal
                show={otpModalShow}
                onHide={() => { setOtpModalShow(false); setTime(false); setMinutes(1); setSeconds(0); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="otpModel"
                backdrop="static"
            >
                <Modal.Header className="" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        OTP Verification
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="mb-5 text-center">
                            A 6 digit code has been sent to
                            <br />
                            <strong>{values.email}</strong>
                        </div>
                        <OTPInput
                            className="mb-5 justify-content-center otpFromGroup"
                            value={OTP}
                            onChange={setOTP}
                            OTPLength={6}
                            otpType="number"
                        />
                        <div className="mb-3 text-center">
                            Please check your spam if it is not in your inbox
                        </div>
                        <div className="mb-4 d-flex justify-content-center text-center registerNow">
                            <span className="clrBlack">Didn't receive OTP?</span> &nbsp;
                            <button
                                onClick={resendOTP}
                                className="linkBlk"
                                disabled={!isResend}>
                                {minutes > 0 || seconds > 0 ? <>({`0${minutes}`}: {seconds < 10 ? `0${seconds}` : seconds})</> : "Resend OTP"}
                            </button>

                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button type="button" shape="round" className='btn_Black' onClick={verifyOTP} disabled={OTP === "" ? true : false}>
                        {isLoading ? <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                        </>
                            : ''
                        }
                        {isLoading ? 'loading...' : 'Verify and Sign up'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <TermsandConditionModal show={modaltermsand} onHide={() => setModalTermsand(false)} />
        </>
    );
};


export default Signup;
