import React, { useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import profileIamge from "../../../assets/admin/images/dummy-profile-pic.jpg";
import AxiosInstance from "../../../helpers/AxiosRequest";
import url from "../../../helpers/development.json";

const AdminHeaderPage = () => {
  const { Header } = Layout;
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserDetails()
  }, [])
  

  const getUserDetails = async () => {
    try {
      const response = await AxiosInstance.get(url.AdmingetAdminDetails);
      if (response.status === 200 && response.data.statusText === "Success") {
        setUser(response.data.data)
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Header className="header my-header-color">
      <Row className="mt-4">
        <Col span={12}></Col>
        <Col span={12}>
          <div className="profile-menu ant-layout-header me-5">
            <Menu mode="horizontal">
              <Menu.SubMenu
                key="profileMenu"
                title={
                  <>
                  <div className="d-flex align-items-center">
                  <img
                      style={{ height: "50px", width: '50px', border: "1px solid black", padding: "2px" }}
                      className="profile-img-icon "
                      alt=""
                      src={profileIamge}
                    />
                    <span>{user?.name}</span>
                  </div>
                   
                  </>
                }

              >
                <Menu.Item
                  key="profile-profile"
                  className="d-flex align-items-center headerMenuCustom"
                >
                  <span className="me-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.62975 8.88889C8.75072 8.88889 7.89143 8.62823 7.16055 8.13986C6.42966 7.6515 5.86001 6.95738 5.52362 6.14526C5.18723 5.33314 5.09921 4.43952 5.2707 3.57738C5.44219 2.71524 5.86549 1.92332 6.48705 1.30175C7.10862 0.680183 7.90054 0.256891 8.76268 0.0854007C9.62482 -0.0860891 10.5184 0.00192572 11.3306 0.338315C12.1427 0.674705 12.8368 1.24436 13.3252 1.97525C13.8135 2.70613 14.0742 3.56542 14.0742 4.44445C14.0742 5.62319 13.6059 6.75365 12.7724 7.58714C11.939 8.42064 10.8085 8.88889 9.62975 8.88889ZM9.62975 1.48148C9.04373 1.48148 8.47087 1.65526 7.98361 1.98083C7.49636 2.30641 7.11659 2.76916 6.89233 3.31057C6.66807 3.85198 6.60939 4.44773 6.72372 5.02249C6.83804 5.59725 7.12024 6.1252 7.53462 6.53958C7.949 6.95395 8.47694 7.23615 9.0517 7.35048C9.62646 7.4648 10.2222 7.40613 10.7636 7.18187C11.305 6.95761 11.7678 6.57784 12.0934 6.09058C12.4189 5.60332 12.5927 5.03046 12.5927 4.44445C12.5927 3.65862 12.2805 2.90498 11.7249 2.34931C11.1692 1.79365 10.4156 1.48148 9.62975 1.48148Z"
                        fill="black"
                      />
                      <path
                        d="M15.5556 20H3.70373C2.72145 20 1.7794 19.6098 1.08482 18.9152C0.390239 18.2206 2.81697e-05 17.2786 2.81697e-05 16.2963C-0.000742597 16.2079 0.0143094 16.1201 0.0444726 16.037C0.756322 14.1592 2.02101 12.5415 3.67153 11.3975C5.32204 10.2536 7.28073 9.63712 9.28892 9.62964H9.9704C11.9786 9.63712 13.9373 10.2536 15.5878 11.3975C17.2383 12.5415 18.503 14.1592 19.2148 16.037C19.245 16.1201 19.2601 16.2079 19.2593 16.2963C19.2593 17.2786 18.8691 18.2206 18.1745 18.9152C17.4799 19.6098 16.5379 20 15.5556 20ZM1.48151 16.4222C1.51371 16.9895 1.76197 17.5229 2.17528 17.9128C2.58859 18.3027 3.13554 18.5194 3.70373 18.5185H15.5556C16.1238 18.5194 16.6707 18.3027 17.084 17.9128C17.4973 17.5229 17.7456 16.9895 17.7778 16.4222C17.1544 14.8593 16.0779 13.5186 14.6867 12.5722C13.2955 11.6257 11.653 11.1169 9.9704 11.1111H9.28892C7.6063 11.1169 5.96386 11.6257 4.57262 12.5722C3.18139 13.5186 2.10491 14.8593 1.48151 16.4222Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <Link className="a-remove-text-decoration" to="/admin/profile">
                    {" "}
                    Profile{" "}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="change-password-profile"
                  className="d-flex align-items-center headerMenuCustom"
                >
                  <span className="me-2">
                    <svg
                      width="15"
                      height="20"
                      viewBox="0 0 15 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9579 8.3393H12.7156V5.37247C12.7156 2.4101 10.3148 0 7.36103 0C4.40728 0 2.0065 2.41167 2.0065 5.37598V8.3393H0.777938C0.352245 8.3393 0 8.67828 0 9.10505V18.069C0 19.1342 0.871567 20 1.93408 20H12.8018C13.8643 20 14.7221 19.1342 14.7221 18.069V9.10505C14.7221 8.67828 14.3836 8.3393 13.9579 8.3393ZM3.54637 5.37598C3.54637 3.26385 5.2573 1.54551 7.36103 1.54551C9.46478 1.54551 11.1757 3.26226 11.1757 5.37247V8.3393H3.54637V5.37598ZM13.1822 18.069C13.1822 18.282 13.0143 18.4562 12.8018 18.4562H1.93408C1.72157 18.4562 1.53987 18.282 1.53987 18.069V9.88314H13.1822V18.069Z"
                        fill="black"
                      />
                      <path
                        d="M7.361 16.4848C7.78669 16.4848 8.13093 16.1388 8.13093 15.712V12.9302C8.13093 12.5034 7.78668 12.1574 7.361 12.1574C6.93532 12.1574 6.59106 12.5034 6.59106 12.9302V15.712C6.59106 16.1388 6.93531 16.4848 7.361 16.4848Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <Link
                    className="a-remove-text-decoration"
                    to="/admin/change-password"
                  >
                    {" "}
                    Change Password{" "}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="logout-profile"
                  className="d-flex align-items-center headerMenuCustom"
                  onClick={() => localStorage.removeItem("adminAccessToken")}
                >
                  <span className="me-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.88889 11.1111V0H11.1111V11.1111H8.88889ZM10 20C8.62963 20 7.33778 19.7363 6.12444 19.2089C4.91185 18.6807 3.85185 17.963 2.94444 17.0556C2.03704 16.1481 1.31926 15.0881 0.791111 13.8756C0.263704 12.6622 0 11.3704 0 10C0 8.51852 0.305556 7.12037 0.916667 5.80556C1.52778 4.49074 2.38889 3.35185 3.5 2.38889L5.11111 4C4.18519 4.7037 3.47222 5.58333 2.97222 6.63889C2.47222 7.69445 2.22222 8.81482 2.22222 10C2.22222 12.1481 2.98148 13.9815 4.5 15.5C6.01852 17.0185 7.85185 17.7778 10 17.7778C12.1667 17.7778 14.0044 17.0185 15.5133 15.5C17.023 13.9815 17.7778 12.1481 17.7778 10C17.7778 8.81482 17.5322 7.69445 17.0411 6.63889C16.5507 5.58333 15.8333 4.7037 14.8889 4L16.5 2.38889C17.6111 3.35185 18.4722 4.49074 19.0833 5.80556C19.6944 7.12037 20 8.51852 20 10C20 11.3704 19.7363 12.6622 19.2089 13.8756C18.6807 15.0881 17.9678 16.1481 17.07 17.0556C16.1715 17.963 15.1159 18.6807 13.9033 19.2089C12.69 19.7363 11.3889 20 10 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <Link className="a-remove-text-decoration" to="/admin/auth/login">
                    {" "}
                    Logout{" "}
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </div>
        </Col>
      </Row>
    </Header>
  );
};
export default AdminHeaderPage;
