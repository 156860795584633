export const handleNValue = (e) => {
    // // without dot 
    if (!(/^[0-9]*$/.test(e.target.value))) {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }
    return e.target.value

    // // with dot
    // e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
    // if (!(/^(\d*\.?\d*)?$/.test(e.target.value))) {
    //     e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    // }
    // return e.target.value;
}