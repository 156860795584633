import { toast } from 'react-toastify';

const SuccessIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 108 108" fill="none">
        <circle cx="54" cy="54" r="48" fill="#5AA94A" />
        <circle cx="54" cy="54" r="51" stroke="#5AA94A" stroke-opacity="0.5" stroke-width="6" />
        <path d="M77.8615 36.1482C76.3434 34.6175 73.8817 34.617 72.3625 36.1482L45.2006 63.5354L36.6373 54.9009C35.1187 53.3708 32.657 53.3702 31.1389 54.9015C29.6204 56.4321 29.6204 58.9143 31.1389 60.4456L42.4517 71.8519C43.211 72.6169 44.2058 73 45.2012 73C46.1966 73 47.1914 72.6169 47.9507 71.8519L77.8615 41.6923C79.3795 40.1616 79.3795 37.6794 77.8615 36.1482Z" fill="white" />
    </svg>
)
const ErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 108 108" fill="none">
        <circle cx="54" cy="54" r="48" fill="#E67161" />
        <circle cx="54" cy="54" r="51" stroke="#E67161" stroke-opacity="0.5" stroke-width="6" />
        <path d="M54.5 47.5704L38.3648 31.4352C36.4513 29.5216 33.3487 29.5216 31.4352 31.4352C29.5216 33.3487 29.5216 36.4513 31.4352 38.3648L47.5704 54.5L31.4352 70.6352C29.5216 72.5487 29.5216 75.6513 31.4352 77.5648C33.3487 79.4784 36.4513 79.4784 38.3648 77.5648L54.5 61.4296L70.6352 77.5648C72.5487 79.4784 75.6513 79.4784 77.5648 77.5648C79.4784 75.6513 79.4784 72.5487 77.5648 70.6352L61.4296 54.5L77.5648 38.3648C79.4784 36.4513 79.4784 33.3487 77.5648 31.4352C75.6513 29.5216 72.5487 29.5216 70.6352 31.4352L54.5 47.5704Z" fill="white" />
    </svg>
)

const CustomToastPopupUi = ({ message, icon }) => (
    <div className='d-flex flex-column align-items-center customDiv'>
        <span className='customIcon'>{icon}</span>
        <div className='mb-3 customMsg'>{message}</div>
    </div>
)

const CustomCloseButton = ({ closeToast, className = "" }) => {
    return (
        <button className={`customCloseButton ${className}`} onClick={closeToast}>
            OK
        </button>
    );
};

const successResponse = (response) => {
    if (response.status === 200) {
        if (response.data.message) {
            // toast.success(<CustomToastPopupUi message={response.data.message} icon={<SuccessIcon />} />, {
            //     className: 'customToastSuccess',
            //     autoClose: 2500,
            //     closeButton: <CustomCloseButton className='success' />
            // });
            toast.success(response.data.message)
        }
    }
}

const customMessage = (message) => {
    // toast.success(<CustomToastPopupUi message={message} icon={<SuccessIcon />} />, {
    //     className: 'customToastSuccess',
    //     autoClose: 2500,
    //     closeButton: <CustomCloseButton className='success' />
    // });
    toast.success(message)
}

const customErrorMessage = (message) => {
    // toast.error(<CustomToastPopupUi message={message} icon={<ErrorIcon />} />, {
    //     className: 'customToastError',
    //     autoClose: 2500,
    //     closeButton: <CustomCloseButton className='error' />
    // });
    toast.error(message)
}

const SendSuccessResponse = (message) => {
    // toast.success(<CustomToastPopupUi message={message} icon={<SuccessIcon />} />, {
    //     className: 'customToastSuccess',
    //     autoClose: 2500,
    //     closeButton: <CustomCloseButton className='success' />
    // });
    toast.success(message)
}

const errorResponse = (response) => {
    // toast.error(<CustomToastPopupUi message={response.data.message} icon={<ErrorIcon />} />, {
    //     className: 'customToastError',
    //     autoClose: 2500,
    //     closeButton: <CustomCloseButton className='error' />
    // });
    toast.error(response.data.message)
}

export { successResponse, customMessage, customErrorMessage, errorResponse, SendSuccessResponse };
