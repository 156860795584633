import React from "react";
import { useEffect } from 'react';
import { Layout } from "antd";
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Navigate, Outlet, useOutlet, useOutletContext } from 'react-router-dom';

const AdminMaster = () => {
    const { props: { children: { props: { children } } } } = useOutlet()

    const user = localStorage.getItem("adminAccessToken") ? true : false;
    useEffect(() => {
        var value = localStorage.getItem('theme-color')
        const settingColorThemeCollection = document.getElementsByClassName("my-theme-color");
        for (let i = 0; i < settingColorThemeCollection.length; i++) {
            settingColorThemeCollection[i].classList.add(value)
        }

        const muButtonsettingColorThemeCollection = document.getElementsByClassName("my-theme-color-button");
        for (let i = 0; i < muButtonsettingColorThemeCollection.length; i++) {
            muButtonsettingColorThemeCollection[i].classList.add(value)
        }
    }, []);


    useEffect(() => {
        document.title = children?.props?.title || "Meridiem";
    }, [children?.props?.title]);

    return (
        <>
            <Layout style={{ minHeight: '100vh'}} className="adminLayout">
                <Sidebar />
                <Layout>
                    <Header />
                    {user ? <Outlet /> : <Navigate to={"/admin/auth/login"} />}
                    <Footer />
                </Layout>
            </Layout>
        </>
    );
}

export default AdminMaster;
