import React, { useState } from "react";
import { useNavigate  ,Link} from "react-router-dom";
import { Spinner, Button, Form, Card  } from "react-bootstrap";
import Logo from "../../assets/admin/images/Logo_white.png";
import Email from "./Email";
import { customErrorMessage,customMessage } from "../../helpers/response";
import AxiosInstance from '../../helpers/AxiosRequest';
import url from "../../helpers/development.json";


const AdminForgotPassword = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [emailReceived, ] = useState(false);
  const [values,setValues] = useState({});
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();


  const onSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
      }else {
        setIsLoading(true);
        const response = await AxiosInstance.post(url.AdminforgotPassword, {email:values.email});
        if(response.status === 200 && response.data.statusText === "Success") {
          customMessage("We sent a password reset link to " + values.email);
          navigate(`/admin/auth/check-email/${response.data.data.token}`);
      } else {
          setIsLoading(false);
          customErrorMessage("Email not found!");
      }
    }
  }
  
  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setValues({ ...values, [name]: value });
};
   

  return (
    <>
      {emailReceived ? (
        <Email />
      ) : (
        <div className="d-flex justify-content-center flex-column align-items-center forheight">
          <div className="loginBox customHEAD">
            <div className="text-center mb-3">
              <img src={Logo} alt=""/>
            </div>
              <Card className="login-form">
                <div className="p-4">
                  <h4 className="loginTitle">Forgot Password</h4>
                  <Form className="loginForm"
                    onSubmit={onSubmit}
                    validated={validated}
                    noValidate
                    autoComplete="off"
                  >
                    <Form.Group
                      className="mb-4 inputOuter_"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="defLabel_">
                        Email ID<span className="fontred">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Email ID"
                        className="inputs defInput_"
                        name="email"
                        required
                        onChange={onChange}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Email.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="login-button w-100"
                    >
                      {isLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                          />
                        </>
                      ) : (
                        ""
                      )}
                     {isLoading ? "loading..." : "Submit"}
                    </Button>

                    < Link to="/admin/auth/login" className="setpassword fontsizeset text-decoration-none">
                      <h6 className="mt-4 text-center">
                        <b>Back to Login</b>
                      </h6>
                    </Link>

                  </Form>
                </div>
              </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminForgotPassword;
