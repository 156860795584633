import React, { useState, useEffect, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Table,
    Form,
    Modal,
    Button,
    Badge,
    Nav,
    Spinner,
} from "react-bootstrap";
import bitcoin from "../../../assets/images/bitcoinIcon.png";
import property from "../../../assets/images/icProperty.png";
import Commodity from "../../../assets/images/icCommodity.png";
import mCoin from "../../../assets/images/mCoin.png";
import { Link, NavLink } from "react-router-dom";
// import Marquee from "react-marquee-master";
// import { useDispatch } from "react-redux";
// import { getPosts } from "../../../../src/";
import icCrypto from "../../../assets/images/icCrypto.png";
import icProperty from "../../../assets/images/icProperty.png";
import icCommodity from "../../../assets/images/icCommodity.png";
import icBonds from "../../../assets/images/icBonds.png";
import icShares from "../../../assets/images/icShares.png";
import Web3 from 'web3';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { _getUserDetails, getInvestmentStats, getUserInvestmentList, investmentGetDetail, voteNow } from "../../../store/user/action";
import { toast } from "react-toastify";
import { customErrorMessage } from "../../../helpers/response";
import AxiosInstance from "../../../helpers/AxiosRequest";
import { handleNValue } from "../../../helpers/handleNValue";
import AddBalancePopup from "../paymentComponent/AddBalancePopup";


const status = {
    upcoming: "upcoming",
    marketing: "marketing",
    fund: "fund",
    voting: "voting",
    result: "result",
    unknown: "unknown"
}
const Dashboard = () => {
    const dispatch = useDispatch();
    const [claimDividend, setClaimDividend] = useState(false);

    const initInvestPopupValue = { show: false, investmentId: null }
    const [investPopup, setInvestPopup] = useState(initInvestPopupValue);
    const [addBalance, setAddBalancePopup] = useState({ show: false });

    const [isKycCompleted, setIsKycCompleted] = useState("");
    const user = useMemo(() => localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null, [])
    const { investmentDetail, investmentStats, _userDetails } = useSelector((state) => state.userStore);
    const [totalInvestmentAmount, setTotalInvestmentAmount] = useState();
    const [investmentList, setInvestmentList] = useState([])

    useEffect(() => {
        dispatch(investmentGetDetail())
    }, [dispatch])

    useEffect(() => {
        dispatch(_getUserDetails())
    }, [dispatch])

    const handleVoteNow = async (e, item) => {
        try {
            e.target.innerHTML =
                '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Vote Now';
            e.target.disabled = true;

            if (investmentDetail?.data?.userHasVoted) return false

            const payload = {
                investment_id: investmentDetail.data._id,
                opportunity_id: item._id
            }
            const { data } = await AxiosInstance.post(
                `/investment/vote/${payload.investment_id}`,
                { opportunity_id: payload.opportunity_id });
            // console.log('data', data);
            toast.success("Voted successfully, thank you for voting!")
            await dispatch(investmentGetDetail())
        } catch (error) {
            customErrorMessage(error?.response?.data?.message || "Something went wrong!")
            console.error(error);
        } finally {
            e.target.innerHTML = 'Vote Now';
            e.target.disabled = false;
        }
    }


    // const createWallet = async () => {
    //     const providerUrl = "https://goerli.infura.io/v3/ac06866f794b47adb170e6bf8e6d2798";
    //     const web3 = new Web3(new Web3.providers.HttpProvider(providerUrl));
    //     const account = web3.eth.accounts.create();

    //     // Convert the account object to JSON
    //     const accountJson = JSON.stringify(account);
    //     // Create a Blob containing the JSON data
    //     const jsonFile = new Blob([accountJson], { type: 'application/json' });

    //     const ext = jsonFile.type.split("/");
    //     const dir = `Users/Wallet/${user._id}.${ext[1]}`;
    //     const data = await uploadToS3(jsonFile, dir);
    //     console.log('data', data)
    // }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("profile"))) {
            setIsKycCompleted(
                JSON.parse(localStorage.getItem("profile")).is_kyc_completed
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const activeStatus = (status) => {
        if (!investmentDetail.loading && investmentDetail.data) {
            if (status === investmentDetail.data.status) return 'active'
        }
        return ""
    }

    const getInvestmentByCategory = async () => {
        try {
            const { data } = await AxiosInstance.post("/investment/getcategorylist");
            setTotalInvestmentAmount(data.data.totalInvestmentAmount);
            setInvestmentList(data.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        dispatch(getInvestmentStats());
    }, [dispatch, getInvestmentStats])

    useEffect(() => {
        getInvestmentByCategory()
    }, [])


    return (
        <>
            <div className="mt-4 mb-4 page_main dashboard">
                <Container fluid>
                    <h1>
                        Members Collective Investment platform{" "}
                        <span className="font14Blk">
                            This dashboard illustrates a snapshot of all the important
                            information from our exclusive platform on one screen
                        </span>
                    </h1>
                    <Col md={12} lg={12}>
                        <div className="divBg_white mb-3">
                            {investmentDetail.loading ?
                                <div className="d-flex align-items-center justify-content-center py-5 my-5 h-100 w-100">
                                    <Spinner size="md" variant="dark" />
                                </div> :
                                investmentDetail?.data?.investment_Title ?
                                    <>
                                        <div className="row gap-5 justify-content-between investmentRoundStep">
                                            <div className="col-auto partLeft">
                                                <h3>{investmentDetail.data?.investment_Title || "-"}</h3>
                                                <div className="title">Platform activity stages:</div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-lg-0 px-3 partMiddle">
                                                <div className="progress-container">
                                                    <div className="progress" id="progress"></div>
                                                    <div className={`circle step1 ${activeStatus(status.marketing)}`}>1</div>
                                                    <div className={`circle step2 ${activeStatus(status.fund)}`}>2</div>
                                                    <div className={`circle step3 ${activeStatus(status.voting)}`}>3</div>
                                                    <div className={`circle step4 ${activeStatus(status.result)}`}>4</div>
                                                </div>
                                            </div>
                                            <div className="col-auto partRight">
                                                {/* Step Two */}
                                                {/* <Button onClick={createWallet} className="btn_Orange">Create Wallet</Button> */}
                                                {(investmentDetail.data?.status === status.fund) && (
                                                    <Button
                                                        disabled={_userDetails.loading}
                                                        onClick={() => {
                                                            if (Number(_userDetails?.data?.userWallet || 0) > 0) {
                                                                setInvestPopup(prev => {
                                                                    return { ...prev, show: true, investmentId: investmentDetail?.data?._id }
                                                                })
                                                            } else setAddBalancePopup(prev => ({ ...prev, show: true }))
                                                        }}
                                                        className="btn_Orange">
                                                        Invest Now
                                                    </Button>
                                                )}

                                                {/* Step Four */}
                                                {(investmentDetail.data?.status === status.result) && (
                                                    <div className="mx-auto text-center">
                                                        <Badge bg="" className="statusSuccess">
                                                            Success
                                                        </Badge>
                                                        <div className="font14ExLight text-center">
                                                            -
                                                        </div>
                                                        <div className="font16BlkBold text-center">{investmentDetail.data?.votedOpportunity?.investment_title}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="tableSM">
                                            <Table responsive striped borderless>
                                                <thead>
                                                    <tr>
                                                        <th>Title (Click on each row for more information)</th>
                                                        <th>Company</th>
                                                        <th>Category</th>
                                                        <th className="text-center">Seeking funds £</th>
                                                        <th className="text-center">Gross profit PA £</th>
                                                        <th className="text-center">Number of votes</th>
                                                        <th className="text-center">Risk factor</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {investmentDetail.data?.opportunities?.length === 0 ?
                                                        <tr className="text-center">
                                                            <td colSpan={8}>No Opportunities Found!</td>
                                                        </tr> :
                                                        investmentDetail.data?.opportunities?.map((item, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    {item.investment_title}
                                                                    <br />
                                                                    <span>Proposal created by {item.createdby}</span>
                                                                </td>
                                                                <td>{item.company_name}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {/* <span className="catgSymbol me-1">
                                                                            <img src={icCrypto} />
                                                                        </span> */}
                                                                        {item.investment_category}
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">30,000</td>
                                                                <td className="text-center">{item.gross_profit_yearly}</td>
                                                                <td className="text-center">{item?.totalVoting || 0}</td>
                                                                <td className="text-center">Medium</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <NavLink
                                                                            to={`/opportunity/detail/${item._id}`}
                                                                            className="btn_Black viewMore"
                                                                        >
                                                                            View More
                                                                        </NavLink>
                                                                        {(investmentDetail.data?.status === status.voting) &&
                                                                            <button
                                                                                disabled={investmentDetail?.data?.userHasVoted}
                                                                                onClick={e => handleVoteNow(e, item)}
                                                                                className="btn_Orange2 viewMore ms-2"
                                                                            >
                                                                                {investmentDetail?.data?.userHasVoted ?
                                                                                    "Voted" :
                                                                                    "Vote Now"}

                                                                            </button>}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    {/* <tr>
                                                    <td>
                                                        Property investment
                                                        <br />
                                                        <span>Proposal created by John Dao</span>
                                                    </td>
                                                    <td>ABCD</td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <span className="catgSymbol me-1">
                                                                <img src={icCrypto} />
                                                            </span>
                                                            Property
                                                        </div>
                                                    </td>
                                                    <td className="text-center">100,000</td>
                                                    <td className="text-center">XXX</td>
                                                    <td className="text-center">XXX</td>
                                                    <td className="text-center">High</td>
                                                    <td>
                                                        <NavLink
                                                            to="/asset-portfolio"
                                                            className="btn_Black viewMore"
                                                        >
                                                            View More
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Crypotocurrency market index Crypto-10 2<br />
                                                        <span>Proposal created by John Dao</span>
                                                    </td>
                                                    <td>Solana</td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <span className="propertySymbol me-1">
                                                                <img src={icProperty} />
                                                            </span>
                                                            Crypto
                                                        </div>
                                                    </td>
                                                    <td className="text-center">30,000</td>
                                                    <td className="text-center">XXX</td>
                                                    <td className="text-center">XXX</td>
                                                    <td className="text-center">Medium</td>
                                                    <td>
                                                        <NavLink
                                                            to="/asset-portfolio"
                                                            className="btn_Black viewMore"
                                                        >
                                                            View More
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Crypotocurrency market index Crypto-10 2<br />
                                                        <span>Proposal created by John Dao</span>
                                                    </td>
                                                    <td>Eth</td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <span className="catgSymbol me-1">
                                                                <img src={icCrypto} />
                                                            </span>
                                                            Crypto
                                                        </div>
                                                    </td>
                                                    <td className="text-center">30,000</td>
                                                    <td className="text-center">XXX</td>
                                                    <td className="text-center">XXX</td>
                                                    <td className="text-center">Low</td>
                                                    <td>
                                                        <NavLink
                                                            to="/asset-portfolio"
                                                            className="btn_Black viewMore"
                                                        >
                                                            View More
                                                        </NavLink>
                                                    </td>
                                                </tr> */}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </> : "No Investment Found!"
                            }
                        </div>
                    </Col>
                    {isKycCompleted === false ? (
                        <Row className="mb-4">
                            <Col md={12} lg={12}>
                                <div className="welcomeSection divBg_white">
                                    <h4 className="welcomeTitle mb-3">Welcome to Meridiem</h4>
                                    <span className="d-block mb-4">
                                        Verify your account to start investing
                                    </span>
                                    {/* <button type="button" className="btn_Orange verifyNow">
                                    Verify Now
                                </button> */}

                                    {/* <VerifyButton /> */}
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}

                    <Row>
                        <Col xxl={4} xl={6} lg={12} md={12} sm={12} className="mb-4">
                            <div className="assetPortfolio sectionItem divBg_white">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h6 className="heading">All Members Asset Portfolio</h6>
                                    <NavLink to="/asset-portfolio" className="btn_Black viewMore">
                                        View More
                                    </NavLink>
                                </div>
                                <div className="roi mb-3">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="title text-dark">ROI : </span>
                                            <span className="content">+£60,000 (+20%)</span>
                                        </div>
                                        <div className="d-flex flex-column align-items-end topRight">
                                            <span className="title">
                                                Total Investors : <span className="total">
                                                    {investmentStats?.data?.totalInvestment?.investors || 0}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center boxParent g-1">
                                    <div className="col-12 col-md-4">
                                        <div className="boxItemGray p-1">
                                            <span className="title d-block me-2">Total invested</span>
                                            <span className="content me-2">
                                                £{investmentStats?.data?.totalInvestment?.totalInvested || 0}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="boxItemGray p-1">
                                            <span className="title d-block me-2">
                                                Total investment rounds
                                            </span>
                                            <span className="content me-2">
                                                {investmentStats?.data?.totalInvestment?.investmentRounds || 0}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="boxItemGray p-1">
                                            <span className="title d-block me-2">Dividend yield</span>
                                            <span className="content me-2">
                                                £{investmentStats?.data?.totalInvestment?.dividendYield && Number(investmentStats?.data?.totalInvestment?.dividendYield).toFixed(2) || 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="tableSM">
                                    <Table responsive striped borderless className="mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Title</th>
                                                <th>Inv. Category</th>
                                                <th className="text-center">Inv. Amount £</th>
                                                <th className="text-center">Inv. %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {investmentList.length > 0 && (
                                                <>
                                                    {investmentList.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">{data.investment_Title}</td>
                                                                <td>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                        {/* <span className="catgSyme */}
                                                                        <span>{data._id}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">{data.investment_Amount}</td>
                                                                <td className="text-center">{Number((data.investment_Amount / totalInvestmentAmount) * 100).toFixed(2)}%</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>

                        <Col xxl={4} xl={6} lg={12} md={12} sm={12} className="mb-4">
                            <div className="myInvestment sectionItem divBg_white h-100">
                                <div className="d-flex flex-column justify-content-between h-100">
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <h6 className="heading">My Investment</h6>
                                            <NavLink to="/exit-investment" className="link12Blk">
                                                <svg
                                                    width="27"
                                                    height="25"
                                                    viewBox="0 0 27 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M26.9815 0H0.454346V24.5404H26.9815V0Z"
                                                        fill="black"
                                                    />
                                                    <path
                                                        d="M5.23853 3.37891L22.42 20.5604"
                                                        stroke="white"
                                                    />
                                                    <path
                                                        d="M22.42 3.37891L5.23853 20.5604"
                                                        stroke="white"
                                                    />
                                                </svg>
                                                <span className="ms-1">Exit inverstment rounds</span>
                                            </NavLink>
                                            <NavLink
                                                to="/my-investment"
                                                className="btn_Black viewMore"
                                            >
                                                View More
                                            </NavLink>
                                        </div>
                                        {/* <div className="d-flex justify-content-between align-items-end pb-2 mb-3 mCoin">
                                            <div className="d-flex align-items-center mb-1">
                                                <img src={mCoin} alt="m coin" className="me-2 mCoinImg" />
                                                <div>
                                                    <div className="title">Total Balance of M Coin</div>
                                                    <div className="content">5,00,000,000</div>
                                                </div>
                                            </div>
                                            <button href="#" className="btn_Orange addFund">
                                                Add Liquidity Funds
                                            </button>
                                        </div> */}
                                        <div className="roi mb-3">
                                            <span className="title text-dark">ROI : </span>
                                            <span className="content">+£1,000 (+10%)</span>
                                        </div>
                                        <div className="row text-center boxParent g-1">
                                            <div className="col-12 col-md-4">
                                                <div className="boxItemGolden p-1">
                                                    <span className="title d-block me-2">
                                                        Total invested
                                                    </span>
                                                    <span className="content me-2">
                                                        £{investmentStats?.data?.myInvestment?.totalInvested || 0}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="boxItemGolden p-1">
                                                    <span className="title d-block me-2">
                                                        Number of rounds
                                                    </span>
                                                    <span className="content me-2">
                                                        {investmentStats?.data?.myInvestment?.investmentRounds || 0}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="boxItemGolden p-1">
                                                    <span className="title d-block me-2">
                                                        Total dividend
                                                    </span>
                                                    <span className="content me-2">
                                                        £{Number(investmentStats?.data?.myInvestment?.dividendYield || 0).toFixed(2)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end mb-3 mt-2">
                                            <div className="col-12 col-xl-12 col-xxl-5">
                                                {/* <div className="font14LightBlk text-xl-left text-xxl-center mt-2">
                                                    You’re total dividend available
                                                </div>
                                                <div className="font14BoldBlk text-xl-left text-xxl-center mb-2">
                                                    £X,XX,XXX
                                                </div> */}

                                                <Nav.Item>
                                                    <Nav.Link
                                                        as={Link}
                                                        to="/my-dividends"
                                                        className="btn_Orange claimBtn w-auto d-inline-block text-center"
                                                    >
                                                        Dividend transaction history
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 font14BoldBlk mb-2">
                                            Peer to peer asset transfer
                                        </div>
                                        <div className="row m-0">
                                            <div className="col-auto p-0">
                                                <NavLink to={"/buy-assets"} className="btnBuy">
                                                    <svg
                                                        width="17"
                                                        height="14"
                                                        viewBox="0 0 17 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M1.26489 7H16.253" stroke="white" />
                                                        <path
                                                            d="M7.63842 12.841L1.26489 6.91254L7.63842 1"
                                                            stroke="white"
                                                        />
                                                    </svg>
                                                    Buy
                                                </NavLink>
                                            </div>
                                            <div className="col-auto p-0">
                                                <NavLink to={"/sell-assets"} className="btnSell">
                                                    Sell
                                                    <svg
                                                        width="17"
                                                        height="14"
                                                        viewBox="0 0 17 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M0.730469 6.79565H15.7185"
                                                            stroke="white"
                                                        />
                                                        <path
                                                            d="M9.34521 1L15.7186 6.91254L9.34521 12.841"
                                                            stroke="white"
                                                        />
                                                    </svg>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xxl={4} xl={6} lg={12} md={12} sm={12} className="mb-4">
                            <div className="myInvestment sectionItem divBg_white h-100">
                                <div className="d-flex flex-column justify-content-between h-100">
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <h6 className="heading">M Coin</h6>
                                            <NavLink to={"/m-coin-detail"} className="btn_Black viewMore">
                                                View More
                                            </NavLink>
                                        </div>

                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between align-items-end mb-2 mCoin">
                                                <div className="d-flex align-items-center mb-1">
                                                    <img
                                                        src={mCoin}
                                                        alt="m coin"
                                                        className="me-2 mCoinImg"
                                                    />
                                                    <div>
                                                        <div className="content">Community</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row text-center boxParent g-1">
                                                <div className="col-12 col-md">
                                                    <div className="boxItemGray p-1">
                                                        <span className="title d-block me-2">
                                                            Total in circulation
                                                        </span>
                                                        <span className="fontGreen me-2">
                                                            +{investmentStats?.data?.totalMCoin?.totalCirculation || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="boxItemGray p-1">
                                                        <span className="title d-block me-2">
                                                            Community
                                                            <br />
                                                            Ewallet
                                                        </span>
                                                        <span className="fontGreen me-2">
                                                            +£{investmentStats?.data?.totalMCoin?.communityEwallet || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="boxItemGray p-1">
                                                        <span className="title d-block me-2">
                                                            Income
                                                            <br />
                                                            per 1 M coin
                                                        </span>
                                                        <span className="content me-2">
                                                            £{investmentStats?.data?.totalMCoin?.incomePerMCoin || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="boxItemGray p-1">
                                                        <span className="title d-block me-2">
                                                            M Coin value
                                                        </span>
                                                        <span className="content me-2">
                                                            £{investmentStats?.data?.totalMCoin?.mCoinValue || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between align-items-end mb-2 mCoin">
                                                <div className="d-flex align-items-center mb-1">
                                                    <img
                                                        src={mCoin}
                                                        alt="m coin"
                                                        className="me-2 mCoinImg"
                                                    />
                                                    <div>
                                                        <div className="content">Personal ({user.name})</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row text-center boxParent g-1">
                                                <div className="col-12 col-md">
                                                    <div className="boxItemGray p-1">
                                                        <span className="title d-block me-2">Holdings</span>
                                                        <span className="fontGreen me-2">
                                                            +£{investmentStats?.data?.myMCoin?.holding || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="boxItemGray p-1">
                                                        <span className="title d-block me-2">Value</span>
                                                        <span className="fontGreen me-2">
                                                            +£{investmentStats?.data?.myMCoin?.value || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="boxItemGray p-1">
                                                        <span className="title d-block me-2">Income</span>
                                                        <span className="fontGreen me-2">
                                                            +£{investmentStats?.data?.myMCoin?.income || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 ">
                                                    <Nav.Item className="text-start">
                                                        <Nav.Link
                                                            as={Link}
                                                            to="/my-mcoins"
                                                            className="btn_Orange claimBtn me-1 w-auto d-inline-block text-center"
                                                        >
                                                            View Transaction history
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-between align-items-end mb-2 mCoin">
                                            <div className="d-flex align-items-center mb-1">
                                                <img
                                                    src={mCoin}
                                                    alt="m coin"
                                                    className="me-2 mCoinImg"
                                                />
                                                <div>
                                                    <div className="content">M Coins</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="col-auto p-0">
                                                <NavLink to={"/buy-coin"} className="btnBuy">
                                                    <svg
                                                        width="17"
                                                        height="14"
                                                        viewBox="0 0 17 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M1.26489 7H16.253" stroke="white" />
                                                        <path
                                                            d="M7.63842 12.841L1.26489 6.91254L7.63842 1"
                                                            stroke="white"
                                                        />
                                                    </svg>
                                                    Buy
                                                </NavLink>
                                            </div>
                                            <div className="col-auto p-0">
                                                <NavLink to={"/sell-coin"} className="btnSell">
                                                    Sell
                                                    <svg
                                                        width="17"
                                                        height="14"
                                                        viewBox="0 0 17 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M0.730469 6.79565H15.7185"
                                                            stroke="white"
                                                        />
                                                        <path
                                                            d="M9.34521 1L15.7186 6.91254L9.34521 12.841"
                                                            stroke="white"
                                                        />
                                                    </svg>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="mb-4">
                        <Col md={12} lg={12}>
                            <div className="hotProposals divBg_white">
                                <h5 className="mb-4">Hot Proposals</h5>
                                <div
                                    className="d-flex flex-column gap-2"
                                    onMouseEnter={(e) => {
                                        window.stop();
                                    }}
                                >
                                    <Marquee
                                        onMouseOut={(e) => this.start()}
                                        inverseMarqueeItems={false}
                                        marqueeItems={[...Array(6)].map((_, i) => (
                                            <div key={i} className="mb-2 items">
                                                <div className="d-flex justify-content-between flex-wrap align-items-center p-2 first">
                                                    <div className="title mx-2">
                                                        <span>
                                                            A Proposal for a Cryptocurrency Market Index
                                                            Crypto-10 2
                                                        </span>
                                                    </div>
                                                    <div className="same mx-2 text-center">
                                                        <span className="d-block">Amount</span>
                                                        <span>£30,000</span>
                                                    </div>
                                                    <div className="same mx-2 text-center">
                                                        <span className="d-block">Growth %</span>
                                                        <span>20%</span>
                                                    </div>
                                                    <div className="same mx-2 text-center">
                                                        <span className="d-block">Total Votes</span>
                                                        <span>550</span>
                                                    </div>
                                                    <div className="moreDetail mx-2">
                                                        <NavLink
                                                            to="/proposal-detail"
                                                            className="btn_Black"
                                                        >
                                                            View More Details
                                                        </NavLink>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between flex-wrap align-items-center bgLightBlue p-1 px-2 sec">
                                                    <span>Proposal Created by : John Dao</span>
                                                    <span>
                                                        Voting ends approximately July 30, 2022 01:01 PM
                                                        (GMT+1){" "}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    ></Marquee>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
                {/* ====== Claim Your Dividend modal ===== */}
                <Modal
                    show={claimDividend}
                    onHide={() => setClaimDividend(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="claimModal"
                >
                    <Modal.Header className="p-0 pt-1 mb-2" closeButton>
                        <div className="py-2 ps-4">
                            <Modal.Title id="contained-modal-title-vcenter">
                                Claim Your Dividend
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Form className="loginForm claimForm">
                        <Modal.Body>
                            <div className="col-7 mx-auto">
                                <Form.Group
                                    className="mb-3 inputOuter_"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="defLabel_">
                                        Amount <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Claim Your Amount"
                                        className="inputs defInput_"
                                    />
                                </Form.Group>
                                <div className="p-0 mb-4 fSize-12">
                                    Available to withdraw : <strong>500 BUSD</strong>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="">
                            <button
                                onClick={() => setClaimDividend(false)}
                                type="button"
                                className="btn_White"
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn_Black">
                                Claim
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
            <InvestNowPopup
                show={investPopup.show}
                onHide={() => setInvestPopup(initInvestPopupValue)}
                investmentId={investPopup.investmentId}
            />
            {/* ======= */}
            <AddBalancePopup
                show={addBalance.show}
                onHide={() => setAddBalancePopup(prev => ({ ...prev, show: false }))}
                getUserDetails={_getUserDetails}
                msg={<small>You don't have balance! Please Add Balance</small>}
                _setInvestPopup={() => setInvestPopup(prev => {
                    return { ...prev, show: true, investmentId: investmentDetail?.data?._id }
                })}
            />
        </>
    );
};

const InvestNowPopup = ({ show, onHide, investmentId }) => {
    const dispatch = useDispatch()
    const { _userDetails } = useSelector((state) => state.userStore);

    useEffect(() => {
        if (show) dispatch(_getUserDetails())
    }, [dispatch, show])

    const schema = Yup.object({
        amount: Yup
            .number()
            .test("A", "You don't have enough balance!", val => Number(val || 0) <= Number(_userDetails?.data?.userWallet || 0))
            .min(1, "Amount must be greater than 0")
            .nullable()
            .required("This field is required.")
    });

    const formik = useFormik({
        initialValues: {
            amount: "",
        },
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const { data } = await AxiosInstance.post(
                    `/investment/fund/${investmentId}`, { "amount": Number(values.amount) }
                );
                toast.success(data?.message || "Investmented successfully!")
                resetForm()
                onHide()
            } catch (error) {
                customErrorMessage(error?.response?.data?.message || error || "Something went wrong!")
                console.error('Error making payment:', error);
            }
        }
    });

    const handleClosePopup = () => {
        onHide()
        formik.resetForm()
    }

    return (
        <Modal
            show={show}
            onHide={handleClosePopup}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="claimModal"
        >
            <Modal.Header className="p-0 pt-1 mb-2" closeButton>
                <div className="py-2 ps-4">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Invest
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Form className="loginForm claimForm">
                <Modal.Body>
                    <div className="mx-auto">
                        <div className="d-flex align-items-end justify-content-end mb-4">
                            <h6 className="mb-0 fw-bold">Available Balance:</h6>
                            <h6 className="mb-0">
                                {_userDetails.loading ? "Loading..." : parseFloat(_userDetails?.data?.userWallet).toFixed(2) || "00"}
                            </h6>
                        </div>
                        <Form.Group
                            className="mb-3 inputOuter_"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="defLabel_">
                                Amount <span className="requireStar">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="0"
                                className="inputs defInput_"
                                name="amount"
                                value={formik.values.amount}
                                onChange={e => formik.setFieldValue("amount", handleNValue(e))}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched?.amount && formik.errors?.amount && (
                                <Form.Text className="text-danger">{formik.errors?.amount}</Form.Text>
                            )}
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer className="">
                    <button
                        onClick={handleClosePopup}
                        type="button"
                        className="btn_White"
                        disabled={formik.isSubmitting}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={formik.handleSubmit}
                        type="button"
                        className="btn_Black">
                        {formik.isSubmitting ? "Please wait..." : "Invest Now"}
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default Dashboard;
