import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import AxiosInstance from "./AxiosRequest";

export const uploadImageWithSignUrl = async (file, contentType, path, ext) => {
  const { data } = await AxiosInstance.post("/user/get-signed-url", {
    file: `${path}/${uuidv4()}.${ext}`,
    contentType: contentType,
    path: path
  });   
  const axiosInstance = axios.create();
  await axiosInstance.put(data.data.signedUrl, file, {
    headers: { "Content-Type": contentType },
  });

  return data.data.url;
};
