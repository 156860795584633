// import React from 'react';
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { Button, Modal } from "react-bootstrap";
import Header from "./common/Header";
import Footer from "./common/Footer";
// import { NavLink } from "react-router-dom";
import foodImg from "./assets/images/foodImg.png";
import beverageImg from "./assets/images/beverageImg.png";
import logoWhite from "./assets/images/logoWhite.png";
import logoBlkText from "./assets/images/logoBlkText.png";



const FoodAndBeverage = () => {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    const [showMenuModal, setShowMenuModal] = useState(false);
    const [showBeverageModal, setShowBeverageModal] = useState(false);
    

    return (
        <>
            <Header />

            <div className='middlePart'>
                <div className='FoodAndBeveragePart'>
                    <div className='container'>
                        <div className='FoodAndBeverageBG'>
                            <div className='p-2 p-md-5'>
                                <div className='mx-auto col-12 col-md-12 col-lg-8'>
                                    <h2 className='fontBlack34Bold text-center'>Food & Beverage</h2>
                                    <p className='font18Black text-center mb-5'>Please view our sample menu and drinks.<br />Some of our venue’s cater for breakfast, lunch and dinner.</p>
                                    <div className='position-relative mb-5 text-center'>
                                        <div className='menuAbs'>
                                            <Button variant="" className='siteBlackBtn menuBtn' onClick={()=>setShowMenuModal(true)}>MENU</Button>
                                        </div>
                                        <img src={foodImg} className="img-fluid" alt="foodImg" />
                                    </div>
                                    <div className='position-relative mb-5 text-center'>
                                        <div className='menuAbs'>
                                            <Button variant="" className='siteBlackBtn menuBtn2' onClick={()=>setShowBeverageModal(true)}>WINE AND<br />COCKTAIL LIST</Button>
                                        </div>
                                        <img src={beverageImg} className="img-fluid" alt="beverageImg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Last BlankPart */}
                <div className='lastBG'></div>
                
            </div>

        <Footer />

        {/* == Menu modal== */}
        <Modal show={showMenuModal} onHide={()=>setShowMenuModal(false)} centered size="lg" className="modalCustom">
            <div className="modalPadding">
                <Modal.Header className="redPattern">
                    <Modal.Title>
                        <div className="mb-3"><img src={logoWhite} alt="logoWhite" /></div>
                        <div>Food Menu</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="menuMain">
                        <div className="menuPart mb-4">
                            <div className="font15Blk text-center">Starters</div>
                            <div className="font15primary text-center">Prawns cocktail</div>
                            <p className="text-center">King prawns with cocktail dressing and baby gem lettuce</p>
                            <div className="font15primary text-center">Dip selection</div>
                            <p className="text-center">Lebanese flatbread with dips/Red pepper houmous, Aubergine & truffle,<br />Blue cheese Tomato bruschetta</p>
                            <p className="text-center">Chicken - chorizo skewers with caviar mayo</p>
                        </div>
                        
                        
                        <div className="menuPart mb-4">
                            <div className="font15Blk text-center">Mains</div>
                            <div className="font15primary text-center">Beef tagliatelle</div>
                            <p className="text-center">Sirloin steak with creamy red vine sauce</p>
                            <div className="font15primary text-center">Wagyu cheeseburger</div>
                            <p className="text-center">with homemade sauce, baby gem lettuce, beef tomato, red leicester cheese, rosemary fries</p>
                            <div className="font15primary text-center">Cajun chicken burger</div>
                            <p className="text-center">with smashed avocado, baby gem lettuce, beef tomato,red leicester cheese, rosemary fries</p>
                            <div className="font15primary text-center">Butternut squash and kale salad</div>
                            <p className="text-center mb-0">with blue cheese and cranberry dressing</p>
                        </div>

                        <div className="menuPart mb-4">
                            <div className="font15Blk text-center">Sides</div>
                            <p className="text-center mb-0">
                                Fries<br />Truffle fries<br />Olives<br />Padron peppers
                            </p>
                        </div>
                        <div className="menuPart mb-4">
                            <div className="font15Blk text-center">Desserts</div>
                            <p className="text-center mb-0">
                                Nutella cheesecake<br />Tiramisu<br />Cheese board<br />Charcuterie board
                            </p>
                        </div>
                        <div className=''>
                            <p className="mb-0" style={{textAlign: 'right'}}>
                                <small><strong>The displayed menu is subject to change. Please check with the restaurant directly.</strong></small>
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button variant="" className="p-0" onClick={()=>setShowMenuModal(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.569" height="32.569" viewBox="0 0 32.569 32.569">
                            <g id="Group_389" data-name="Group 389" transform="translate(-740.861 -1867.932)">
                                <line id="Line_171" data-name="Line 171" x2="31.155" y2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
                                <line id="Line_172" data-name="Line 172" y1="31.155" x2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
                            </g>
                        </svg>
                    </Button>                    
                </Modal.Footer>
                <div></div>
            </div>
        </Modal>

        {/* == Beverage Modal == */}
        <Modal show={showBeverageModal} onHide={()=>setShowBeverageModal(false)} centered size="lg" className="modalCustom">
            <div className="modalPadding">
                <Modal.Header className="goldenPattern">
                    <Modal.Title>
                        <div className="mb-3"><img src={logoBlkText} alt="logoBlkText" /></div>
                        <div>Cocktail Menu</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="menuMain">
                        <div className="menuPart mb-4">
                            <div className="font15Blk text-center">Short Cocktails</div>
                            <div className="font15Golden text-center">Espresso Tiramisu</div>
                            <p className="text-center">Rum, espresso, Amaretto, vanilla almond milk foam</p>
                            <div className="font15Golden text-center">Basil Smash</div>
                            <p className="text-center">Gin, homemade Basil Syrup, lemon juice</p>
                            <div className="font15Golden text-center">Paper Plane</div>
                            <p className="text-center">Bourbon, Nonino Amaro, Apperittini, lemon juice</p>
                        </div>
                        
                        <div className="menuPart mb-4">
                            <div className="font15Blk text-center">Spritz Cocktails</div>
                            <div className="font15Golden text-center">Classic Spritz</div>
                            <p className="text-center">Choose between Nonino Aperitivo or Amaro, Prosecco,<br />London Essence Grapefruit tonic</p>
                            <div className="font15Golden text-center">Pesca Spritz</div>
                            <p className="text-center">Sauvignon Blanc, Amaretto, London Essence White Peach<br />and Jasmine soda</p>
                            <div className="font15Golden text-center">Caribbean Fizz</div>
                            <p className="text-center">Four Square Spiced rum, lemon juice and London Essence<br />Roasted Pineapple soda</p>
                        </div>

                        <div className="menuPart mb-4">
                            <div className="font15Blk text-center">0° Cocktails (Non-alcoholic)</div>
                            <div className="font15Golden text-center">0° Café</div>
                            <p className="text-center">Mahala spirit, espresso, demerara syrup,<br />vanilla Almond Milk foam</p>
                            <div className="font15Golden text-center">0° Basil Collins</div>
                            <p className="text-center">Mahala spirit, homemade Basil Syrup, lemon juice,<br />London Essence soda</p>
                        </div>
                        
                    </div>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button variant="" className="p-0" onClick={()=>setShowBeverageModal(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.569" height="32.569" viewBox="0 0 32.569 32.569">
                            <g id="Group_389" data-name="Group 389" transform="translate(-740.861 -1867.932)">
                                <line id="Line_171" data-name="Line 171" x2="31.155" y2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
                                <line id="Line_172" data-name="Line 172" y1="31.155" x2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
                            </g>
                        </svg>
                    </Button>                    
                </Modal.Footer>
                <div></div>
            </div>
        </Modal>

            
        </>
    )
}

export default FoodAndBeverage