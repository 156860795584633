import React, { useState, useEffect } from "react";
import {
  Container,
  NavLink,
  Form,
  Button,
  Breadcrumb,
  Modal,
  Badge,
} from "react-bootstrap";
import Footer from "../common/Footer";
import Header from "../common/Header";
// import Logo from "../../assets/images/Logo.png";
import Book from "../../assets/images/Book.png";
import { Link } from "react-router-dom";

const McoinviewmorePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setalertMessage] = useState(false);

  return (
    <>
      <div className="mt-4 mb-4 page_main dashboard  ">
        <Container fluid>
          <h1>
            M Coin
            <span className="fWeight-300"> Hold, Buy and Sell</span>
          </h1>

          <div className="divBg_white mb-3 ">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>View more</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="d-flex justify-content-between align-items-start flex-wrap">
              <div className="d-flex flex-wrap col-xxl-8 col-xl-7 col-lg-6  col-md-12  mb-lg-0  mb-5">
                <div className="Viewmorecircle">
                  <img src={'Logo'} alt="Meridiem Logo" className="CenterLogo" />
                </div>
                <div className="col ms-5">
                  <h4 className="fWeight-700 fSize-22 me-5">
                    The Mcoin in the Collective Investment schemes{" "}
                  </h4>
                  <div className="me-5">
                    The Mcoin provides members the opportunity to benefit from
                    all potential
                    <br />
                    dividends derived from all investment rounds on the
                    platform.
                  </div>
                  <div className="mt-5">
                    The economics driving the Mcoin’s are the successful
                    investment rounds and the dividend from the assets. The
                    stages are explained below;
                  </div>
                </div>
              </div>

              <div className="d-flex col-xxl col-xl col-lg col-md-12 pt-3 pt-lg-0 ">
                <div
                  className="customrectange d-flex col-md-auto col-lg-auto me-5"
                  style={{ marginTop: "-48px" }}
                >
                  <div className="custombook me-3">
                    <img src={Book} alt="Meridiem Logo" />
                  </div>
                  <div className="fWeight-700 fSize-12">
                    <div>For a more detailed </div>
                    <div>overview please</div>
                    <div>download our brochure</div>
                  </div>
                </div>
                <div className="col-md-auto col-lg-auto">
                  <div>
                    <span className="largeblackbox me-3"></span>On blockchain
                  </div>
                  <div>
                    <span className="largeCyanbox me-3"></span>On blockchain
                  </div>
                  <div>
                    <span
                      className="smallblackbox"
                      style={{ marginRight: "2px" }}
                    ></span>
                    <span className="smallCyanbox me-3"></span>Both
                  </div>
                </div>
              </div>
            </div>

            <div className="divTable mt-5" style={{ border: "1px solid #fff" }}>
              <div className="divTableBody">
                <div className="divTableRow ">
                  <div className="divTableCell border-end-0 border-top-0 border-start-0 px-0 ">
                    <div className="me-5 " style={{ lineHeight: "8px" }}>
                      Stages
                    </div>
                    <div className="investmentNumMain"></div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0  border-start-0 text-center fw-bold px-0">
                    <div className="d-flex">
                      <span className="Mediumblackbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">1</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      Upcoming - Viewing the Opportunities
                    </div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0 ">
                    <div className="d-flex">
                      <span className="Mediumblackbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">2</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      {" "}
                      Collective investment raise{" "}
                    </div>
                  </div>

                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0 ">
                    <div className="d-flex">
                      <span className="Mediumblackbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">3</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      Collective voting on opportunities
                    </div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0">
                    <div className="d-flex">
                      <span className="Mediumblackbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">4</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      Voting ends
                    </div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0 ">
                    <div className="d-flex">
                      <span className="Mediumcyanbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">5</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      Negotiating and finalising investor terms on ownership
                    </div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0 ">
                    <div className="d-flex">
                      <span className="Mediumsmblackbox d-inline-block ms-2"></span>
                      <span className="Mediumsmcyanbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">6</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      Funds leave the treasury
                    </div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0 ">
                    <div className="d-flex">
                      <span className="Mediumsmblackbox d-inline-block ms-2"></span>
                      <span className="Mediumsmcyanbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">7</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      When the assets start to pay a yearly dividend
                    </div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0">
                    <div className="d-flex">
                      <span className="Mediumsmblackbox d-inline-block ms-2"></span>
                      <span className="Mediumsmcyanbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum active" >8</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      Mcoin Income
                    </div>
                  </div>
                  <div className="divTableCell border-end-0 border-top-0 text-center fw-bold px-0">
                    <div className="d-flex">
                      <span className="Mediumblackbox d-inline-block ms-2"></span>
                    </div>
                    <div className="investmentNumMain">
                      <div className="investmentNum">9</div>
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                      Buy and Sell
                    </div>
                  </div>
                </div>

                <div className="divTableRow fSize-13 ">
                  <div className="divTableCell border-bottom-0 border-end-0 border-start-0 ">

                    <div className="fSize-16 me-5">What happens</div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 border-start-0 ">
                    <div className="">
                      A list of{" "}
                      <span className="fw-bold">
                        investment opportunities are presented in the dashboard
                        for all members to view
                      </span>{" "}
                      Their financials, business plan and other relevant
                      information can be viewed and downloaded.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div>
                      <span className="fw-bold">
                        {" "}
                        Members can collectively deposit their contributions
                        into the investment treasury.
                      </span>{" "}
                      This process is done on-chain using a secure blockchain
                      platform called POLYGON. If the raise does not hit the
                      minimum target, contributions are returned to members and
                      the investment round does not move to the next stage.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div>
                      <span className="fw-bold">
                        Members who participated in the previous stage can cast
                        their votes
                      </span>{" "}
                      on which opportunity should receive the accumulated funds
                      in the investment treasury.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div>
                      <span className="fw-bold">
                        The majority vote will be displayed
                      </span>{" "}
                      and the negotiations will start in the next stage.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div>
                      <span className="fw-bold">
                        {" "}
                        Negotiations take place between the entity that won the
                        majority vote and the members who participated by
                        investing.
                      </span>
                      Meridiem will be managing the negotiations with a legal
                      team.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div>
                      Once terms are agreed,
                      <span className="fw-bold">
                        funds are pulled from the treasury to finalise the
                        purchase of the asset
                      </span>{" "}
                      When the funds are removed from the treasury, Mcoins are
                      minted and distributed to the members who participated in
                      the investment round. The amount of Mcoins each member
                      receives will be 5% of their original investment. So, if
                      they invested £100,000, they will receive 5000 Mcoins.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div className="fw-bold text-center mb-4">
                      <div className="fSize-24"> 95%</div> to Investors{" "}
                      <div className="fSize-24">5%* </div>to ecommunity wallet
                    </div>
                    <div>
                      In time the{" "}
                      <span className="fw-bold">
                        successful investment schemes will start to build an
                        investment portfolio for the members.
                      </span>{" "}
                      Once these assets start to pay a yearly dividend, 100% of
                      the dividend will be converted to stable coins onchain and
                      submitted to the blockchain. * Please note 5% of the
                      dividend only and not ownership. 100% ownership of the
                      asset or shareholding belongs to the investor. * The
                      income received maybe taxable.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div>
                      <sapn className="fw-bold">
                        {" "}
                        The dividend income accumulated in the ecommunity wallet
                        will be distributed to all Mcoinholders
                      </sapn>{" "}
                      each 01 of April. The higher number of Mcoins you hold the
                      higher share of the dividend you will receive. *the above
                      is subject to if the assets accumulate a dividend and if
                      there are funds in the ecommunity wallet.
                    </div>
                  </div>
                  <div className="divTableCell border-bottom-0 border-end-0 ">
                    <div>
                      <span className="fw-bold">
                        Members can trade the Mcoin on the platform
                      </span>{" "}
                      You can obtain quick liquidity by selling your Mcoins or
                      you can obtain the potential of a higher yearly income by
                      purchasing more Mcoins.{" "}
                      <sapn className="fw-bold">The valuation the Mcoin</sapn>{" "}
                      The Mcoins are valued at 2* the dividend total in the
                      ecommunity wallet/ total number of Mcoins in circulation.
                      It is valued every April and it holds its value every year
                      until the following April where it may adjust to higher or
                      lower depending on the amount held in the ecommunity
                      wallet.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default McoinviewmorePage;
