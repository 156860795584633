import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import LogoBig from "./assets/images/logoBig.png";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import url from "../../helpers/development.json";
import AxiosInstance from "../../helpers/AxiosRequest";
import { customErrorMessage, SendSuccessResponse } from "../../helpers/response";

const CreateNewPassword = () => {
    const [isLoading, ] = useState(false);
    const [validated, setValidated] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const { state } = useLocation();
    const navigate = useNavigate();
    const { token, id } = useParams();
    const [userData, setUserData] = useState({
        token: "",
        _id: ""
    });

    useEffect(()=> {
        if(token) {
            fetchUserData(token);
        }
        sessionStorage.removeItem("timer");
        sessionStorage.removeItem("resent");
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const fetchUserData = async (token) => {
        const response = await AxiosInstance.post(url.verifyLink, { token: token });
        if(response.status === 200 && response.data.statusText === "Success") {
            setUserData(response.data.data);
        } else {
            setErrorMessage("Invalid or expired link");
            customErrorMessage("Invalid or expired link!");
        }
    }

    const handleSubmit = async (event) => {
        setValidated(true);
        setErrorMessage("")
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const response = await AxiosInstance.post(url.createNewPassword, { token: userData.token, _id: userData._id, newPassword: confirmPassword });
            if(response.status === 200 && response.data.statusText === "Success") {
                SendSuccessResponse(response.data.message);
                navigate(`/login`);
            } else {
                customErrorMessage("Invalid or expired link!");
            }
        }
    };

    const validateInput = (event) => {
        setConfirmPassword(event.target.value);
        if(password === event.target.value) {
            setErrorMessage("");
        } else {
            setErrorMessage("Password and Confirm password Does not match");
        }
    }

    useEffect(() => {
        if(token && id) {
            setUserData({
                token: token,
                _id: id
            });
        }
        if(state && state._id && state.token) {
            setUserData({
                token: state.token,
                _id: state.id
            });
        }
    }, []);

    return (
        <>
            <div className="login_parent">
                <Container fluid>
                    <Row className="height100vh_">
                    <Col
                            lg={6}
                            md={12}
                            sm={12}
                            className="p-0 d-none d-md-none d-lg-block"
                        >
                            <div className="leftSlider">
                                <div className="logo text-center">
                                    <img src={LogoBig} alt="" />
                                </div>
                                <div className="d-flex align-itmes-center flex-column contentSlide">
                                    <div className="bottomContent text-center">
                                        <span>We are private members</span>
                                        <h6 className="mb-0">club in the heart of Holborn</h6>
                                        <p>With a great cocktail bar and exquisite menu</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="p-0">
                            <div className="rightForm">
                                <div className="d-flex flex-column justify-content-center col-10 col-xl-8 mx-auto h-100 gap80">
                                    <h4 className="loginTitle">Create New Password</h4>
                                    <div className="formGuide">
                                        Your new password must be different from previous used
                                        password.
                                    </div>
                                    <Form
                                        noValidate
                                        className="loginForm"
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form.Group
                                            className="mb-5 inputOuter_"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="defLabel_">
                                                Password <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="New Password"
                                                className="inputs defInput_"
                                                name="password"
                                                required
                                                value={password}
                                                onChange={(e)=> setPassword(e.target.value)}
                                                disable={errorMessage === "Invalid or expired link"}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-5 inputOuter_"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="defLabel_">
                                                Confirm Password <span className="requireStar">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Confirm Password"
                                                className="inputs defInput_"
                                                name="confirmPassword"
                                                required
                                                value={confirmPassword}
                                                onChange={ (e)=> validateInput(e) }
                                                disable={errorMessage === "Invalid or expired link"}
                                            />
                                            {errorMessage.length > 0 && errorMessage !== "Invalid or expired link" && (
                                                <span className="text-danger">
                                                    {errorMessage}
                                                </span>
                                            )}
                                        </Form.Group>
                                        <Button type="submit" className='btn_Black' disabled={errorMessage.length > 0}>
                                            {isLoading ? <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                            </>
                                                : ''
                                            }
                                            {isLoading ? 'loading...' : 'Submit'}
                                        </Button>

                                        <div className="text-center registerNow mt-4">
                                            <NavLink to="/" className="clrOrange_">
                                                Back to login
                                            </NavLink>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default CreateNewPassword;