// import React from 'react';
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { Button, Modal } from "react-bootstrap";
import Header from "./common/Header";
import Footer from "./common/Footer";
// import { NavLink } from "react-router-dom";
import mcoin1 from "./assets/images/mcoin-1.jpg";
import mcoin2 from "./assets/images/mcoin-2.jpg";
import mcoin3 from "./assets/images/mcoin-3.jpg";
import foodImg from "./assets/images/foodImg.png";
import beverageImg from "./assets/images/beverageImg.png";
import logoWhite from "./assets/images/logoWhite.png";
import logoBlkText from "./assets/images/logoBlkText.png";

const Mcoin = () => {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const [showMenuModal, setShowMenuModal] = useState(false);
    const [showBeverageModal, setShowBeverageModal] = useState(false);


    return (
        <>
            <Header />

            <div className='middlePart'>
                <div className='FoodAndBeveragePart'>
                    <div className='container'>
                        <div className='FoodAndBeverageBG'>
                            <div className='p-2 p-md-5'>
                                <div className='mx-auto col-12 col-md-12 col-lg-8'>
                                    <h2 className='fontBlack34Bold text-center'>Investment Platform & Mcoin</h2>
                                    <p className='font18Black text-center mb-5'>The Mcoin provides members the opportunity to benefit from all potential dividends derived from all collective investment rounds on the platform.<br />As an Mcoin holder you can receive a yearly income or buy and sells Mcoins.</p>
                                    <div className='position-relative mb-5 text-center'>
                                        <div className='menuAbs'>
                                            <Button variant="" className='siteBlackBtn menuBtn' onClick={() => setShowMenuModal(true)}>MCOIN</Button>
                                        </div>
                                        <img src={mcoin1} className="img-fluid" alt="foodImg" style={{ maxWidth: '600px', width: '100%', boxShadow: '0px -2px 10px #CCC' }} />
                                    </div>
                                    <div className='position-relative mb-5 text-center'>
                                        <div className='menuAbs'>
                                            <Button variant="" className='siteBlackBtn menuBtn2' onClick={() => setShowBeverageModal(true)}>click here for <br /> further information</Button>
                                        </div>
                                        <img src={mcoin2} className="img-fluid" alt="beverageImg" style={{ maxWidth: '600px', width: '100%', boxShadow: '0px -2px 10px #CCC' }} />
                                    </div>
                                    {/* <h2 className='font18Black fw-bold text-center mb-0'>The Mcoin</h2>
                                    <p className='font18Black text-center mb-2'>
                                        The Mcoin provides members the opportunity to benefit from all potential dividends derived from all collective investment rounds on the platform. As an Mcoin holder you can receive a yearly income or buy and sells Mcoins.
                                    </p>
                                    <p>
                                        The economics driving the Mcoin’s are the successful investment rounds and the
                                        dividend from the assets. Therefore, the Mcoins model will only truly be successful
                                        if the majority of companies or assets invested in are profitable. For further information please
                                        download our brochure.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Last BlankPart */}
                <div className='lastBG'></div>

            </div>

            <Footer />

            {/* == Menu modal== */}
            <Modal show={showMenuModal} onHide={() => setShowMenuModal(false)} centered size="lg" className="modalCustom">
                <div className="modalPadding">
                    <Modal.Header className="redPattern">
                        <Modal.Title>
                            <div className="mb-3"><img src={logoWhite} alt="logoWhite" /></div>
                            <div>MCOIN</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="menuMain">
                            <img src={mcoin1} className="img-fluid" alt="foodImg" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="px-0">
                        <Button variant="" className="p-0" onClick={() => setShowMenuModal(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.569" height="32.569" viewBox="0 0 32.569 32.569">
                                <g id="Group_389" data-name="Group 389" transform="translate(-740.861 -1867.932)">
                                    <line id="Line_171" data-name="Line 171" x2="31.155" y2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" />
                                    <line id="Line_172" data-name="Line 172" y1="31.155" x2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" />
                                </g>
                            </svg>
                        </Button>
                    </Modal.Footer>
                    <div></div>
                </div>
            </Modal>

            {/* == Beverage Modal == */}
            <Modal show={showBeverageModal} onHide={() => setShowBeverageModal(false)} centered size="lg" className="modalCustom modalFull" >
                <div className="modalPadding">
                    <Modal.Header className="goldenPattern">
                        <Modal.Title>
                            <div className="mb-3"><img src={logoBlkText} alt="logoBlkText" /></div>
                            <div>FURTHER INFORMATION</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="menuMain">
                            <img src={mcoin3} className="img-fluid" alt="foodImg" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="px-0">
                        <Button variant="" className="p-0" onClick={() => setShowBeverageModal(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.569" height="32.569" viewBox="0 0 32.569 32.569">
                                <g id="Group_389" data-name="Group 389" transform="translate(-740.861 -1867.932)">
                                    <line id="Line_171" data-name="Line 171" x2="31.155" y2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" />
                                    <line id="Line_172" data-name="Line 172" y1="31.155" x2="31.155" transform="translate(741.568 1868.639)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" />
                                </g>
                            </svg>
                        </Button>
                    </Modal.Footer>
                    <div></div>
                </div>
            </Modal>


        </>
    )
}

export default Mcoin