import { useFormik } from "formik";
import React, { useState } from "react";
import { Container, NavLink, Form, Breadcrumb, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { getAssetsList } from "../../store/user/action";
import { CategoryType } from "../../helpers/enum";
import _ from "lodash";
import AxiosInstance from "../../helpers/AxiosRequest";
import { customErrorMessage } from "../../helpers/response";
import { toast } from "react-toastify";

const schema = yup.object({
  category: yup.string().required("This field is required").nullable(),
  assets: yup.string().required("This field is required").nullable(),
  reason: yup.string().required("This field is required").nullable(),
});

const ExitinvestmentPage = () => {
  const dispatch = useDispatch();
  const [alertMessage, setalertMessage] = useState(false);
  const { assetsList } = useSelector((state) => state.userStore);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      category: "",
      assets: "",
      reason: "",
    },
    validationSchema: schema,
  });

  const submit = async () => {
    formik.setTouched({
      category: true,
      assets: true,
      reason: true,
    });
    const errors = await formik.validateForm();
    if (
      _.has(errors, "category") ||
      _.has(errors, "assets") ||
      _.has(errors, "reason")
    ) {
      return false;
    }
    setalertMessage(true)
  };

  const handleExitInvestment = async () => {
    try {
      const payload = {
        category_name: formik.values.category,
        investment_id: formik.values.assets,
        reason: formik.values.reason
      }
      setIsLoading(true)
      const { data } = await AxiosInstance.post(`/exitinvestment/create`, payload);
      formik.setFieldValue("reason", "");
      setalertMessage(false);
      toast.success(data?.message || "Asset Sell Successfully!");
      formik.resetForm();
    } catch (error) {
      customErrorMessage(error?.response?.data?.message || error || "Something went wrong!")
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = async (_field, _value) => {
    try {
      if (_field === "category") {
        dispatch(getAssetsList({ category: _value }));
        // ==== fields to reset ====
        await formik.setFieldValue(_field, _value);
        await formik.setFieldValue("assets", "");
      }
      if (_field === "assets") {
        await formik.setFieldValue(_field, _value);
        // ==== fields to reset ====
      }
    } catch (err) {}
  };

  return (
    <div className="mt-4 mb-4 page_main dashboard  ">
      <Container fluid>
        <h1>
          Exit Investment
          <span className="font14Blk"></span>
        </h1>

        <div className="divBg_white mb-3 ">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="breadcrumb-item" to={"/dashboard"}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Exit Investment</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 py-5 ">
              <Form className="pb-4 mb-4  formArea">
                <div className="row flex-column">
                  <div className="col-12">
                    <Form.Group className="mb-4 inputOuter_">
                      <Form.Label className=" defLabel_">
                        Choose Category
                      </Form.Label>
                      <Form.Select
                        name="category"
                        value={formik.values.category}
                        onChange={(e) =>
                          handleChange("category", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        className="defInput_"
                      >
                        {assetsList.loading ? (
                          <option value="" disabled selected>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="" disabled defaultValue>
                              Choose Category
                            </option>
                            {Object.values(CategoryType).map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Select>
                      {formik.touched.category && formik.errors.category ? (
                        <Form.Text className="text-danger">
                          {formik.errors.category}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-12">
                    <Form.Group className="mb-4 inputOuter_">
                      <Form.Label className=" defLabel_">
                        Choose Assets
                      </Form.Label>
                      <Form.Select
                        disabled={
                          formik.values.category === "" ||
                          assetsList.data.length === 0
                        }
                        name="assets"
                        value={formik.values.assets}
                        onChange={(e) => handleChange("assets", e.target.value)}
                        // onChange={e => {
                        //   const _amount = assetsList.data.length > 0 ? assetsList.data.find(item => item._id === e.target.value)?.payments?.amount || 0 : 0
                        //   formik.setFieldValue("assets", e.target.value)
                        //   formik.setFieldValue("_amount", _amount)
                        // }}
                        onBlur={formik.handleBlur}
                        className="defInput_"
                      >
                        {assetsList.loading ? (
                          <option value="" disabled selected>
                            Loading...
                          </option>
                        ) : assetsList.data.length === 0 ? (
                          <option value="" disabled selected>
                            No Assets Found!
                          </option>
                        ) : (
                          <>
                            <option value="" disabled selected>
                              Choose Assets
                            </option>
                            {assetsList.data.map((item, i) => (
                              <option key={i} value={item._id}>
                                {item.investment_Title}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Select>
                      {formik.touched.assets && formik.errors.assets ? (
                        <Form.Text className="text-danger">
                          {formik.errors.assets}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-12">
                    <Form.Group className="mb-4 inputOuter_">
                      <Form.Label className=" defLabel_">
                        Specify Reason
                      </Form.Label>

                      <Form.Control
                        type="text"
                        className="textarea"
                        as="textarea"
                        placeholder=""
                        row={5}
                        onBlur={formik.handleBlur}
                        name="reason"
                        onChange={e => formik.setFieldValue("reason", e.target.value)}
                      />
                      {formik.touched.reason && formik.errors.reason ? (
                        <Form.Text className="text-danger">
                          {formik.errors.reason}
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  </div>
                </div>

                <div className="d-inline-block ">
                  <NavLink
                    to={"/"}
                    onClick={submit}
                    className="btnSell btnBuySM"
                  >
                    Submit
                    <svg
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.730469 6.79565H15.7185" stroke="white" />
                      <path
                        d="M9.34521 1L15.7186 6.91254L9.34521 12.841"
                        stroke="white"
                      />
                    </svg>
                  </NavLink>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        show={alertMessage}
        onHide={() => setalertMessage(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalComman"
      >
        <Form className="">
          <Modal.Body>
            <div className="d-flex-coloum text-center">
              <h3 className="mt-5 mb-3">Are You Sure</h3>
              <h5 className="mb-4">
                you want to exit from this investment??
              </h5>
              <div className="mb-5">
                <button
                  type="button"
                  className="btn_White lm"
                  onClick={() => setalertMessage(false)}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn_Orange lm ms-2"
                  onClick={() => handleExitInvestment()}
                  disabled={isLoading}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </div>
  );
};

export default ExitinvestmentPage;
