import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"
import AxiosInstance from "../../helpers/AxiosRequest";
import url from "../../helpers/development.json";
import { customErrorMessage, SendSuccessResponse } from "../../helpers/response";
import { Link } from "react-router-dom";

const ReservationCancellation = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [, setData] = useState();
    const [error, setError] = useState();
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        getReservationDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getReservationDetails = async () => {
        try {
            const response = await AxiosInstance.get(url.getReservation + "/" + id);
            if (response.status === 200 && response.data.statusText === "Success") {
                setIsActive(response.data.data.isActive);
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    const handleSubmit = async () => {
        setError("");
        const response = await AxiosInstance.get(url.cancelReservation + "/" + id);
        console.log(response);
        // return false;
        setData(response.data.data);
        setIsActive(false);
        if (response.status === 200 && response.data.statusText === "Success") {
            SendSuccessResponse(response.data.message);
            navigate(`/login`);
        } else {
            customErrorMessage("");
        }
    }

    return (
        <>
            {!isActive ? <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <div className="p-5" style={{ background: "#fff" }}>
                    <h5 className="mb-4">You have already cancelled this reservation.</h5>
                    <div className="mb-5">
                        <Link to = "/" type="button" className="btn_White lm" style={{ marginLeft: 20 }}>Back to Home</Link>
                       
                    </div>
                </div>
            </div> :
                <div className="d-flex-coloum text-center d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                    <div className="p-5" style={{ background: "#fff" }}>
                        <h3 className="mt-5 mb-3">Confirm</h3>
                        <h5 className="mb-4">Are you sure you want to cancel your reservation?</h5>
                        <div className="mb-5">
                            <button type="button" className="btn_Orange lm ms-2" onClick={handleSubmit} disabled={error && error.length > 0}>
                                Yes
                            </button>

                            <Link to="/login" type="button" className="btn_White lm" style={{ marginLeft: 20 }}>
                                No
                            </Link>
                        </div>
                    </div>
                    Back to Home
                </div>
            }
        </>
    );
};

export default ReservationCancellation;
