import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout } from "antd";
import AxiosInstance from "../../helpers/AxiosRequest";
import { CoinsIcon, InvestmentIcon, UsersIcons } from "../common/Icons";
import ReactApexChart from 'react-apexcharts';

const { Content } = Layout;
const DashboardGraph = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [opportunities, setOpportunity] = useState({
    totalApprovedOpportunities: 0,
    totalOpportunities: 0,
    totalPendingOpportunities: 0,
    totalRejectedOpportunities: 0,
    _id: null,
  });
  const [totalInvestment, setTotalInvestment] = useState({
    dividendYield: 0,
    investmentRounds: 0,
    investors: 0,
    totalInvested: 0,
  });
  const [totalMCoin, setTotalMCoin] = useState({
    communityEwallet: 0,
    incomePerMCoin: 0,
    mCoinValue: 0,
    totalCirculation: 0,
  });
  const [totalExitInvestments, setToatalExitInvestments] = useState({
    _id: null,
    totalExitInvestment: 0,
    totalExitInvestmentAmount: 0,
  });

  // useEffect(() => {
  //   getInvestmentsCount();
  //   getMcoinCount();
  //   getOpportunitiesCount();
  //   getExitInvestmentsCount();
  // }, []);

  const getOpportunitiesCount = async () => {
    const { data } = await AxiosInstance.get("/admin/dashboard/opportunities");
    setOpportunity(data.data.opportunities);
    setTotalUsers(data.data.totalUsers);
  };

  const getInvestmentsCount = async () => {
    const { data } = await AxiosInstance.get("/admin/dashboard/investments");
    setTotalInvestment(data.data.totalInvestment);
  };

  const getMcoinCount = async () => {
    const { data } = await AxiosInstance.get("/admin/dashboard/mcoin");
    setTotalMCoin(data.data.totalMCoin);
  };

  const getExitInvestmentsCount = async () => {
    const { data } = await AxiosInstance.get("/admin/dashboard/exitInvestment");
    setToatalExitInvestments(data.data.totalExitInvestments);
  };

  // =======

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    colors: ['#c99e67'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'TOTAL USERS',
      align: 'left'
    },
    grid: {
      show: false, // This will remove the grid lines (background lines)
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    }
  });

  const [series, setSeries] = useState([{
    name: "",
    data: [10, 41, 35, 71, 49, 62, 100, 180, 250]
  }]);

  return (
    <Content className="site-layout-background">
      {/* <Breadcrumb
        style={{
          margin: "16px 0",
        }}
        items={[
          {
            title: "Home",
          },
        ]}
      ></Breadcrumb> */}
      <h3 className="mt-3 mb-5">
        <b>DASHBOARD</b>
      </h3>
      {/* <span>
      <svg
        width="234"
        height="93"
        viewBox="0 0 234 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 91.1427L26.148 71.9604C27.895 70.6278 30.3268 70.6629 32.0346 72.0454L50.3564 86.8774C52.9211 88.9536 56.7687 87.8567 57.8532 84.7403L85.8192 4.37047C87.2757 0.184833 93.1576 0.0816088 94.7601 4.21357L115.292 57.1575C116.7 60.788 121.633 61.2876 123.74 58.013L136.238 38.5914C138.203 35.539 142.722 35.717 144.441 38.9144L155.064 58.683C155.706 59.8783 156.828 60.743 158.148 61.06L176.955 65.5785C177.783 65.7774 178.543 66.1945 179.155 66.7864L203.01 89.8599C203.864 90.6852 204.994 91.1622 206.181 91.1974L233.251 92"
          stroke="#00C48C"
          strokeLinejoin="round"
        />
      </svg>
    </span> */}

      {/* ============== */}
      <div className="row mb-5">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <div className="box-title mb-2">TOTAL USERS</div>
          <div className="customCardBox" style={{ padding: '20px', boxSizing: 'border-box' }}>
            <div id="chart" style={{ maxWidth: '100%', height: 'auto' }}>
              <ReactApexChart options={options} series={series} type="line" height={250} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row mb-5">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <div className="box-title mb-2">INVESTMENTS</div>
          <div className="customCardBox">
            <div className="d-flex align-items-start justify-content-between">
              <div className="cardContentArea">
                <span className="_title">SUCCESSFUL INVESTMENTS</span>
                <span className="_number">50</span>
                <div className="d-flex align-items-center">
                  <span className="_percent text-danger me-2">-5.32%</span>
                  <span className="_duration">Since last year</span>
                </div>
              </div>
              <span className="_icon">
                <InvestmentIcon />
              </span>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <div className="box-title opacity-0 mb-2">.</div>
          <div className="customCardBox">
            <div className="d-flex align-items-start justify-content-between">
              <div className="cardContentArea">
                <span className="_title">TOTAL VALUE OF INVESTMENTS</span>
                <span className="_number">50</span>
                <div className="d-flex align-items-center">
                  <span className="_percent text-danger me-2">-7.02%</span>
                  <span className="_duration">Since last month</span>
                </div>
              </div>
              <span className="_icon">
                <InvestmentIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <div className="box-title mb-2">MCOIN</div>
          <div className="customCardBox">
            <div className="d-flex align-items-start justify-content-between">
              <div className="cardContentArea">
                <span className="_title">TOTAL MCOIN IN CIRCULATION</span>
                <span className="_number">10</span>
                <div className="d-flex align-items-center">
                  <span className="_percent text-success me-2">+6%</span>
                  <span className="_duration">Since last year</span>
                </div>
              </div>
              <span className="_icon">
                <CoinsIcon />
              </span>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <div className="box-title opacity-0 mb-2">.</div>
          <div className="customCardBox">
            <div className="d-flex align-items-start justify-content-between">
              <div className="cardContentArea">
                <span className="_title">TOTAL MCOIN E COMMUNITY WALLET</span>
                <span className="_number">25</span>
                <div className="d-flex align-items-center">
                  <span className="_percent text-danger me-2">-10.11%</span>
                  <span className="_duration">Since last year</span>
                </div>
              </div>
              <span className="_icon">
                <CoinsIcon />
              </span>
            </div>
          </div>
        </div>
      </div> */}
      {/* ============== */}


      {/* <div className="box-title mb-3">TOTAL USERS</div>
      <div className="row mb-5">
        <div className="col-md-2">
          <div className="info-box" style={{ marginBottom: "0rem" }}>
            <div className="info-box-content">
              <span className="info-box-text">Total Users</span>
              <span className="info-box-number">{totalUsers}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="box-title mb-3">TOTAL INVESTMENTS</div>
      <div className="row mb-5">
        <div className="col-12">
          <div className="">
            <div className="d-flex align-items-center justify-content-start mx-auto">
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">
                      Total Investment Rounds
                    </span>
                    <span className="info-box-number">
                      {totalInvestment.investmentRounds}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Invested</span>
                    <span className="info-box-number">
                      {totalInvestment.totalInvested}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Dividend</span>
                    <span className="info-box-number">
                      {totalInvestment.dividendYield}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Investors</span>
                    <span className="info-box-number">
                      {totalInvestment.investors}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-title mb-3">TOTAL OPPORTUNITIES</div>
      <div className="row mb-5">
        <div className="col-12">
          <div className="">
            <div className="d-flex align-items-center justify-content-start">
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Opportunities</span>
                    <span className="info-box-number">
                      {opportunities.totalOpportunities}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">
                      Total Approved Opportunities
                    </span>
                    <span className="info-box-number">
                      {opportunities.totalApprovedOpportunities}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">
                      Total Pending Opportunities
                    </span>
                    <span className="info-box-number">
                      {opportunities.totalPendingOpportunities}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">
                      Total Rejected Opportunities
                    </span>
                    <span className="info-box-number">
                      {opportunities.totalRejectedOpportunities}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-title mb-3">TOTAL MCOIN</div>
      <div className="row mb-5">
        <div className="col-12">
          <div className="">
            <div className="d-flex align-items-center justify-content-start mx-auto">
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Total In Circulation</span>
                    <span className="info-box-number">
                      {totalMCoin.totalCirculation}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Community Ewallet</span>
                    <span className="info-box-number">
                      {totalMCoin.communityEwallet}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Income Per Coin</span>
                    <span className="info-box-number">
                      {totalMCoin.incomePerMCoin}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">Total Coin Value</span>
                    <span className="info-box-number">
                      {totalMCoin.mCoinValue}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-title mb-3">TOTAL EXITINVESTMENT</div>
      <div className="row mb-5">
        <div className="col-12">
          <div className="">
            <div className="d-flex align-items-center justify-content-start mx-auto">
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">
                      Total Exit Investments
                    </span>
                    <span className="info-box-number">
                      {totalExitInvestments.totalExitInvestment}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 me-5">
                <div className="info-box" style={{ marginBottom: "0rem" }}>
                  <div className="info-box-content">
                    <span className="info-box-text">
                      Total Exit Investments Amount
                    </span>
                    <span className="info-box-number">
                      {totalExitInvestments.totalExitInvestmentAmount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Content >
  );
};

export default DashboardGraph;
