import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import { LinkdinIcon } from "./Icons";
// import Logo from "../../assets/images/logo.png";
import logoNew from "../../assets/images/logoNew.png";
import ReservationModal from "../publicPages/common/ReservatinModal";

function Footer() {

  const [reservationModal, setReservationModal] = useState(false);

  return (
    <footer className="m-0 p-0 footerLast">
      <Container style={{ padding: "0 15px" }} fluid>
        <div className="footerLogo row justify-content-between">
          
          <div className="col-12 col-sm-12 col-md-6 col-lg-auto mb-xl-0">
            <div className="mb-4"><img src={logoNew} alt=""/></div>
            <div className="footerSocial">
              <a title="Linkedln" href={process.env.REACT_APP_LINKEDIN_LINK} target="_blank">
                <LinkdinIcon />
              </a>
            </div>
            {/* <div className="mb-3">
              Subscribe for our newsletter to stay always update.
            </div> */}
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-auto mb-5 mb-xl-0">
            <ul className="footerNav">\<li className="text-uppercase">
                <NavLink to="/dashboard">DASHBOARD</NavLink>
              </li>
              {/* <li className="text-uppercase">
                <NavLink to="#">Connect Wallet</NavLink>
              </li>
              <li className="text-uppercase">
                <NavLink to="#">Club</NavLink>
              </li>
              <li className="text-uppercase">
                <NavLink to="#">Labour pool</NavLink>
              </li>
              <li className="text-uppercase">
                <NavLink to="#" onClick={() => setReservationModal(true)}>Reservations</NavLink>
              </li> */}
              <li className="text-uppercase">
                <NavLink to="/faq">FAQ</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
              </li>
              <li>
                <NavLink to="/termsconditions">TERMS & CONDITIONS</NavLink>
              </li>
            </ul>
          </div>
         
          <div className="col-12 col-sm-12 col-md-12 col-lg-auto mb-5 mb-xl-0">
            <div className="address text-start text-lg-end">
              MERIDIEM 18 PROCTER STREET LONDON, WC1V 6NX
            </div>
            <div className="text-start text-lg-end">
              <div>
                <a href="mailto:info@meridiem.club" className="footerlink" target="_blank" rel="noreferrer">info@meridiem.club</a>
              </div>
              <div className="footerlink">
                <NavLink to="/" className="footerlink">www.meridiem.club</NavLink>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {reservationModal && <ReservationModal reservationModal={reservationModal} setReservationModal= {setReservationModal} />}
    </footer>
  );
}

export default Footer;