import React, { useEffect } from 'react'
import { Navigate, Outlet, useOutlet } from 'react-router-dom';


const AdminAuth = () => {

    const { props: { children: { props: { children } } } } = useOutlet()

    useEffect(() => {
        document.title = children?.props?.title;
    }, [children?.props?.title]);

    const user = localStorage.getItem("adminAccessToken") ? true : false;
    return !user ? <Outlet /> : <Navigate to={"/admin/dashboard"} />
}

export default AdminAuth