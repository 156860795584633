import React, { useRef } from "react";
import { useState } from "react";
import { Container, Form, Breadcrumb } from "react-bootstrap";
import url from "../../helpers/development.json";
import AxiosInstance from "../../helpers/AxiosRequest";
import { customErrorMessage, successResponse } from "../../helpers/response";

const HelpPage = () => {
  const [values, setValues] = useState({});
  const [validated, setValidated] = useState(false);
  
  const formRef = useRef(null);

  const onFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const response = await AxiosInstance.post(url.helpAndSupport, {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.Description,
      });

      if (response.status === 200) {
        successResponse(response);
        formRef.current.reset();
        setValidated(true)
//        document.getElementById("helpForm").reset();

        setValidated(false);

        // setValues({
        //   name: "",
        //   email: "",
        //   subject: "",
        //   Description: ""
        // });

      } else {
        customErrorMessage("Something went wrong");
      }
    }
  };
console.log(values)
  return (
    <>
      <div className="mt-4 mb-4 page_main dashboard">
        <Container fluid>
          <h1>
            Support Form
            <span className="font14Blk ms-2">Hi, how can we help you?</span>
          </h1>

          <div className="divBg_white mb-3 ">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item href="/update-profile">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Help & Support</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-12 col-md-6 col-lg-3 py-5 ">
                <Form
                  className="pb-4 mb-4  formArea"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  ref={formRef}
                  // id="helpForm"
                >
                  <div className="row flex-column">
                    <div className="col-12 ">
                      <Form.Group className="mb-4 inputOuter_ ">
                        <Form.Label className="defLabel_">Your Name</Form.Label>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="Enter your name"
                          className="defInput_ "
                          required
                          onChange={onFormChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-12 ">
                      <Form.Group className="mb-4 inputOuter_ ">
                        <Form.Label className="defLabel_">Email Id</Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Enter your email id"
                          className="defInput_ "
                          required
                          onChange={onFormChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid email.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-12 ">
                      <Form.Group className="mb-4 inputOuter_ ">
                        <Form.Label className="defLabel_">
                          Your Question
                        </Form.Label>
                        <Form.Control
                          name="subject"
                          type="text"
                          placeholder="Your question"
                          className="defInput_ "
                          required
                          onChange={onFormChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid subject.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-12 ">
                      <Form.Group className="mb-4 inputOuter_">
                        <Form.Label className="defLabel_">Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          required
                          placeholder="Your message"
                          name="Description"
                          className="inputs textarea pt-3"
                          rows="8"
                          onChange={onFormChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid message.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn_Orange lm w-100">
                        SEND MESSAGE
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HelpPage;
