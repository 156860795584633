 export const CompanyType = {
  AppleInc: "Apple Inc",
  SaudiAramco: "Saudi Aramco",
  MicrosoftCorporation: "Microsoft Corporation",
  AmazoncomInc: "Amazon.com Inc",
  TeslaInc: "Tesla Inc",
  JohnsonJohnson: "Johnson & Johnson",
  ExxonMobilCorp: "Exxon Mobil Corp",
  WalmartInc: "Walmart Inc.",
};
  
export const CategoryType = {
  Stocks: "Stocks",
  Bonds: "Bonds",
  MutualFunds: "Mutual Funds",
  ExchangeTradedFunds: "Exchange-Traded Funds(ETFs)",
  CertificatesofDeposit: "Certificates of Deposit(CDs)",
  RetirementPlans: "Retirement Plans",
  Options: "Options",
  Annuities: "Annuities",
  Derivatives: "Derivatives",
  Commodities: "Commodities",
  PublicProvidentFund: "Public Provident Fund(PPF)",
};
  
 export const InvestmentType = {
  MultiCapFund: "Multi Cap-Fund",
  LargeCapFund: " Large Cap-Fund",
  LargeMidCapFund: "Large & Mid Cap Fund",
  MidCapFund: "Mid Cap Fund",
  SmallcapFund: "Small cap Fund",
  ValueFund: "Value Fund",
};

export const InvestorType  = {
  IndividualSophisticatedInvestor : "Individual Sophisticated Investor",
  HighNetWorthIndividual : "High Net Worth Individual",
  RetailInvestor : "Retail Investor",
  FamilyOffice : "Family Office"
}
  