import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import ReactIntlTelInput from "react-intl-tel-input-v2";
import url from "../../../helpers/development.json";
import AxiosInstance from "../../../helpers/AxiosRequest";
import { customErrorMessage, successResponse } from "../../../helpers/response";
import { useParams } from "react-router-dom";

const ReservationModal = ({ reservationModal, setReservationModal }) => {
  const [phoneNumber, setPhoneNumber] = useState({
    iso2: "gb",
    dialCode: "+44",
    phone: "",
  });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({});
  const [countryCode, setCountryCode] = useState();
  const [isNotifyAll, setIsNotifyAll] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [club, setClub] = useState("");
  const params = useParams();
  const [timeError , setTimeError] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const onFormChange = (e, updatedAt) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
    if (name === "from" || name === "to") {
        const error = validateTimes(values.from, values.to);
        setTimeError(error);
      }
  };
  const onReservationSubmit = async (data) => {
    setIsLoading(true);
    const response = await AxiosInstance.post(url.createReservation, { ...data, country_code: countryCode, phone_number: phoneNumber.phone });
    if(response.status === 200 && response.data.statusText === "Success") {
        setIsLoading(false);
        successResponse(response);
        setReservationModal(false);
        setIsLoading(false);
    } else {
        setIsLoading(false);
        customErrorMessage("Something went wrong!");
    }
}

const handleReservationSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      if (phoneNumber.phone === "" || phoneNumber.phone === null || phoneNumber.phone === undefined) {
        setPhoneNumberError("Please Provide a valid mobile number");
      }
      event.stopPropagation();
    } else {
      if (phoneNumber.phone === "" || phoneNumber.phone === null || phoneNumber.phone === undefined) {
        setPhoneNumberError("Please Provide a valid mobile number");
      } else {
        setPhoneNumberError("");
        const error = validateTimes(values.from, values.to);
        if (error !== null) {
          setErrorMessage(error);
        } else {
          let data = {
            user_id: params.id,
            number_of_guest: values.number_of_guest,
            email: values.email,
            booking_date: values.booking_date,
            club: club,
            phone_number: values.phone_number,
            isNotifyAll: isNotifyAll,
            from: values.from,
            to: values.to
          };
          await onReservationSubmit(data);
          setPhoneNumber("");
          setValidated(false);
          form.reset();
        }
      }
    }
  };
  

const handlePhoneNumber = (value, country) => {
    const regex = /^[0-9]*$/; 
    if(regex.test(value.phone) && value.phone.length <= 15) {
      setPhoneNumber(value);
      setCountryCode(value.dialCode);
      setPhoneNumberError('');
    } else {
      setPhoneNumberError('Please enter a valid phone number.');
    }
};

const validateTimes = (fromTime, toTime)  => {
    const fromDateTime = new Date(`2000-01-01T${fromTime}:00`);
    const toDateTime = new Date(`2000-01-01T${toTime}:00`);
    if (toDateTime <= fromDateTime) {
     return "End time must be after start time.";
    }
    return null;
}

  
const handleClub = (e) => {
    const value = e.target.value;
    setClub(value);
}

  
  return (
    <Modal
    show={reservationModal}
    onHide={() => { setReservationModal(false); setIsNotifyAll(false); setIsLoading(false); setPhoneNumber("") }}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="claimModal"
>
    <Modal.Header className="p-0 pt-1 mb-2" closeButton>
        <div className="py-2 ps-4 otpModel">
            <Modal.Title id="contained-modal-title-vcenter">
                Reservations
            </Modal.Title>
        </div>
    </Modal.Header>
    <Form className="loginForm claimForm"
                    noValidate
                    validated={validated}
                    onSubmit={handleReservationSubmit}
                >
                    <Modal.Body>
                        <div className="col-12 mx-auto">

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                        Club<span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Select aria-label="Default select example" name="club" className="inputs defInput_" required value={club} onChange={handleClub}>
                                            <option value="" selected>Select</option>
                                            <option value="HOLBORN">HOLBORN</option>
                                            <option value="FARRINGDON">FARRINGDON</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                            Booking Date<span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            id='date'
                                            type="date"
                                            name="booking_date"
                                            placeholder="01 Apr 2023 - 11:00:00 AM"
                                            className="inputs defInput_"
                                            min={new Date().toISOString().split("T")[0]}
                                            required
                                            onChange={onFormChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        Please select a date that is in the future.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                           From Time  <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            name="from"
                                            placeholder="Enter  your arrival time"
                                            className="inputs defInput_"
                                            required
                                            onChange={onFormChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid time.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                           To Time <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            name="to"
                                            placeholder=""
                                            className="inputs defInput_"
                                            required
                                            onChange={onFormChange}
                                            isInvalid={timeError !== null}
                                        />
                                        {timeError !== null && (
                                            <Form.Control.Feedback type="invalid">
                                                {timeError}
                                            </Form.Control.Feedback>
                                        )}                                    </Form.Group>
                                </div>

                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                            Number of Guest <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="number_of_guest"
                                            placeholder=""
                                            className="inputs defInput_"
                                            required
                                            pattern="[0-9]+"
                                            onChange={onFormChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Number Of guest.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                            Mobile Number <span className="requireStar">*</span>
                                        </Form.Label>
                                        <ReactIntlTelInput 
                                            className="mb-3 inputs defInput_ customIntlTel"
                                            value={ phoneNumber }
                                            required
                                            maxLength={15}  
                                            onChange={handlePhoneNumber}
                                        />
                                        {phoneNumberError.length > 0 && <span style={{marginTop: "0.25rem", fontSize: ".875em", color: "#dc3545"}} >                                                                        
                                            {phoneNumberError} 
                                        </span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-4 inputOuter_"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="defLabel_">
                                            Email ID <span className="requireStar">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Enter Email "
                                            className="inputs defInput_"
                                            required
                                            onChange={onFormChange}
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className="col-md-6 col-12">
                                    <Form.Group
                                        className="mb-2 d-flex align-items-center gap-1 mt-2"
                                        controlId="formBasicEmail"
                                    >
                                        <input
                                            type="checkbox"
                                            onClick={() => setIsNotifyAll(!isNotifyAll)}
                                            id="notification"
                                            style={{ height: "30px", width: "30px" }}
                                        />
                                        <label htmlFor="" className="fSize-14 fWeight-500 text-center">
                                            Notify other members
                                        </label>
                                    </Form.Group>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer className="">
                        <button
                            onClick={() => { setReservationModal(false); setIsNotifyAll(false); setIsLoading(false) } }
                             type="button"
                            className="btn_WhiteNew"
                        >
                            Cancel
                        </button>
                        <Button type="submit" className='btn_OrangeNew'  disabled= {(phoneNumberError.length > 0 ) || (errorMessage.length > 0 )}>                                
                            {isLoading ? <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2 ' />
                            </>
                                : ''
                            }
                            {isLoading ? 'loading...' : 'Reservations'}
                        </Button>


                    </Modal.Footer>
                </Form>
</Modal>
  );
};

export default ReservationModal;
