import React, { useState, useEffect } from "react";
import {
  Container,
  NavLink,
  Form,
  Button,
  Breadcrumb,
  Modal,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const Dividendtrans = () => {
  return (
    <>
      <div className="mt-4 mb-4 page_main dashboard  ">
        <Container fluid>
          <h1>
            Dividend - Transaction History
            <span className="font14Blk"></span>
          </h1>

          <div className="divBg_white mb-3 ">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Dividend</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="tableSM">
              <Table responsive striped borderless>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Category</th>
                    <th className="text-center">Assets Name</th>
                    <th className="text-center">Dividend Amount</th>
                    <th className="text-center">Dividend Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Share</td>
                    <td className="text-center">Facebook</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">10 June 2022</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Share</td>
                    <td className="text-center">Amazon</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">22 June 2022</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Share</td>
                    <td className="text-center">Facebook</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">10 June 2022</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Share</td>
                    <td className="text-center">Amazon</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">22 June 2022</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Share</td>
                    <td className="text-center">Facebook</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">10 June 2022</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Share</td>
                    <td className="text-center">Amazon</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">22 June 2022</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Share</td>
                    <td className="text-center">Facebook</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">10 June 2022</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Share</td>
                    <td className="text-center">Amazon</td>
                    <td className="text-center">10.00</td>
                    <td className="text-center">22 June 2022</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Container>

      </div>
    </>
  );
};

export default Dividendtrans;
