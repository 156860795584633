import React, { useEffect, useState } from "react";
import { Container, Row, Breadcrumb, Spinner } from "react-bootstrap";
import AxiosInstance from "../../../helpers/AxiosRequest";
import url from "../../../helpers/development.json";
import { Link, useParams } from "react-router-dom";
import { BitcoinIcon } from '../../common/Icons';
import "./opportunity.scss";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunityDetails } from "../../../store/user/action";


const DetailOpportunity = () => {

    const params = useParams();
    const dispatch = useDispatch()
    const { opportunityDetails } = useSelector((state) => state.userStore);
    console.log('opportunityDetails', opportunityDetails)

    useEffect(() => {
        if (params.id) {
            dispatch(getOpportunityDetails({ _id: params.id }))
        }

    }, [params.id]);

    return (
        <>
            <main className="mt-4 mb-4 page_main">
                <Container fluid>
                    <h1>Opportunity Details</h1>
                    <div className="divBg_white mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Opportunity Details</Breadcrumb.Item>
                        </Breadcrumb>

                        <Row className="opportunity-details">
                            <div className="col-xl col-lg-6 col-md col-sm-12 mb-4 section-left">
                                <div className='bg-dark-v1 p-4 h-100' style={{ backgroundColor: '#000000  !important' }}>
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                            <Spinner size="md" variant="light" />
                                        </div> :
                                        (
                                            <>
                                                <div className=" d-flex flex-column justify-content-between">
                                                    <div className='mb-5'>
                                                        <h4 className='title opportunity-title'>Investment title</h4>
                                                        <p className='text-white detail'>{opportunityDetails.data?.investment_title || "-"}</p>
                                                    </div>
                                                    <div className='mb-5'>
                                                        <h4 className='title opportunity-title'>Company name</h4>
                                                        <p className='text-white detail'>{opportunityDetails.data?.company_name || "-"}</p>
                                                    </div>
                                                    <div className='mb-5'>
                                                        <p className='text-white detail'>
                                                            {opportunityDetails.data?.company_website || "-"}
                                                        </p>
                                                    </div>
                                                    <div className='mb-5'>
                                                        <p className='text-white detail'>
                                                            {opportunityDetails.data?.UK_SEIS_EIS_Qualified ? 'UK SEIS/EIS Qualified' : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                {opportunityDetails.data?.featuresURL && (
                                                    <a href={opportunityDetails.data.featuresURL} target="_blank" rel='noopener noreferrer' className='mt-4 download-btn'>DOWNLOAD OPPORTUNITY DOCUMENTS</a>
                                                )}
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="col-xl col-lg-6 col-md col-sm-12 mb-4 d-flex flex-column justify-content-between">
                                <div className='d-flex justify-content-between align-items-center flex-row p-3 mb-4 bgGray investment-section'>
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> :
                                        (<>
                                            <div>
                                                <h4 className='title mb-5 text-dark investment-title'>Investment category</h4>
                                                <h3 className='mb-0 text-dark titleH3 investment-subtitle'>{opportunityDetails.data?.investment_category || "-"}</h3>
                                            </div>
                                            <div className='cryptoIconBg'>
                                                <BitcoinIcon className={"icon"} />
                                            </div>
                                        </>)}

                                </div>
                                <div className='d-flex justify-content-between align-items-center flex-row p-3 mb-4 bgGray investment-section opportunityImg-3'>
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <div>
                                                <h4 className='title mb-5 text-dark investment-title'>Highlight key features</h4>
                                                <h3 className='mb-0 text-dark titleH3 investment-subtitle'>{opportunityDetails.data?.highlight_key_features || "-"}</h3>
                                            </div>
                                        )}

                                </div>
                                <div className='p-3 bgGray investment-section-v1'>
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <>
                                                <div className='d-flex justify-content-between mb-4 w-100'>
                                                    <h4 className='title mb-0 text-dark investment-title'>Investment type</h4>
                                                    <h3 className='mb-0 text-dark titleH3 investment-subtitle'>{opportunityDetails.data?.investment_type || "-"}</h3>
                                                </div>
                                                <div className='mb-3 progressLine w-100'>
                                                    <span style={{ width: "33.33%", backgroundColor: "#000000" }}></span>
                                                    <span style={{ width: "33.33%", backgroundColor: "#E3614F" }}></span>
                                                    <span style={{ width: "33.33%", backgroundColor: "#000000" }}></span>
                                                </div>
                                                <h3 className='mb-0 text-dark text-end titleH3 investment-subtitle'>£2 to £10</h3>
                                                <h4 className='mb-0 text-dark text-end title investment-title'>(Capitalisation normally public)</h4>
                                            </>
                                        )}
                                </div>
                            </div>

                            <div className="col-xl col-lg-4 col-md col-sm-12 mb-4 d-flex flex-column justify-content-between">
                                <div className='d-flex flex-column justify-content-between p-3 bgCream'
                                >
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <>
                                                <h3 className='text-dark titleH3 customyearmargin'>Yearly</h3>
                                                <div>
                                                    <div className='d-flex justify-content-between mb-4 w-100'>
                                                        <h4 className='title text-dark statistics'>
                                                            Average <br />turnover
                                                        </h4>
                                                        <h3 className='text-dark titleH3'>£{opportunityDetails.data?.turnover_average_yearly || "-"}</h3>
                                                    </div>
                                                    <div className='d-flex justify-content-between w-100'>
                                                        <h4 className='mb-0 title text-dark statistics'>
                                                            Gross <br />profit
                                                        </h4>
                                                        <h3 className='mb-3 text-dark titleH3'>£{opportunityDetails.data?.gross_profit_yearly || "-"}</h3>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </div>
                                <div className='d-flex flex-column justify-content-between p-3  bgCream opportunityImg-1'>
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <>
                                                <h4 className='title text-dark statistics'>
                                                    How much are you looking to raise?
                                                </h4>
                                                <h3 className='mb-0 text-dark text-end titleH3'>£{opportunityDetails.data?.looking_to_raise || "-"}</h3>
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="col-xl col-lg-4 col-md col-sm-12 mb-4 d-flex flex-column justify-content-between ">
                                <div className='d-flex flex-column justify-content-between p-3 mb-4 bgCream opportunityImg-2'
                                >
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center py-3 h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <>
                                                <h4 className='title text-dark statistics mb-5'>
                                                    What benefits are investors receiving in return?
                                                </h4>
                                                <h3 className='mb-0 text-dark text-end titleH3'>{opportunityDetails.data?.receiving_in_return || "00"}%</h3>
                                            </>
                                        )}
                                </div>
                                <div className='d-flex flex-column justify-content-between p-3 mb-4 bgCream'
                                >
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center py-3 h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (<>
                                            <h4 className='title text-dark statistics'>
                                                Valuation asset
                                            </h4>
                                            <h3 className='mb-0 text-dark text-end titleH3'>£{opportunityDetails.data?.valuation_of_the_asset || "-"}</h3>
                                        </>)}
                                </div>
                                <div className='d-flex flex-column justify-content-between p-3 mb-4 bgCream'
                                >
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center py-3 h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <>
                                                <h4 className='title text-dark statistics'>
                                                    Include any financial information
                                                </h4>
                                                <p className='text-dark mb-0 detail'>
                                                    {opportunityDetails.data?.highlight_key_features || "-"}
                                                </p>
                                            </>
                                        )}
                                </div>
                                <div className='d-flex justify-content-between align-items-center flex-row p-3 bgCream'
                                >
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center py-3 h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <>
                                                <div>
                                                    <h4 className='mb-5 title text-dark statistics'>
                                                        Highlight key risks
                                                    </h4>
                                                    <p className='text-dark mb-0 detail'>{opportunityDetails.data?.please_highlight_key_risks || "-"}</p>
                                                </div>
                                                <span className='infoText'>!</span>
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md col-sm-12 mb-4 customminheight">
                                <div className='p-4 pb-0 h-100 bgLightCream opportunityImg-4'>
                                    {opportunityDetails.loading ?
                                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                            <Spinner size="md" variant="dark" />
                                        </div> : (
                                            <>
                                                <h4 className='mb-4 title text-dark statistics'>
                                                    Total votes
                                                </h4>
                                                <h1 className='my-5 text-center creamTriangleImg bigText '>
                                                    {opportunityDetails.data?.votingCount || "0"}
                                                </h1>
                                            </>
                                        )}
                                </div>
                            </div>
                        </Row>
                    </div>

                </Container>
            </main>
        </>
    )
}

export default DetailOpportunity;