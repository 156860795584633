import { useRef, useState, useEffect } from "react";
import { Container, Form, Breadcrumb, Spinner } from "react-bootstrap";
import url from "../../../helpers/development.json";
import AxiosInstance from "../../../helpers/AxiosRequest";
import { customErrorMessage, SendSuccessResponse } from "../../../helpers/response";
import { useNavigate } from "react-router-dom";
import { CompanyType, CategoryType, InvestmentType } from "../../../helpers/enum";
import ReactAutocomplete from "react-autocomplete";
import { uploadImageWithSignUrl } from "../../../helpers/UploadImageWiths3";

const CreateOpportunity = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({});
    const [file, setFile] = useState({});
    const [newfile, setNewfile] = useState({});
    const financialFile = useRef();
    const otherFile = useRef();
    const formRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [errMessage, setErrMessage] = useState("");
    const [userdata, setUserdata] = useState();
    const [timestamp1, setTimestamp1] = useState();
    const [timestamp2, setTimestamp2] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [numberErrMessage, setNumberErrMessage] = useState("");
    const [numberErrMessage2, setNumberErrMessage2] = useState("");
    const [numberErrMessage3, setNumberErrMessage3] = useState("");
    const [numberErrMessage4, setNumberErrMessage4] = useState("");
    const [numberErrMessage5, setNumberErrMessage5] = useState("");
    const [companywebsite, setCompanywebsite] = useState("");
    const [urlError, setUrlError] = useState("");
    const [qualifiedError, setQualifiedError] = useState(null);
    const [companyName, setCompanyName] = useState([{ company_name: '' }]);
    const [companyNameError, setCompanyNameError] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getUserDetails();
        setValues({
            ...values,
            company_name: "",
            investment_type: "Multi Cap-Fund",
            investment_category: "Stocks"
        })
        getopportunityCompany();
    }, []);


    const getopportunityCompany = async () => {
        try {
            const response = await AxiosInstance.get(url.opportunityCompany);
            if (response.status === 200 && response.data.statusText === "Success") {
                const companyData = response?.data?.data || [];
                const formattedCompanyData = companyData?.map((item) => ({ company_name: item?.company_name }));
                setCompanyName(formattedCompanyData);
            }
        } catch (error) {
            return error;
        }
    };

    const getUserDetails = async () => {
        try {
            const response = await AxiosInstance.get(url.getUserDetails);
            if (response.status === 200 && response.data.statusText === "Success") {
                setUserdata(response.data.data);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        } catch (error) {
            return error;
        }
    };

    const onFormChange = (e, updatedAt) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });
        if (name === "turnover_average_yearly" && isNaN(value)) {
            setNumberErrMessage("Please enter only numbers in this field.")
        } else {
            setNumberErrMessage("");
        }
        if (name === "gross_profit_yearly" && isNaN(value)) {
            setNumberErrMessage2("Please enter only numbers in this field.");
        } else {
            setNumberErrMessage2("");
        }
        if (name === "looking_to_raise" && isNaN(value)) {
            setNumberErrMessage3("Please enter only numbers in this field");
        } else {
            setNumberErrMessage3("");
        }
        if (name === "receiving_in_return" && isNaN(value)) {
            setNumberErrMessage4("Please enter only numbers in this field");
        } else {
            setNumberErrMessage4("");
        }
        if (name === "valuation_of_the_asset" && isNaN(value)) {
            setNumberErrMessage5("Please enter only numbers in this field");
        } else {
            setNumberErrMessage5("");
        }
        if (name === "same" && !value) {
            setQualifiedError("Please select any one choice")
        } else {
            setQualifiedError("")
        }
    };

    const onSelectCompany = (value) => {
        setValues({ ...values, company_name: value });
    };

    const handleurl = (e) => {
        const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        if (regex.test(e.target.value)) {
            setUrlError("");
            setCompanywebsite(e.target.value);
        } else {
            setCompanywebsite("");
            setUrlError("Please enter valide URL");
        }
    }

    const onReset = () => {
        formRef.current.reset();
        setFile({});
        setNewfile({});
        setIsLoading(false);
    };

    const onCheckboxChange = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setValues({ ...values, [name]: value });
    };

    const getLocation = async (params) => {
        // const { Location } = await s3.upload(params).promise();
        const url = await uploadImageWithSignUrl(params.Body, params.ContentType, params.path, params.ext);
        return url
    }

    const getLocation1 = async (params) => {
        // const { Location } = await s3.upload(params).promise();
        // return Location
        const url = await uploadImageWithSignUrl(params.Body, params.ContentType, params.path, params.ext);
        return url
    }

    const handleSubmit = async (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            if (values === "") {
                setErrorMessage("");
            }
            event.stopPropagation();
        } else {
            if (values === "" || values === null || values === undefined) {
                setErrorMessage("Please Provide all information");
                let isFormValid = true;
                console.log('values', values);
                if (values.company_name === "" || values.company_name === null || values.company_name === undefined) {
                    setCompanyNameError('Please provide a Company Name.');
                    isFormValid = false;
                }
                if (isNaN(values.turnover_average_yearly)) {
                    setNumberErrMessage("Please enter only numbers in this field.");
                    isFormValid = false;
                }
                if (isNaN(values.gross_profit_yearly)) {
                    setNumberErrMessage2("Please enter only numbers in this field.");
                    isFormValid = false;
                }
                if (isNaN(values.looking_to_raise)) {
                    setNumberErrMessage3("Please enter only numbers in this field.");
                    isFormValid = false;
                }
                if (isNaN(values.receiving_in_return)) {
                    setNumberErrMessage4("Please enter only numbers in this field.");
                    isFormValid = false;
                }
                if (isNaN(values.valuation_of_the_asset)) {
                    setNumberErrMessage5("Please enter only numbers in this field.");
                    isFormValid = false;
                }
                if (companywebsite === "" || companywebsite === null || companywebsite === undefined) {
                    setUrlError("Please enter valide URL.");
                    isFormValid = false;
                }
                if (values.same === "" || values.same === null || values.same === undefined) {
                    isFormValid = false;
                }
                if (!isFormValid) {
                    event.stopPropagation();
                    return;
                }
            } else {
                setIsLoading(true);
                const ext = file.name.substring(file.name.lastIndexOf(".") + 1);
                const contentType = 'application/octet-stream';
                const params = {
                    ext: ext,
                    path: "Opportunity",
                    Body: file,
                    ContentType: contentType,
                };
                const location = await getLocation(params);

                const ext1 = newfile.name.substring(newfile.name.lastIndexOf(".") + 1);
                const contentType1 = 'application/octet-stream';
                const params1 = {
                    ext: ext1,
                    path: "Opportunity",
                    Body: newfile,
                    ContentType: contentType1,
                };
                const location1 = await getLocation1(params1);
                setIsSubmitting(true);
                const response = await AxiosInstance.post(url.createOpportunity, {
                    investment_title: values.investment_title,
                    company_name: values.company_name,
                    investment_type: values.investment_type,
                    benifits: values.benifits,
                    createdby: userdata.name,
                    investment_category: values.investment_category,
                    turnover_average_yearly: values.turnover_average_yearly,
                    gross_profit_yearly: values.gross_profit_yearly,
                    looking_to_raise: values.looking_to_raise,
                    receiving_in_return: values.receiving_in_return,
                    valuation_of_the_asset: values.valuation_of_the_asset,
                    financial_info: values.financial_info,
                    please_highlight_key_risks: values.please_highlight_key_risks,
                    company_website: companywebsite,
                    highlight_key_features: values.highlight_key_features,
                    company_information: values.company_information,
                    UK_SEIS_EIS_Qualified: values.same === "Yes" ? true : false,
                    free_cashflow_reports: values.free_cashflow_reports,
                    yearly_percentage_growth: values.yearly_percentage_growth,
                    shares_outstanding: values.shares_outstanding,
                    annual_dividend: values.annual_dividend,
                    potential_income: values.potential_income,
                    UK_SEIS_EIS_HMRC: values.UK_SEIS_EIS_HMRC,
                    marketcap_in_$_or: values.marketcap_in_$_or,
                    average_ROE_return_on_equity: values.average_ROE_return_on_equity,
                    if_Bond_investment_income_potential:
                        values.if_Bond_investment_income_potential,
                    if_property_potential_asset_valuation:
                        values.if_property_potential_asset_valuation,
                    commodity_potential_price_increase:
                        values.commodity_potential_price_increase,
                    other: values.other,
                    featuresURL: location,
                    company_informationURL: location1,
                });

                if (response.status === 200 && response.data.statusText === "Success") {
                    setValues(response.data.data);
                    SendSuccessResponse("Opportunity created successfully!");
                    formRef.current.reset();
                    setFile({});
                    setNewfile({});
                    setValidated(false);
                    setIsLoading(false);
                    setIsSubmitting(false);
                    // navigate(`/opportunity/detail/${response.data.data._id}`);
                    navigate("/my-opportunities")
                    // window.location.reload();
                } else {
                    customErrorMessage("Something went wrong!");
                    setIsLoading(false);
                    setIsSubmitting(false);
                }
                setIsLoading(false);
            }
        }
    };

    const handleFileSelect = (e) => {
        financialFile.current.click();
        if (e.target.files) {
            const selectedFile = e.target.files[0];
            const fileSizeKB = selectedFile && selectedFile.size / 1024; // Size in KB
            const fileSizeMB = fileSizeKB / 1024; //Side in MB
            const allowedExtensions = /(\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
            if (!allowedExtensions.exec(e.target.files[0].name)) {
                setErrorMessage(
                    "Invalid file type. Please upload a PDF, DOC, DOCX,XLSX or XLS file."
                );
                setFile({});
            } else if (fileSizeMB > 5) {
                setErrorMessage("File size must be less then 5 MB");
                setFile({});
            } else if (fileSizeKB < 100) {
                setErrorMessage("File size must be more then 100 KB");
                setFile({});
            } else {
                setTimestamp1(new Date().getTime());
                setFile(e.target.files[0]);
                setErrorMessage("");
            }
        }
    };

    const handleSelectFile = (e) => {
        otherFile.current.click();
        if (e.target.files) {
            const selectedFile = e.target.files[0];
            const fileSizeKB = selectedFile && selectedFile.size / 1024; // Size in KB
            const fileSizeMB = fileSizeKB / 1024; //Side in MB
            const allowedExtensions = /(\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
            if (!allowedExtensions.exec(e.target.files[0].name)) {
                setErrMessage(
                    "Invalid file type. Please upload a PDF, DOC, DOCX,XLSX or XLS file."
                );
                setNewfile({});
            } else if (fileSizeMB > 5120) {
                setErrMessage("File size must be less then 5 MB");
                setNewfile({});
            } else if (fileSizeKB < 100) {
                setErrMessage("File size must be more then 100 KB");
                setNewfile({});
            } else {
                setTimestamp2(new Date().getTime());
                setNewfile(e.target.files[0]);
                setErrMessage("");
            }
        }
    };

    return (
        <>
            <main className="mt-4 mb-4 page_main">
                <Container fluid>
                    <h1>Create Opportunity</h1>

                    <div className="divBg_white p-3 mb-3">
                        <div>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>Create Opportunity</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Form
                            className="custForm"
                            validated={validated}
                            noValidate
                            onSubmit={handleSubmit}
                            ref={formRef}
                            autoComplete="off"
                        >
                            <div>
                                <div className="col-12 mx-auto">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Investment Title{" "}
                                                    <span className="requireStar">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Company Name or Other Title"
                                                    className=" custinput"
                                                    onChange={onFormChange}
                                                    name="investment_title"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a investment Title.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Company Name <span className="requireStar">*</span>
                                                </Form.Label>
                                                <ReactAutocomplete
                                                    items={companyName}
                                                    shouldItemRender={(item, value) =>
                                                        item.company_name.toLowerCase().indexOf(value.toLowerCase()) > -1
                                                    }
                                                    getItemValue={(item) => item.company_name}
                                                    renderItem={(item, highlighted) => (
                                                        <div
                                                            key={item.company_name}
                                                            style={{
                                                                backgroundColor: highlighted ? '#0a58ca' : 'transparent',
                                                                color: highlighted ? 'white' : "#212529",
                                                                fontWeight: '500 !important',
                                                                display: 'block',
                                                                whiteSpaceCollapse: 'collapse',
                                                                textWrap: ' nowrap',
                                                                minHeight: '1.2em',
                                                                // padding: '0.375rem 2.25rem 0.375rem',
                                                                fontSize: '14px',
                                                                paddingLeft: '23px'
                                                            }}
                                                        >
                                                            {item.company_name}
                                                        </div>
                                                    )}
                                                    value={values.company_name}
                                                    onChange={(e) => setValues({ ...values, company_name: e.target.value })}
                                                    onSelect={onSelectCompany}
                                                    wrapperStyle={false}
                                                    inputProps={{
                                                        placeholder: "Select Company Name",
                                                        style: {
                                                            display: 'block',
                                                            width: '100%',
                                                            fontSize: '14px !important',
                                                            outline: 'transparent',
                                                            height: '55px',
                                                            fontWeight: '400 !important',
                                                            paddingLeft: '23px',
                                                            border: '1px solid #000',
                                                            borderRadius: 0,
                                                            backgroundColor: '#fff !important'
                                                        },
                                                    }}
                                                    menuStyle={{
                                                        position: 'absolute',
                                                        zIndex: '9999',
                                                        borderRadius: '0%',
                                                        border: '1px solid #000',
                                                        display: 'block',
                                                        backgroundColor: '#fff',
                                                        top: 'calc(100% + 0px)',
                                                        left: 0,
                                                        maxHeight: '120px',
                                                        overflowY: 'auto',
                                                    }}
                                                />
                                                {/* <Form.Select
                                                    aria-label="Default select example"
                                                    className=" custinput"
                                                    onChange={onFormChange}
                                                    name="company_name"
                                                    required
                                                >
                                                    <option value="" disabled defaultValue>
                                                        {" "}
                                                        Select Company Name{" "}
                                                    </option>
                                                    {Object.values(CompanyType).map((type) => (
                                                        <option key={type} value={type}>
                                                            {type}
                                                        </option>
                                                    ))}
                                                </Form.Select> */}
                                                {companyNameError && (
                                                    <span className="text-danger">{companyNameError}</span>
                                                )}
                                                {/* <Form.Control.Feedback type="invalid" style={{ display: 'contents' }}>
                                                    Please provide a  Company Name.
                                                </Form.Control.Feedback> */}
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Investment Type <span className="requireStar">*</span>
                                                </Form.Label>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    className=" custinput"
                                                    name="investment_type"
                                                    onChange={onFormChange}
                                                    required
                                                >
                                                    <option value="" disabled defaultValue>
                                                        {" "}
                                                        Select Investment Type{" "}
                                                    </option>
                                                    {Object.values(InvestmentType).map((type) => (
                                                        <option key={type} value={type}>
                                                            {type}
                                                        </option>
                                                    ))}
                                                </Form.Select>

                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a investment type.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        {/* ================ */}
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Investment Category{" "}
                                                    <span className="requireStar">*</span>
                                                </Form.Label>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    className=" custinput"
                                                    onChange={onFormChange}
                                                    name="investment_category"
                                                    required
                                                >
                                                    <option value="" disabled defaultValue>
                                                        {" "}
                                                        Select Investment Category {" "}
                                                    </option>
                                                    {Object.values(CategoryType).map((type) => (
                                                        <option key={type} value={type}>
                                                            {type}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a Investment Category.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Turnover Average Yearly{" "}
                                                    <span className="requireStar">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="current or expected turnover if a start up value in $ or £ "
                                                    className=" custinput"
                                                    onChange={onFormChange}
                                                    name="turnover_average_yearly"
                                                    required
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {numberErrMessage.length > 0 && (
                                                    <span className="text-danger">{numberErrMessage}</span>
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a turnover average yearly".
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Gross Profit Yearly(Before Tax){" "}
                                                    <span className="requireStar">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Current or expected turnover if a start up before Tax value in £ or $"
                                                    className=" custinput"
                                                    name="gross_profit_yearly"
                                                    required
                                                    onChange={onFormChange}
                                                    pattern="[0-9]*"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {numberErrMessage2.length > 0 && (
                                                    <span className="text-danger">{numberErrMessage2}</span>
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a Gross Profit Yearly.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        {/* =============== */}
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    How much are you looking to raise?{" "}
                                                    <span className="requireStar">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Value in £ or $"
                                                    className=" custinput"
                                                    name="looking_to_raise"
                                                    required
                                                    onChange={onFormChange}
                                                    pattern="[0-9]*"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {numberErrMessage3.length > 0 && (
                                                    <span className="text-danger">{numberErrMessage3}</span>
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a looking to raise.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-xl-8 col-xxl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    What benefits are investors receiving in return?{" "}
                                                    <span className="requireStar">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="30% interest"
                                                    className=" custinput"
                                                    name="receiving_in_return"
                                                    required
                                                    onChange={onFormChange}
                                                    pattern="[0-9]*"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {numberErrMessage4.length > 0 && (
                                                    <span className="text-danger">{numberErrMessage4}</span>
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a investors receiving in return.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    What is the valuation of the asset?
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="$50,00,000"
                                                    className=" custinput"
                                                    name="valuation_of_the_asset"
                                                    required
                                                    pattern="[0-9]*"
                                                    onChange={onFormChange}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {numberErrMessage5.length > 0 && (
                                                    <span className="text-danger">{numberErrMessage5}</span>
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valuation of the asset.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        {/* =============== */}
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Please highlight key features
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Growth prospects, Past dividend pay-outs"
                                                    className=" custinput"
                                                    name="highlight_key_features"
                                                    required
                                                    onChange={onFormChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a highlight key features.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Please highlight key risks
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please highlight key risks"
                                                    className=" custinput"
                                                    name="please_highlight_key_risks"
                                                    required
                                                    onChange={onFormChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a highlight key risks.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Company Website
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="www.facebook.com"
                                                    className=" custinput"
                                                    required
                                                    onChange={handleurl}
                                                />
                                                {
                                                    urlError.length > 0 &&
                                                    <span style={{ marginTop: "0.25rem", fontSize: ".875em", color: "#dc3545" }}>
                                                        {urlError}
                                                    </span>
                                                }
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a company website.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        {/* =============== */}
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="custLabel">
                                                    Please include key financial information{" "}
                                                    <span className="requireStar">*</span>
                                                </Form.Label>

                                                <div id="file-upload-form" className="uploader w-100" style={{ maxWidth: "100%" }}>
                                                    <input
                                                        id="file-upload"
                                                        type="file"
                                                        accept=".pdf,.doc,.docx,.XLS,"
                                                        required
                                                        ref={financialFile}
                                                        onChange={handleFileSelect}
                                                    />
                                                    <label htmlFor="file-upload" id="file-drag">
                                                        {file?.name ? (
                                                            <div className="d-flex">
                                                                {file.name}
                                                                <div id="start">
                                                                    <svg
                                                                        width="47"
                                                                        height="34"
                                                                        viewBox="0 0 47 34"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M23.5002 0C19.8433 0 16.1667 1.36508 13.3786 4.09391C11.305 6.12336 10.0481 8.64049 9.51495 11.2657C4.10438 12.2928 0 16.9175 0 22.4995C0 28.8329 5.27865 33.9995 11.75 33.9995H36.7826C42.4166 33.9995 47 29.5136 47 23.9995C47 18.7955 42.9037 14.5594 37.7086 14.0933C37.7324 10.4738 36.4189 6.84693 33.6055 4.09337C30.8202 1.36669 27.1571 0 23.5002 0ZM23.5002 3.00016C26.3806 3.00016 29.2329 4.04819 31.4348 6.20327C33.9792 8.69353 35.0557 12.0765 34.6599 15.3122C34.605 15.7387 34.7393 16.1677 35.0294 16.4903C35.3195 16.8129 35.7371 16.9984 36.1761 16.9995H36.7826C40.7713 16.9995 43.9346 20.0955 43.9346 23.9993C43.9346 27.9031 40.7712 30.999 36.7826 30.999H11.75C6.93535 30.999 3.06533 27.211 3.06533 22.4992C3.06533 18.0492 6.523 14.4241 10.9518 14.0303C11.2992 13.9972 11.6246 13.8496 11.874 13.6107C12.1238 13.3722 12.2827 13.0572 12.3248 12.7182C12.6067 10.3406 13.6818 8.03074 15.5498 6.20245C17.7496 4.04987 20.6198 3.00016 23.5002 3.00016ZM23.5002 12.0001C22.9892 12.0001 22.7191 12.1609 22.4625 12.3907L16.8427 17.3907C16.1938 17.9801 16.2052 18.9368 16.7469 19.5158C17.2885 20.0947 18.3237 20.1366 18.9182 19.6096L21.9675 16.891V26.4998C21.9675 27.3282 22.6535 27.9999 23.5002 27.9999C24.3469 27.9999 25.0329 27.3282 25.0329 26.4998V16.891L28.0822 19.6096C28.6766 20.1369 29.6805 20.0655 30.2535 19.5158C30.8572 18.9368 30.7467 17.9237 30.1577 17.3907L24.5379 12.3907C24.2343 12.1183 24.0112 12.0001 23.5002 12.0001Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div id="start">
                                                                <svg
                                                                    width="47"
                                                                    height="34"
                                                                    viewBox="0 0 47 34"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M23.5002 0C19.8433 0 16.1667 1.36508 13.3786 4.09391C11.305 6.12336 10.0481 8.64049 9.51495 11.2657C4.10438 12.2928 0 16.9175 0 22.4995C0 28.8329 5.27865 33.9995 11.75 33.9995H36.7826C42.4166 33.9995 47 29.5136 47 23.9995C47 18.7955 42.9037 14.5594 37.7086 14.0933C37.7324 10.4738 36.4189 6.84693 33.6055 4.09337C30.8202 1.36669 27.1571 0 23.5002 0ZM23.5002 3.00016C26.3806 3.00016 29.2329 4.04819 31.4348 6.20327C33.9792 8.69353 35.0557 12.0765 34.6599 15.3122C34.605 15.7387 34.7393 16.1677 35.0294 16.4903C35.3195 16.8129 35.7371 16.9984 36.1761 16.9995H36.7826C40.7713 16.9995 43.9346 20.0955 43.9346 23.9993C43.9346 27.9031 40.7712 30.999 36.7826 30.999H11.75C6.93535 30.999 3.06533 27.211 3.06533 22.4992C3.06533 18.0492 6.523 14.4241 10.9518 14.0303C11.2992 13.9972 11.6246 13.8496 11.874 13.6107C12.1238 13.3722 12.2827 13.0572 12.3248 12.7182C12.6067 10.3406 13.6818 8.03074 15.5498 6.20245C17.7496 4.04987 20.6198 3.00016 23.5002 3.00016ZM23.5002 12.0001C22.9892 12.0001 22.7191 12.1609 22.4625 12.3907L16.8427 17.3907C16.1938 17.9801 16.2052 18.9368 16.7469 19.5158C17.2885 20.0947 18.3237 20.1366 18.9182 19.6096L21.9675 16.891V26.4998C21.9675 27.3282 22.6535 27.9999 23.5002 27.9999C24.3469 27.9999 25.0329 27.3282 25.0329 26.4998V16.891L28.0822 19.6096C28.6766 20.1369 29.6805 20.0655 30.2535 19.5158C30.8572 18.9368 30.7467 17.9237 30.1577 17.3907L24.5379 12.3907C24.2343 12.1183 24.0112 12.0001 23.5002 12.0001Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        )}
                                                    </label>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a financial information.
                                                    </Form.Control.Feedback>
                                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                                </div>

                                                <div className="fSize-10 mt-1">
                                                    Supported upload formats: 100KB min and 5MB max in .DOCX or .PDF or .XLS
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-6 col-12">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    name="free_cashflow_reports"
                                                                    onChange={onCheckboxChange}
                                                                />
                                                                <label>Free cashflow reports </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="yearly_percentage_growth"
                                                                />
                                                                <label>Yearly percentage growth</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="shares_outstanding"
                                                                />
                                                                <label> Shares outstanding</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="annual_dividend"
                                                                />
                                                                <label>Annual dividend yield in percentage</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="potential_income"
                                                                />
                                                                <label>Annual dividend potential income </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="UK_SEIS_EIS_HMRC"
                                                                />
                                                                <label>UK SEIS/EIS HMRC </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-6 col-12 mb-3">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="marketcap_in_$_or"
                                                                />
                                                                <label>Market cap in $ or £ </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="average_ROE_return_on_equity"
                                                                />
                                                                <label>Average ROE return on equity</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="if_Bond_investment_income_potential"
                                                                />
                                                                <label>
                                                                    {" "}
                                                                    If Bond investment- income potential (interest and
                                                                    capital repayment)
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="if_property_potential_asset_valuation"
                                                                />
                                                                <label>
                                                                    If property, potential asset valuation increase,
                                                                    potential rental income
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-0">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="commodity_potential_price_increase"
                                                                />
                                                                <label>Commodity- potential price increase.</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check checkboxlable mb-2">
                                                                <input
                                                                    className="form-check-input checkboxlable"
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onChange={onCheckboxChange}
                                                                    name="other"
                                                                />
                                                                <label>Other </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12 mb-3">


                                            <Form.Group className=" inputOuter_">
                                                <Form.Label className="form-label mb-0">
                                                    UK SEIS/EIS Qualified
                                                </Form.Label>
                                                <div className="">
                                                    <div className="radioInput  form-check-inline grayBG" >
                                                        <Form.Check className="form-control" onChange={onFormChange} name="same" required label="Yes" value="Yes" type='radio' style={{ border: "transparent" }} />
                                                    </div>
                                                    <div className="radioInput  form-check-inline grayBG">
                                                        <Form.Check className="form-control" onChange={onFormChange} name="same" required label="No" value="No" type='radio' style={{ border: "transparent" }} />
                                                    </div>

                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    Please select one option
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12 ">
                                            <Form.Group className=" inputOuter_" >
                                                <Form.Label className="custLabel" >
                                                    <label className=" customLable mb-0" >
                                                        Company Information
                                                        <span className="requireStar">*</span>
                                                    </label>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Please add information on founders, ceo, or team and any story behind the company or asset"
                                                    className="rounded-0 custinput customtextarea pt-3"
                                                    name="company_information"
                                                    required
                                                    onChange={onFormChange}
                                                    row={5}
                                                    col={5}
                                                    style={{ minHeight: "70px" }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a  Company Information.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 col-md-12 col-12">
                                            <Form.Group className=" inputOuter_ FE">
                                                <Form.Label className="custLabel">
                                                    File Upload
                                                    <span className="requireStar">*</span>
                                                </Form.Label>

                                                <div id="file-upload-form" className="uploader w-100" style={{ maxWidth: "100%" }}>
                                                    <input
                                                        id="new-file-upload"
                                                        type="file"
                                                        accept=".pdf,.doc,.docx,.XLS,"
                                                        required
                                                        ref={otherFile}
                                                        onChange={handleSelectFile}
                                                    />
                                                    <label htmlFor="new-file-upload" id="file-drag-1">
                                                        {newfile?.name ? (
                                                            <div className="d-flex">
                                                                {newfile.name}
                                                                <div id="start">
                                                                    <svg
                                                                        width="47"
                                                                        height="34"
                                                                        viewBox="0 0 47 34"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M23.5002 0C19.8433 0 16.1667 1.36508 13.3786 4.09391C11.305 6.12336 10.0481 8.64049 9.51495 11.2657C4.10438 12.2928 0 16.9175 0 22.4995C0 28.8329 5.27865 33.9995 11.75 33.9995H36.7826C42.4166 33.9995 47 29.5136 47 23.9995C47 18.7955 42.9037 14.5594 37.7086 14.0933C37.7324 10.4738 36.4189 6.84693 33.6055 4.09337C30.8202 1.36669 27.1571 0 23.5002 0ZM23.5002 3.00016C26.3806 3.00016 29.2329 4.04819 31.4348 6.20327C33.9792 8.69353 35.0557 12.0765 34.6599 15.3122C34.605 15.7387 34.7393 16.1677 35.0294 16.4903C35.3195 16.8129 35.7371 16.9984 36.1761 16.9995H36.7826C40.7713 16.9995 43.9346 20.0955 43.9346 23.9993C43.9346 27.9031 40.7712 30.999 36.7826 30.999H11.75C6.93535 30.999 3.06533 27.211 3.06533 22.4992C3.06533 18.0492 6.523 14.4241 10.9518 14.0303C11.2992 13.9972 11.6246 13.8496 11.874 13.6107C12.1238 13.3722 12.2827 13.0572 12.3248 12.7182C12.6067 10.3406 13.6818 8.03074 15.5498 6.20245C17.7496 4.04987 20.6198 3.00016 23.5002 3.00016ZM23.5002 12.0001C22.9892 12.0001 22.7191 12.1609 22.4625 12.3907L16.8427 17.3907C16.1938 17.9801 16.2052 18.9368 16.7469 19.5158C17.2885 20.0947 18.3237 20.1366 18.9182 19.6096L21.9675 16.891V26.4998C21.9675 27.3282 22.6535 27.9999 23.5002 27.9999C24.3469 27.9999 25.0329 27.3282 25.0329 26.4998V16.891L28.0822 19.6096C28.6766 20.1369 29.6805 20.0655 30.2535 19.5158C30.8572 18.9368 30.7467 17.9237 30.1577 17.3907L24.5379 12.3907C24.2343 12.1183 24.0112 12.0001 23.5002 12.0001Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div id="start">
                                                                <svg
                                                                    width="47"
                                                                    height="34"
                                                                    viewBox="0 0 47 34"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M23.5002 0C19.8433 0 16.1667 1.36508 13.3786 4.09391C11.305 6.12336 10.0481 8.64049 9.51495 11.2657C4.10438 12.2928 0 16.9175 0 22.4995C0 28.8329 5.27865 33.9995 11.75 33.9995H36.7826C42.4166 33.9995 47 29.5136 47 23.9995C47 18.7955 42.9037 14.5594 37.7086 14.0933C37.7324 10.4738 36.4189 6.84693 33.6055 4.09337C30.8202 1.36669 27.1571 0 23.5002 0ZM23.5002 3.00016C26.3806 3.00016 29.2329 4.04819 31.4348 6.20327C33.9792 8.69353 35.0557 12.0765 34.6599 15.3122C34.605 15.7387 34.7393 16.1677 35.0294 16.4903C35.3195 16.8129 35.7371 16.9984 36.1761 16.9995H36.7826C40.7713 16.9995 43.9346 20.0955 43.9346 23.9993C43.9346 27.9031 40.7712 30.999 36.7826 30.999H11.75C6.93535 30.999 3.06533 27.211 3.06533 22.4992C3.06533 18.0492 6.523 14.4241 10.9518 14.0303C11.2992 13.9972 11.6246 13.8496 11.874 13.6107C12.1238 13.3722 12.2827 13.0572 12.3248 12.7182C12.6067 10.3406 13.6818 8.03074 15.5498 6.20245C17.7496 4.04987 20.6198 3.00016 23.5002 3.00016ZM23.5002 12.0001C22.9892 12.0001 22.7191 12.1609 22.4625 12.3907L16.8427 17.3907C16.1938 17.9801 16.2052 18.9368 16.7469 19.5158C17.2885 20.0947 18.3237 20.1366 18.9182 19.6096L21.9675 16.891V26.4998C21.9675 27.3282 22.6535 27.9999 23.5002 27.9999C24.3469 27.9999 25.0329 27.3282 25.0329 26.4998V16.891L28.0822 19.6096C28.6766 20.1369 29.6805 20.0655 30.2535 19.5158C30.8572 18.9368 30.7467 17.9237 30.1577 17.3907L24.5379 12.3907C24.2343 12.1183 24.0112 12.0001 23.5002 12.0001Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                                <div className="mt-2">
                                                                    Upload, pitch deck, business plan, or any useful
                                                                    information
                                                                </div>
                                                            </div>
                                                        )}
                                                    </label>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a File.
                                                    </Form.Control.Feedback>
                                                    {errMessage && <p style={{ color: 'red' }}>{errMessage}</p>}
                                                </div>

                                                <div className="fSize-10 mt-1">
                                                    Supported upload formats: 100KB min and 5MB max in .DOCX or .PDF or .XLS
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <button type="button" className="btn_Black2 mb-2 me-2" onClick={onReset}>
                                    Reset
                                </button>
                                <button
                                    type="submit"
                                    className="btn_Orange"
                                    id="sign-in-button"
                                    disabled={isSubmitting || isLoading || urlError || numberErrMessage || numberErrMessage2 || numberErrMessage3 || numberErrMessage4 || numberErrMessage5}
                                >
                                    {isLoading ?
                                        <>
                                            <Spinner size="sm" className='me-2' />
                                        </> :
                                        ''
                                    }
                                    Create Opportunity
                                </button>
                            </div>
                        </Form>
                    </div>
                </Container>
            </main>
        </>
    );
};

export default CreateOpportunity;
