import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  Form,
  Breadcrumb,
  Modal,
  Table,
} from "react-bootstrap";
import icCrypto from "../../assets/images/icCrypto.png";
import icShares from "../../assets/images/icShares.png";
import { Link } from "react-router-dom";
import AxiosInstance from "../../helpers/AxiosRequest";
import { customErrorMessage } from "../../helpers/response";
import { toast } from "react-toastify";
import moment from "moment";
import { Badge } from "antd";

const ExitinvestmentlistPage = () => {
  const exitInvestmentStatusClass = {
    Approved: "statusSuccess",
    Rejected: "statusReject",
    Exit: "BadgeclassVoting",
    Voting: "BadgeclassVoting",
    Live: "BadgeclassVoting",
  };
  const [list, setList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const limit = 10;
  const totalPages = useMemo(() => {
    return Math.ceil(totalDataCount / limit || 1);
  }, [totalDataCount, limit]);
  const [voteMdoal, setVoteMdoal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const currentDate = moment.utc().format();
  const [count, setCount] = useState();

  useEffect(() => {
    if (selectedRow) {
      getCountById();
    }
  }, [selectedRow]);

  const handleList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post("/exitinvestment/list", {
        page: page,
        limit: limit,
      });
      setList(data.data.data);
      setTotalDataCount(data.data.metadata.total);
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  }, [page, limit]);

  const getCountById = async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post(
        "/exitinvestmentvote/countVotesById",
        {
          investment_id: selectedRow.investment_id,
          exit_investment_id: selectedRow._id,
        }
      );
      setCount(data.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleVote = async (status) => {
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post("/exitinvestmentvote/create", {
        exit_investment_id: selectedRow._id,
        investment_id: selectedRow.investment_id,
        status: status,
      });
      handleList();
      setVoteMdoal(false);
      toast.success(data?.message || "Asset Sell Successfully!");
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleList();
  }, [handleList]);

  useEffect(() => {
    if (count && voteMdoal) {
      const meter1 = document.getElementById("progress--circle1");

      const rangeValue1 = (count.approve_count / count.total_user) * 100;

      meter1.style.strokeDashoffset = 100 - rangeValue1;

      if (rangeValue1 === "0") {
        meter1.style.stroke = "none";
      } else {
        meter1.style.stroke = "#009444";
      }

      const meter2 = document.getElementById("progress--circle2");

      const rangeValue2 = (count.reject_count / count.total_user) * 100;

      meter2.style.strokeDashoffset = 100 - rangeValue2;

      if (rangeValue2 === "0") {
        meter2.style.stroke = "none";
      } else {
        meter2.style.stroke = "#FF0000";
      }
    }
  }, [count]);

  return (
    <div className="mt-4 mb-4 page_main dashboard  ">
      <Container fluid>
        <h1>
          Exit Investment List
          <span className="font14Blk"></span>
        </h1>

        <div className="divBg_white mb-3 ">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="breadcrumb-item" to={"/dashboard"}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Exit Investment List</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {list.length > 0 &&
            list.map((data, index) => {
              return (
                <div className="tableSM" key={index}>
                  <Table responsive striped borderless>
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>Category</th>
                        <th className="text-center">Valuation £</th>
                        <th className="text-center">Equity</th>
                        <th className="text-center">Benefits</th>
                        <th className="text-center">Gross profit PA £</th>
                        <th className="text-center">Number of votes</th>
                        <th className="text-center">Dividend yield PA £</th>
                        <th className="text-center">Risk factor</th>
                        <th>Reason</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {data?.opportunities?.company_name}
                          <br />
                          <span>Proposal created by {data.user.name}</span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            <span className="sharesSymbol me-1">
                              <img src={icShares} alt="Shares" />
                            </span>
                            <span>{data?.category_name}</span>
                          </div>
                        </td>
                        <td className="text-center">XXX</td>
                        <td className="text-center">XXX</td>
                        <td className="text-center">XXX</td>
                        <td className="text-center">XXX</td>
                        <td className="text-center">{data.no_of_votes || 0}</td>
                        <td className="text-center">XXX</td>
                        <td className="text-center">XXX</td>
                        <td>
                          <span>{data.reason}</span>
                        </td>
                        <td>
                          <div className="">
                            {currentDate >=
                              moment(data.voting_start_date).utc().format() &&
                            currentDate <=
                              moment(data.voting_end_date).utc().format() &&
                            data.status === "Live" ? (
                              <>
                                {data.status}
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCount();
                                    setSelectedRow(data);
                                    setVoteMdoal(true);
                                  }}
                                  className="btn_Orange claimBtn me-1 btnvoteSM"
                                >
                                  Vote Now
                                </button>
                              </>
                            ) : (
                              <Badge
                                className={`${
                                  exitInvestmentStatusClass[data?.status]
                                }`}
                                color=""
                              >
                                {data?.status}
                              </Badge>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              );
            })}
        </div>
      </Container>
      {voteMdoal && selectedRow && (
        <Modal
          show={voteMdoal && selectedRow}
          onHide={() => setVoteMdoal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modalComman"
        >
          <Form className="">
            <Modal.Header closeButton>Voting</Modal.Header>
            <Modal.Body>
              <div className="mb-4">
                <span>{selectedRow?.opportunities?.company_name}</span>
                <br />
                <span>Proposal created by {selectedRow.user.name}</span>
                <br />
                <div>
                  Voting ends approximately{" "}
                  {moment(selectedRow.voting_end_date).format(
                    "MMMM DD, YYYY hh:mm A"
                  )}{" "}
                </div>
              </div>
              <div className="d-flex justify-content-between voteProgress mb-3">
                <div className="approveDiv d-flex flex-column">
                  <div id="progress-bar--container1">
                    <svg viewBox="0 0 100 100">
                      <circle
                        stroke="#EAEAEA"
                        stroke-width="5"
                        cx="50"
                        cy="50"
                        r="30"
                        fill="none"
                      />
                      <circle
                        id="progress--circle1"
                        stroke="#28411B"
                        stroke-width="5"
                        cx="50"
                        cy="50"
                        r="30"
                        fill="none"
                        pathLength="100"
                      />
                      <text
                        id="progress--text1"
                        x="50"
                        y="48"
                        text-anchor="middle"
                        dominant-baseline="middle"
                      >
                        {/* {count && count.approve_count} */}
                        APPROVE
                      </text>
                      <text
                        id="progress--text2"
                        class="progress--text--second"
                        x="50"
                        y="55"
                        text-anchor="middle"
                        dominant-baseline="middle"
                      >
                        {count && count.approve_count}
                      </text>
                    </svg>
                  </div>
                  <button
                    type="button"
                    className="btn_White lm"
                    onClick={() => handleVote("Approved")}
                    disabled={isLoading || !count}
                  >
                    Vote For Approve
                  </button>
                </div>
                <div className="rejectDiv d-flex flex-column">
                  <div id="progress-bar--container2">
                    <svg viewBox="0 0 100 100">
                      <circle
                        stroke="#EAEAEA"
                        stroke-width="5"
                        cx="50"
                        cy="50"
                        r="30"
                        fill="none"
                      />
                      <circle
                        id="progress--circle2"
                        stroke="#28411B"
                        stroke-width="5"
                        cx="50"
                        cy="50"
                        r="30"
                        fill="none"
                        pathLength="100"
                      />
                      <text
                        id="progress--text1"
                        x="50"
                        y="48"
                        text-anchor="middle"
                        dominant-baseline="middle"
                      >
                        REJECT
                      </text>
                      <text
                        id="progress--text2"
                        class="progress--text--second"
                        x="50"
                        y="55"
                        text-anchor="middle"
                        dominant-baseline="middle"
                      >
                        {count && count.reject_count}
                      </text>
                    </svg>
                  </div>
                  <button
                    type="button"
                    className="btn_Orange lm ms-2"
                    onClick={() => handleVote("Rejected")}
                    disabled={isLoading || !count}
                  >
                    Vote For Reject
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default ExitinvestmentlistPage;
