import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  Form,
  Breadcrumb,
  Modal,
  Table,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AxiosInstance from "../../helpers/AxiosRequest";
import { toast } from "react-toastify";
import { customErrorMessage } from "../../helpers/response";
import PaginationComponent from "../common/PaginationComponent";

const BuycoinPage = () => {
  const [list, setList] = useState({})
  const [alertMessage, setalertMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const limit = 10;
  const totalPages = useMemo(() => {
    return Math.ceil((totalDataCount / limit) || 1)
  }, [totalDataCount, limit]);


  const handleBuyAndCancel = async (event, id) => {
    try {
      setIsLoading(true)
      const { data } = await AxiosInstance.get(`/mcoin/${event === "Buy" ? "buy" : "cancel"}/${id}`);
      handleList()
      toast.success(data?.message || "Coin Sell Successfully!")
    } catch (error) {
      customErrorMessage(error?.response?.data?.message || error || "Something went wrong!")
    } finally {
      setIsLoading(false)
    }
  }

  const handleList = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await AxiosInstance.post("mcoin/list", {
        page: page,
        limit: limit,
      });
      setList(data.data.data);
      setTotalDataCount(data.data.metadata.total);
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
    } finally {
      setIsLoading(false)
    }
  }, [page, limit]);

  useEffect(() => {
    handleList()
  }, [handleList])

  return (
    <div className="mt-4 mb-4 page_main dashboard  ">
      <Container fluid>
        <h1>
          Buy - M Coin
          <span className="font14Blk"></span>
        </h1>

        <div className="divBg_white mb-3 ">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="breadcrumb-item" to={"/dashboard"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Buy  M Coin</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {list.length === undefined ? <div className="d-flex align-items-center justify-content-center py-5 my-5 h-100 w-100">
            <Spinner size="md" variant="dark" />
          </div> :
            <div className="tableSM">
              <Table responsive striped borderless>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Seller Name</th>
                    <th className="text-center">No. of M Coin</th>
                    <th className="text-center">Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.length > 0 && list.map((data, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{data.user.name} </td>
                        <td className="text-center">{data.coins}</td>
                        <td className="text-center">{data.total_price}</td>
                        <td>
                          <div className="d-inline-block float-end p-0">
                            {!data.currentUser ? <button onClick={() => handleBuyAndCancel("Buy", data._id)} className="btnBuy btnBuySM">
                              {!data.currentUser && <svg
                                width="17"
                                height="14"
                                viewBox="0 0 17 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1.26489 7H16.253" stroke="white" />
                                <path
                                  d="M7.63842 12.841L1.26489 6.91254L7.63842 1"
                                  stroke="white"
                                />
                              </svg>}
                              {data.currentUser ? "Cancel" : "Buy"}
                            </button> :
                              <button onClick={() => handleBuyAndCancel("Cancel", data._id)} className="btnCancel btnBuySM">
                                Cancel
                              </button>}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              <PaginationComponent
                selectedPage={page}
                handlePageChange={p => setPage(p)}
                totalPages={totalPages}
              />
            </div>}
        </div>
        {isLoading && <div className="d-flex align-items-center justify-content-center h-100 w-100" style={{ zIndex: '999', width: '100%', position: 'fixed', top: '0', left: '0', backgroundColor: '#4f4f4f85' }}>
          <Spinner size="md" variant="light" />
        </div>}
      </Container>
      <Modal
        show={alertMessage}
        onHide={() => setalertMessage(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalComman"
      >
        <Form className="">
          <Modal.Body>
            <div className="d-flex-coloum text-center">
              <h3 className="mt-5 mb-3">Are You Sure</h3>
              <h5 className="mb-4">
                you want to sell <span>10%</span> at this amount?
              </h5>
              <div className="mb-5">
                <button
                  type="button"
                  className="btn_White lm"
                  onClick={() => setalertMessage(false)}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn_Orange lm ms-2"
                  onClick={() => setalertMessage(false)}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </div>
  );
};

export default BuycoinPage;
