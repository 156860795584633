import { useEffect } from 'react';
import Master from '../pages/common/Master';
import { Navigate } from "react-router-dom";
import AdminMaster from "../pages/admin/layout/Main";
import AdminAuth from "../pages/admin/layout/AdminAuth";

// Public Pages
import LandingPage from "../pages/publicPages/LandingPage";
import Login from "../pages/publicPages/Login";
import Signup from "../pages/publicPages/Signup";
import ErrorPage from "../pages/publicPages/ErrorPage";
import ForgotPassword from "../pages/publicPages/ForgotPassword";
import CheckEmail from "../pages/publicPages/CheckEmail";
import CreateNewPassword from "../pages/publicPages/CreateNewPassword";
import FoodAndBeverage from "../pages/publicPages/FoodAndBeverage";
import Mcoin from "../pages/publicPages/Mcoin";
import MembershipPage from "../pages/publicPages/MembershipPage";
import Farringdon from "../pages/publicPages/Farringdon";
import Holborn from "../pages/publicPages/Holborn";
import Enquire from "../pages/publicPages/Enquire";
import TermsAndConditions from "../pages/publicPages/common/TermsAndConditions";
import PrivacyPolicy from "../pages/publicPages/common/PrivacyPolicy";
import ReservationCancellation from "../pages/publicPages/Cancel";

// Protected Pages
import UpdateProfile from "../pages/protectedPages/UpdateProfile";
import SellcoinPage from "../pages/protectedPages/SellcoinPage";
import SellassetsPage from "../pages/protectedPages/SellassetsPage";
import ExitinvestmentPage from "../pages/protectedPages/ExitinvestmentPage";
import BuyassetsPage from "../pages/protectedPages/BuyassetsPage";
import BuycoinPage from "../pages/protectedPages/BuycoinPage";
import ExitinvestmentlistPage from "../pages/protectedPages/ExitinvestmentlistPage";
import MembershipDetail from "../pages/protectedPages/MembershipDetail";
import Faq1 from "../pages/protectedPages/Faq";
import HelpPage from "../pages/protectedPages/Help";
import CreateOpportunity from "../pages/protectedPages/opportunity/CreateOpportunity";
import DetailOpportunity from "../pages/protectedPages/opportunity/DetailOpportunity";
import NewTermsPage from "../pages/protectedPages/NewTermsPage";
import OpayoResponseHandler from "../pages/publicPages/OpayoResponseHandler";
import OpayoFailureHandler from "../pages/publicPages/OpayoFailureHandler";
import MembershipForm from "../pages/publicPages/MembershipForm";
import ErrorPageAdmin from "../pages/admin/ErrorPage";
import Dashboard from '../pages/protectedPages/dashboard/Dashboard';
import MyInvestment from '../pages/protectedPages/MyInvestment';
import Dividendtrans from '../pages/protectedPages/Dividendtrans';
import McoinviewmorePage from '../pages/protectedPages/McoinviewmorePage';
import AssetPortfolio from '../pages/protectedPages/AssetPortfolio';

import { publicRoutes, privateRoutes } from "./adminRoutes";
import MyOpportunities from '../pages/protectedPages/opportunity/MyOpportunities';
import MCoinList from '../pages/protectedPages/mCoin/MCoinList';
import DividendList from '../pages/protectedPages/mCoin/DividendList';
import ExitInvestmentAmountList from '../pages/protectedPages/ExitInvestmentAmountList';


const PublicRoute = ({ component: Component, title }) => {
    const isLogin = localStorage.getItem("accessToken") || false;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return !isLogin ? <>
        <Component />
    </> :
        <Navigate to="/dashboard" />
}

const PrivateRoute = ({ component: Component, title }) => {
    const isLogin = localStorage.getItem("accessToken") || false;
    return isLogin ?
        <Master ptitle={title}><Component /></Master> :
        <Navigate to="/login" />
}

const routes = () => {
    return [
        // Protected Pages
        {
            path: "/update-profile",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Profile" component={UpdateProfile} />
        },
        {
            path: "/buy-coin",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Buy Coin" component={BuycoinPage} />
        },
        {
            path: "/sell-coin",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Sell Coin" component={SellcoinPage} />
        },
        {
            path: "/buy-assets",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Buy Assets" component={BuyassetsPage} />
        },
        {
            path: "/sell-assets",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Sell Assets" component={SellassetsPage} />
        },
        {
            path: "/my-investment",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - My Investment" component={MyInvestment} />
        },
        {
            path: "/exit-investment",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Exit Investment" component={ExitinvestmentPage} />
        },
        {
            path: "/exit-investment-list",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Exit Investments" component={ExitinvestmentlistPage} />
        },
        {
            path: "/membership-detail",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Membership Detail" component={MembershipDetail} />
        },
        {
            path: "/help&support",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Help & Support" component={HelpPage} />
        },
        {
            path: "/create-opportunity",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Create Opportunity" component={CreateOpportunity} />
        },
        {
            path: "/opportunity/detail/:id",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Opportunity Detail" component={DetailOpportunity} />
        },
        {
            path: "/my-opportunities",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - My Opportunities" component={MyOpportunities} />
        },
        {
            path: "/termsconditions",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Terms and Conditions" component={NewTermsPage} />
        },
        {
            path: "/dashboard",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Dashboard" component={Dashboard} />
        },
        {
            path: "/dividend-transactions-history",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Dividend Transactions" component={Dividendtrans} />
        },
        {
            path: "/m-coin-detail",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - M Coin" component={McoinviewmorePage} />
        },
        {
            path: "/asset-portfolio",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Asset Portfolio" component={AssetPortfolio} />
        },
        {
            path: "/my-mcoins",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - My M Coins" component={MCoinList} />
        },
        {
            path: "/my-dividends",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - My Dividends" component={DividendList} />
        },
        {
            path: "/exit-investment-amount",
            exact: true,
            auth: true,
            element: <PrivateRoute title="Meridiem - Exit Investment Amount List" component={ExitInvestmentAmountList} />
        },

        // Public Routing
        {
            path: "/",
            exact: true,
            auth: false,
            element: <LandingPage title="Meridiem Club" />
        },
        {
            path: "login",
            exact: true,
            auth: false,
            element: <PublicRoute title="Meridiem - Login" component={Login} />
        },
        {
            path: "/signup",
            exact: true,
            auth: false,
            element: <PublicRoute title="Meridiem - Sign up" component={Signup} />
        },
        {
            path: "/membership",
            exact: true,
            auth: false,
            element: <PublicRoute title="Meridiem - Buy Membership" component={MembershipForm} />
        },
        {
            path: "/forgot-password",
            exact: true,
            auth: false,
            element: <PublicRoute title="Meridiem - Forgot Password" component={ForgotPassword} />
        },
        {
            path: "/check-email/:token",
            exact: true,
            auth: false,
            element: <PublicRoute title="Check Email" component={CheckEmail} />
        },
        {
            path: "/create-new-password/:token/:id",
            exact: true,
            auth: false,
            element: <PublicRoute title="Meridiem - Reset Password" component={CreateNewPassword} />
        },
        {
            path: "/membership-page",
            exact: true,
            auth: false,
            element: <MembershipPage title="Meridiem - Membership" />
        },
        {
            path: "/payment-response",
            exact: true,
            auth: false,
            element: <PublicRoute title="Meridiem - Payment Successful" component={OpayoResponseHandler} />
        },
        {
            path: "/payment-failure",
            exact: true,
            auth: false,
            element: <PublicRoute title="Meridiem - Payment Failure" component={OpayoFailureHandler} />
        },
        {
            path: "/food-and-beverage",
            exact: true,
            auth: false,
            element: <FoodAndBeverage title="Meridiem - Food And Beverage" />
        },
        {
            path: "/mcoin",
            exact: true,
            auth: false,
            element: <Mcoin title="Meridiem - Mcoin" />
        },
        {
            path: "/holborn",
            exact: true,
            auth: false,
            element: <Holborn title="Meridiem - Holborn" />
        },
        {
            path: "/farringdon",
            exact: true,
            auth: false,
            element: <Farringdon title="Meridiem - Farringdon" />
        },
        {
            path: "/enquire",
            exact: true,
            auth: false,
            element: <Enquire title="Meridiem - Enquire" />
        },
        {
            path: "/terms-and-conditions",
            exact: true,
            auth: false,
            element: <TermsAndConditions title="Meridiem - Terms And Conditions" />
        },
        {
            path: "/privacy-policy",
            exact: true,
            auth: false,
            element: <PrivacyPolicy title="Meridiem - Privacy Policy" />
        },
        {
            path: "/faq",
            exact: true,
            auth: "both",
            element: <Faq1 title="Meridiem - FAQ" />
        },
        {
            path: "/cancel-reservation/:id",
            exact: true,
            auth: false,
            element: <ReservationCancellation title="Meridiem - Cancel Reservation" />
        },
        {
            path: "*",
            exact: true,
            auth: false,
            element: <ErrorPage title="Meridiem - Page Not Found" />
        },
        {
            path: "/admin",
            children: [
                {
                    path: "auth",
                    element: <AdminAuth />,
                    children: [
                        ...publicRoutes
                    ]
                },
                {
                    path: "",
                    element: <AdminMaster />,
                    children: [
                        ...privateRoutes
                    ]
                },
                {
                    path: "/admin/*",
                    exact: true,
                    auth: false,
                    element: <ErrorPageAdmin title="Meridiem - Page Not Found" />
                }
            ]
        }
    ]
}

export default routes;