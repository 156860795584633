import React, { useEffect } from "react";
import {
    Container,
    Nav,
    Navbar,
    NavDropdown
} from "react-bootstrap";
import Logo from "../assets/images/logo.png";
import logoMobile from "../assets/images/logoMobile.png";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function Header() {

    const isLogin = localStorage.getItem("accessToken") || false;

    return (
        <>
            <Navbar bg="" expand="lg" className="navbarWebsite" fixed="top">
                <Container>
                    <Navbar.Brand className="logoAbs">
                        <NavLink to={"/"}>
                            <img src={Logo} className="d-none d-md-block" alt="logo" />
                            <img src={logoMobile} className="d-block d-md-none" alt="logoMobile" />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="menu">
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                    </Navbar.Toggle>

                    {/* Desktop Menu */}
                    <Navbar.Collapse id="basic-navbar-nav" className="w-100 d-none d-lg-block">
                        <Nav className="w-100">
                            <NavDropdown title="LOCATIONS" id="basic-nav-dropdown">
                                <NavLink className={'dropdown-item'} to={'/holborn'} >HOLBORN</NavLink>
                                <NavLink className={'dropdown-item'} to={'/farringdon'} >FARRINGDON</NavLink>
                            </NavDropdown>
                            <div className="ms-0 ms-md-auto d-block d-md-flex mobileNavFull">
                                <NavDropdown title="Membership" id="basic-nav-dropdown">
                                    <NavLink className={'dropdown-item'} to={'/membership-page'} >ABOUT</NavLink>
                                    <NavLink className={'dropdown-item'} to={'/signup'} >Become a member</NavLink>
                                    {/* <NavLink className={'dropdown-item'} to={'/food-and-beverage'} >FOOD & BEVERAGE</NavLink> */}
                                    <NavLink className={'dropdown-item'} to={'/mcoin'} >MCOIN</NavLink>
                                </NavDropdown>
                                <NavLink className={'nav-link'} to={'/enquire'} >ENQUIRE</NavLink>
                                {
                                    !isLogin ?  
                                        (<NavLink className={'nav-link'} to={'/login'} >Sign in</NavLink>)
                                        :
                                        <NavLink className={'nav-link'} to={'/update-profile'} >Profile</NavLink>
                                }
                                
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    

                    {/* Mobile Menu */}
                    <div className="w-100 d-block d-lg-none">
                        <Navbar.Collapse id="basic-navbar-nav" className="">
                            <Nav className="w-100">
                                <NavLink className={'nav-link'} to={'/'} >Home</NavLink>
                                <NavLink className={'nav-link'} to={'/login'} >Sign in</NavLink>
                                <NavDropdown title="Membership" id="basic-nav-dropdown">
                                    <NavLink className={'dropdown-item'} to={'/membership-page'} >ABOUT</NavLink>
                                    <NavLink className={'dropdown-item'} to={'/signup'} >Become a member</NavLink>
                                    {/* <NavLink className={'dropdown-item'} to={'/food-and-beverage'} >FOOD & BEVERAGE</NavLink> */}
                                    <NavLink className={'dropdown-item'} to={'/mcoin'} >MCOIN</NavLink>
                                </NavDropdown>
                                <NavDropdown title="LOCATIONS" id="basic-nav-dropdown">
                                    <NavLink className={'dropdown-item'} to={'/holborn'} >HOLBORN</NavLink>
                                    <NavLink className={'dropdown-item'} to={'/farringdon'} >FARRINGDON</NavLink>
                                </NavDropdown>
                                <NavLink className={'nav-link'} to={'/enquire'} >ENQUIRE</NavLink>
                                <NavLink className={'nav-link'} to={'/privacy-policy'} >Privacy policy</NavLink>
                                <NavLink className={'nav-link'} to={'/terms-and-conditions'} >Terms & conditions</NavLink>
                                    
                            </Nav>
                        </Navbar.Collapse>
                    </div>

                </Container>
            </Navbar>
        </>
    );
}

export default Header;
