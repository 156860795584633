import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import icShares from "../../../assets/images/icShares.png";
import { Link } from "react-router-dom";
import AxiosInstance from "../../../helpers/AxiosRequest";
import { customErrorMessage } from "../../../helpers/response";
import { Layout, Breadcrumb, Badge } from "antd";
import CommonPagination from "../../common/CommonPagination";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleNValue } from "../../../helpers/handleNValue";

const exitInvestmentStatusClass = {
  Approved: "statusSuccess",
  Rejected: "statusReject",
  Exit: "BadgeclassVoting",
  Voting: "BadgeclassVoting",
  Live: "BadgeclassVoting",
};

const ExitinvestmentlistPage = () => {
  const { Content } = Layout;

  const [list, setList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const initFilter = { invetment_type: "", page: 1, limit: 10, search: "" };
  const [filter, setFilter] = useState(initFilter);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const totalPage = useMemo(() => {
    return Math.ceil(totalDataCount / filter.limit || 1);
  }, [totalDataCount, filter]);
  const [search, setSearch] = useState("");
  const timeoutRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post("/admin/exitinvestment/list", {
        page: filter.page,
        limit: filter.limit,
      });
      setList(data.data.data);
      setTotalDataCount(data.data.metadata.total);
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    handleList();
  }, [handleList]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(
      () => setFilter((prev) => ({ ...prev, search: value, page: 1 })),
      700
    );
  };

  const handleStatusUpdate = async (id, status) => {
    try {
      setIsLoading(true);
      await AxiosInstance.post("/admin/exitinvestment/update-status", {
        id,
        status,
      });
      handleList();
    } catch (error) {
      customErrorMessage(
        error?.response?.data?.message || error || "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Content className="site-layout-background">
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <Breadcrumb.Item>
            <Link to="/admin/dashboard">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Investment Round</Breadcrumb.Item>
        </Breadcrumb>

        <hr className="lineTheme" />
        <div className="page-card-view">
          <div className="card-header-part mb-2">
            <div className="card-header-action">
              <div className="d-flex justify-content-between align-items-center">
                <h5 class="fw-bold fs-5 mt-2">EXIT INVESTMENT ROUND</h5>
                <div className="form-group mb-0 mr-3">
                  {/* <input
                    type="text"
                    className="form-control rounded-0"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearch}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="table-part">
            <div className="">
              <Table bordered className="">
                <thead className="fontsize">
                  <tr>
                    <th>Company Name</th>
                    <th>Category</th>
                    <th className="text-center">Valuation £</th>
                    <th className="text-center">Equity</th>
                    <th className="text-center">Benefits</th>
                    <th className="text-center">Gross profit PA £</th>
                    <th className="text-center">Dividend yield PA £</th>
                    <th className="text-center">Risk factor</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={12}>
                        <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                          <Spinner size="md" variant="dark" />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length > 0 ? (
                        list.map((data, index) => {
                          return (
                            <tr>
                              <td>
                                {data?.opportunities?.company_name}
                                <br />
                                {/* <span>Proposal created by {data.user.name}</span> */}
                              </td>
                              <td>
                                <div className="d-flex justify-content-start align-items-center">
                                  <span className="sharesSymbol me-1">
                                    <img src={icShares} alt="Shares" />
                                  </span>
                                  <span>{data?.category_name}</span>
                                </div>
                              </td>
                              <td className="text-center">XXX</td>
                              <td className="text-center">XXX</td>
                              <td className="text-center">XXX</td>
                              <td className="text-center">XXX</td>
                              <td className="text-center">XXX</td>
                              <td className="text-center">XXX</td>
                              <td>
                                <span>{data.reason}</span>
                              </td>
                              <td>
                                <>
                                  {/* {data.status === "Approved" ? (
                                    <Badge bg="" className="statusSuccess">
                                      Approved
                                    </Badge>
                                  ) : data.status === "Rejected" ? (
                                    <Badge bg="" className="statusReject">
                                      Rejected
                                    </Badge>
                                  ) : (
                                    <>{data.status}</>
                                  )} */}
                                  <span className="ms-2">
                                    <Badge
                                      className={`${
                                        exitInvestmentStatusClass[data?.status]
                                      }`}
                                      color=""
                                    >
                                      {data?.status}
                                    </Badge>
                                  </span>
                                </>
                              </td>
                              <td>
                                {data.status === "Pending" ? (
                                  <UncontrolledDropdown group>
                                    <Button color="">Please Select</Button>
                                    <DropdownToggle caret color="" />
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          handleStatusUpdate(data._id, "Live");
                                        }}
                                      >
                                        Live
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          handleStatusUpdate(
                                            data._id,
                                            "Rejected"
                                          );
                                        }}
                                      >
                                        Reject
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                ) : data.status === "Approved" ? (
                                  <button
                                    // className="statusReject"
                                    onClick={() => {
                                      setSelectedRow(data);
                                      setShowModal(true);
                                    }}
                                  >
                                    Exit
                                  </button>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td colSpan={12}>
                          <div className="d-flex align-items-center justify-content-center py-5 h-100 w-100">
                            No data found!
                          </div>
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="PaginationLink">
          <CommonPagination
            filter={filter}
            setFilter={setFilter}
            totalPage={totalPage}
          />
        </div>
      </Content>
      {showModal && (
        <ApproveExitInvestment
          showModal={showModal}
          setShowModal={setShowModal}
          selectedRow={selectedRow}
          handleList={handleList}
        />
      )}
    </Fragment>
  );
};

const ApproveExitInvestment = ({
  showModal,
  setShowModal,
  selectedRow,
  handleList,
}) => {
  const schema = Yup.object({
    amount: Yup.number()
      .min(1, "Amount must be greater than 0")
      .nullable()
      .required("This field is required."),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const { data } = await AxiosInstance.post(
          `/admin/exitinvestment/exit-investment`,
          {
            amount: Number(values.amount),
            id: selectedRow.investment_id,
            exit_investmemt_id: selectedRow._id,
          }
        );
        handleList();
        toast.success(
          data?.message || "ExitInvestment amount added successfully!"
        );
        resetForm();
        setShowModal(false);
      } catch (error) {
        customErrorMessage(
          error?.response?.data?.message || error || "Something went wrong!"
        );
        console.log("Error making payment:", error);
      }
    },
  });
  return (
    <Modal
      isOpen={showModal}
      toggle={() => setShowModal(false)}
      size="mg"
      centered
    >
      <ModalHeader toggle={() => setShowModal(false)}>
        <h5 className="fw-bold fs-5">ADD EXITINVESTMENT AMOUNTS</h5>{" "}
      </ModalHeader>
      <ModalBody>
        <div class="col-sm-12 mb-2">
          <label>
            ExitInvestment Amount<span className="fontred">*</span>
          </label>
          <input
            type="text"
            class="form-control rounded-0 position-relative"
            placeholder=""
            name="amount"
            onChange={(e) => formik.setFieldValue("amount", handleNValue(e))}
            value={formik.values.amount}
            onBlur={formik.handleBlur}
          />
          {formik.errors.amount && formik.touched.amount ? (
            <Form.Text className="text-danger">
              {formik.errors.amount}
            </Form.Text>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={formik.isSubmitting}
          className="my-reset-button ms-2"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </Button>
        <Button
          type="button"
          disabled={formik.isSubmitting}
          className="my-submit-button"
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? "Please wait..." : "Submit"}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ExitinvestmentlistPage;
