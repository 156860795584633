import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Form, Modal } from "react-bootstrap";
import bitcoin from "../../assets/images/bitcoinIcon.png";
import property from "../../assets/images/icProperty.png";
import Commodity from "../../assets/images/icCommodity.png";
import Bonds from "../../assets/images/icBonds.png";
import Shares from "../../assets/images/icShares.png";
import chart from "../../assets/images/chart.png";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AxiosInstance from "../../helpers/AxiosRequest";

const AssetPortfolio = () => {
  const [Rebalance, setRebalance] = useState(false);
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState();
  const [investmentList, setInvestmentList] = useState([]);

  useEffect(() => {
    getInvestmentByCategory();
  }, []);

  const getInvestmentByCategory = async () => {
    try {
      const { data } = await AxiosInstance.post("/investment/getcategorylist");
      setTotalInvestmentAmount(data.data.totalInvestmentAmount);
      setInvestmentList(data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="mt-4 mb-4 page_main assetPortfolioPage">
        <Container fluid>
          <Row>
            <Col md={12} lg={12}>
              <div className="divBg_white">
                <h4>Asset Portfolio</h4>
                <div className="row">
                  <div className="col-lg-8 col-md-12 order-2 order-md-2 order-lg-1">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="bgLightBlue">
                          <Row className="d-flex justify-content-between align-items-center px-4 py-3 lineOne">
                            <Col
                              xl={"auto"}
                              lg={4}
                              md={6}
                              sm={12}
                              className="p-0"
                            >
                              <div className="text-center item itemMob mb-3 mb-xl-0">
                                <span className="title2 d-block mb-1">
                                  Liquidity Fund
                                </span>
                                <span className="content">£10,00,000</span>
                              </div>
                            </Col>
                            <Col
                              xl={"auto"}
                              lg={4}
                              md={6}
                              sm={12}
                              className="p-0"
                            >
                              <div className="text-center item itemMob mb-3 mb-xl-0">
                                <span className="title2 d-block mb-1">
                                  Invested Fund
                                </span>
                                <span className="content">£{totalInvestmentAmount}</span>
                              </div>
                            </Col>
                            <Col
                              xl={"auto"}
                              lg={4}
                              md={6}
                              sm={12}
                              className="p-0"
                            >
                              <div className="text-center item itemMob mb-3 mb-xl-0">
                                <span className="title2 d-block mb-1">
                                  Liquidity Reserve
                                </span>
                                <span className="content">£7,00,000</span>
                              </div>
                            </Col>
                            <Col
                              xl={"auto"}
                              lg={4}
                              md={6}
                              sm={12}
                              className="p-0"
                            >
                              <div className="text-center item itemMob mb-3 mb-xl-0">
                                <span className="title2 d-block mb-1">ROI</span>
                                <span className="content text-success">
                                  +£60,000 (+20%)
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {/* ------ */}
                        <div className="assetPortfolioTable">
                          <div className="table-responsive">
                            <Table className="mb-0 border border-1">
                              <thead>
                                <tr>
                                  <th>Inv. Title</th>
                                  <th className="text-start">Inv. Category</th>
                                  <th>Inv. Amount</th>
                                  <th>Inv. %</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {investmentList.length > 0 && (
                                  <>
                                    {investmentList.map((data, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{data.investment_Title}</td>
                                          <td>
                                            <div className="d-flex justify-content-start align-items-center">
                                              {/* <img
                                                src={bitcoin}
                                                alt="bitcoin"
                                                className="me-3"
                                              /> */}
                                              <span>{data._id}</span>
                                            </div>
                                          </td>
                                          <td>£{data.investment_Amount}</td>
                                          <td>{(data.investment_Amount / totalInvestmentAmount) * 100}%</td>
                                          <td>
                                            <button
                                              type="button"
                                              class="btn_Orange btnSM"
                                              onClick={() => setRebalance(true)}
                                            >
                                              Rebalance
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                          {/* ---------- */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 text-center mb-4 order-1 order-md-1 order-lg-2">
                    <img src={chart} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal
          show={Rebalance}
          onHide={() => setRebalance(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="claimModal"
        >
          <Modal.Header className="p-0 pt-1 mb-2" closeButton>
            <div className="py-2 ps-4">
              <Modal.Title id="contained-modal-title-vcenter">
                Rebalance
              </Modal.Title>
            </div>
          </Modal.Header>
          <Form className="loginForm claimForm">
            <Modal.Body>
              <div className="col-7 mx-auto">
                <Form.Group
                  className="mb-4 inputOuter_"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="defLabel_">
                    Vote Start Date <span className="requireStar">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    className="inputs defInput_"
                  />
                </Form.Group>
              </div>
              <div className="col-7 mx-auto">
                <Form.Group
                  className="mb-3 inputOuter_"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="defLabel_">
                    Vote End Date <span className="requireStar">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    className="inputs defInput_"
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer className="">
              <button
                onClick={() => setRebalance(false)}
                type="button"
                className="btn_White"
              >
                Cancel
              </button>
              <button type="submit" className="btn_Black">
                Submit
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default AssetPortfolio;
