import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    investmentRounds: {
      data: [],
      metaData: null,
      loading: false,
    },
    opportunityList: {
      data: [],
      metaData: null,
      loading: false,
    },
    mCoinList: {
      data: [],
      metaData: null,
      loading: false,
    },
    dividendList: {
      data: [],
      metaData: null,
      loading: false,
    },
    distributionHistoryList: {
      data: [],
      metaData: null,
      loading: false,
    },
    dashboardStats: {
      data: null,
      loading: false,
    },
  },
  reducers: {
    adminSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const initialState = adminSlice.getInitialState();

export const { adminSetState } = adminSlice.actions;

export default adminSlice.reducer;
